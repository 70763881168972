import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import DialogTitle from '@components/_mui/DialogTitle'

import Mask from '@shared/components/src/Mask'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { MedicalRecordStatus, snakeToTitleCase } from '@shared/utils'

import { useROIMedicalRecordFax } from './FaxRoiMedicalRecord.hooks'

export default function FaxRoiMedicalRecord({ patientId, data, open = false, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <Content patientId={patientId} data={data} onClose={onClose} />
    </Dialog>
  )
}

function Content({ patientId, data, onClose }) {
  const roiMedicalRecordsEntities = useLookup(Lookup.RoiMedicalRecordsEntities)

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        fax: Yup.string().min(10, 'Please enter valid fax number').max(10, 'Please enter valid fax number'),
      })
    ),
    defaultValues: {
      fax: data?.faxNumber || '',
    },
  })

  const faxROI = useROIMedicalRecordFax(patientId, data?.id)

  const onSubmit = ({ fax }) => {
    return faxROI.mutateAsync({ fax_number: fax }).then((data) => {
      onClose()
      if (data.status === MedicalRecordStatus.FaxFailed) {
        toast.error('Failed to fax ROI Medical Record')
      } else {
        toast.success('ROI Medical Record fax queued successfully')
      }
    })
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Fax ROI Medical Record</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            <DateRangePicker readOnly defaultValue={[dayjs(data?.startDate), dayjs(data?.endDate)]} />
            <FormControl>
              <FormLabel id="entities-checkbox">Records of</FormLabel>
              <FormGroup row sx={{ gap: 1 }}>
                {roiMedicalRecordsEntities.map((value) => {
                  const checked = data?.selection.includes(value)
                  return <FormControlLabel key={value} label={snakeToTitleCase(value)} control={<Checkbox checked={checked} />} />
                })}
              </FormGroup>
            </FormControl>
            <Controller
              name="fax"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Fax Number (optional)"
                  autoComplete="off"
                  disabled={isSubmitting}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={{ maxWidth: 200 }}
                  slotProps={{
                    input: { inputComponent: Mask.Phone },
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" disabled={isSubmitting} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" loading={isSubmitting}>
            Send
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  )
}
