import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import DialogTitle from '@components/_mui/DialogTitle'

import Mask from '@shared/components/src/Mask'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { snakeToTitleCase } from '@shared/utils'

import { dateRangeShortcuts } from '@utils/DatesHelper'

import { useROIPreview, useROISubmit } from './CreateRoiMedicalRecord.hooks'
import { getInitialValues, toAPIData, validationSchema } from './CreateRoiMedicalRecord.utils'

export default function CreateRoiMedicalRecord({ patientId, task, open = false, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
      <Content patientId={patientId} task={task} onClose={onClose} />
    </Dialog>
  )
}

function Content({ patientId, task, onClose }) {
  const roiMedicalRecordsEntities = useLookup(Lookup.RoiMedicalRecordsEntities)

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialValues(roiMedicalRecordsEntities),
  })

  const submitROI = useROISubmit(patientId)
  const previewROI = useROIPreview(patientId)

  const onPreview = () => {
    const data = toAPIData(form.getValues())
    return previewROI.mutateAsync(data)
  }

  const onSubmit = (values) => {
    const data = toAPIData(values)
    return submitROI.mutateAsync({ ...data, task_id: task.id }).then(() => {
      toast.success('ROI Medical Record created successfully')
      onClose()
    })
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Create ROI Medical Record</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            <Alert severity="info" variant="border">
              Once the Medical Record is created, it will be stored in the patient’s documents and will be available for download at any
              time.
              <br />
              If you want the Medical Record to be sent immediately after creation, please provide the fax number.
            </Alert>
            <DateRangePicker
              disabled={isSubmitting}
              disableFuture
              value={[form.watch('start'), form.watch('end')]}
              onChange={(value) => {
                form.setValue('start', value[0])
                form.setValue('end', value[1])
                form.trigger()
              }}
              slotProps={{
                shortcuts: { items: dateRangeShortcuts },
                textField: ({ position }) => {
                  const error = form.formState.errors[position]
                  return {
                    InputLabelProps: { shrink: true },
                    error: Boolean(error),
                    autoComplete: 'off',
                    helperText: error?.message,
                  }
                },
              }}
            />
            <Controller
              name="entities"
              render={({ field, fieldState }) => (
                <FormControl error={Boolean(fieldState.error)}>
                  <FormLabel id="entities-checkbox">Records of</FormLabel>
                  <FormGroup row sx={{ gap: 1 }}>
                    {roiMedicalRecordsEntities.map((value) => {
                      const checked = field.value.includes(value)
                      return (
                        <FormControlLabel
                          key={value}
                          label={snakeToTitleCase(value)}
                          disabled={isSubmitting}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={() => field.onChange(checked ? field.value.filter((v) => v !== value) : [...field.value, value])}
                            />
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              )}
            />
            <Controller
              name="fax"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Fax Number (optional)"
                  autoComplete="off"
                  disabled={isSubmitting}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={{ maxWidth: 200 }}
                  slotProps={{
                    input: { inputComponent: Mask.Phone },
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" disabled={isSubmitting} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" disabled={isSubmitting || !form.formState.isValid || previewROI.isPending} onClick={onPreview}>
            Preview
          </Button>
          <Button type="submit" variant="contained" loading={isSubmitting}>
            Create
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  )
}
