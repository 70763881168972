import { useState } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'

import { handleError, toBase64 } from '@shared/utils'

import { AttachmentIcon, MoreOutlinedIcon } from '@icons'
import File from '@components/File'
import FileUploader from '@components/FileUploader'
import InputControl from '@components/InputControl'
import RenderControl from '@components/RenderControl'

import { useDocumentAttach, useDocuments, useDocumentUpload } from './AttachFile.hooks'

export function AttachFileButton({ task, disabled, icon = null }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        id="attach-file"
        disabled={disabled}
        shape="rounded"
        size="small"
        color="secondary"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        {icon || <AttachmentIcon />}
      </IconButton>
      <AttachFileDialog task={task} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export function AttachFileDialog({ task, open, onClose }) {
  const [tab, setTab] = useState('upload')

  const Content = tab === 'upload' ? UploadForm : AttachForm

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="paper">
      <DialogTitle onClose={onClose}>Attach a File</DialogTitle>
      <Content task={task} onClose={onClose} onSwitch={() => setTab((t) => (t === 'upload' ? 'attach' : 'upload'))} />
    </Dialog>
  )
}

function UploadForm({ task, onClose, onSwitch }) {
  const upload = useDocumentUpload(task.patient.id, task.id)

  const formik = useFormik({
    initialValues: {
      fileName: '',
      files: [],
    },
    validationSchema: Yup.object().shape({
      fileName: Yup.string().max(32, 'File name must be less than 32 characters long'),
      files: Yup.array().min(1, 'File is required').of(Yup.mixed()).required('File is required'),
    }),
    onSubmit: async ({ fileName, files }) => {
      const blob = files[0]
      const base64File = await toBase64(blob)
      const file = {
        name: fileName,
        content: base64File.split(',')[1],
        mimetype: blob.type,
        filename: blob.name,
        permission: 'admin',
        documenttype: 'Other',
      }

      return upload
        .mutateAsync(file)
        .then(() => {
          onClose()
          toast.success('File was uploaded')
        })
        .catch((error) => handleError(error, { showResponse: true }))
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogContent dividers>
        <Stack spacing={3}>
          <ButtonGroup fullWidth>
            <Button variant="contained">Upload File</Button>
            <Button variant="outlined" onClick={onSwitch}>
              Patient Documents
            </Button>
          </ButtonGroup>
          <InputControl field="fileName" formikProps={formik}>
            <TextField fullWidth placeholder="example.pdf" slotProps={{ htmlInput: { maxLength: 32 } }} />
          </InputControl>
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <FileUploader
              files={formik.values.files}
              onChange={(files) => formik.setFieldValue('files', files)}
              error={Boolean(formik.touched.files && formik.errors.files)}
              allowedDescription="Allowed *.png, *.jpeg, *.jpg, *.gif, *.pdf"
              dropzoneOptions={{
                accept: {
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': ['.png'],
                  'image/gif': ['.gif'],
                  'application/pdf': ['.pdf'],
                },
              }}
            />
            {formik.touched.files && formik.errors.files && <FormHelperText error>{formik.errors.files}</FormHelperText>}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" disabled={formik.isSubmitting} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" disabled={!formik.dirty} loading={formik.isSubmitting}>
          Upload
        </Button>
      </DialogActions>
    </form>
  )
}

function AttachForm({ task, onClose, onSwitch }) {
  const [selected, setSelected] = useState(null)

  const attach = useDocumentAttach(task.id)
  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useDocuments(task.patient.id)

  const handleAttach = () => {
    if (!selected) return
    attach.mutateAsync({ file_id: selected.id, file_type: 'user' }).then(() => {
      toast.success('File was attached')
      onClose()
    })
  }

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={3}>
          <ButtonGroup fullWidth>
            <Button variant="outlined" onClick={onSwitch}>
              Upload File
            </Button>
            <Button variant="contained">Patient Documents</Button>
          </ButtonGroup>
          <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="Patient does not have any existing documents">
            <Stack spacing={2}>
              <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'space-around' }}>
                {data?.map((file) => (
                  <File
                    key={file.id}
                    data={file}
                    selected={file.id === selected?.id}
                    onClick={() => setSelected((s) => (s?.id === file.id ? null : file))}
                  />
                ))}
              </Stack>
              {hasNextPage && (
                <Button
                  fullWidth
                  loading={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                  endIcon={<MoreOutlinedIcon rotate={90} />}
                  loadingPosition="end"
                  sx={{ textTransform: 'none' }}
                >
                  more
                </Button>
              )}
            </Stack>
          </RenderControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" disabled={attach.isPending}>
          Cancel
        </Button>
        <Button loading={attach.isPending} onClick={handleAttach} variant="contained" disabled={!selected}>
          Attach
        </Button>
      </DialogActions>
    </>
  )
}
