import Button from '@mui/material/Button'

import { MessageOutlinedIcon, MoreOutlinedIcon } from '@icons'
import Section from '@components/Details/Section'
import TimelineNotes, { TimelineNoteCreation } from '@components/TimelineNotes'

import { usePrescriptionNoteCreation, usePrescriptionNotes } from '../Prescription.hooks'

export default function NotesSection({ prescriptionId, readOnly, mini = false, onCreate }) {
  const create = usePrescriptionNoteCreation(prescriptionId, onCreate)
  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = usePrescriptionNotes(prescriptionId)

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Section mini={mini} title="Notes" icon={mini ? null : <MessageOutlinedIcon />}>
      {!readOnly && (
        <TimelineNoteCreation draftKey={`prescription-${prescriptionId}-note`} onSend={(message) => create.mutateAsync(message)} />
      )}
      {showLoading && <TimelineNotes.Loading />}
      {showEmpty && <TimelineNotes.Empty readOnly={readOnly} />}
      {showData && (
        <TimelineNotes>
          {data.map((note, i) => {
            return <TimelineNotes.Item key={note.id} data={note} isLast={data.length - 1 === i} />
          })}
          {hasNextPage && (
            <Button
              fullWidth
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              endIcon={<MoreOutlinedIcon rotate={90} />}
              loadingPosition="end"
              sx={{ textTransform: 'none' }}
            >
              more
            </Button>
          )}
        </TimelineNotes>
      )}
    </Section>
  )
}
