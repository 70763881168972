import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { handleError } from '@shared/utils'

import { PrescriptionDetailsModal } from '@pages/Prescriptions/Prescription'
import InputControl from '@components/InputControl'

import { useTasksFilters } from '../../Tasks.hooks'
import { useMarkCompleted, useTaskNoteCreation } from './TaskInfo.hooks'
import { isNotDone, Patient } from './TaskInfo.utils'

export default function RxClarificationInfo({ data, patient }) {
  const [resolveOpen, setResolveOpen] = useState(false)
  const [detailsOpen, setDetailsOpen] = useState(false)

  const notDone = isNotDone(data)

  return (
    <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
      <Button variant="outlined" color="secondary" onClick={() => setDetailsOpen(true)}>
        Prescription Details
      </Button>
      {notDone && (
        <Button variant="contained" color="primary" onClick={() => setResolveOpen(true)}>
          Resolve
        </Button>
      )}
      {notDone && (
        <Dialog open={resolveOpen} onClose={() => setResolveOpen(false)} fullWidth maxWidth="sm">
          <ClarifyPrescriptionContent task={data} patient={patient} onClose={() => setResolveOpen(false)} />
        </Dialog>
      )}
      <PrescriptionDetailsModal prescriptionId={data.relatedModelId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
    </Stack>
  )
}

function ClarifyPrescriptionContent({ task, patient, onClose }) {
  const [, updateFilters] = useTasksFilters()

  const createNote = useTaskNoteCreation(task.id)
  const markCompleted = useMarkCompleted(task.id)

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object({
      message: Yup.string().trim().required('Message is required'),
    }),
    onSubmit: async ({ message }) => {
      try {
        await createNote.mutateAsync(message)
        await markCompleted.mutateAsync()
        updateFilters({ task: undefined })
      } catch (e) {
        handleError(e)
      }
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Prescription Clarification Needed</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Typography variant="h4">Provide updated prescription details/instructions</Typography>
          <InputControl hideLabel field="message" formikProps={formik}>
            <TextField required fullWidth multiline rows={6} placeholder="Write something..." disabled={formik.isSubmitting} />
          </InputControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} type="submit" variant="contained">
          Resolve
        </Button>
      </DialogActions>
    </form>
  )
}
