import { useMemo, useRef, useState } from 'react'
import range from 'lodash/range'
import PropTypes from 'prop-types'

import AvatarMui from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import Avatar from '@components/Avatar'

import styles from './LimitedUserPicker.styles'

const LIMIT = 6

LimitedUserPicker.propTypes = {
  // Can be used to show loading state
  loading: PropTypes.bool,

  // List of users
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      nickname: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      fullName: PropTypes.string,
    })
  ),

  // Ids of selected users
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  // Called when selection changes
  onChange: PropTypes.func.isRequired,
}

export default function LimitedUserPicker({ loading = false, users = [], value = [], onChange }) {
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const selectedMap = useMemo(() => {
    return value.reduce((acc, id) => ({ ...acc, [id]: true }), {})
  }, [value])

  const handleToggle = (userId) => {
    onChange(selectedMap[userId] ? value.filter((id) => id !== userId) : [...value, userId])
  }

  const [show, hide] = useMemo(() => {
    const copy = [...users]
    return [copy.slice(0, LIMIT).reverse(), copy.slice(LIMIT, users.length)]
  }, [users])

  const hiddenIsSelected = useMemo(() => {
    return hide.some((user) => selectedMap[user.id])
  }, [hide, selectedMap])

  if (loading) return <Loading />

  return (
    <Box sx={styles.container}>
      {hide.length > 0 && (
        <AvatarMui
          variant="rounded"
          onClick={() => setOpenMenu(true)}
          ref={anchorRef}
          sx={{ ...styles.selectButton, ...(hiddenIsSelected ? styles.selected : {}) }}
        >
          {`+${hide.length}`}
        </AvatarMui>
      )}
      {show.map((user) => (
        <Avatar
          key={user.id}
          variant="rounded"
          hover="simple"
          user={user}
          onClick={() => handleToggle(user.id)}
          sx={{ ...styles.item, ...(selectedMap[user.id] ? styles.selected : {}) }}
        />
      ))}
      <Menu anchorEl={anchorRef.current} open={openMenu} onClose={() => setOpenMenu(false)}>
        {hide.map((user) => {
          const isSelected = selectedMap[user.id]

          return (
            <MenuItem key={user.id} selected={isSelected}>
              <FormControlLabel
                control={<Checkbox onChange={() => handleToggle(user.id)} checked={isSelected} value={isSelected} />}
                label={
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center', ml: 1 }}>
                    <Avatar size="xs" user={user} />
                    <Typography>{user.fullName}</Typography>
                  </Stack>
                }
                sx={{ flexGrow: 1, mr: 0 }}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

function Loading() {
  return (
    <Box sx={styles.container}>
      {range(0, LIMIT + 1).map((i) => (
        <Skeleton key={i} variant="rounded" width={40} height={40} sx={styles.item} />
      ))}
    </Box>
  )
}
