import { useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router'

import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@components/_mui/IconButton'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import usePopover from '@shared/hooks/src/usePopover'
import { getThreadName, useThreadUsers } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole, userRoleToLabel } from '@shared/utils'

import {
  CloseOutlinedIcon,
  LockOutlinedIcon,
  LogoutOutlinedIcon,
  MoreHorizontalIcon,
  PushpinOutlinedIcon,
  UnlockOutlinedIcon,
  UserSwitchOutlinedIcon,
} from '@icons'
import Avatar from '@components/Avatar'
import Confirmation from '@components/Dialog/Confirmation'

import { MessageAuthor } from '../Message'
import ThreadUsersEditDialog from '../ThreadUsersEditDialog'
import { useArchiveToggle, usePinToggle, useThreadUserAdd, useThreadUserRemove } from './ThreadHeader.hooks'

const styles = {
  container: {
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    py: 1,
    px: 2,
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
  avatars: {
    '& .MuiAvatar-root': {
      fontSize: '0.75rem',
      height: '24px',
      width: '24px',
      ml: '-16px',
      '&:nth-of-type(2)': { mt: '12px' },
    },
  },
}

export default function ThreadHeader({ thread, user, onClose }) {
  if (thread) return <Header thread={thread} onClose={onClose} />
  if (user) return <UserHeader user={user} onClose={onClose} />
  return <Loading onClose={onClose} />
}

function UserHeader({ user, onClose }) {
  return (
    <Stack direction="row" spacing={2} sx={styles.container}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', minWidth: 0 }}>
        <AvatarGroup max={2}>
          <Avatar key={user.id} user={user} variant="circular" />
        </AvatarGroup>
        <Box sx={{ minWidth: 0 }}>
          <Typography noWrap>{user.fullName}</Typography>
          <ThreadLabel users={[user]} />
        </Box>
      </Stack>
      {onClose && (
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
    </Stack>
  )
}

function Header({ thread, onClose }) {
  const [editOpen, setEditOpen] = useState(false)

  const togglePin = usePinToggle(thread.id)
  const toggleArchive = useArchiveToggle(thread.id)
  const removeUser = useThreadUserRemove(thread.id)
  const addUser = useThreadUserAdd(thread.id)

  const users = useThreadUsers(thread)
  const name = getThreadName(users)
  const isGroup = users.length > 1
  const isPrivate = users.length === 1

  return (
    <>
      <ThreadUsersEditDialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        thread={thread}
        onAdd={(userId) => addUser.mutateAsync({ user_id: userId })}
        onRemove={(userId) => removeUser.mutateAsync(userId)}
      />
      <Stack direction="row" spacing={2} sx={styles.container}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', minWidth: 0 }}>
          {isGroup && (
            <AvatarGroup max={2} sx={styles.avatars}>
              {users.map((user) => (
                <Avatar key={user.id} user={user} variant="circular" />
              ))}
            </AvatarGroup>
          )}
          {isPrivate && <MessageAuthor hover="card" user={users[0]} />}
          <Box sx={{ minWidth: 0 }}>
            <Typography noWrap>{name}</Typography>
            <ThreadLabel users={users} />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <MenuButton
            thread={thread}
            onTogglePin={togglePin.mutateAsync}
            onToggleArchive={toggleArchive.mutateAsync}
            onEdit={() => setEditOpen(true)}
            onLeave={(myId) => removeUser.mutateAsync(myId).then(onClose)}
          />
          {onClose && (
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </>
  )
}

function Loading({ onClose }) {
  return (
    <Stack direction="row" spacing={2} sx={styles.container}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', minWidth: 0 }}>
        <AvatarGroup max={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </AvatarGroup>
        <Box sx={{ minWidth: 0 }}>
          <Typography>
            <Skeleton width={200} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={100} />
          </Typography>
        </Box>
      </Stack>
      {onClose && (
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
    </Stack>
  )
}

function MenuButton({ thread, onTogglePin, onToggleArchive, onEdit, onLeave }) {
  const me = useMe()
  const popover = usePopover()

  const isPinned = thread.pinned
  const isArchived = thread.archived
  const isCurrentUserParticipant = thread.users?.some((u) => u.id === me.id)
  const canLeave = isCurrentUserParticipant && thread.users?.length > 2

  const confirmLeave = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      maxWidth: 'xs',
      title: 'Leave Conversation',
      description: 'Are you sure you want to leave this conversation?',
      rejectLabel: 'Cancel',
      confirmLabel: 'Confirm',
      onReject: () => close(),
      onConfirm: () => onLeave(me.id).then(() => close()),
    }),
  })

  return (
    <>
      <Tooltip title="More options">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <MoreHorizontalIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open}>
        {isCurrentUserParticipant && (
          <MenuItem
            onClick={() => {
              popover.handleClose()
              onTogglePin(!isPinned)
            }}
          >
            <ListItemIcon>
              <PushpinOutlinedIcon />
            </ListItemIcon>
            <Typography>{isPinned ? 'Unpin' : 'Pin'}</Typography>
          </MenuItem>
        )}
        {me.superAdmin && (
          <MenuItem
            onClick={() => {
              popover.handleClose()
              onToggleArchive(!isArchived)
            }}
          >
            <ListItemIcon>{isArchived ? <UnlockOutlinedIcon /> : <LockOutlinedIcon />}</ListItemIcon>
            <Typography>{isArchived ? 'Unarchive' : 'Archive'}</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            popover.handleClose()
            onEdit()
          }}
        >
          <ListItemIcon>
            <UserSwitchOutlinedIcon />
          </ListItemIcon>
          <Typography>Edit</Typography>
        </MenuItem>
        {canLeave && <Divider />}
        {canLeave && (
          <MenuItem
            onClick={() => {
              popover.handleClose()
              confirmLeave()
            }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <Typography>Leave</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

function ThreadLabel({ users = [] }) {
  const patient = useMemo(() => {
    return users.find((user) => user.role === UserRole.Patient)
  }, [users])

  if (patient) {
    return (
      <Link component={RouterLink} to={`/app/patients/${patient.id}`} variant="body2" target="_blank" noWrap>
        Click here to open patient profile MRN {patient.id}
      </Link>
    )
  }

  if (users.length === 1) {
    return (
      <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
        {userRoleToLabel[users[0].role]}
      </Typography>
    )
  }

  return (
    <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
      {users.length + 1} Participants
    </Typography>
  )
}
