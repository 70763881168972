import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { useMe } from '@shared/providers/src/MeProvider'
import { handleError, UserRole } from '@shared/utils'

import usePatientAvailableProviders from '@hooks/usePatientAvailableProviders'
import { usePatientEncounterCreate } from '@pages/Encounters/PatientEncounters'
import { PlusOutlinedIcon } from '@icons'

import { IconCircleButton } from '../ProfileTab.utils'
import { usePossibleEncounterTypes } from './Encounters.hooks'

export function CreateEncounterButton({ patient }) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const onClose = () => setAnchorEl(null)
  const canCreateEncounter = !patient?.enrolled || !patient?.active || Boolean(patient?.disabled)

  const createEncounter = usePatientEncounterCreate(patient?.id)

  const handleSubmit = (data) => {
    return createEncounter
      .mutateAsync(data)
      .then((encounter) => {
        console.log('{encounter}', encounter)
        return navigate(`/app/patients/${patient.id}/encounters/${encounter.id}`)
      })
      .catch(handleError)
  }

  return (
    <>
      <IconCircleButton disabled={canCreateEncounter} size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <PlusOutlinedIcon />
      </IconCircleButton>
      <Popover
        id="encounter-creation"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={createEncounter.isPending ? undefined : onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <EncounterForm patient={patient} onCancel={onClose} onSubmit={handleSubmit} />
      </Popover>
    </>
  )
}

function EncounterForm({ patient, onCancel, onSubmit }) {
  const me = useMe()

  const [encounterTypeId, setEncounterTypeId] = useState('')
  const [providerId, setProviderId] = useState(me.providerId || patient.provider?.id || '')

  const meIsAdmin = me.role === UserRole.Admin
  const meIsSupportOrCBO = [UserRole.CBO, UserRole.Support].includes(me.role)
  const noProviderAssigned = !patient.provider
  const showProviderSelector = meIsAdmin || (noProviderAssigned && meIsSupportOrCBO)

  const { data: providers, isFetching: isFetchingProviders } = usePatientAvailableProviders(patient.id, undefined, {
    enabled: showProviderSelector,
  })
  const { data: encounterTypes, isFetching: isFetchingEncounterTypes } = usePossibleEncounterTypes(patient.id, {
    provider_id: providerId,
  })

  const [handleSubmit, isLoading] = usePromiseLoading(() => {
    return onSubmit({ provider_id: providerId, encounter_type_id: encounterTypeId })
  })

  // Reset encounter type if it's not available for the selected provider
  useEffect(() => {
    if (encounterTypes?.some((et) => et.id === encounterTypeId)) return
    setEncounterTypeId('')
  }, [encounterTypeId, encounterTypes])

  return (
    <Paper sx={{ p: 2, width: 400 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Select Encounter settings</Typography>
        {showProviderSelector && (
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Provider"
            value={providerId}
            onChange={(e) => setProviderId(e.target.value)}
          >
            {isFetchingProviders && <MenuItem disabled>Loading...</MenuItem>}
            {providers?.map((provider) => (
              <MenuItem key={provider.id} value={provider.id}>
                {provider.fullName}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Encounter Type"
          value={encounterTypeId}
          onChange={(e) => setEncounterTypeId(e.target.value)}
        >
          {isFetchingEncounterTypes && <MenuItem disabled>Loading...</MenuItem>}
          {encounterTypes?.map((encounterType) => (
            <MenuItem key={encounterType.id} value={encounterType.id}>
              {encounterType.description}
            </MenuItem>
          ))}
        </TextField>
        <Stack direction="row" spacing={1}>
          <Button size="small" fullWidth color="secondary" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={!encounterTypeId || !providerId}
            loading={isLoading}
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}
