import { useLocation, useNavigate } from 'react-router'
import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, mapCache, pageParam, QK } from '@shared/utils'

import { NotificationType } from '@pages/Notifications/Notifications.utils'

const LIMIT = 20

export function useUserNotifications(params = {}, options = {}) {
  const me = useMe()
  const query = { ...params, limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.users.id(me.id).notifications.list(query),
    queryFn: ({ pageParam }) => API.users.id(me.id).notifications.list({ ...query, offset: pageParam * LIMIT }),
    placeholderData: keepPreviousData,
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}

export function useUnreadNotificationsCount() {
  const { data, hasNextPage } = useUserNotifications({ read: false }, { enabled: false })

  const count = data ? `${data.filter((n) => !n.read).length}${hasNextPage ? '+' : ''}` : ''
  if (['0', '0+'].includes(count)) return ''
  return count
}

export function useNotificationUpdate() {
  const me = useMe()
  return useMutation({
    mutationFn: ({ id, read }) => {
      if (read) return API.users.id(me.id).notifications.id(id).mark.read()
      return API.users.id(me.id).notifications.id(id).mark.unread()
    },
    onSuccess: (notification, { id }) => {
      return queryClient.setQueriesData(
        { queryKey: QK.users.id(me.id).notifications.lists },
        mapCache((item) => (item.id === id ? { ...item, ...notification } : item))
      )
    },
  })
}

export function useMarkAllRead() {
  const me = useMe()
  return useMutation({
    mutationFn: () => API.users.id(me.id).notifications.read(),
    onSuccess: () => {
      return queryClient.setQueriesData(
        { queryKey: QK.users.id(me.id).notifications.lists },
        mapCache((item) => ({ ...item, read: true }))
      )
    },
  })
}

export function useNotificationOnClick(notification) {
  const navigate = useNavigate()
  const location = useLocation()

  const currentPath = location.pathname
  const searchParams = new URLSearchParams(location.search)

  const update = useNotificationUpdate()

  const isVisitPage = currentPath.startsWith('/app/visits')

  return () => {
    if (!notification) return

    if (notification.read === false) update.mutate({ id: notification.id, read: true })

    if (notification.type === NotificationType.Message && notification.properties?.patientId) {
      const chatPath = notification.properties?.messageId
        ? `/app/patients/${notification.properties.patientId}/chat?messageId=${notification.properties.messageId}`
        : `/app/patients/${notification.properties.patientId}/chat`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${chatPath}`, '_blank') : navigate(chatPath)
    }

    if (notification.type === NotificationType.ReassignedPatient && notification.properties?.patientId) {
      const patientPath = `/app/patients/${notification.properties.patientId}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${patientPath}`, '_blank') : navigate(patientPath)
    }

    if (notification.type === NotificationType.PatientUpdatedCBO && notification.properties?.patientId) {
      const patientPath = `/app/patients/${notification.properties.patientId}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${patientPath}`, '_blank') : navigate(patientPath)
    }

    if (notification.type === NotificationType.DoseSpot && notification.properties?.url) {
      window.open(notification.properties.url, '_blank')
    }

    if (notification.type === NotificationType.QuestLabQuestionnaire && notification.properties?.id) {
      const labPath = `/app/labs/${notification.properties.id}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${labPath}`, '_blank') : navigate(labPath)
    }

    if (notification.type === NotificationType.Availabilities) {
      const date = dayjs(notification.properties.startTimeTz, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')

      searchParams.set('availability', 'calendar')
      searchParams.set('date', date)

      const calendarPath = `${currentPath}?${searchParams.toString()}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${calendarPath}`, '_blank') : navigate(calendarPath)
    }

    if (notification.type === NotificationType.NewAppointment) {
      const apptPath = `/app/visits/${notification.properties.appointmentId}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${apptPath}`, '_blank') : navigate(apptPath)
    }
  }
}
