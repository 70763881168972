import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useUpdateEffect } from 'usehooks-ts'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { mapCache, QK } from '@shared/utils'

const LIMIT = 20

/**
 * Responsible for loading messages for a given conversation
 * Handles pagination and refetching conversation if received new message
 */
export default function useConversationMessages(conversationId) {
  const me = useMe()
  const meInvalidated = useRef(false)

  // Reset the flag when the conversation changes
  useUpdateEffect(() => {
    meInvalidated.current = false
  }, [conversationId])

  usePubNub(
    `conversation_${conversationId}`,
    ({ action, attributes }) => {
      // If the message ourselves, we don't need to refetch
      if (action !== Channels.NewMessage || attributes.sender === me.id) return

      queryClient.invalidateQueries({ queryKey: QK.conversations.id(conversationId).messages.lists })
    },
    { enabled: Boolean(conversationId) }
  )

  usePubSub(
    PubSubEvents.ConversationMessageSent,
    ({ conversationId: eventConversationId }) => {
      // If the message is not for this conversation, we don't need to refetch
      if (eventConversationId != conversationId) return

      queryClient.invalidateQueries({ queryKey: QK.conversations.id(conversationId).messages.lists })
    },
    { enabled: Boolean(conversationId) }
  )

  const query = useInfiniteQuery({
    queryKey: QK.conversations.id(conversationId).messages.list(),
    queryFn: ({ pageParam }) => API.conversations.id(conversationId).messages.list({ last: pageParam || undefined, limit: LIMIT }),
    select: (data) => [...data.pages].reverse().flat(),
    enabled: Boolean(conversationId),
    staleTime: 0,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      // If there are fewer messages than the perPage, we reached the end
      if (lastPage.length !== LIMIT) return undefined
      // Otherwise, we return the id of the oldest message to load the next page
      return lastPage[0]?.id
    },
  })

  useQueryEvents(query, {
    onSuccess: () => {
      // When we fetch the messages, we reset the unread count
      queryClient.setQueriesData(
        { queryKey: QK.conversations.lists },
        mapCache((t) => (t.id == conversationId ? { ...t, unreadMessageCount: 0 } : t))
      )

      // Refetch the 'me' query when we enter a conversation and fetching messages for the first time
      // This is to update the patient.totalUnreadMessageCount
      // TODO Add check to only invalidate if the conversation is unread
      if (!meInvalidated.current) {
        meInvalidated.current = true
        queryClient.invalidateQueries({ queryKey: QK.me(me.id).details })
      }
    },
  })

  return query
}
