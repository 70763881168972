import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'
import { useMe } from '@shared/providers/src/MeProvider'
import { base64toBlob, handleError, LabTypes, UserRole } from '@shared/utils'

import { CopyOutlinedIcon, FileOutlinedIcon, FileSearchOutlinedIcon, SaveOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'
import Property from '@components/Details/Property'
import Section from '@components/Details/Section'
import FileHover from '@components/FileHover'
import { PdfStatic } from '@components/PdfViewer'

import { usePatientLabFile, usePatientLabFileRename } from '../Lab.hooks'

export default function ResultsSection({ data, mini = false }) {
  const me = useMe()

  const hasObservations = data.observations?.length > 0
  const hasResultFiles = data.resultFiles?.length > 0
  const noResultsAvailable = !hasObservations && !hasResultFiles

  const isManual = data.lab === LabTypes.Manual
  const title = isManual ? 'Uploaded Lab Work' : 'Results'

  return (
    <Section
      mini={mini}
      title={title}
      icon={mini ? null : <FileSearchOutlinedIcon />}
      action={
        hasObservations ? (
          <CopyToClipboard>
            {({ copy }) => (
              <Button endIcon={<CopyOutlinedIcon />} size="small" onClick={() => copy(gatherObservations(data.observations))}>
                Copy
              </Button>
            )}
          </CopyToClipboard>
        ) : null
      }
    >
      {noResultsAvailable && <Typography>No results available</Typography>}
      {hasResultFiles &&
        data.resultFiles.map((file) => (
          <ResultFile
            key={file.uid}
            patientId={data.user?.id}
            labId={data.id}
            file={file}
            allowRename={isManual && me.role === UserRole.Provider}
          />
        ))}
      {hasObservations && (
        <Paper variant="outlined">
          {data.observations.map((ob, i) => (
            <Property key={i} label={ob.testName || ob.name}>
              <Typography copy={observationToString(ob)}>{ob.result}</Typography>
            </Property>
          ))}
        </Paper>
      )}
    </Section>
  )
}

ResultsSection.Loading = ({ title = 'Results', mini = false }) => (
  <Section mini={mini} title={title} icon={mini ? null : <FileSearchOutlinedIcon />}>
    <Paper variant="outlined">
      <Property.Loading label={<Skeleton />} />
      <Property.Loading label={<Skeleton />} />
      <Property.Loading label={<Skeleton />} />
    </Paper>
    <Skeleton variant="rounded" height={400} />
  </Section>
)

function ResultFile({ allowRename, patientId, labId, file }) {
  const [filename, setFilename] = useState(file.filename)

  const rename = usePatientLabFileRename(patientId, labId, file.id)

  const handleSave = () => {
    if (!filename) return
    if (file.filename === filename) return

    return rename
      .mutateAsync({ filename })
      .then(() => toast.success('File renamed successfully'))
      .catch((e) => {
        setFilename(file.filename)
        return handleError(e)
      })
  }

  const handleBlur = () => {
    if (!filename) setFilename(file.filename)
  }

  const isImage = file.mimetype?.startsWith('image')
  const isPdf = file.mimetype === 'application/pdf'

  if (isImage || isPdf) {
    return (
      <Stack>
        <TextField
          hiddenLabel
          variant="standard"
          value={filename}
          disabled={rename.isPending}
          onChange={(e) => setFilename(e.target.value)}
          onBlur={handleBlur}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">Name:</InputAdornment>,
              endAdornment: allowRename ? (
                <InputAdornment position="end">
                  <Button
                    onClick={handleSave}
                    loading={rename.isPending}
                    disabled={!filename || file.filename === filename}
                    endIcon={<SaveOutlinedIcon />}
                  >
                    Save
                  </Button>
                </InputAdornment>
              ) : undefined,
            },
            htmlInput: { readOnly: !allowRename },
          }}
        />
        {isImage && <ImageResult file={file} />}
        {isPdf && <PDFResult patientId={patientId} fileId={file.id} />}
      </Stack>
    )
  }
  if (isImage) return <ImageResult file={file} />
  if (isPdf) return <PDFResult patientId={patientId} fileId={file.id} />

  return null
}

function ImageResult({ file }) {
  return (
    <Link target="_blank" href={file.url} sx={{ position: 'relative', borderRadius: 1, overflow: 'hidden' }}>
      <Image
        src={file.url}
        fit="contain"
        duration={500}
        errorIcon={<FileOutlinedIcon />}
        style={{ width: 'unset', height: 'unset', maxWidth: '100%', maxHeight: '100%', minHeight: 200 }}
      />
      <FileHover />
    </Link>
  )
}

function PDFResult({ patientId, fileId }) {
  const { data: file, isPending } = usePatientLabFile(patientId, fileId)

  const pdf = useMemo(() => {
    if (isPending || !file) return null
    return base64toBlob(file.content, file.mimetype)
  }, [file, isPending])

  if (isPending) {
    return <Skeleton variant="rounded" height={400} />
  }

  return <PdfStatic file={pdf} filename={file.filename} initialScale={1.2} />
}

const observationToString = (ob) => `${ob.testName || ob.name}: ${ob.result}`
const gatherObservations = (observations = []) => observations.map((ob) => observationToString(ob)).join('\n')
