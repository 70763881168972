import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'

import PatientProvider, { withPatient } from '@shared/providers/src/PatientProvider'

import { BreakGlassInDialog } from '@components/BreakGlass'
import { SlideUp } from '@components/Transitions'

import { useTask } from '../Kanban.hooks'
import Header from './Header'
import PatientDetails from './PatientDetails'
import TaskDetails from './TaskDetails'

export default function TaskDialog({ taskId, open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} TransitionComponent={SlideUp}>
      <Content taskId={taskId} onClose={onClose} />
    </Dialog>
  )
}

function Content({ taskId, onClose }) {
  const { data: task, isPending } = useTask(taskId)

  if (isPending) return <Loading />

  return (
    <PatientProvider id={task.patientId}>
      <BreakGlass onClose={onClose}>
        <Wrap>
          <Header task={task} onClose={onClose} />
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={4}>
            <PatientDetails task={task} />
            <TaskDetails task={task} />
          </Stack>
        </Wrap>
      </BreakGlass>
    </PatientProvider>
  )
}

function Loading() {
  return (
    <Wrap>
      <Header.Loading />
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={4}>
        <PatientDetails.Loading />
        <TaskDetails.Loading />
      </Stack>
    </Wrap>
  )
}

function Wrap({ children }) {
  return (
    <DialogContent sx={{ pb: '0 !important', overflowX: 'hidden' }}>
      <Stack spacing={3}>{children}</Stack>
    </DialogContent>
  )
}

const BreakGlass = withPatient(BreakGlassInDialog)
