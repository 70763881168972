import { useParams } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useInsurancesModal() {
  return useQueryParam('insurances', withDefault(BooleanParam, false), { updateType: 'replaceIn' })
}

export function useUserStatusUpdate() {
  const { id } = useParams()

  return useMutation({
    mutationFn: ({ type, data }) => API.users.id(id).status[type](data),
    onSuccess: (patient) => {
      queryClient.setQueryData(QK.patients.id(id).details, (prev = {}) => ({ ...prev, ...patient }))
      queryClient.invalidateQueries({ queryKey: QK.patients.id(id).notes.lists })
    },
  })
}

export const UpdateType = {
  Activate: 'activate',
  Deactivate: 'deactivate',
  Disable: 'disable',
}
