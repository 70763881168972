import { useState } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { TaskPriority, TaskStatus, UserRole } from '@shared/utils'

import { PlusOutlinedIcon, PriorityIcon } from '@icons'
import InputControl from '@components/InputControl'
import SelectPrescription from '@components/SelectPrescription'
import SelectUser from '@components/SelectUser'

import { useTaskCreation } from './NewTask.hooks'

export function NewTaskButton() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)} startIcon={<PlusOutlinedIcon />} sx={{ minWidth: 120 }}>
        Add Task
      </Button>
      <NewTaskDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export function NewTaskDialog({ patient, open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="paper">
      <Form patient={patient} onClose={onClose} />
    </Dialog>
  )
}

function Form({ patient = null, onClose }) {
  const [assignedToMe, setAssignedToMe] = useState(false)

  const me = useMe()
  const taskTypes = useLookup(me.role === UserRole.MA ? Lookup.MATasks : Lookup.CSTasks)
  const rxRequiredTypes = useLookup(Lookup.RXRequiredTasks)
  const isRxRequired = (type) => rxRequiredTypes.map((t) => t.kind).includes(type)

  const createTask = useTaskCreation()

  const formik = useFormik({
    initialValues: {
      type: '',
      priority: TaskPriority.Medium,
      patient,
      assigned: null,
      prescription: null,
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Type is required'),
      priority: Yup.string().required('Priority is required'),
      patient: Yup.object().required('Patient is required').nullable(),
      assigned: Yup.object().nullable(),
      prescription: Yup.object().nullable(),
    }),
    onSubmit: (values) => {
      const assigneeId = assignedToMe ? me.id : values.assigned?.id

      return createTask
        .mutateAsync({
          task: {
            patient_id: values.patient.id,
            assignee_id: assigneeId,
            kind: values.type,
            priority: values.priority,
            status: Boolean(assigneeId) ? TaskStatus.InProgress : TaskStatus.ToDo,
          },
          ...(isRxRequired(values.type) && Boolean(values.prescription) && { prescription_id: values.prescription.id }),
        })
        .then(() => {
          toast.success('Task created')
          onClose()
        })
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Add New Task</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputControl field="type" formikProps={formik}>
              <TextField label="Task type" required fullWidth select>
                {taskTypes.map(({ kind, label }) => (
                  <MenuItem key={kind} value={kind}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </InputControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputControl field="priority" formikProps={formik}>
              <TextField required fullWidth select>
                {Object.entries(TaskPriority).map(([label, value]) => (
                  <MenuItem key={value} value={value}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <PriorityIcon value={value} />
                      <Typography>{label}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </TextField>
            </InputControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Stack spacing={1}>
              <Stack spacing={1}>
                <InputLabel error={formik.touched.patient && Boolean(formik.errors.patient)} sx={{ color: 'text.inputLabel' }}>
                  Patient
                </InputLabel>
                <SelectUser
                  value={formik.values.patient}
                  onChange={(user) => {
                    formik.setFieldValue('patient', user)
                    formik.setFieldValue('prescription', null)
                  }}
                  error={formik.touched.patient && Boolean(formik.errors.patient)}
                  helperText={formik.touched.patient && formik.errors.patient}
                  role={UserRole.Patient}
                  disabled={Boolean(patient)}
                />
              </Stack>
              <Collapse in={isRxRequired(formik.values.type)}>
                <Stack spacing={1}>
                  <InputLabel error={formik.touched.prescription && Boolean(formik.errors.prescription)} sx={{ color: 'text.inputLabel' }}>
                    Prescription
                  </InputLabel>
                  <SelectPrescription
                    patientId={formik.values.patient?.id}
                    value={formik.values.prescription}
                    onChange={(prescription) => formik.setFieldValue('prescription', prescription)}
                    error={formik.touched.prescription && Boolean(formik.errors.prescription)}
                    helperText={formik.touched.prescription && formik.errors.prescription}
                    placeholder="Select related Rx"
                  />
                </Stack>
              </Collapse>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Stack spacing={1}>
              <InputLabel error={formik.touched.assigned && Boolean(formik.errors.assigned)} sx={{ color: 'text.inputLabel' }}>
                Assignee{' '}
                <Typography component="span" sx={{ color: 'text.secondary' }}>
                  (optional)
                </Typography>
              </InputLabel>
              <SelectUser
                value={formik.values.assigned}
                onChange={(user) => formik.setFieldValue('assigned', user)}
                error={formik.touched.assigned && Boolean(formik.errors.assigned)}
                helperText={formik.touched.assigned && formik.errors.assigned}
                role={me.role}
                disabled={assignedToMe}
              />
              <FormControlLabel
                label="Assign to me"
                control={
                  <Checkbox
                    checked={assignedToMe}
                    onChange={(e, newValue) => {
                      // Clear the assigned field if the user checks the checkbox
                      if (newValue && formik.values.assigned) formik.setFieldValue('assigned', null)
                      setAssignedToMe(newValue)
                    }}
                  />
                }
                labelPlacement="end"
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={createTask.isPending} variant="contained" type="submit">
          Add
        </Button>
      </DialogActions>
    </form>
  )
}
