import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'
import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

/**
 * Figure out the conversation id by the participants
 *
 * TODO: This is a temporary solution until we have a better way to figure out the conversation id
 */
export default function useConversationIdCalc(userId) {
  const me = useMe()

  const query = { user_id: userId, between_users: true, limit: 1 }
  const { data, isPending } = useQuery({
    queryKey: QK.conversations.betweenUsers(query),
    queryFn: () => API.conversations.list(query),
    enabled: Boolean(userId),
    select: (data) => data?.[0],
  })

  const mineUserId = me?.id
  const conversationId = data?.id
  const doNotHaveConversationId = Boolean(mineUserId) && Boolean(userId) && !conversationId

  /*
   Subscribe to user channel to get notified when a new conversation is created
   Enabled only when we don't have an existing conversation
   */
  usePubNub(
    `user_${mineUserId}`,
    ({ action }) => {
      if (action !== Channels.NewMessage) return

      queryClient.invalidateQueries({ queryKey: QK.conversations.betweenUsers(query) })
    },
    { enabled: doNotHaveConversationId }
  )

  /*
   Subscribe to sent messages to get notified when a new conversation is created
   Enabled only when we don't have an existing conversation
   */
  usePubSub(
    PubSubEvents.ConversationMessageSent,
    (data) => {
      // If the message is not for current user, we don't need to refetch
      if (data.userId != userId) return

      queryClient.invalidateQueries({ queryKey: QK.conversations.betweenUsers(query) })
    },
    { enabled: doNotHaveConversationId }
  )

  return [conversationId, isPending]
}
