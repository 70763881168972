export const styles = {
  grid: {
    display: 'grid',
    gap: { xs: 1, sm: 2 },
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: {
      xs: `
        auto
        auto
        minmax(200px, max-content)
      `,
      md: `
        1fr
        minmax(200px, max-content)
      `,
    },
    gridTemplateAreas: {
      xs: `
        'content content content'
        'select  select  select'
        'pad     pad     pad'
      `,
      md: `
        'content content select'
        'pad     pad     pad'
      `,
    },
  },
  hideScroll: {
    height: '100%',
    overflow: 'hidden',
  },
  scroll: {
    height: '100%',
    overflow: 'auto',
  },
}
