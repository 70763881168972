import Box from '@mui/material/Box'

import usePopover from '@shared/hooks/src/usePopover'

import { PagesHistoryPopover } from '@pages/PagesHistory'
import { HistoryOutlinedIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function PagesHistory() {
  const popover = usePopover()

  return (
    <Box>
      <IconButton
        id="pages-history-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        aria-label="Pages History"
        data-testid="header-pages-history"
      >
        <HistoryOutlinedIcon style={{ fontSize: 18 }} />
      </IconButton>
      <PagesHistoryPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
    </Box>
  )
}
