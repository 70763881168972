import dayjs from 'dayjs'

export const dateRangeShortcuts = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs()
      return [today.startOf('week'), today]
    },
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = dayjs()
      return [today.startOf('month'), today]
    },
  },
  {
    label: 'This Year',
    getValue: () => {
      const today = dayjs()
      return [today.startOf('year'), today]
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(7, 'day'), today]
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs()
      const prevWeek = today.subtract(7, 'day')
      return [prevWeek.startOf('week'), prevWeek.endOf('week')]
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs()
      const endOfPreviousMonth = today.startOf('month').subtract(1, 'day')
      return [endOfPreviousMonth.startOf('month'), endOfPreviousMonth]
    },
  },
  {
    label: 'Last Year',
    getValue: () => {
      const today = dayjs()
      const endOfPreviousYear = today.startOf('year').subtract(1, 'day')
      return [endOfPreviousYear.startOf('year'), endOfPreviousYear]
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
]
