import { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'

/**
 * Dialog for confirming task expiration
 *
 * @param open - open state of the dialog
 * @param onClose - close handler
 * @param onConfirm - confirm handler
 */
export default function ExpireConfirmation({ open, onCancel, onConfirm }) {
  return (
    <Dialog open={open} onClose={() => onCancel()} fullWidth maxWidth="sm">
      <Content onCancel={onCancel} onConfirm={onConfirm} />
    </Dialog>
  )
}

function Content({ onCancel, onConfirm }) {
  const [reason, setReason] = useState('')

  const expireReasons = useLookup(Lookup.TaskExpiredReasons)

  return (
    <>
      <DialogTitle>Confirm Task Expiration</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Typography>Please select a reason for expiring this task:</Typography>
          <TextField
            select
            label={reason ? '' : 'Select Reason'}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            sx={{ minWidth: 200 }}
            slotProps={{
              inputLabel: { shrink: false },
            }}
          >
            {expireReasons.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button
          variant="contained"
          disabled={reason.trim() === ''}
          onClick={() => {
            onConfirm(reason)
            setTimeout(() => setReason(''), 300)
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}
