import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import useSendMessage from '@shared/messaging/src/hooks/useSendMessage'
import { richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { handleError, processRichTextJsonToMessage } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import InputControl from '@components/InputControl'
import { getExtensions, RichTextEditor } from '@components/RichText'

import { useTasksFilters } from '../../Tasks.hooks'
import { useMarkCompleted, useMarkNotApplicable } from './TaskInfo.hooks'
import { isNotDone, Patient } from './TaskInfo.utils'

export default function IncompleteLabInfo({ data, patient }) {
  const [declineOpen, setDeclineOpen] = useState(false)
  const [reminderOpen, setReminderOpen] = useState(false)

  if (isNotDone(data)) {
    return (
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="contained" color="error" onClick={() => setDeclineOpen(true)}>
          Not Applicable
        </Button>
        <Button variant="contained" color="primary" onClick={() => setReminderOpen(true)}>
          Follow Up
        </Button>
        <Dialog open={declineOpen} onClose={() => setDeclineOpen(false)} fullWidth maxWidth="xs">
          <DeclineContent task={data} patient={patient} onClose={() => setDeclineOpen(false)} />
        </Dialog>
        <Dialog open={reminderOpen} onClose={() => setReminderOpen(false)} fullWidth maxWidth="sm">
          <ReminderContent task={data} patient={patient} onClose={() => setReminderOpen(false)} />
        </Dialog>
      </Stack>
    )
  }
}

function ReminderContent({ task, patient, onClose }) {
  const editorRef = useRef()
  const isEmpty = editorRef.current?.isEmpty

  const [, updateFilters] = useTasksFilters()

  const sendMessage = useSendMessage({ userId: task.patientId })
  const markCompleted = useMarkCompleted(task.id)

  const handleSend = () => {
    const message = editorRef.current?.getJSON()
    const sendMessageRequest = sendMessage.mutateAsync({ message: processRichTextJsonToMessage(message) })
    const markCompletedRequest = markCompleted.mutateAsync()

    return Promise.all([sendMessageRequest, markCompletedRequest])
      .then(() => toast.success('Message sent'))
      .then(() => updateFilters({ task: undefined }))
      .catch(handleError)
  }

  return (
    <>
      <DialogTitle>Follow Up for Incomplete Labs</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Typography variant="h4">Send a reminder message to the patient to complete their lab work.</Typography>
          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150, maxHeight: 400 } }]}>
            <RichTextEditor ref={editorRef} extensions={getExtensions({ enableQPhrases: true })} initialValue={defaultMessage} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button
          loading={sendMessage.isPending || markCompleted.isPending}
          disabled={isEmpty}
          variant={isEmpty ? 'text' : 'contained'}
          onClick={handleSend}
        >
          Send Message
        </Button>
      </DialogActions>
    </>
  )
}

function DeclineContent({ task, patient, onClose }) {
  const [, updateFilters] = useTasksFilters()
  const reasons = useLookup(Lookup.IncompleteLabDeclineReasons)
  const markNotApplicable = useMarkNotApplicable(task.id)

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: ({ reason }) => {
      return markNotApplicable
        .mutateAsync(reason)
        .then(() => updateFilters({ task: undefined }))
        .catch(handleError)
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Not Applicable</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Alert severity="warning" variant="border">
            Choosing "Not Applicable" will remove this task and not send the patient a lab reminder message.
          </Alert>
          <Stack sx={{ width: '100%' }}>
            <InputControl field="reason" formikProps={formik}>
              <TextField select required fullWidth label="Reason">
                {reasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </TextField>
            </InputControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} color="error" type="submit" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </form>
  )
}

const defaultMessage = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'I am reaching out to you to see if you have had an opportunity to complete your lab work. It is important to complete your labs as soon as possible to prevent a gap in your PrEP medication.',
        },
      ],
    },
  ],
}
