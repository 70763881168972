import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'

/**
 * Get the change healthcare iframe src url.
 */
export function useGetChangeUrl(providerId) {
  return useMutation({
    mutationFn: (data) => API.providers.id(providerId).prescriptions.url(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['change-url', providerId] }),
  })
}

/**
 * Tell BE to sync prescriptions with change healthcare.
 */
export function useSyncPrescriptions(providerId) {
  return useMutation({
    mutationFn: (data) => API.providers.id(providerId).prescriptions.sync(data),
  })
}
