import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useGileadApplication(patientId, gileadApplicationId) {
  return useQuery({
    queryKey: QK.patients.id(patientId).gilead.id(gileadApplicationId).details,
    queryFn: () => API.patients.id(patientId).gilead.id(gileadApplicationId).details(),
  })
}

export function useGileadDecline(patientId, gileadApplicationId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).gilead.id(gileadApplicationId).decline(data),
  })
}

export function useGileadSubmit(patientId, gileadApplicationId) {
  return useMutation({
    mutationFn: () => API.patients.id(patientId).gilead.id(gileadApplicationId).providerSign(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.tasks.lists }),
  })
}
