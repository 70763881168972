import { Link as RouterLink } from 'react-router'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { QuestionCircleOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'

export const styles = {
  container: {
    p: 2,
    height: '100%',
    width: '100%',
  },
}

export function PatientHeader({ user }) {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Avatar size="xxl" user={user} />
      <Stack>
        <Typography variant="h4">{user.fullName}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{user.pronouns}</Typography>
        <Link component={RouterLink} to={`/app/patients/${user.id}`} target="_blank">
          {user.id}
        </Link>
      </Stack>
    </Stack>
  )
}

PatientHeader.Loading = function () {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Skeleton variant="rounded" width={84} height={84} />
      <Stack>
        <Typography variant="h4">
          <Skeleton width={200} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
        <Skeleton width={80} />
      </Stack>
    </Stack>
  )
}

export function Property({ label, children, ...rest }) {
  return (
    <Stack direction="row" spacing={1} {...rest}>
      <Typography sx={{ color: 'text.secondary', minWidth: 80 }}>{label}</Typography>
      {typeof children === 'string' ? <Typography>{children}</Typography> : children}
    </Stack>
  )
}

Property.Loading = function ({ width }) {
  return (
    <Property label={<Skeleton width={70} />}>
      <Typography sx={{ width: '100%' }}>
        <Skeleton width={width} />
      </Typography>
    </Property>
  )
}

export const FAQIcon = styled(QuestionCircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))
