import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea'

const styles = {
  container: {
    width: '100%',
    border: '1px solid',
    borderRadius: 1,
    p: 1,
    pl: 3,
    position: 'relative',
    overflow: 'hidden',
    transition: 'background-color 100ms linear',
  },
  left: {
    position: 'absolute',
    left: 8,
    top: 8,
    bottom: 8,
    width: 8,
  },
  short: {
    bottom: 'none',
    height: 24,
  },
}

CardBase.propTypes = {
  /** Left border color */
  color: PropTypes.string,

  /** Override color with red when urgent */
  urgent: PropTypes.bool,

  /** The size of left border can be shortened */
  short: PropTypes.bool,

  /** Whether the card is flagged */
  flagged: PropTypes.bool,

  /** Whether the card is selected */
  selected: PropTypes.bool,

  /** Callback when card is clicked */
  onClick: PropTypes.func,

  /** Card content */
  children: PropTypes.node,
}

export default function CardBase({ color, urgent = false, short = false, flagged = false, selected = false, onClick, children }) {
  const bgColor = urgent ? 'error.main' : color

  return (
    <Container selected={selected} flagged={flagged} onClick={onClick}>
      <Box sx={[styles.left, { backgroundColor: bgColor || 'transparent' }, short ? styles.short : {}]} />
      {children}
    </Container>
  )
}

CardBase.Loading = function ({ children, short = false }) {
  return (
    <Container>
      <Box sx={[styles.left, { backgroundColor: 'divider' }, short ? styles.short : {}]} />
      {children}
    </Container>
  )
}

function Container({ selected, flagged, onClick, children }) {
  let backgroundColor = 'background.paper'
  if (selected) backgroundColor = 'primary.lighter'
  if (flagged) backgroundColor = 'warning.lighter'

  return (
    <CardActionArea
      onClick={onClick}
      sx={[
        styles.container,
        {
          backgroundColor,
          borderColor: selected ? 'primary.main' : 'divider',
          outlineWidth: selected ? 1 : 0,
          outlineStyle: 'solid',
          outlineColor: (theme) => theme.palette.primary.main,
        },
      ]}
    >
      {children}
    </CardActionArea>
  )
}
