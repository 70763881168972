import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, handleError, mapCache, QK } from '@shared/utils'

export function useAssessmentReview(patientId, encounterId, assessmentId) {
  return useMutation({
    mutationFn: () => API.patients.id(patientId).encounters.id(encounterId).assessments.id(assessmentId).review(),
    onSuccess: (assessment) => {
      toast.success('Assessment reviewed successfully')
      queryClient.setQueriesData(
        { queryKey: QK.patients.id(patientId).encounters.id(encounterId).assessments.lists },
        mapCache((asmt) => (asmt.id === assessment.id ? { ...asmt, ...assessment } : asmt))
      )
      queryClient.setQueriesData(
        { queryKey: QK.assessments.pending.lists },
        filterCache((asmt) => asmt.id !== assessment.id)
      )
    },
    onError: handleError,
  })
}
