import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Typography from '@components/_mui/Typography'

import { usePatientPrescriptions } from '@pages/Prescriptions/PatientPrescriptions'
import { PrescriptionDetailsModal } from '@pages/Prescriptions/Prescription'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import StatusChip from '@components/StatusChip'
import TableSortCell from '@components/TableSortCell'

export default function Prescriptions({ task }) {
  const [tab, setTab] = useState('recent')
  const [order, setOrder] = useState('desc')
  const [{ page, perPage }, setPageParams] = useState({ page: 1, perPage: 5 })
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [prescriptionId, setPrescriptionId] = useState()

  const recentQuery = { order: 'desc', limit: 3 }
  const allQuery = { order, limit: perPage, offset: (page - 1) * perPage }
  const query = tab === 'recent' ? recentQuery : allQuery

  const { data, isPending } = usePatientPrescriptions(task.patientId, query, { placeholderData: keepPreviousData })

  return (
    <Stack>
      <PrescriptionDetailsModal prescriptionId={prescriptionId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
      <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
        <Tab label="Recent Rx" value="recent" />
        <Tab label="All" value="all" />
      </Tabs>
      <Divider />
      <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ERx</TableCell>
                <TableCell>Qty</TableCell>
                {tab === 'recent' ? (
                  <TableCell>ERx Date</TableCell>
                ) : (
                  <TableSortCell
                    active
                    direction={order}
                    onChange={(order) => {
                      setOrder(order)
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    ERx Date
                  </TableSortCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((rx) => (
                <TableRow
                  key={rx.id}
                  hover
                  onClick={() => {
                    setPrescriptionId(rx.id)
                    setDetailsOpen(true)
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Stack spacing={1}>
                      <Typography>{`${rx.drugName}${rx.isRefill ? ' (Refill)' : ''}`}</Typography>
                      <Typography color="secondary">{rx?.pharmacy?.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Stack direction="row" spacing={1}>
                      <StatusChip label={`${rx.quantity}+${rx.refills}`} type="warning" />
                      <StatusChip label={rx.remain || 0} type="success" />
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Typography>{rx.createdAt ? dayjs(rx.createdAt).format('L') : ''}</Typography>
                    <StatusChip.Rx status={rx.rxStatus} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RenderControl>
      {tab === 'all' && (
        <>
          <Divider />
          <Pagination
            disableRowsPerPage
            page={page}
            perPage={perPage}
            setPageParams={setPageParams}
            loading={isPending}
            last={data?.length < perPage}
            sx={{ mt: 1 }}
          />
        </>
      )}
    </Stack>
  )
}
