import { useMemo } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { Logger } from '@shared/utils'

import Transitions from '@components/Transitions'

const log = Logger('RenderControl.js')

RenderControl.propTypes = {
  /** Display the loading state */
  loading: PropTypes.bool,

  /** Display empty state */
  isEmpty: PropTypes.bool,

  /** Option title to display when isEmpty is true */
  emptyTitle: PropTypes.string,

  /** Option props to pass to the empty state */
  emptyProps: PropTypes.object,

  /** Children */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

/**
 * A wrapper to manage the display of loading and empty states for async data.
 *
 * @example
 * const {data, isPending, error} = useQuery(...)
 * return (
 *   <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No items to display">
 *     {data?.map((item) => <p>{item.name}</p>)}
 *   </RenderControl>
 */
export default function RenderControl({ loading = false, isEmpty = false, emptyTitle = '', emptyProps = {}, children }) {
  const content = useMemo(() => {
    if (loading && isEmpty) {
      log.warn('Component received true for both loading and isEmpty. This is not supported.')
    }

    if (isEmpty) return <Empty show title={emptyTitle} {...emptyProps} />
    if (loading) return <Spinner loading={true} />
    return children
  }, [children, emptyProps, emptyTitle, isEmpty, loading])

  return <Box sx={{ height: '100%', minHeight: 100, position: 'relative' }}>{content}</Box>
}

function Wrap({ height, children }) {
  return (
    <Box sx={{ height }}>
      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}

function Empty({ show = false, title = 'No data', height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={show} unmountOnExit>
      <Wrap height={height}>
        <Typography variant="h6" color="secondary" {...rest}>
          {title}
        </Typography>
      </Wrap>
    </Transitions>
  )
}

function Spinner({ loading = false, height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={loading} unmountOnExit>
      <Wrap height={height}>
        <CircularProgress {...rest} />
      </Wrap>
    </Transitions>
  )
}
