import React from 'react'
import dayjs from 'dayjs'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { AssessmentStatus, toTitleCase, UserRole } from '@shared/utils'

import AssessmentAnswersCard from '@components/AssessmentAnswersCard'
import CopyIconButton from '@components/CopyIconButton'
import { spacing } from '@components/Details/Details.utils'
import Property from '@components/Details/Property'
import Section from '@components/Details/Section'

import { useAssessmentReview } from './AssessmentDetailsModal.hooks'

export default function AssessmentDetailsModal({ patientId, encounterId, assessment, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
    >
      <Content patientId={patientId} encounterId={encounterId} assessment={assessment} onClose={onClose} />
    </Dialog>
  )
}

function Content({ patientId, encounterId, assessment, onClose }) {
  const reviewAssessment = useAssessmentReview(patientId, encounterId, assessment.id)

  return (
    <>
      <DialogTitle onClose={onClose}>
        <Stack spacing={-0.5}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: 'normal', color: 'text.secondary' }}>ID: {assessment.id}</Typography>
            <CopyIconButton fn={(copy) => copy(assessment.id)} />
          </Stack>
          <Typography component="h2" variant="h4">
            {assessment.name}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          <Section paper mini title="Details">
            <Property label="Status">{toTitleCase(assessment.status)}</Property>
            <Property label="Created">{dayjs(assessment.createdAt).format('L LT')}</Property>
            {assessment.dateReturned && <Property label="Answered">{dayjs(assessment.dateReturned).format('L LT')}</Property>}
          </Section>
          {assessment.answers.length > 0 && (
            <Section paper mini title="Response">
              <AssessmentAnswersCard answers={assessment.answers} elevation={0} />
            </Section>
          )}
          {Boolean(patientId) && Boolean(encounterId) && (
            <RoleGuard allowed={[UserRole.Provider]}>
              {assessment.status === AssessmentStatus.Finished && (
                <Button
                  variant="contained"
                  loading={reviewAssessment.isPending}
                  onClick={() => reviewAssessment.mutateAsync().then(onClose)}
                  sx={{ alignSelf: 'center' }}
                >
                  Mark Reviewed
                </Button>
              )}
              {assessment.status === AssessmentStatus.Reviewed && (
                <Button variant="outlined" sx={{ alignSelf: 'center' }}>
                  Reviewed
                </Button>
              )}
            </RoleGuard>
          )}
        </Stack>
      </DialogContent>
    </>
  )
}
