import { useState } from 'react'

import Box from '@mui/material/Box'

import CBOsSupportMapDialog from '@pages/CBOs/CBOsSupportMapDialog'
import { GlobalIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function CBOSupportMap() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box>
        <IconButton id="cbo-support-button" onClick={() => setOpen(true)} aria-label="CBO support map" data-testid="header-cbo-support-map">
          <GlobalIcon style={{ fontSize: 18 }} />
        </IconButton>
      </Box>
      <CBOsSupportMapDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}
