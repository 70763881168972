import React, { useMemo, useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'
import range from 'lodash/range'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import usePageParams from '@shared/hooks/src/usePageParams'
import { toTitleCase } from '@shared/utils'

import AssessmentDetailsModal from '@pages/Assessments/AssessmentDetailsModal'
import { CaretDownOutlinedIcon, CaretRightOutlinedIcon, ExpandAltOutlinedIcon } from '@icons'
import AssessmentAnswersCard from '@components/AssessmentAnswersCard'
import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import TableCollapsableCell from '@components/TableCollapsableCell'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'
import { useAssessment, useAssessments, useAssessmentsFilters } from './Assessments.hooks'

export default function Assessments() {
  const [isAsmtDetailsOpen, setIsAsmtDetailsOpen] = useState(false)
  const [selectedAsmtId, setSelectedAsmtId] = useState(null)

  const { data, isRefreshing, isPending } = useAssessments({ order: 'desc', limit: 5 })
  const [{ asmtsExpanded }, updateFilters] = useAssessmentsFilters()

  const selectedAsmt = useMemo(() => {
    return data?.find((a) => a.id === selectedAsmtId)
  }, [data, selectedAsmtId])

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Tile
      title="Assessments"
      refreshing={isRefreshing}
      action={
        <IconCircleButton disabled={(data?.length ?? 0) === 0} size="small" onClick={() => updateFilters({ asmtsExpanded: true })}>
          <ExpandAltOutlinedIcon />
        </IconCircleButton>
      }
      sx={{ height: '100%' }}
    >
      <ExpandedDialog open={asmtsExpanded} onClose={() => updateFilters({ asmtsExpanded: false, asmtDetailed: undefined })} />
      <AssessmentDetailsModal assessment={selectedAsmt} open={isAsmtDetailsOpen} onClose={() => setIsAsmtDetailsOpen(false)} />
      {showLoading && <Loading />}
      {showEmpty && <Empty />}
      {showData && (
        <List disablePadding data-testid="assessments">
          {data?.map((asmt) => (
            <Item
              key={asmt.id}
              asmt={asmt}
              onClick={() => {
                setSelectedAsmtId(asmt.id)
                setIsAsmtDetailsOpen(true)
              }}
            />
          ))}
        </List>
      )}
    </Tile>
  )
}

function Item({ asmt, onClick }) {
  return (
    <>
      <ListItemButton onClick={onClick}>
        <Stack direction="row" sx={{ gap: 0.5, justifyContent: 'space-between', width: '100%' }}>
          <Typography noWrap>{asmt.name}</Typography>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Typography>{dayjs(asmt.createdAt).format('L')}</Typography>
            <Typography>{toTitleCase(asmt.status)}</Typography>
          </Stack>
        </Stack>
      </ListItemButton>
      <Divider />
    </>
  )
}

Item.Loading = function () {
  return (
    <>
      <ListItemButton>
        <Stack direction="row" sx={{ gap: 0.5, justifyContent: 'space-between', width: '100%' }}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Typography>
              <Skeleton width={80} />
            </Typography>
            <Typography>
              <Skeleton width={80} />
            </Typography>
          </Stack>
        </Stack>
      </ListItemButton>
      <Divider />
    </>
  )
}

function Loading() {
  return range(0, 3).map((i) => <Item.Loading key={i} />)
}

function Empty() {
  return (
    <Typography variant="h6" align="center" sx={{ color: 'text.secondary', p: 2 }}>
      No data to display
    </Typography>
  )
}

function ExpandedDialog({ open, onClose }) {
  const [{ asmtsOrder, asmtDetailed }, updateFilters] = useAssessmentsFilters()
  const [page, perPage] = usePageParams({ id: 'asmts' })

  const { data, isRefreshing, isPending } = useAssessments(
    {
      order: asmtsOrder,
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="body">
      <DialogTitle>Assessments</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sortDirection={asmtsOrder}>
                    <TableSortLabel
                      active
                      direction={asmtsOrder}
                      onClick={() => updateFilters({ asmtsOrder: asmtsOrder === 'desc' ? 'asc' : 'desc' })}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((asmt) => (
                  <React.Fragment key={asmt.id}>
                    <TableRow
                      hover
                      onClick={() =>
                        updateFilters(({ asmtDetailed }) => {
                          return { asmtDetailed: asmtDetailed === asmt.id ? undefined : asmt.id }
                        })
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <IconButton size="small">
                          {asmtDetailed !== asmt.id ? <CaretRightOutlinedIcon /> : <CaretDownOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{asmt.name}</TableCell>
                      <TableCell>{toTitleCase(asmt.status)}</TableCell>
                      <TableCell>{dayjs(asmt.createdAt).format('L')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCollapsableCell open={asmtDetailed === asmt.id} colSpan={4}>
                        <AssessmentDetails asmt={asmt} />
                      </TableCollapsableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <Pagination id="asmts" loading={isPending} last={data?.length < perPage} />
      </DialogActions>
    </Dialog>
  )
}

function AssessmentDetails({ asmt }) {
  const { data, isRefreshing, isPending } = useAssessment(asmt.id)

  return (
    <>
      <LinearProgress loading={isRefreshing} color="warning" />
      <Stack
        sx={{
          margin: '0 auto',
          maxWidth: 800,
          width: '100%',
          my: 1,
        }}
      >
        <RenderControl loading={isPending} isEmpty={data?.answers?.length === 0} emptyTitle="No data to display">
          <AssessmentAnswersCard answers={data?.answers} />
        </RenderControl>
      </Stack>
    </>
  )
}
