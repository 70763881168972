import SvgIcon from '@mui/material/SvgIcon'

export default function MessageIcon(props) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M6.5 7.248a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zm3.125 0a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zm-6.25 0a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zm10.331-2.712A7 7 0 0 0 7.25.248h-.031a6.984 6.984 0 0 0-6.97 7.033A7.022 7.022 0 0 0 1 10.404v2.375a.719.719 0 0 0 .72.719h2.376c.97.488 2.039.745 3.124.75h.032c.936 0 1.844-.181 2.699-.536a6.944 6.944 0 0 0 2.231-1.487A6.983 6.983 0 0 0 14.25 7.28a6.973 6.973 0 0 0-.544-2.744zm-2.36 6.843A5.782 5.782 0 0 1 7.25 13.06h-.027a5.832 5.832 0 0 1-2.704-.68l-.132-.07h-2.2v-2.2l-.07-.13a5.833 5.833 0 0 1-.68-2.705A5.777 5.777 0 0 1 3.12 3.153a5.769 5.769 0 0 1 4.106-1.717h.026a5.784 5.784 0 0 1 4.113 1.701 5.781 5.781 0 0 1 1.701 4.138 5.79 5.79 0 0 1-1.72 4.104z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
