import LogoReversed from '@assets/logos/logo-full-color-reversed.svg'
import Logo from '@assets/logos/logo-full-color.svg'
import LogoMarkReversed from '@assets/logos/logo-mark-reversed.svg'
import LogoMark from '@assets/logos/logo-mark.svg'
import LogoPride from '@assets/logos/logo-pride.png'

export function resolveLogo(mode = 'light', small = false) {
  if (small) return mode === 'dark' ? LogoMarkReversed : LogoMark
  if (new Date().getMonth() === 5) return LogoPride
  return mode === 'dark' ? LogoReversed : Logo
}
