import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export default function useConversation(conversationId, options = {}) {
  return useQuery({
    queryKey: QK.conversations.id(conversationId).details,
    queryFn: () => API.conversations.id(conversationId).details(),
    ...options,
  })
}
