import { useMemo } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { usePatient } from '@shared/providers/src/PatientProvider'
import { OneClickIndicator } from '@shared/utils'

import useInsuranceSummary from '@hooks/useInsuranceSummary'
import { InsurancesModal } from '@pages/Insurances'
import { CopyOutlinedIcon, RightOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'
import Property from '@components/Property'
import StatusChip from '@components/StatusChip'
import Tile from '@components/Tile'

import InsurancePartner from './InsurancePartner'
import Menu from './Menu'
import { useInsurancesModal } from './Status.hooks'

export default function Status() {
  const { id } = useParams()
  const [insurancesModalOpen, setInsurancesModalOpen] = useInsurancesModal()

  const patient = usePatient()
  const { data: insurance, isRefreshing, isPending } = useInsuranceSummary(id)

  const statusChips = useMemo(() => {
    if (!patient) return <Skeleton variant="rounded" width={200} height={24} />
    return (
      <>
        <StatusChip label={patient?.enrolled ? 'Enrolled' : 'Not Enrolled'} type={patient?.enrolled ? 'success' : 'warning'} />
        <StatusChip label={patient?.active ? 'Active' : 'Inactive'} type={patient?.active ? 'success' : 'warning'} />
        <StatusChip label={!patient?.disabled ? 'Enabled' : 'Disabled'} type={!patient?.disabled ? 'success' : 'secondary'} />
      </>
    )
  }, [patient])

  const insuranceChips = useMemo(() => {
    if (!patient || isPending) return <Skeleton variant="rounded" width={200} height={24} />
    return (
      <>
        <StatusChip icon label={patient?.insured ? 'Insured' : 'Not Insured'} type={patient?.insured ? 'success' : 'error'} />
        {patient.va && <StatusChip label="VA" type="success" />}
        {patient.medicare && <StatusChip label="Medicaid" type="success" />}
        {insurance?.medicarePartBIndicator && <StatusChip label="Medicare Part B" type="success" />}
      </>
    )
  }, [patient, isPending, insurance?.medicarePartBIndicator])

  return (
    <Tile refreshing={isRefreshing} title="Status" action={<Menu />} sx={{ height: '100%' }}>
      <Stack spacing={1} sx={{ px: 2, pt: 0.5, pb: 3 }}>
        <div>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <CopyToClipboard>
                {({ copy }) => <Property label="MRN" value={patient?.id} endAdornment={<CopyIcon />} onClick={() => copy(patient?.id)} />}
              </CopyToClipboard>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Property label="Treatment" value={patient?.treatmentType} />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Property label="Enrolled" value={patient?.enrollmentDate ? dayjs(patient?.enrollmentDate).format('L') : ''} />
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Property
                label="Status"
                value={
                  <Stack direction="row" spacing={1}>
                    {statusChips}
                  </Stack>
                }
              />
            </Grid>
            {patient?.oneClickIndicator && Object.values(OneClickIndicator).includes(patient.oneClickIndicator) && (
              <Grid size={12}>
                <Property
                  label="One Click"
                  value={
                    <div>
                      {OneClickIndicator.Enrolled === patient.oneClickIndicator && <StatusChip label="Enrolled" type="success" />}
                      {[OneClickIndicator.Declined, OneClickIndicator.DeclinedWithTypo].includes(patient.oneClickIndicator) && (
                        <StatusChip label="Declined" type="error" />
                      )}
                      {OneClickIndicator.NeedToOffer === patient.oneClickIndicator && <StatusChip label="Need to Offer" type="warning" />}
                    </div>
                  }
                />
              </Grid>
            )}
          </Grid>
        </div>

        <Divider />

        {/* Insurance */}
        <Stack
          id="patient-insurance-status"
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
        >
          <Typography variant="h5" sx={{ width: '100%' }}>
            Insurance
          </Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            {insurance?.lastActiveInsurance?.validatedByAvita && <StatusChip label="Validated" type="success" icon />}
            <IconButton size="medium" onClick={() => setInsurancesModalOpen(true)}>
              <RightOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>

        <InsurancesModal patientId={id} open={insurancesModalOpen} onClose={() => setInsurancesModalOpen(false)} />

        <InsuranceSection insurance={insurance} />

        <Property
          label="Initial"
          value={
            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
              {insuranceChips}
            </Stack>
          }
        />

        <InsurancePartner insurance={insurance} patient={patient} isLoading={isPending} />
      </Stack>
    </Tile>
  )
}

// FIXME: this should be moved to a separate component in this folder and have a story
// Handle the different types of insurance that may show
function InsuranceSection({ insurance }) {
  if (!insurance) return null

  if (insurance.canTreat === false) {
    return (
      <>
        <Stack
          sx={{
            border: 2,
            borderColor: 'error.main',
            borderRadius: '4px',
            p: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'error.main', my: 2 }}>
            CBO Requires Insurance
          </Typography>
        </Stack>
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', my: 2 }}>
            Do not prescribe!
          </Typography>
        </Stack>
      </>
    )
  }

  if (!insurance.lastActiveInsurance) return <Typography sx={{ my: 2 }}>No Active Insurance Cards</Typography>

  return (
    <div>
      <Typography sx={{ fontWeight: 'bold', my: 2 }}>{insurance.lastActiveInsurance.company}</Typography>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Property label="ID" value={insurance.lastActiveInsurance.idnum} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Property label="PCN" value={insurance.lastActiveInsurance.pcn} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Property label="BIN" value={insurance.lastActiveInsurance.bin} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Property label="RX Grp Num" value={insurance.lastActiveInsurance.groupnum} />
        </Grid>
      </Grid>
    </div>
  )
}

const CopyIcon = styled(CopyOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
