import { useState } from 'react'
import { Document, Page } from 'react-pdf'

import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { downloadFile } from '@shared/utils'

import {
  CloseOutlinedIcon,
  DownloadOutlinedIcon,
  ExpandOutlinedIcon,
  LeftOutlinedIcon,
  RightOutlinedIcon,
  RotateLeftOutlinedIcon,
  RotateRightOutlinedIcon,
  ZoomInOutlinedIcon,
  ZoomOutOutlinedIcon,
} from '@icons'

const styles = {
  container: {
    display: 'grid',
    height: '100%',
    '& .react-pdf__Page.loadingPage': {
      display: 'none',
    },
    '& .react-pdf__Document': {
      padding: 1,
      overflow: 'scroll',
    },
    '& .react-pdf__Page__canvas': {
      margin: '0 auto',
    },
    '& .react-pdf__message': {
      height: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
}

/**
 * Display a PDF file in a static viewer
 *
 * @param file - the PDF file to display
 * @param filename - the name of the file for download
 * @param initialScale - the initial scale of the PDF
 * @returns {JSX.Element}
 */
export function PdfStatic({ file, filename, initialScale }) {
  const [fullScreen, setFullScreen] = useState(false)

  return (
    <div>
      <PdfViewer
        file={file}
        filename={filename}
        initialScale={initialScale}
        expand={
          <IconButton color="primary" variant="contained" onClick={() => setFullScreen((f) => !f)}>
            <ExpandOutlinedIcon />
          </IconButton>
        }
      />
      <PdfModal file={file} filename={filename} open={fullScreen} initialScale={initialScale} onClose={() => setFullScreen(false)} />
    </div>
  )
}

/**
 * Display a PDF file in a controlled modal
 *
 * @param file - the PDF file to display
 * @param filename - the name of the file for download
 * @param open - whether the modal is open
 * @param title - if present show a header with the title
 * @param onClose - callback when the modal is closed
 * @param initialScale - the initial scale of the PDF
 * @returns {JSX.Element}
 */
export function PdfModal({ file, filename, open, onClose, title, initialScale }) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Grow}>
      <PdfViewer
        file={file}
        filename={filename}
        title={title}
        initialScale={initialScale}
        expand={
          <IconButton color="primary" variant="contained" onClick={() => onClose()}>
            <CloseOutlinedIcon />
          </IconButton>
        }
        onClose={onClose}
      />
    </Dialog>
  )
}

/**
 * Display a PDF file
 *
 * @param file - the PDF file to display
 * @param filename - the name of the file for download
 * @param title - if present show a header with the title
 * @param expand - the expand button to display. If not provided, the viewer will not be expandable
 * @param onClose - callback when the modal is closed
 * @param initialScale - the initial scale of the PDF
 * @returns {JSX.Element}
 */
function PdfViewer({ file, filename, title, expand, onClose, initialScale = 1 }) {
  const theme = useTheme()

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [renderedPageNumber, setRenderedPageNumber] = useState(null)
  const [rotate, setRotate] = useState(0)
  const [pageScale, setPageScale] = useState(initialScale)

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  const changePage = (offset) => setPageNumber((prevPageNumber) => prevPageNumber + offset)
  const previousPage = () => changePage(-1)
  const nextPage = () => changePage(1)

  const changeRotation = (by) => setRotate((prevRotate) => (prevRotate + by + 360) % 360)
  const rotateLeft = () => changeRotation(-90)
  const rotateRight = () => changeRotation(90)

  const changePageScale = (by) =>
    setPageScale((prevScale) => {
      // This is to avoid floating point errors in JS
      const newScale = Number.parseFloat((prevScale + by).toFixed(1))
      return 0.5 <= newScale && newScale <= 2 ? newScale : prevScale
    })
  const decreaseScale = () => changePageScale(-0.1)
  const increaseScale = () => changePageScale(0.1)

  const isLoading = renderedPageNumber !== pageNumber

  return (
    <Paper sx={styles.container}>
      <Stack sx={{ height: Boolean(title) ? 52 + theme.mixins.toolbar.height : 52 }}>
        <PdfViewerHeader title={title} onClose={onClose} />
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', p: 1, height: 52 }}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <IconButton color="primary" variant="contained" onClick={decreaseScale}>
              <ZoomOutOutlinedIcon />
            </IconButton>
            <IconButton color="primary" variant="contained" onClick={increaseScale}>
              <ZoomInOutlinedIcon />
            </IconButton>
            <IconButton color="default" variant="outlined" sx={{ width: 60 }}>
              <Typography sx={{ color: 'text.primary' }}>{Math.round(pageScale * 100)}%</Typography>
            </IconButton>
            <IconButton color="default" variant="outlined" onClick={rotateLeft}>
              <RotateLeftOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
            <IconButton color="default" variant="outlined" onClick={rotateRight}>
              <RotateRightOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <IconButton color="default" variant="outlined" onClick={() => downloadFile(file, filename || 'file', 'application/pdf')}>
              <DownloadOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
            <IconButton color="default" variant="outlined" disabled={pageNumber <= 1} onClick={previousPage}>
              <LeftOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
            <Typography noWrap>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </Typography>
            <IconButton color="default" variant="outlined" disabled={pageNumber >= numPages} onClick={nextPage}>
              <RightOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
            {!title && expand}
          </Stack>
        </Stack>
      </Stack>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {isLoading && renderedPageNumber ? (
          <Page
            key={renderedPageNumber}
            pageNumber={renderedPageNumber}
            scale={pageScale}
            rotate={rotate}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ) : null}
        <Page
          key={pageNumber}
          pageNumber={pageNumber}
          scale={pageScale}
          rotate={rotate}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
          className={`${isLoading ? 'loadingPage' : ''}`}
        />
      </Document>
    </Paper>
  )
}

function PdfViewerHeader({ title, onClose }) {
  const theme = useTheme()

  if (!title) return null

  return (
    <AppBar position="static" sx={{ background: theme.palette.primary[600], boxShadow: 'none', height: theme.mixins.toolbar.height }}>
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="close" sx={{ mr: 1 }} onClick={() => onClose()}>
          <CloseOutlinedIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
