import { useState } from 'react'
import toast from 'react-hot-toast'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import usePageParams from '@shared/hooks/src/usePageParams'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { handleError, UserRole } from '@shared/utils'

import { CaretDownOutlinedIcon, CaretRightOutlinedIcon, DeleteOutlinedIcon, EditOutlinedIcon } from '@icons'
import Confirmation from '@components/Dialog/Confirmation'
import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import StatusChip from '@components/StatusChip'
import TableCollapsableCell from '@components/TableCollapsableCell'
import TableSortCell from '@components/TableSortCell'

import {
  useInsurance,
  useInsuranceCreation,
  useInsuranceRemove,
  useInsurances,
  useInsurancesFilters,
  useInsuranceUpdate,
} from '../Insurances.hooks'
import InsuranceUpdate from '../InsuranceUpdate'
import InsuranceDetailed from './InsuranceDetailed'

export default function InsurancesModal({ patientId, open, onClose, canDelete }) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="paper">
      <Content patientId={patientId} onClose={onClose} />
    </Dialog>
  )
}

function Content({ patientId, onClose }) {
  const [addOpen, setAddOpen] = useState(false)

  const [page, perPage] = usePageParams({ id: 'insurances' })
  const [{ insurancesSort }, updateFilters] = useInsurancesFilters()
  const { data, isRefreshing, isPending } = useInsurances(
    patientId,
    { order: insurancesSort, limit: perPage, offset: (page - 1) * perPage },
    { placeholderData: keepPreviousData }
  )

  const create = useInsuranceCreation(patientId)

  return (
    <>
      <DialogTitle onClose={onClose}>Insurance</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>ID</TableCell>
                  <TableCell>Insurance Provider / Subscriber</TableCell>
                  <TableCell>ID / Group</TableCell>
                  <TableCell>BIN / PCN</TableCell>
                  <TableSortCell direction={insurancesSort} onChange={(insurancesSort) => updateFilters({ insurancesSort })}>
                    Date
                  </TableSortCell>
                  <TableCell>Status</TableCell>
                  <RoleGuard allowed={[UserRole.Admin, UserRole.MA, UserRole.Support, UserRole.Provider]}>
                    <TableCell align="right">Actions</TableCell>
                  </RoleGuard>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((insurance) => (
                  <Insurance key={insurance.id} patientId={patientId} data={insurance} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Pagination id="insurances" loading={isPending} last={data?.length < perPage} />
        <RoleGuard allowed={[UserRole.Admin, UserRole.MA, UserRole.Support, UserRole.Provider, UserRole.CBO]}>
          <Button variant="contained" disabled={isPending} color="primary" onClick={() => setAddOpen(true)}>
            Add Insurance
          </Button>
        </RoleGuard>
      </DialogActions>
      <InsuranceUpdate onUpdate={create.mutateAsync} open={addOpen} onClose={() => setAddOpen(false)} />
    </>
  )
}

function Insurance({ patientId, data }) {
  const [detailedInfoOpen, setDetailedInfoOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const detailedInsurance = useInsurance(patientId, data.id, { enabled: editOpen || detailedInfoOpen })

  const update = useInsuranceUpdate(patientId, data.id)
  const remove = useInsuranceRemove(patientId)

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ item: insuranceId, close }) => ({
      title: 'Delete Insurance?',
      description: 'Are you sure you want to delete this insurance record?',
      rejectLabel: 'Cancel',
      confirmLabel: 'Delete',
      onReject: () => close(),
      onConfirm: () => {
        return remove
          .mutateAsync(insuranceId)
          .then(() => {
            close()
            toast.success('Insurance has been deleted')
          })
          .catch(handleError)
      },
    }),
  })

  return (
    <>
      <TableRow hover onClick={() => setDetailedInfoOpen((o) => !o)} sx={{ cursor: 'pointer' }}>
        <TableCell padding="checkbox">
          <IconButton size="small">{detailedInfoOpen ? <CaretDownOutlinedIcon /> : <CaretRightOutlinedIcon />}</IconButton>
        </TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>{data.id}</TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>
          <Typography variant="h5">{data.company}</Typography>
          <Typography>{data.subscriber}</Typography>
        </TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>
          <Stack direction="row">
            <Typography color="secondary" sx={{ pr: 4 }}>
              ID
            </Typography>
            <Typography>{data.idnum}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography color="secondary" sx={{ pr: 1 }}>
              Group
            </Typography>
            <Typography>{data.groupnum}</Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>
          <Stack direction="row">
            <Typography color="secondary" sx={{ pr: 2 }}>
              BIN
            </Typography>
            <Typography>{data.bin}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography color="secondary" sx={{ pr: 1 }}>
              PCN
            </Typography>
            <Typography>{data.pcn}</Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>{data.created ? dayjs(data.created).format('L') : ''}</TableCell>

        <TableCell sx={{ verticalAlign: 'top' }}>
          {data.validatedByAvita ? <StatusChip label="Validated" type="success" icon /> : null}
        </TableCell>

        <RoleGuard allowed={[UserRole.Admin, UserRole.MA, UserRole.Support, UserRole.Provider]}>
          <TableCell align="right" sx={{ verticalAlign: 'top' }}>
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'flex-end' }}>
              <IconButton
                variant="outlined"
                size="small"
                shape="rounded"
                disabled={remove.isPending || update.isPending}
                onClick={(e) => {
                  e.stopPropagation()
                  confirmRemove(data.id)
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              <IconButton
                variant="contained"
                color="inherit"
                size="small"
                shape="rounded"
                disabled={remove.isPending || update.isPending || (editOpen && detailedInsurance.isPending)}
                onClick={(e) => {
                  e.stopPropagation()
                  setEditOpen(true)
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Stack>
          </TableCell>
        </RoleGuard>
      </TableRow>
      <TableRow key={data.id}>
        <TableCollapsableCell open={detailedInfoOpen} colSpan={8}>
          <InsuranceDetailed data={detailedInsurance.data} isLoading={detailedInsurance.isPending} />
        </TableCollapsableCell>
      </TableRow>
      <InsuranceUpdate
        open={Boolean(editOpen && detailedInsurance.data)}
        insurance={detailedInsurance.data}
        onUpdate={update.mutateAsync}
        onClose={() => setEditOpen(false)}
      />
    </>
  )
}
