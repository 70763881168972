import { useState } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import range from 'lodash/range'

import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { PrescriptionsModal } from '@pages/Prescriptions'
import { usePatientPrescriptions, usePatientPrescriptionsFilters } from '@pages/Prescriptions/PatientPrescriptions'
import { PrescriptionDetailsModal } from '@pages/Prescriptions/Prescription'
import { ExpandAltOutlinedIcon } from '@icons'
import StatusChip from '@components/StatusChip'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'

export default function Rx() {
  const { id } = useParams()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [prescriptionId, setPrescriptionId] = useState()

  const { data, isRefreshing, isPending } = usePatientPrescriptions(id, { order: 'desc', limit: 5 })
  const [{ rxExpanded }, updateFilters] = usePatientPrescriptionsFilters()

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Tile
      title="Rx"
      refreshing={isRefreshing}
      action={
        <IconCircleButton disabled={(data?.length ?? 0) === 0} size="small" onClick={() => updateFilters({ rxExpanded: true })}>
          <ExpandAltOutlinedIcon />
        </IconCircleButton>
      }
      sx={{ height: '100%' }}
    >
      <PrescriptionDetailsModal prescriptionId={prescriptionId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
      <PrescriptionsModal id={id} open={rxExpanded} onClose={() => updateFilters({ rxExpanded: false })} />
      {showLoading && <Loading />}
      {showEmpty && <Empty />}
      {showData && (
        <List disablePadding data-testid="prescriptions">
          {data?.map((prescription) => (
            <Item
              key={prescription.id}
              prescription={prescription}
              onClick={() => {
                setPrescriptionId(prescription.id)
                setDetailsOpen(true)
              }}
            />
          ))}
        </List>
      )}
    </Tile>
  )
}

function Item({ prescription, onClick }) {
  const disabled = ['CANCELLED', 'CANCELED', 'DELETED'].includes(prescription.rxStatus.toUpperCase())

  return (
    <>
      <ListItemButton onClick={onClick} sx={{ opacity: disabled ? 0.4 : 1 }}>
        <Stack direction="row" sx={{ gap: 0.5, justifyContent: 'space-between', width: '100%' }}>
          <Typography noWrap>{`${prescription.drugName}${prescription.isRefill ? ' (Refill)' : ''}`}</Typography>
          <Stack direction="row" sx={{ gap: 1 }}>
            <StatusChip label={`${prescription.quantity}+${prescription.refills}`} type="warning" />
            <Typography>{prescription.createdAt ? dayjs(prescription.createdAt).format('L') : ''}</Typography>
            <StatusChip label={prescription.remain || 0} type="success" />
          </Stack>
        </Stack>
      </ListItemButton>
      <Divider />
    </>
  )
}

Item.Loading = function () {
  return (
    <>
      <ListItemButton>
        <Stack direction="row" sx={{ gap: 0.5, justifyContent: 'space-between', width: '100%' }}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography>
            <Skeleton width={110} />
          </Typography>
        </Stack>
      </ListItemButton>
      <Divider />
    </>
  )
}

function Loading() {
  return range(0, 5).map((i) => <Item.Loading key={i} />)
}

function Empty() {
  return (
    <Typography variant="h6" align="center" sx={{ color: 'text.secondary', p: 2 }}>
      No data to display
    </Typography>
  )
}
