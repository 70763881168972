import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Typography from '@components/_mui/Typography'

import { LabDetailsModal } from '@pages/Labs/Lab'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import StatusChip from '@components/StatusChip'
import TableSortCell from '@components/TableSortCell'

import { useLabs } from './Labs.hooks'

export default function Labs({ task }) {
  const [tab, setTab] = useState('recent')
  const [sort, setSort] = useState('ordered')
  const [order, setOrder] = useState('desc')
  const [{ page, perPage }, setPageParams] = useState({ page: 1, perPage: 5 })
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [labId, setLabId] = useState()

  const recentQuery = { sort: 'ordered', order: 'desc', limit: 3 }
  const allQuery = { sort, order, limit: perPage, offset: (page - 1) * perPage }
  const query = tab === 'recent' ? recentQuery : allQuery

  const { data, isPending } = useLabs(task.patientId, query, { placeholderData: keepPreviousData })

  return (
    <Stack>
      <LabDetailsModal labId={labId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
      <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
        <Tab label="Recent Labs" value="recent" />
        <Tab label="All" value="all" />
      </Tabs>
      <Divider />
      <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tab === 'recent' ? (
                  <TableCell>Type</TableCell>
                ) : (
                  <TableSortCell
                    active={sort === 'lab'}
                    direction={order}
                    onChange={(order) => {
                      setOrder(order)
                      setSort('lab')
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Type
                  </TableSortCell>
                )}
                <TableCell>Lab work</TableCell>
                {tab === 'recent' ? (
                  <TableCell>Ordered</TableCell>
                ) : (
                  <TableSortCell
                    active={sort === 'ordered'}
                    direction={order}
                    onChange={(order) => {
                      setOrder(order)
                      setSort('ordered')
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Ordered
                  </TableSortCell>
                )}
                {tab === 'recent' ? (
                  <TableCell>Results</TableCell>
                ) : (
                  <TableSortCell
                    active={sort === 'result_date'}
                    direction={order}
                    onChange={(order) => {
                      setOrder(order)
                      setSort('result_date')
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Results
                  </TableSortCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((lab) => (
                <TableRow
                  key={lab.id}
                  hover
                  onClick={() => {
                    setLabId(lab.id)
                    setDetailsOpen(true)
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell width={150}>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                      <Typography>{lab.lab}</Typography>
                      <StatusChip.Lab status={lab.status} noLabel />
                    </Stack>
                  </TableCell>
                  <TableCell>{lab.panelsToString}</TableCell>
                  <TableCell width={100}>{lab.ordered ? dayjs(lab.ordered).format('L') : ''}</TableCell>
                  <TableCell width={100}>{lab.resultDate ? dayjs(lab.resultDate).format('L') : ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RenderControl>
      {tab === 'all' && (
        <>
          <Divider />
          <Pagination
            disableRowsPerPage
            page={page}
            perPage={perPage}
            setPageParams={setPageParams}
            loading={isPending}
            last={data?.length < perPage}
            sx={{ mt: 1 }}
          />
        </>
      )}
    </Stack>
  )
}
