import React from 'react'
import { isMobile } from 'react-device-detect'
import dayjs from 'dayjs'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { UserRole, userRoleToLabel } from '@shared/utils'

import Avatar from '@components/Avatar'
import DateTooltip from '@components/DateTooltip'
import StatusChip from '@components/StatusChip'
import { bindDelayedHover } from '@components/UserCard/UserCard.utils'

import { usePrescription } from './PrescriptionCard.hooks'

const propTypes = {
  /** Patient id to fetch the prescription data */
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** prescription id to fetch the prescription data */
  prescriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** Popover props */
  popoverProps: PropTypes.object,
}

PrescriptionCard.propTypes = propTypes
PrescriptionCardPopper.propTypes = propTypes

export default function PrescriptionCard({ patientId, prescriptionId }) {
  const { data, isError, isPending } = usePrescription(patientId, prescriptionId)

  if (isPending) return <Loading />
  if (isError) return <Error />

  return (
    <Container>
      <Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          {data.pharmacy ? (
            <Typography variant="h5" noWrap>
              {data.pharmacy.name}
            </Typography>
          ) : (
            <div />
          )}
          <Stack direction="row" spacing={1} sx={{ whiteSpace: 'nowrap' }}>
            <Typography sx={{ fontWeight: 'light' }}>Rx ID</Typography>
            <Typography variant="h5">{data.id}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography noWrap>{`${data.drugName}${data.isRefill ? ' (Refill)' : ''}`}</Typography>
          <Stack direction="row" spacing={2}>
            <StatusChip label={`${data.quantity}+${data.refills}`} type="warning" />
            <StatusChip label={data.remain || 0} type="success" />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <Property label="Ordered">
          <DateTooltip date={data.createdAt}>
            <Typography sx={{ fontWeight: 'bold' }}>{data.createdAt ? dayjs(data.createdAt).format('L') : ''}</Typography>
          </DateTooltip>
        </Property>
        <Property label="Avita Rx Num">
          <Typography sx={{ fontWeight: 'bold' }}>{data.avitaRxNum || 'n/a'}</Typography>
        </Property>
        <Property label="Status">
          <StatusChip.Rx status={data.rxStatus} />
        </Property>
      </Stack>
      {data.provider && (
        <>
          <Divider />
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar user={data.provider} />
            <Box sx={{ display: 'grid' }}>
              <Typography variant="h5" noWrap>
                {data.provider.fullName}
              </Typography>
              <Typography sx={{ fontWeight: 'light' }}>{userRoleToLabel[UserRole.Provider]}</Typography>
            </Box>
          </Stack>
        </>
      )}
    </Container>
  )
}

export function PrescriptionCardPopper({ children, patientId, prescriptionId, popoverProps = {} }) {
  // Don't show popper if patientId or prescriptionId is not defined
  if (!patientId || !prescriptionId) return children

  return (
    <PopupState variant="popover" popupId="prescription-card-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 750)

        return (
          <div
            style={{ cursor: 'progress' }}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <div {...hoverHandler}>{children}</div>
            <HoverPopover {...popoverProps} {...bindPopover(popupState)}>
              <PrescriptionCard patientId={patientId} prescriptionId={prescriptionId} />
            </HoverPopover>
          </div>
        )
      }}
    </PopupState>
  )
}

function Loading() {
  return (
    <Container>
      <Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">
            <Skeleton width={200} />
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: 'light' }}>Rx ID</Typography>
            <Typography variant="h5">
              <Skeleton width={50} />
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>
            <Skeleton width={250} />
          </Typography>
          <Skeleton width={200} variant="rounded" />
        </Stack>
      </Stack>
      <Stack>
        <Property label="Ordered">
          <Typography>
            <Skeleton width={80} />
          </Typography>
        </Property>
        <Property label="Avita Rx Num">
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Property>
        <Property label="Status">
          <Typography>
            <Skeleton width={120} />
          </Typography>
        </Property>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton width={40} height={40} variant="circular" />
        <Box>
          <Typography variant="h5">
            <Skeleton width={150} />
          </Typography>
          <Typography sx={{ fontWeight: 'light' }}>
            <Skeleton width={80} />
          </Typography>
        </Box>
      </Stack>
    </Container>
  )
}

function Error() {
  return (
    <Container>
      <Stack sx={{ height: 200, justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" align="center" sx={{ width: 260 }}>
          Unexpected error occurred, please try again later.
        </Typography>
      </Stack>
    </Container>
  )
}

function Container({ children }) {
  return (
    <Paper sx={{ width: 540, height: '100%' }}>
      <Stack spacing={2} sx={{ p: 3 }}>
        {children}
      </Stack>
    </Paper>
  )
}

function Property({ label, children }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ fontWeight: 'light', minWidth: 100 }}>{label}</Typography>
      {children}
    </Stack>
  )
}
