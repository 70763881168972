import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useROIMedicalRecord(patientId, medicalRecordId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).roiMedicalRecords.id(medicalRecordId).details,
    queryFn: () => API.patients.id(patientId).roiMedicalRecords.id(medicalRecordId).details(),
    ...options,
  })
}
