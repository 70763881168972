import dayjs from 'dayjs'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  start: Yup.lazy(() => Yup.date().min('1901-01-01', 'Must be after 01/01/1901').nullable().typeError('Invalid Date').required('Required')),
  end: Yup.lazy(() =>
    Yup.date()
      .min(Yup.ref('start'), 'End date must be after start date')
      .max(dayjs(), "Must not exceed today's date")
      .nullable()
      .typeError('Invalid Date')
      .required('Required')
  ),
  entities: Yup.array().of(Yup.string()).min(1, 'Select at least one entity').required('Required'),
  fax: Yup.string().test('len', 'Please enter valid fax number', (val) => val.length === 0 || val.length === 10),
})

export const getInitialValues = (entities = []) => {
  return {
    start: null,
    end: null,
    entities,
    fax: '',
  }
}

export function toAPIData(values) {
  return {
    start_date: dayjs(values.start).format('YYYY-MM-DD'),
    end_date: dayjs(values.end).format('YYYY-MM-DD'),
    entities_selection: values.entities,
    fax_number: values.fax,
  }
}
