import * as Yup from 'yup'

/**
 * Get the initial values for any required fields.
 *
 * @param {Object} insurance - The insurance object.
 */
export const getInitialValues = (insurance) => {
  return {
    subscriber: insurance?.subscriber || '',
    company: insurance?.company || '',
    idnum: insurance?.idnum || '',
    groupnum: insurance?.groupnum || '',
    bin: insurance?.bin || '',
    pcn: insurance?.pcn || '',
    front: insurance?.front ? `data:image/*;base64,${insurance.front}` : null,
    back: insurance?.back ? `data:image/*;base64,${insurance.back}` : null,
  }
}

export const validationSchema = Yup.object().shape({
  subscriber: Yup.string().required('Subscriber name is required'),
  company: Yup.string().required('Insurance provider name is required'),
  idnum: Yup.string().required('Patient ID number is required'),
  groupnum: Yup.string(),
  bin: Yup.string(),
  pcn: Yup.string(),
})

/**
 * Convert formik newValues to a format that can be sent to the API.
 */
export const formikToApiData = (newValues, hidePhoto) => {
  const values = {
    subscriber: newValues.subscriber,
    company: newValues.company,
    idnum: newValues.idnum,
    groupnum: newValues.groupnum,
    bin: newValues.bin,
    pcn: newValues.pcn,
  }

  if (hidePhoto) return values

  const { front, back } = newValues

  if (front?.base64) {
    values.front_base64_image = front.base64
    values.front_mime_type = front.mimeType
  }

  if (!front) {
    values.front_remove = true
  }

  if (back?.base64) {
    values.back_base64_image = back.base64
    values.back_mime_type = back.mimeType
  }

  if (!back) {
    values.back_remove = true
  }

  return values
}
