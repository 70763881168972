import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useLabs(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).labs.list(query),
    queryFn: () => API.patients.id(patientId).labs.list(query),
    ...options,
  })
}
