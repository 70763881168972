import { useMemo } from 'react'
import { BooleanParam, createEnumParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import { Lookup, useDropdownOptions } from '@shared/providers/src/DropdownOptionsProvider'
import API from '@shared/services/src/API'
import { QK, TaskParam } from '@shared/utils'

export function useTasks(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).tasks.list(query),
    queryFn: () => API.patients.id(patientId).tasks.list(query),
    ...options,
  })
}

const OrderParam = createEnumParam(['asc', 'desc'])
const SortParam = createEnumParam(['identifier', 'created_at', 'kind', 'status', 'assignee'])

export function useTasksFilters() {
  return useFiltering({
    task: TaskParam,
    tasksExpanded: withDefault(BooleanParam, false),
    tasksOrder: withDefault(OrderParam, 'desc'),
    tasksSort: withDefault(SortParam, 'created_at'),
  })
}

export function useTaskTypes() {
  const options = useDropdownOptions()

  return useMemo(() => {
    const types = [
      ...options[Lookup.MATasks],
      ...options[Lookup.CSTasks],
      ...options[Lookup.PharmacyTasks],
      ...options[Lookup.ProviderTasks],
    ]
    return types.reduce((acc, { kind, label }) => ({ ...acc, [kind]: label }), {})
  }, [options])
}
