import SvgIcon from '@mui/material/SvgIcon'

export default function MoreHorizontalIcon(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M3.75 8.984a.875.875 0 1 0 1.75 0 .875.875 0 0 0-1.75 0zm4.375 0a.875.875 0 1 0 1.75 0 .875.875 0 0 0-1.75 0zm4.375 0a.875.875 0 1 0 1.75 0 .875.875 0 0 0-1.75 0z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
