import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { LockOutlinedIcon } from '@icons'

import { useForm, withBreakGlassCheck } from './BreakGlass.hooks'
import styles from './BreakGlass.styles'
import PasswordField from './components/PasswordField'

/**
 * User must enter their password to continue and their access will be recorded.
 *
 * @param {Number} patientId - The patient object.
 * @param {function} [onSuccess] - Callback function to be called when the user successfully unlocks the patient.
 * @param {function} [onClose] - Callback function to be called when the user closes the form.
 *
 * @example
 * <BreakGlassInDialog patientId={patientId}>
 *   <Page />
 * </BreakGlassInDialog>
 */

function BreakGlassInDialog({ patientId, onSuccess, onClose }) {
  const formik = useForm({ patientId, onSuccess })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Confidential</DialogTitle>
      <Fade in>
        <DialogContent dividers sx={styles.dialogContent}>
          <Stack spacing={1} sx={styles.content}>
            <Typography variant="h4" sx={{ color: 'primary.700' }}>
              You must enter your password to continue and your access will be recorded.
            </Typography>
            <PasswordField formik={formik} />
          </Stack>
        </DialogContent>
      </Fade>
      <DialogActions>
        {onClose && (
          <Button
            color="error"
            onClick={() => {
              formik.resetForm()
              onClose()
            }}
            data-testid="break-glass-cancel-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          loading={formik.isSubmitting}
          variant="contained"
          endIcon={<LockOutlinedIcon />}
          data-testid="break-glass-unlock-button"
        >
          Unlock
        </Button>
      </DialogActions>
    </form>
  )
}

export default withBreakGlassCheck(BreakGlassInDialog)
