import { useParams } from 'react-router'

import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { AppointmentStatus, QK } from '@shared/utils'

/**
 * Get a patient's future appointments.
 */
export function useUpcomingAppointments(options = {}) {
  const { id } = useParams()
  const query = { appointment_status: AppointmentStatus.Scheduled }

  // This was originally planned to use a param on the endpoint named 'upcoming' that
  // would return future scheduled appointments.
  //
  // However, there is some odd behavior in the platform.
  // When an 'unscheduled' appointment is created, it will always be in the past. And should be included
  // in determining if the warning about future appointments is shown.
  // These 'unscheduled' appointments ARE included when the status is 'scheduled' so we will use that.
  return useQuery({
    queryKey: QK.patients.id(id).appointments.list(query),
    queryFn: () => API.patients.id(id).appointments.list(query),
    ...options,
  })
}
