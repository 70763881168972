import { useFormik } from 'formik'
import * as Yup from 'yup'

import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import { TaskPriority, toTitleCase } from '@shared/utils'

import { outlineSearchStyling } from '@utils/StylesHelper'
import { PriorityIcon } from '@icons'

import { usePriorityUpdate } from '../Kanban.hooks'

export default function SelectPriority({ task, disabled = false }) {
  const updatePriority = usePriorityUpdate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { priority: task.priority },
    validationSchema: Yup.object({ priority: Yup.string().required('Priority is required') }),
    onSubmit: (values) => {
      if (task.priority === values.priority) return
      return updatePriority.mutate({ taskId: task.id, priority: values.priority })
    },
  })

  const handleBlur = (e) => {
    formik.handleBlur(e)
    formik.handleSubmit()
  }

  if (disabled) {
    return (
      <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
        <PriorityIcon value={task.priority} />
        <Typography>{toTitleCase(task.priority)}</Typography>
      </Stack>
    )
  }

  return (
    <TextField
      key={task.priority}
      select
      fullWidth
      id="priority"
      name="priority"
      size="small"
      variant="outlined"
      value={formik.values.priority}
      onChange={formik.handleChange}
      onBlur={handleBlur}
      error={formik.touched.priority && Boolean(formik.errors.priority)}
      helperText={formik.touched.priority && formik.errors.priority}
      sx={{ ml: -1, ...outlineSearchStyling }}
      slotProps={{
        select: { IconComponent: () => null },
      }}
    >
      {Object.entries(TaskPriority).map(([label, value]) => (
        <MenuItem key={value} value={value}>
          <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
            <PriorityIcon value={value} />
            <Typography>{label}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}
