import { useEffect, useState } from 'react'

export const useHighlight = (highlighted) => {
  const [isHighlighted, setIsHighlighted] = useState(false)

  useEffect(() => {
    if (highlighted) {
      setIsHighlighted(true)
      setTimeout(() => setIsHighlighted(false), 3000)
    }
  }, [highlighted])

  return isHighlighted
}
