import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@components/_mui/Typography'

import { useInsurances } from '@pages/Insurances'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'

const perPage = 6

export default function Insurances({ patient }) {
  const [page, setPage] = useState(1)

  const { data, isPending } = useInsurances(
    patient.id,
    { limit: perPage, offset: (page - 1) * perPage },
    { placeholderData: keepPreviousData }
  )

  return (
    <Stack sx={{ height: '100%' }}>
      <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No insurance found">
        <TableContainer>
          <Table size="small">
            <TableBody>
              {data?.map((i) => (
                <TableRow key={i.id} sx={{ '& .MuiTableCell-root': { verticalAlign: 'top' } }}>
                  <TableCell>
                    <Typography>{i.id}</Typography>
                    <Typography>{i.created ? dayjs(i.created).format('L') : ''}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="h5">{i.company}</Typography>
                    <Typography>{i.subscriber}</Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Typography color="secondary">ID:</Typography>
                      <Typography>{i.idnum}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="secondary">Group:</Typography>
                      <Typography>{i.groupnum}</Typography>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Typography color="secondary">BIN:</Typography>
                      <Typography>{i.bin}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="secondary">PCN:</Typography>
                      <Typography>{i.pcn}</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RenderControl>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Pagination
          disableRowsPerPage
          page={page}
          perPage={perPage}
          setPageParams={({ page }) => setPage(page)}
          loading={isPending}
          last={data?.length < perPage}
        />
      </Stack>
    </Stack>
  )
}
