import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, handleError, pageParam, QK } from '@shared/utils'

const LIMIT = 3

export function usePatientNotes(patientId) {
  const query = { limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.patients.id(patientId).notes.list(query),
    queryFn: ({ pageParam }) => API.patients.id(patientId).notes.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

export function useTaskNotes(taskId) {
  const query = { limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.tasks.id(taskId).notes.list(query),
    queryFn: ({ pageParam }) => API.tasks.id(taskId).notes.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

export function usePatientNoteCreation(patientId) {
  return useMutation({
    mutationFn: (message) => API.patients.id(patientId).notes.create({ message }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).notes.lists }),
    onError: handleError,
  })
}

export function useTaskNoteCreation(taskId) {
  return useMutation({
    mutationFn: (message) => API.tasks.id(taskId).notes.create({ message }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.tasks.id(taskId).notes.lists }),
    onError: handleError,
  })
}
