import React from 'react'
import dayjs from 'dayjs'

import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { CarOutlined } from '@icons'
import Avatar from '@components/Avatar'
import Section from '@components/Details/Section'

const styles = {
  container: {
    p: 0,
    '& .MuiTimelineItem-root:before': {
      flex: 0,
      padding: 0,
    },
  },
  dot: {
    p: 0,
    borderWidth: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export default function FillsHistorySection({ data = [], mini = false }) {
  const hasFills = data.length > 0

  return (
    <Section mini={mini} title="Fills History" icon={mini ? null : <CarOutlined />}>
      {!hasFills && <Typography>Information is unavailable</Typography>}
      {hasFills && (
        <Timeline sx={styles.container}>
          {data.toReversed().map((fill, i) => {
            const isFirst = i === 0

            return (
              <React.Fragment key={fill.id}>
                {fill.delivered && (
                  <TimelineItem>
                    <TimelineSeparator>
                      {!isFirst && <TimelineConnector />}
                      <TimelineDot variant="outlined" sx={styles.dot}>
                        <Avatar.Company />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent sx={styles.content}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                        <b>Fill #{fill.id} Delivered to Patient</b>
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {formatDate(fill.delivered)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot variant="outlined" sx={styles.dot}>
                      <Avatar.Company />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent sx={styles.content}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                      <b>Fill #{fill.id} Shipped to Patient</b>
                    </Typography>
                    {(fill.trackingUrl || fill.trackingNumber) && (
                      <Stack direction="row" spacing={1}>
                        <Typography>Tracking number</Typography>
                        <Link target="_blank" href={fill.trackingUrl || '#'}>
                          {fill.trackingNumber}
                        </Link>
                      </Stack>
                    )}
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {formatDate(fill.shipped)}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </React.Fragment>
            )
          })}
        </Timeline>
      )}
    </Section>
  )
}

FillsHistorySection.Loading = ({ mini = false }) => (
  <Section mini={mini} title="Fills History" icon={mini ? null : <CarOutlined />}>
    <Timeline sx={styles.container}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" sx={styles.dot}>
            <Skeleton variant="circular" width={40} height={40} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle1">
            <Skeleton width={200} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={120} />
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined" sx={styles.dot}>
            <Skeleton variant="circular" width={40} height={40} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle1">
            <Skeleton width={200} />
          </Typography>
          <Typography>
            <Skeleton width={300} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={120} />
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  </Section>
)

export const formatDate = (date) => {
  return date ? dayjs(date).format('L LT') : 'Pending'
}
