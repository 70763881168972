import { useDropzone } from 'react-dropzone'
import { defaults } from 'lodash'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import RejectionFiles from '@components/RejectionFiles'
import UploadCover from '@assets/upload/upload.svg'

import FilesPreview from './FilesPreview'

const DropzoneWrapper = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(4, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': { opacity: 0.65, cursor: 'pointer' },
}))

FileUploader.propTypes = {
  /** Optional label */
  label: PropTypes.string,

  /** Array of files */
  files: PropTypes.array,

  /** Function to change selected files */
  onChange: PropTypes.func,

  /** Can show error state */
  error: PropTypes.bool,

  /** Can select multiple files */
  multiple: PropTypes.bool,

  /**
   * Dropzone options
   * @see https://react-dropzone.js.org/#src
   */
  dropzoneOptions: PropTypes.object,

  /** Description of allowed files */
  allowedDescription: PropTypes.string,

  /** Can pass mui sx style */
  sx: PropTypes.object,
}

/**
 * Drag and drop file uploader with multiple files possibility.
 */
export default function FileUploader({ label, error, files, onChange, allowedDescription, dropzoneOptions, sx }) {
  const { multiple, ...rest } = defaults(dropzoneOptions, {
    multiple: false,
    maxFiles: 1,
  })

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    ...rest,
    onDropAccepted: (acceptedFiles) => {
      onChange(files && multiple ? [...files, ...acceptedFiles] : acceptedFiles)
    },
  })

  const onRemove = (file) => {
    const filteredItems = files && files.filter((_file) => _file !== file)
    onChange(filteredItems)
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {label && <Typography sx={{ color: 'text.secondary', mb: 1 }}>{label}</Typography>}
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.65 }),
          ...((isDragReject || fileRejections.length > 0 || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: 1,
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          <CardMedia component="img" image={UploadCover} sx={{ width: 150 }} />
          <Stack sx={{ p: 3 }} spacing={1}>
            <Typography variant="h5">Drag & Drop or Select file{multiple ? 's' : ''}</Typography>
            <Stack>
              <Typography color="secondary">Drop file{multiple ? 's' : ''} here or click to select from your device.</Typography>
              {allowedDescription && (
                <Typography variant="body2" color="secondary" sx={{ mt: 1 }}>
                  {allowedDescription}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DropzoneWrapper>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
      {files?.length > 0 && <FilesPreview files={files} onRemove={onRemove} />}
    </Box>
  )
}
