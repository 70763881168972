import { Link as RouterLink } from 'react-router'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { EditOutlinedIcon, LoginOutlinedIcon } from '@icons'

export default function Other({ user }) {
  return (
    <RoleGuard allowed={[UserRole.Admin]}>
      <Stack spacing={1}>
        <Divider />
        <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
          Admin Utilities
        </Typography>
        <Stack direction="row" sx={{ gap: 1, flexWrap: 'wrap' }}>
          {user?.role !== UserRole.Patient && (
            <Button component={RouterLink} startIcon={<EditOutlinedIcon />} to={`/app/users/${user.id}/edit`} disabled={!user}>
              Edit
            </Button>
          )}
          <Button
            component={RouterLink}
            startIcon={<LoginOutlinedIcon />}
            to={`/app/login-as?user_id=${user?.id}`}
            disabled={!user || user.disabled || (user.role === UserRole.Patient && !user.enrolled)}
          >
            Login
          </Button>
        </Stack>
      </Stack>
    </RoleGuard>
  )
}
