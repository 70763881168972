import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import useSendMessage from '@shared/messaging/src/hooks/useSendMessage'
import { richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { handleError, processRichTextJsonToMessage, TaskStatus } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import InputControl from '@components/InputControl'
import { getExtensions, RichTextEditor } from '@components/RichText'

import { Patient } from '../../../Tasks/components/TaskInfo/TaskInfo.utils'
import { usePARsFilters } from '../../PatientAtRisk.hooks'
import { useMarkCompleted } from './TaskInfo.hooks'

export function ResolveDialog({ taskId, patient, reasonsKey, freeTextReason, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <ResolveContent taskId={taskId} patient={patient} reasonsKey={reasonsKey} freeTextReason={freeTextReason} onClose={onClose} />
    </Dialog>
  )
}

export function FollowUpDialog({ patient, defaultMessage = null, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <FollowUpContent patient={patient} defaultMessage={defaultMessage} onClose={onClose} />
    </Dialog>
  )
}

function FollowUpContent({ patient, defaultMessage = null, onClose }) {
  const editorRef = useRef()
  const isEmpty = editorRef.current?.isEmpty

  const sendMessage = useSendMessage({ userId: patient?.id })

  const handleSend = () => {
    const message = editorRef.current?.getJSON()
    sendMessage
      .mutateAsync({ message: processRichTextJsonToMessage(message) })
      .then(() => toast.success('Message sent'))
      .then(onClose)
      .catch(handleError)
  }

  return (
    <>
      <DialogTitle>PAR Follow Up</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Typography variant="h4">Send a message to this patient who is at risk of falling out of care</Typography>
          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150, maxHeight: 400 } }]}>
            <RichTextEditor ref={editorRef} extensions={getExtensions({ enableQPhrases: true })} initialValue={defaultMessage} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={sendMessage.isPending} disabled={isEmpty} variant={isEmpty ? 'text' : 'contained'} onClick={handleSend}>
          Send Message
        </Button>
      </DialogActions>
    </>
  )
}

function ResolveContent({ taskId, patient, reasonsKey, freeTextReason, onClose }) {
  const [, updateFilters] = usePARsFilters()

  const reasons = useLookup(reasonsKey, (v) => {
    return v.sort((a, b) => {
      if (a === freeTextReason) return 1
      if (b === freeTextReason) return -1
      return a.localeCompare(b)
    })
  })

  const complete = useMarkCompleted(taskId)

  const formik = useFormik({
    initialValues: { reason: '', comments: '' },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('Please select one of the options'),
      comments: Yup.string().when('reason', {
        is: freeTextReason,
        then: (schema) => schema.required('Please provide details'),
      }),
    }),
    onSubmit: (values) => {
      let reason = values.reason
      if (reason === freeTextReason) {
        reason = reason + ': ' + values.comments
      }
      return complete.mutateAsync({ reason }).then(() => {
        onClose()
        updateFilters({ par: undefined })
      })
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>PAR Resolve</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Patient user={patient} />
          <Typography variant="h5">Please select reason</Typography>
          <RadioGroup
            aria-labelledby="reason"
            name="reason"
            value={formik.values.reason}
            onChange={(e) => {
              formik.setFieldValue('comments', '')
              formik.setFieldTouched('comments', false)
              formik.handleChange(e)
            }}
          >
            {reasons.map((reason) => (
              <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
            ))}
            <Collapse in={formik.values.reason === freeTextReason}>
              <Box sx={{ mt: 1, ml: 4 }}>
                <InputControl field="comments" hideLabel formikProps={formik}>
                  <TextField fullWidth multiline rows={3} disabled={formik.isSubmitting} />
                </InputControl>
              </Box>
            </Collapse>
          </RadioGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" disabled={!formik.dirty} loading={formik.isSubmitting}>
          Submit
        </Button>
      </DialogActions>
    </form>
  )
}

export const isNotDone = (task) => ![TaskStatus.Completed, TaskStatus.Expired, TaskStatus.NotApplicable].includes(task.status)
