import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import usePageParams from '@shared/hooks/src/usePageParams'

import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import StatusChip from '@components/StatusChip'
import TableSortCell from '@components/TableSortCell'

import { usePatientLabs, usePatientLabsFilters } from '../PatientLabs/PatientLabs.hooks'

export default function LabsModal({ patientId, open, onClose }) {
  const [{ labsTab, labsOrder, labsSort }, updateFilters] = usePatientLabsFilters()
  const [page, perPage, setPageParams] = usePageParams({ id: 'labs' })

  const { data, isRefreshing, isPending } = usePatientLabs(
    patientId,
    {
      filter: labsTab,
      order: labsOrder,
      sort: labsSort,
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )

  // const confirmCancel = useDialog({
  //   component: Confirmation,
  //   props: ({ close }) => ({
  //     title: 'Cancel lab?',
  //     description: 'If canceled, the patient will not be able to complete the labwork.',
  //     rejectLabel: 'Keep Lab',
  //     confirmLabel: 'Cancel Lab',
  //     onReject: () => close(),
  //     onConfirm: async () => {
  //       await new Promise((resolve) => setTimeout(resolve, 2000))
  //       close()
  //     },
  //   }),
  // })

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="body">
      <DialogTitle>Labs</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <Tabs
          value={labsTab}
          onChange={(e, labsTab) => {
            updateFilters({ labsTab })
            setPageParams({ page: 1, perPage })
          }}
          sx={{ mb: 1 }}
        >
          <Tab label="All" value="all" />
          <Tab label="Complete" value="complete" />
          <Tab label="Pending" value="pending" />
          <Tab label="Follow-up" value="follow-up" />
          <Tab label="Failed" value="failed" />
        </Tabs>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableSortCell
                    active={labsSort === 'lab'}
                    direction={labsOrder}
                    onChange={(labsOrder) => {
                      updateFilters({ labsOrder, labsSort: 'lab' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Type
                  </TableSortCell>
                  <TableCell>Labwork</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableSortCell
                    active={labsSort === 'result_date'}
                    direction={labsOrder}
                    onChange={(labsOrder) => {
                      updateFilters({ labsOrder, labsSort: 'result_date' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Result
                  </TableSortCell>
                  <TableSortCell
                    active={labsSort === 'ordered'}
                    direction={labsOrder}
                    onChange={(labsOrder) => {
                      updateFilters({ labsOrder, labsSort: 'ordered' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Ordered
                  </TableSortCell>
                  {/*<RoleGuard allowed={[UserRole.Admin, UserRole.Provider]}>*/}
                  {/*  <TableCell align="right">Actions</TableCell>*/}
                  {/*</RoleGuard>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((lab) => (
                  <Lab key={lab.id} data={lab} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <Pagination id="labs" loading={isPending} last={data?.length < perPage} />
      </DialogActions>
    </Dialog>
  )
}

function Lab({ data }) {
  return (
    <TableRow hover onClick={() => window.open(`${import.meta.env.VITE_URL}/app/labs/${data.id}`, '_blank')} sx={{ cursor: 'pointer' }}>
      <TableCell>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Typography>{data.lab}</Typography>
          <StatusChip.Lab status={data.status} noLabel />
        </Stack>
      </TableCell>
      <TableCell>{data.panelsToString}</TableCell>
      <TableCell>{data.provider?.name}</TableCell>
      <TableCell>{data.resultDate ? dayjs(data.resultDate).format('L') : ''}</TableCell>
      <TableCell>{data.ordered ? dayjs(data.ordered).format('L') : ''}</TableCell>
      {/*<RoleGuard allowed={[UserRole.Admin, UserRole.Provider]}>*/}
      {/*  <TableCell align="right">*/}
      {/*    <IconButton*/}
      {/*      sx={{ m: -0.5 }}*/}
      {/*      disabled={!data.cancellable}*/}
      {/*      onClick={(e) => {*/}
      {/*        e.stopPropagation()*/}
      {/*        confirmCancel()*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <DeleteOutlinedIcon />*/}
      {/*    </IconButton>*/}
      {/*  </TableCell>*/}
      {/*</RoleGuard>*/}
    </TableRow>
  )
}
