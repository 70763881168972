import { Link } from 'react-router'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/styles/useTheme'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { resolveLogo } from '@utils/Helper'

import PharmacySelector from '../PharmacySelector'

export default function Header({ open }) {
  const theme = useTheme()

  return (
    <Stack>
      <DrawerHeaderStyled open={open}>
        <ButtonBase disableRipple component={Link} to="/app" sx={{ width: open ? 'auto' : 35, height: 35 }}>
          <Box component="img" src={resolveLogo(theme.palette.mode, !open)} alt="logo" sx={{ width: '100%', height: 'auto' }} />
        </ButtonBase>
      </DrawerHeaderStyled>
      <RoleGuard allowed={[UserRole.Pharmacy]}>
        <PharmacySelector minified={!open} />
      </RoleGuard>
    </Stack>
  )
}

const DrawerHeaderStyled = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
}))
