import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Typography from '@components/_mui/Typography'

import Property from '@components/Property'

export default function PatientInfo({ patient }) {
  const { homeAddress, shippingAddress } = patient

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Last Name" value={patient.lastName} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="First Name" value={patient.firstName} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="SSN" value={patient.ssn} clipboard />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="DOB" value={patient.dob} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Birth Gender" value={patient.bornAs} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Height" value={patient.heightInFeetAndInches} clipboard />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Phone" value={patient.phone} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Email" value={patient.email} clipboard />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Property label="Weight" value={`${patient.weight} lbs`} clipboard />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Typography variant="h5">Home Address</Typography>
          <Property label="Address" value={homeAddress.address} clipboard sx={{ mt: 1 }} />
          {homeAddress.address2 && <Property label="Apt, Suite" value={homeAddress.address2} clipboard sx={{ mt: 1 }} />}

          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Property label="City" value={homeAddress.city} clipboard />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Property label="State" value={homeAddress.state} clipboard />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Property label="Zip" value={homeAddress.zip} clipboard />
            </Grid>
          </Grid>
        </Grid>

        {shippingAddress && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Typography variant="h5">Shipping Address</Typography>
            <Property label="Address" value={shippingAddress.address} clipboard sx={{ mt: 1 }} />
            {shippingAddress.address2 && <Property label="Apt, Suite" value={shippingAddress.address2} clipboard sx={{ mt: 1 }} />}

            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Property label="City" value={shippingAddress.city} clipboard />
              </Grid>
              <Grid size={{ xs: 12, sm: 2 }}>
                <Property label="State" value={shippingAddress.state} clipboard />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Property label="Zip" value={shippingAddress.zip} clipboard />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
