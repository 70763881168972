import { forwardRef, useMemo, useState } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { useLayoutState } from '@providers/LayoutStateProvider'
import Transitions from '@components/Transitions'

NavItem.propTypes = {
  selected: PropTypes.bool,
  target: PropTypes.bool,
  external: PropTypes.bool,
  url: PropTypes.string,
  icon: PropTypes.elementType,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  action: PropTypes.node,
  level: PropTypes.number,
}

export default function NavItem({
  selected = false,
  disabled = false,
  url,
  target = false,
  external = false,
  icon,
  title,
  action,
  level = 1,
}) {
  const theme = useTheme()
  const [drawerOpen] = useLayoutState()
  const [open, setOpen] = useState(false)

  // AnchorEl is the element that the Popper will be positioned relative to when the drawer is closed
  const [anchorEl, setAnchorEl] = useState(null)

  // Flag to handle showing the flyout menu/tooltips
  const openMini = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)
  const handleOpen = (event) => {
    // The check for the drawer being open is probably not needed.
    // this is only called when it's closed. But it came from Mantis so leaving as is.
    setAnchorEl(null)
    if (drawerOpen) {
      setOpen(!open)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const itemTarget = target ? '_blank' : '_self'

  // Handle external links. We need to memoize the internal's or it will re-render every time.
  const internalLink = useMemo(() => forwardRef((itemProps, ref) => <Link to={url} ref={ref} {...itemProps} />), [url])
  const listItemProps = external
    ? {
        component: 'a',
        href: url,
        target: itemTarget,
      }
    : { component: internalLink }

  const Icon = icon
  const itemIcon = icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false

  return (
    <ListItemButton
      {...listItemProps}
      id={`sidebar-${title}`}
      data-testid={`sidebar-${title}`}
      disabled={disabled}
      selected={selected}
      {...(!drawerOpen && { onMouseEnter: handleOpen, onMouseLeave: handleClose })}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: 'text.secondary',
            ...(!drawerOpen && {
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
            }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      {/* Submenu items */}
      {(drawerOpen || (!drawerOpen && level !== 1)) && <ListItemText primary={<Typography variant="h6">{title}</Typography>} />}

      {/* Drawer is closed so include a tooltip if it is a top level item */}
      {!drawerOpen && level === 1 && (
        <PopperStyled
          open={openMini}
          anchorEl={anchorEl}
          placement="right-start"
          style={{ zIndex: 2001 }}
          popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [-12, 1] } }] }}
        >
          {({ TransitionProps }) => (
            <Transitions in={openMini} {...TransitionProps}>
              <Paper
                sx={{
                  overflow: 'hidden',
                  mt: 1.5,
                  boxShadow: theme.customShadows.z1,
                  backgroundImage: 'none',
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ m: 2 }}>
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </PopperStyled>
      )}

      {/* Display any count bubbles */}
      {drawerOpen && action}
      {!drawerOpen && <Box sx={{ ml: -5, mt: -4 }}>{action}</Box>}
    </ListItemButton>
  )
}

const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))
