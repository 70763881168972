import { Outlet } from 'react-router'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const styles = {
  root: {
    display: 'flex',
    width: '100%',
  },
  main: {
    width: '100%',
    flexGrow: 1,
    position: 'relative',
  },
  container: {
    position: 'relative',
    minHeight: (theme) => `calc(100vh - ${theme.mixins.toolbar.height}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
}

/**
 * Layout for authenticated users.
 * It contains the sidebar, header and the main content.
 */
export default function Layout() {
  return (
    <Box sx={styles.root}>
      <Header />
      <Sidebar />
      <Box component="main" sx={styles.main}>
        <Toolbar />
        <Container sx={styles.container}>
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  )
}
