import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { handleError } from '@shared/utils'

import { useTasksFilters } from '../../Tasks.hooks'
import { useMarkCompleted } from './TaskInfo.hooks'
import { isNotDone, Patient } from './TaskInfo.utils'

export default function PendingQuestOrderInfo({ data, patient }) {
  const [resolveOpen, setResolveOpen] = useState(false)

  if (isNotDone(data)) {
    return (
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={() => setResolveOpen(true)}>
          Resolve
        </Button>
        <Dialog open={resolveOpen} onClose={() => setResolveOpen(false)} fullWidth maxWidth="xs">
          <ResolveContent task={data} patient={patient} onClose={() => setResolveOpen(false)} />
        </Dialog>
      </Stack>
    )
  }
}

function ResolveContent({ task, patient, onClose }) {
  const [, updateFilters] = useTasksFilters()
  const reasons = useLookup(Lookup.ReasonsPendingQuestOrder)
  const markCompleted = useMarkCompleted(task.id)

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: ({ reason }) => {
      return markCompleted
        .mutateAsync(reason)
        .then(() => updateFilters({ task: undefined }))
        .catch(handleError)
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Resolve Pending Quest Order</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <RadioGroup aria-labelledby="reason" name="reason" value={formik.values.reason} onChange={formik.handleChange}>
            {reasons.map((reason) => (
              <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
            ))}
          </RadioGroup>
        </Stack>
        {formik.touched.reason && Boolean(formik.errors.reason) && <FormHelperText error>{formik.errors.reason}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} type="submit" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </form>
  )
}
