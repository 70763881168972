import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

const styles = {
  answer: (flagged) => ({
    p: 1,
    backgroundColor: flagged ? 'error.lighter' : 'inherit',
    borderBottom: '1px solid',
    borderColor: 'divider',
  }),
}

/**
 * Card to display assessment results
 *
 * @param answers - Array of answers
 * @param rest - Other props
 */
export default function AssessmentAnswersCard({ answers, ...rest }) {
  return (
    <Paper sx={{ p: 2 }} {...rest}>
      <Stack spacing={1}>
        {answers.map((answer) => (
          <AssessmentAnswerMap key={answer.index} answer={answer} />
        ))}
      </Stack>
    </Paper>
  )
}

function AssessmentAnswerMap({ answer }) {
  if (answer.sub_question?.length) {
    const subQuestions = typeof answer.sub_question == 'string' ? answer.sub_question : answer.sub_question.join(', ')

    return (
      <Stack spacing={0.5} sx={styles.answer(answer.flagged)}>
        <AssessmentAnswer answer={answer} />
        <Typography sx={{ color: answer.flagged ? 'text.primary' : 'text.secondary' }}>{subQuestions}</Typography>
      </Stack>
    )
  }

  return <AssessmentAnswer answer={answer} sx={styles.answer(answer.flagged)} />
}

function AssessmentAnswer({ answer, sx }) {
  const color = answer.flagged ? 'text.primary' : 'text.secondary'
  const isArray = Array.isArray(answer.value)

  return (
    <Stack direction="row" spacing={2} sx={[{ justifyContent: 'space-between' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography sx={{ color }}>{answer.text}</Typography>
      <Typography sx={{ color, fontWeight: 'bold' }} align="right">
        {isArray ? answer.value.join(', ') : answer.value}
      </Typography>
    </Stack>
  )
}
