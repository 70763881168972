import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { getPhoneFormatted, handleError, includesOneOfErrorMessages } from '@shared/utils'

import usePatientEncounter from '@hooks/usePatientEncounter'
import { usePatientPrescriptions } from '@pages/Prescriptions/PatientPrescriptions'
import InputControl from '@components/InputControl'

import { useApplicationSubmit } from './GileadApplication.hooks'

export default function SubmitGileadForm({ open, setOpen }) {
  const [confirm, setConfirm] = useState(false)
  const [isAlreadyInProgress, setIsAlreadyInProgress] = useState(false)

  const me = useMe()
  const patient = usePatient()
  const { data, isPending } = usePatientPrescriptions(patient.id, { order: 'desc' })
  const submitApplication = useApplicationSubmit(patient.id)

  const formik = useFormik({
    initialValues: {
      prescriptionId: '',
      attestationLetter: true,
    },
    validationSchema: Yup.object({
      prescriptionId: Yup.string().required('Required'),
      attestationLetter: Yup.boolean(),
    }),
    onSubmit: () => {
      setOpen(false)
      setConfirm(true)
    },
  })

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => formik.resetForm(), 300)
  }

  const selectedPrescription = useMemo(() => {
    return (data || []).find((rx) => rx.id === formik.values.prescriptionId)
  }, [data, formik.values.prescriptionId])

  const encounterId = selectedPrescription?.encounterId
  const provider = selectedPrescription?.provider
  const isInactivePatient = patient.disabled || !patient.active
  const providerSignatureMissing = provider && !provider.hasSignature
  const isDisabled = isInactivePatient || providerSignatureMissing

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  const { data: encounter } = usePatientEncounter(patient.id, encounterId, { enabled: Boolean(encounterId) })

  const handleSubmit = () => {
    return submitApplication
      .mutateAsync({
        prescription_id: formik.values.prescriptionId,
        attestation: formik.values.attestationLetter,
      })
      .then(() => {
        setConfirm(false)
        setTimeout(() => formik.resetForm(), 300)
        toast.success('The Gilead AA application was submitted')
      })
      .catch((e) => {
        if (includesOneOfErrorMessages(e, ['User already has active applications'])) {
          setConfirm(false)
          setIsAlreadyInProgress(true)
          return
        }
        handleError(e)
      })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle>Submit Gilead Advancing Access application</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={3}>
              <Alert severity="info" variant="border">
                This will send a notification to the patient to start the Gilead AA submittal process! <br />
                The patient and provider will be required to sign the application prior to sending it to Gilead.
              </Alert>
              <InputControl field="prescriptionId" formikProps={formik}>
                <TextField label="Select Prescription" fullWidth select required>
                  {showLoading && <MenuItem value={undefined}>Loading...</MenuItem>}
                  {showEmpty && <MenuItem value={undefined}>No prescriptions found</MenuItem>}
                  {showData &&
                    data.map((rx) => {
                      const date = rx.createdAt ? dayjs(rx.createdAt).format('L') : ''
                      return (
                        <MenuItem key={rx.id} value={rx.id}>
                          {[date, rx.drugName, rx.isRefill ? 'Refill' : ''].join(' - ')}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </InputControl>
              <Alert severity="info" variant="border" icon={false}>
                <AlertTitle>Encounter</AlertTitle>
                {encounter ? `${encounter.id} - ${encounter.description}` : 'Unknown'}
              </Alert>
              <FormControlLabel
                name="attestationLetter"
                label="Include attestation letter"
                control={<Checkbox checked={formik.values.attestationLetter} onChange={formik.handleChange} />}
                value="end"
                labelPlacement="end"
              />
              {isDisabled && (
                <Alert severity="error" variant="border">
                  <Typography>Can not submit the application</Typography>
                  <ul>
                    {isInactivePatient && <Typography component="li">Patient is disabled or inactive </Typography>}
                    {providerSignatureMissing && (
                      <Typography component="li">The provider for the selected prescription has not provided a signature</Typography>
                    )}
                  </ul>
                </Alert>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="text">
              Cancel
            </Button>
            <Button disabled={isDisabled} type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={confirm}
        onClose={() => {
          setOpen(true)
          setConfirm(false)
        }}
        sx={{
          '& .MuiDialog-container .MuiPaper-root': {
            width: '100%',
            maxWidth: 560,
          },
        }}
      >
        <DialogTitle>Confirm application</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Alert severity="info" variant="border" icon={false}>
                The following information will be included on the application.
              </Alert>
            </Stack>
            <Stack spacing={2}>
              <Property label="Application Type">PAP / MAP</Property>
              <Property label="Patient Name">{patient.fullName}</Property>
              <Property label="Provider Name">{provider?.fullName}</Property>
              <Property label="Patient Representative">{me.fullName}</Property>
              <Property label="Patient Address">
                <Stack>
                  <Typography>{patient.homeAddress.address}</Typography>
                  {patient.homeAddress.address2 && <Typography>{patient.homeAddress.address2}</Typography>}
                  <Typography>{[patient.homeAddress.city, patient.homeAddress.state, patient.homeAddress.zip].join(', ')}</Typography>
                </Stack>
              </Property>
              <Property label="Patient Phone">{patient.phone ? getPhoneFormatted(patient.phone) : undefined}</Property>
              <Property label="Patient Email">
                <Typography sx={{ wordBreak: 'break-word' }}>{patient.email}</Typography>
              </Property>
              <Property label="Patient DOB">{patient.dob ? dayjs(patient.dob).format('L') : ''}</Property>
              <Property label="Patient SSN">{patient.social ? patient.social.slice(-4) : ''}</Property>
              <Property label="Patient is uninsured">{patient.insured ? 'No' : 'Yes'}</Property>
              <Property label="Household Annual Income">{patient.income}</Property>
              <Property label="Number of household members">{patient.household}</Property>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="text"
            onClick={() => {
              setOpen(true)
              setConfirm(false)
            }}
          >
            Cancel
          </Button>
          <Button loading={submitApplication.isPending} onClick={handleSubmit} variant="contained">
            Confirm and Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={isAlreadyInProgress} onClose={() => setIsAlreadyInProgress(false)}>
        <DialogTitle>Submit Gilead Advancing Access application</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            <Typography variant="h4">Application is in progress</Typography>
            <Typography>An application for the patient is in progress and the status is “Sent to patient”</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsAlreadyInProgress(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function Property({ label, children }) {
  return (
    <Stack
      sx={{
        gap: { xs: 0, sm: 1 },
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', minWidth: 240 }}>{label}</Typography>
      {children}
    </Stack>
  )
}
