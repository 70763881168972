import Box from '@mui/material/Box'

import { useVoiceWidgetState } from '@providers/VoiceWidgetStateProvider'
import { PhoneOutlinedIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function PhoneCall() {
  const { toggle } = useVoiceWidgetState()

  return (
    <Box>
      <IconButton id="phone-call-button" onClick={() => toggle()} aria-label="Phone Call" data-testid="header-phone-call">
        <PhoneOutlinedIcon style={{ fontSize: 19 }} />
      </IconButton>
    </Box>
  )
}
