import { useParams } from 'react-router'
import dayjs from 'dayjs'
import range from 'lodash/range'
import { useEventListener } from 'usehooks-ts'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@components/_mui/Typography'

import { RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { QK, toTitleCase, UserRole } from '@shared/utils'

import { ExpandAltOutlinedIcon, PlusOutlinedIcon } from '@icons'
import { usePatientAccessible } from '@components/PatientAccessGuard'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'
import { useAddNote, useNotes, useNotesFilters } from './Notes.hooks'
import { NotesModal } from './NotesModal'

/**
 * Displays a tile with the latest notes for the patient
 */
export default function Notes() {
  const { id } = useParams()

  const me = useMe()
  const patientAccessible = usePatientAccessible(id)
  const [{ notesExpanded }, updateFilters] = useNotesFilters()
  const { data, isRefreshing, isPending } = useNotes(id, { limit: 6 })

  const addNote = useAddNote(id)

  useEventListener('message', ({ data }) => {
    if (data === 'note-created') {
      queryClient.invalidateQueries({ queryKey: QK.patients.id(id).notes.lists })
    }
  })

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  const canAddNotes = me.role !== UserRole.Manager && patientAccessible

  return (
    <Tile
      title="Notes"
      refreshing={isRefreshing}
      action={
        <Stack direction="row" spacing={1}>
          <IconCircleButton disabled={(data?.length ?? 0) === 0} size="small" onClick={() => updateFilters({ notesExpanded: true })}>
            <ExpandAltOutlinedIcon />
          </IconCircleButton>
          <RoleGuard predicate={() => canAddNotes}>
            <IconCircleButton size="small" onClick={() => addNote()}>
              <PlusOutlinedIcon />
            </IconCircleButton>
          </RoleGuard>
        </Stack>
      }
      sx={{ height: '100%' }}
    >
      <NotesModal
        patientId={id}
        open={notesExpanded}
        onClose={() => updateFilters({ notesExpanded: false })}
        canAddNotes={canAddNotes}
        onAddNote={addNote}
      />
      <TableContainer>
        <Table size="small">
          <TableBody data-testid="notes">
            {showLoading && <Loading />}
            {showEmpty && <Empty />}
            {showData &&
              data.map((note) => {
                const testId = note.message.slice(0, 15)

                return (
                  <TableRow hover key={note.id} sx={{ '& .MuiTableCell-root': { verticalAlign: 'top' } }}>
                    <TableCell width="15%">
                      <Stack>
                        <Typography data-testid={`note-date-${testId}`} noWrap>
                          {dayjs(note.createdAt).format('L LT')}
                        </Typography>
                        <Typography data-testid={`note-source-${testId}`} sx={{ color: 'text.secondary' }}>
                          {toTitleCase(note.source)}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <MessageCell message={note.message} data-testid={`note-content-${testId}`} />
                    <TableCell width="15%">
                      <Stack sx={{ textAlign: 'right' }}>
                        <Typography noWrap data-testid={`note-author-${testId}`}>
                          {note.authorName}
                        </Typography>
                        <Typography data-testid={`note-authorType-${testId}`} sx={{ color: 'text.secondary' }}>
                          {toTitleCase(note.authorUserType)}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Tile>
  )
}

const CustomWidthTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 700,
  },
})

function MessageCell({ message, ...rest }) {
  return (
    <CustomWidthTooltip arrow placement="top" title={<Typography component="span">{message}</Typography>}>
      <TableCell>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
          {...rest}
        >
          {message}
        </Typography>
      </TableCell>
    </CustomWidthTooltip>
  )
}

function Loading() {
  return range(0, 4).map((i) => (
    <TableRow hover key={i}>
      <TableCell width="15%">
        <Stack>
          <Typography>
            <Skeleton width="100%" />
          </Typography>
          <Typography>
            <Skeleton width="80%" />
          </Typography>
        </Stack>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Skeleton width="100%" />
      </TableCell>
      <TableCell width="15%">
        <Stack>
          <Typography>
            <Skeleton width="100%" />
          </Typography>
          <Typography>
            <Skeleton width="80%" />
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  ))
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={5} align="center">
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No data to display
        </Typography>
      </TableCell>
    </TableRow>
  )
}
