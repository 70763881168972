import Typography from '@components/_mui/Typography'

/**
 * Show the number of characters in a string.
 *
 * @param text - The string to count.
 * @param limit - The maximum number of characters allowed.
 * @param rest - Additional props to pass to the Typography component.
 */
export default function CharCounter({ text = '', limit = 100, ...rest }) {
  return (
    <Typography {...rest} sx={[{ color: 'text.secondary' }, ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx])]}>
      {text.length}/ {limit}
    </Typography>
  )
}
