import { useMemo, useRef } from 'react'
import { Link as RouterLink } from 'react-router'

import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import useDelayedHover from '@shared/hooks/src/useDelayedHover'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { BusinessIcon, IdcardOutlinedIcon, LocationIcon, MedicalServiceIcon, VerifiedIcon } from '@icons'
import { rubyLink } from '@config'

import { useProviderCBOs } from '../UserCard.hooks'
import { Avatar } from '../UserCard.utils'

export default function Content({ user }) {
  if (user.role === UserRole.Provider) {
    const provider = user.provider
    const address = provider.homeAddress

    return (
      <>
        <ID user={user} />
        <LocationAndServices
          address={[address.city, address.state].filter(Boolean).join(', ')}
          services={provider.providerTypes?.join(', ')}
        />
        <Licenses providerId={provider.id} licenses={provider.licenses} />
      </>
    )
  }

  if ([UserRole.CBO, UserRole.Pharmacy].includes(user.role)) {
    return (
      <>
        <ID user={user} />
        <Business business={user.cbo?.name || user.pharmacy?.name} />
      </>
    )
  }

  if (user.role === UserRole.Patient) {
    const patient = user.patient
    const address = patient.homeAddress
    return (
      <>
        <LocationAndServices address={[address.city, address.state].filter(Boolean).join(', ')} services={patient.treamentTypes} />
        <MRN userId={user.id} />
        <Divider />
        <CareTeam user={user.patient} />
      </>
    )
  }

  return <ID user={user} />
}

Content.Loading = function () {
  return <Skeleton variant="rounded" width="100%" height={100} />
}

export function ID({ user }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={1}>
        <IdcardOutlinedIcon style={{ fontSize: 16 }} />
        <RoleGuard allowed={[UserRole.Admin]}>
          <Link component={RouterLink} to={`/app/users/${user.id}`} fontWeight="bold" target="_blank">
            {user.id}
          </Link>
        </RoleGuard>
        <RoleGuard prohibited={[UserRole.Admin]}>
          <Typography>{user.id}</Typography>
        </RoleGuard>
      </Stack>
      {user.provider?.id && (
        <Stack direction="row" spacing={1}>
          <Typography color="text.secondary">Provider ID</Typography>
          <RoleGuard allowed={[UserRole.Admin]}>
            <Link component={RouterLink} to={rubyLink(`providers/${user.provider.id}`)} fontWeight="bold" target="_blank">
              {user.provider.id}
            </Link>
          </RoleGuard>
          <RoleGuard prohibited={[UserRole.Admin]}>
            <Typography>{user.provider.id}</Typography>
          </RoleGuard>
        </Stack>
      )}
    </Stack>
  )
}

export function LocationAndServices({ address, services }) {
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}>
      <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
        <LocationIcon style={{ fontSize: 16 }} />
        <Typography>{address || '-'}</Typography>
      </Stack>
      <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
        <MedicalServiceIcon style={{ fontSize: 16 }} />
        <Typography>{services || '-'}</Typography>
      </Stack>
    </Stack>
  )
}

export function Licenses({ providerId, licenses = [] }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <VerifiedIcon style={{ fontSize: 16 }} />
      {licenses?.length === 0 && <Typography>-</Typography>}
      {licenses?.length > 0 && (
        <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap' }}>
          {licenses?.map((state, i) => (
            <State key={state} providerId={providerId} value={state} isLast={licenses.length - 1 === i} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

function State({ providerId, value, isLast }) {
  const ref = useRef(null)
  const isHovered = useDelayedHover(ref, { delay: 200 })

  const { data, isError, isFetching } = useProviderCBOs(providerId, value, { enabled: isHovered })

  const tooltip = useMemo(() => {
    if (isError) return 'Could not load CBOs'
    if (isFetching) return 'Loading...'
    if (data?.length === 0) return 'No CBOs found'
    return data?.map((cbo) => cbo.name).join('\n')
  }, [data, isError, isFetching])

  return (
    <Tooltip
      open={isHovered}
      title={
        <Typography component="span" sx={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
          {tooltip}
        </Typography>
      }
    >
      <Typography ref={ref}>
        {value}
        {isLast ? '' : ','}
      </Typography>
    </Tooltip>
  )
}

export function Business({ business }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <BusinessIcon style={{ fontSize: 16 }} />
      <Typography>{business || '-'}</Typography>
    </Stack>
  )
}

export function MRN({ userId }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: '2px' }}>
        MRN
      </Typography>
      <Link component={RouterLink} to={`/app/patients/${userId}`} fontWeight="bold" target="_blank">
        {userId}
      </Link>
    </Stack>
  )
}

export function CareTeam({ user }) {
  const { pharmacy, provider, cbo } = user

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
        Q Care Team
      </Typography>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Typography>Provider</Typography>
        <Stack direction="row" spacing={1} sx={{ overflow: 'hidden' }}>
          {provider && <Avatar user={provider} size="xs" />}
          <Typography noWrap>{provider?.fullName || '-'}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Typography>CBO</Typography>
        <Typography>{cbo?.name || '-'}</Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Typography>Pharmacy</Typography>
        <Typography>{pharmacy?.name || '-'}</Typography>
      </Stack>
    </Stack>
  )
}
