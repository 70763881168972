import { useState } from 'react'
import dayjs from 'dayjs'

import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useInsuranceSummary from '@hooks/useInsuranceSummary'
import { InsuranceDetailed, useInsurance, useInsurances } from '@pages/Insurances'
import { CaretDownOutlinedIcon, CaretRightOutlinedIcon } from '@icons'
import RenderControl from '@components/RenderControl'
import StatusChip from '@components/StatusChip'
import TableCollapsableCell from '@components/TableCollapsableCell'

export default function Insurances({ task }) {
  const [tab, setTab] = useState('insurance')

  const { data: insurance, isPending: isInsurancePending } = useInsuranceSummary(task.patientId, { enabled: tab === 'insurance' })
  const { data: insurances, isPending: areInsurancesPending } = useInsurances(
    task.patientId,
    { order: 'desc', limit: 3 },
    { enabled: tab === 'all' }
  )

  return (
    <Stack>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
          <Tab id="insurance-tab" aria-controls="insurance-panel" value="insurance" label="Insurance" />
          <Tab id="all-tab" aria-controls="all-panel" value="all" label="All" />
        </Tabs>
        <Divider />
        <TabPanel id="insurance-panel" aria-controls="insurance-tab" value="insurance" sx={{ p: 0 }}>
          <RenderControl loading={isInsurancePending} isEmpty={!insurance?.lastActiveInsurance} emptyTitle="No Active Insurance Card">
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <Insurance patientId={task.patientId} data={insurance?.lastActiveInsurance} />
                </TableBody>
              </Table>
            </TableContainer>
          </RenderControl>
        </TabPanel>
        <TabPanel id="all-panel" aria-controls="all-tab" value="all" sx={{ p: 0 }}>
          <RenderControl loading={areInsurancesPending} isEmpty={insurances?.length === 0} emptyTitle="No Active Insurance Cards">
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {insurances?.map((insurance) => (
                    <Insurance key={insurance.id} patientId={task.patientId} data={insurance} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </RenderControl>
        </TabPanel>
      </TabContext>
    </Stack>
  )
}

function Insurance({ patientId, data }) {
  const [detailedInfoOpen, setDetailedInfoOpen] = useState(false)

  const detailedInsurance = useInsurance(patientId, data.id, { enabled: detailedInfoOpen })

  return (
    <>
      <TableRow hover onClick={() => setDetailedInfoOpen((o) => !o)} sx={{ cursor: 'pointer' }}>
        <TableCell padding="checkbox">
          <IconButton size="small">{detailedInfoOpen ? <CaretDownOutlinedIcon /> : <CaretRightOutlinedIcon />}</IconButton>
        </TableCell>
        <TableCell>
          <Stack>
            <Typography variant="h5">{data.company}</Typography>
            <Typography>{data.subscriber}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            <Typography>ID: {data.idnum}</Typography>
            <Typography>Group: {data.groupnum}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="right" width={120} sx={{ verticalAlign: 'top' }}>
          <Stack>
            <Typography>{data.created ? dayjs(data.created).format('L') : '-'}</Typography>
            {data.validatedByAvita && <StatusChip label="Validated" type="success" icon />}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCollapsableCell open={detailedInfoOpen} colSpan={4}>
          <InsuranceDetailed data={detailedInsurance.data} isLoading={detailedInsurance.isPending} />
        </TableCollapsableCell>
      </TableRow>
    </>
  )
}
