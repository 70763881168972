import { useState } from 'react'

import MenuMui from '@mui/material/Menu'
import IconButton from '@components/_mui/IconButton'

import { MoreHorizontalIcon } from '@icons'

export default function Menu({ children }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event?.currentTarget)
  }

  return (
    <>
      <IconButton
        size="small"
        component="div"
        onClick={(e) => handleClick(e)}
        sx={{
          color: 'grey.900',
          my: -1,
          mr: 0.5,
        }}
      >
        <MoreHorizontalIcon />
      </IconButton>
      <MenuMui
        id="card-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'card-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {children({ close: handleClose })}
      </MenuMui>
    </>
  )
}
