import { useMemo } from 'react'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import { NumberParam, StringParam } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import API from '@shared/services/src/API'
import { flatten, pageParam, QK, TaskStatus } from '@shared/utils'

import { FilterType } from '@components/TableFilters'

const LIMIT = 20

export const useTasksFilters = () => useFiltering({ task: NumberParam, kind: StringParam })

export function useTasks({ options = {} } = {}) {
  const [{ kind }] = useTasksFilters()

  const query = { status: TaskStatus.InProgress, kind, limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.tasks.list(query),
    queryFn: ({ pageParam }) => API.tasks.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    placeholderData: keepPreviousData,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}

export const useFiltersSchema = () => {
  const taskTypes = useLookup(Lookup.ProviderTasks)

  return useMemo(() => {
    return [
      {
        key: 'kind',
        label: 'Type',
        type: FilterType.SELECT,
        options: taskTypes.map(({ label, kind }) => ({ label, value: kind })),
      },
    ]
  }, [taskTypes])
}
