import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, handleError, mapCache, QK } from '@shared/utils'

export function useMarkCompleted(taskId) {
  const me = useMe()
  const providerId = me.provider.id

  return useMutation({
    mutationFn: (data) => API.providers.id(providerId).par.id(taskId).complete(data),
    onSuccess: (updatedTask) => {
      const key = QK.patients.id(updatedTask.patientId).tasks.id(updatedTask.id).details
      queryClient.setQueryData(key, (task = {}) => ({ ...task, ...updatedTask }))
      queryClient.setQueriesData(
        { queryKey: QK.patients.id(updatedTask.patientId).tasks.lists },
        mapCache((task) => (task.id === updatedTask.id ? { ...task, ...updatedTask } : task))
      )
      queryClient.setQueriesData(
        { queryKey: QK.providers.id(providerId).dashboard.parTasks.lists },
        filterCache((task) => task.id !== updatedTask.id)
      )
    },
    onError: handleError,
  })
}
