import { useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import { useEventListener } from 'usehooks-ts'

import Box from '@mui/material/Box'
import BreadcrumbsMui from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/styles/useTheme'

import DocTitle from '@components/DocTitle'

const styles = {
  sticky: (theme) => ({
    minHeight: 100,
    pointerEvents: 'none',

    zIndex: 1,
    top: `calc(${theme.mixins.toolbar.height}px + ${theme.spacing(1)})`,
    position: 'sticky',

    mx: { xs: 1, sm: -1, lg: -2 },
    mb: 2,
  }),
  content: (theme, isScrolled) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 1,

    pointerEvents: 'all',
    px: 2,
    py: isScrolled ? 0.5 : 1,

    borderRadius: 4,
    backdropFilter: `blur(${theme.spacing(1)})`,
    WebkitBackdropFilter: `blur(${theme.spacing(1)})`,

    willChange: 'box-shadow, background-color, font-size, padding-top, padding-bottom',
    transition: 'all 0.3s ease-in-out',

    boxShadow: isScrolled ? theme.customShadows.z2 : 'none',
    backgroundColor: isScrolled
      ? alpha(theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.background.default, 0.7)
      : 'unset',
    fontSize: isScrolled ? theme.typography.h3.fontSize : theme.typography.h2.fontSize,
  }),
  leftBlock: {
    flex: '1 1 auto',
  },
  breadcrumbs: {
    fontSize: 'unset',
  },
  breadcrumb: {
    fontSize: '70%',
    fontWeight: 'normal',
  },
  link: {
    color: 'text.secondary',
    display: 'block',
  },
  typographyLink: {
    color: 'text.primary',
  },
  title: {
    fontSize: '100%',
    fontWeight: 'normal',
  },
}

/**
 * Renders a breadcrumb navigation component with optional document title, links, actions, and layout options.
 *
 * @param {Object} props - The props object.
 * @param {boolean|string} [props.docTitle=false] - Indicates whether to display the html title.
 * @param {string} [props.title] - The title to be displayed in the breadcrumbs section.
 * @param {Array<Object>} [props.links] - An array of link objects, where each link can contain a `title` and an optional `to` property.
 * @param {ReactNode} [props.actions] - Optional additional actions or elements to display on the right side of the block.
 *
 * @return {ReactNode} The rendered breadcrumb navigation component.
 */
export default function Breadcrumbs({ docTitle = false, loading = false, title, links = [], actions }) {
  const theme = useTheme()

  const [isScrolled, setIsScrolled] = useState(window.scrollY > 16)
  useEventListener('scroll', () => setIsScrolled(window.scrollY > 16))

  const docTitleValue = useMemo(() => {
    const isPresent = (v) => typeof v === 'string' && v
    if (docTitle === true && isPresent(title)) return title
    if (isPresent(docTitle)) return docTitle
  }, [docTitle, title])

  const linkComponents = useMemo(() => {
    return links.map((link) => {
      return link.to ? (
        <Link key={link.title} variant="h4" component={RouterLink} to={link.to} underline="hover" sx={[styles.breadcrumb, styles.link]}>
          {link.title}
        </Link>
      ) : (
        <Typography key={link.title} variant="h4" sx={[styles.breadcrumb, styles.typographyLink]}>
          {link.title}
        </Typography>
      )
    })
  }, [links])

  const titleComponent = useMemo(() => {
    if (loading) {
      return (
        <Title>
          <Skeleton width={300} />
        </Title>
      )
    }
    return typeof title === 'string' ? <Title>{title}</Title> : title
  }, [loading, title])

  return (
    <Box sx={styles.sticky}>
      <Box sx={styles.content(theme, isScrolled)}>
        {docTitleValue && <DocTitle title={docTitleValue} />}
        <Stack sx={styles.leftBlock}>
          {Boolean(links.length) && (
            <BreadcrumbsMui sx={styles.breadcrumbs} aria-label="breadcrumb">
              {linkComponents}
            </BreadcrumbsMui>
          )}
          {titleComponent}
        </Stack>
        {actions}
      </Box>
    </Box>
  )
}

const Title = (props) => <Typography variant="h2" sx={styles.title} {...props} />

Breadcrumbs.Title = Title
