import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { useLayoutState } from '@providers/LayoutStateProvider'
import { DownOutlinedIcon, UpOutlinedIcon } from '@icons'
import Transitions from '@components/Transitions'

NavCollapse.propTypes = {
  selected: PropTypes.bool,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
}

export default function NavCollapse({ selected, icon, title, caption, level = 1, action = null, children }) {
  const theme = useTheme()

  const [drawerOpen] = useLayoutState()
  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const openMini = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)
  const handleClick = (event) => {
    setAnchorEl(null)
    if (drawerOpen) {
      setOpen(!open)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const Icon = icon
  const menuIcon = icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false

  const childrenLevel = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { level: level + 1 })
      }
      return child
    })
  }, [children, level])

  return (
    <>
      <ListItemButton
        disableRipple
        selected={selected}
        data-testid={`sidebar-${title}`}
        {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          pl: drawerOpen ? '28px' : 1.5,
          py: !drawerOpen ? 1.25 : 1,
        }}
      >
        {/* Display the icon */}
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: 'text.secondary',
              ...(!drawerOpen && {
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
              }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}

        {/* Display the label */}
        {drawerOpen && (
          <ListItemText
            primary={<Typography variant="h6">{title}</Typography>}
            secondary={
              caption && (
                <Typography variant="caption" sx={{ color: 'secondary' }}>
                  {caption}
                </Typography>
              )
            }
          />
        )}

        {/* displayed the direction caret */}
        {drawerOpen &&
          (openMini || open ? (
            <UpOutlinedIcon style={{ fontSize: '0.625rem', marginLeft: 1, marginRight: '0.25rem', color: theme.palette.primary.main }} />
          ) : (
            <DownOutlinedIcon style={{ fontSize: '0.625rem', marginLeft: 1, marginRight: '0.25rem' }} />
          ))}

        {/* Display any count bubbles */}
        {drawerOpen && action}
        {!drawerOpen && <Box sx={{ ml: -2 }}>{action}</Box>}

        {/* Drawer is closed so show a tooltip with the list of children */}
        {!drawerOpen && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{ zIndex: 2001 }}
            popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [-12, 1] } }] }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{childrenLevel}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>

      {/* animate display */}
      {drawerOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{childrenLevel}</List>
        </Collapse>
      )}
    </>
  )
}

const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))
