import { useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'

import useLoadingState from '@shared/hooks/src/useLoadingState'

import useAvailability from '@hooks/useAvailability'
import useBlackoutPeriods from '@hooks/useBlackoutPeriods'
import { CalendarOutlinedIcon, CloseCircleOutlinedIcon } from '@icons'
import AvailabilityDay from '@components/AvailabilityDay'
import LinearProgress from '@components/LinearProgress'
import MainCard from '@components/MainCard'

import { IconButton as HeaderButton } from '../Header.utils'

const styles = {
  card: {
    border: 'none',
    borderRadius: 0,
    height: '100vh',
    '& .MuiCardHeader-root': {
      color: 'primary.contrastText',
      bgcolor: 'primary.main',
      '& .MuiTypography-root': { fontSize: '1rem' },
    },
  },
}

export default function AvailabilityButton() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box>
        <HeaderButton id="availability" onClick={() => setOpen(true)} aria-label="preview toggler" data-testid="header-calendar">
          <CalendarOutlinedIcon style={{ fontSize: 18 }} />
        </HeaderButton>
      </Box>

      <AvailabilityPreviewDrawer open={open} onClose={() => setOpen(false)} />
    </>
  )
}

function AvailabilityPreviewDrawer({ open, onClose }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: 2001 }}
      PaperProps={{ sx: { width: 340 } }}
      data-testid="drawer-availability"
    >
      <AvailabilityPreview onClose={onClose} />
    </Drawer>
  )
}

function AvailabilityPreview({ onClose }) {
  const period = useMemo(() => {
    return {
      startDate: dayjs().tz(window.timezone).startOf('day'),
      endDate: dayjs().tz(window.timezone).add(6, 'days').endOf('day'),
    }
  }, [])

  const {
    data: availabilityData,
    isPending: isAvailabilityPending,
    isFetching: isAvailabilityFetching,
    isRefreshing: isAvailabilityRefreshing,
  } = useAvailability(period)

  const {
    data: blackoutsData,
    isPending: areBlackoutsPending,
    isFetching: areBlackoutsFetching,
    isRefreshing: areBlackoutsRefreshing,
  } = useBlackoutPeriods(period)

  const data = useMemo(() => {
    return getDates(0, 7).map((date) => {
      const day = date.format('YYYY-MM-DD')

      const availability = availabilityData?.[day]
      const blackouts = blackoutsData?.[day]

      return { date, availability, blackouts }
    })
  }, [availabilityData, blackoutsData])

  const isLoading = useLoadingState(isAvailabilityPending || areBlackoutsPending)
  const isRefreshing = useLoadingState(isAvailabilityRefreshing || areBlackoutsRefreshing || isAvailabilityFetching || areBlackoutsFetching)

  return (
    <MainCard
      title="Upcoming Availability"
      secondary={
        <IconButton shape="rounded" color="inherit" size="small" onClick={onClose} data-testid="close-drawer">
          <CloseCircleOutlinedIcon style={{ fontSize: '1.15rem' }} />
        </IconButton>
      }
      sx={styles.card}
      content={false}
      data-testid="availability-drawer"
    >
      <LinearProgress loading={isRefreshing} color="warning" />

      <Stack spacing={2} sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            component={RouterLink}
            fullWidth
            variant="contained"
            data-testid="view-my-calendar-button"
            to="/app/provider-scheduling"
            onClick={onClose}
            id="view-my-calendar-button"
          >
            View My Calendar
          </Button>
        </Box>

        {data.map(({ date, availability, blackouts }, i) => (
          <AvailabilityDay key={date} date={date} isLoading={isLoading} availability={availability} blackouts={blackouts} />
        ))}
      </Stack>
    </MainCard>
  )
}

function getDates(start, end) {
  const dates = []
  for (let i = start; i < end; i++) {
    dates.push(dayjs().tz(window.timezone).add(i, 'day'))
  }
  return dates
}
