import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import { useDebounce } from 'usehooks-ts'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole, userRoleToLabel } from '@shared/utils'

import { UserDeleteOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'

import { usePossibleUsers } from './ThreadUsersEditDialog.hooks'

const styles = {
  list: {
    display: 'flex',
    listStyle: 'none',
    m: 0,
    p: 0,
  },
  content: {
    alignItems: 'center',
    borderRadius: 1,
    display: 'flex',
    flex: '0 0 auto',
    gap: 1,
    p: 1,
  },
  info: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  text: {
    flex: '1 1 auto',
  },
}

export default function ThreadUsersEditDialog({ open, onClose, thread, onAdd, onRemove }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="body" PaperProps={{ sx: { verticalAlign: 'top' } }}>
      <DialogTitle onClose={onClose}>Participants</DialogTitle>
      <DialogContent>
        <Content thread={thread} onAdd={onAdd} onRemove={onRemove} />
      </DialogContent>
    </Dialog>
  )
}

function Content({ thread, onAdd, onRemove }) {
  const me = useMe()

  const [handleAdd, adding] = usePromiseLoading(onAdd)

  const canAdd = !thread.archived
  const canRemove = me.superAdmin && !thread.archived && thread.users.length > 2

  return (
    <Stack component="ul" spacing={1} sx={styles.list}>
      {thread.users.map((user) => {
        const canRemoveUser = canRemove && user.id !== me.id && user.role !== UserRole.Patient
        return <User key={user.id} data={user} onRemove={canRemoveUser ? () => onRemove(user.id) : undefined} />
      })}
      {canAdd && <SelectParticipant conversationId={thread.id} onSelect={(user) => handleAdd(user.id)} loading={adding} />}
    </Stack>
  )
}

function User({ data, onRemove }) {
  const [handleRemove, removing] = usePromiseLoading(onRemove)

  return (
    <Box component="li">
      <CardActionArea disabled={!onRemove || removing} onClick={handleRemove} sx={styles.content}>
        <Avatar user={data} />
        <Box sx={styles.info}>
          <Typography noWrap variant="body2" sx={styles.text}>
            {data.fullName}
          </Typography>
          <Typography noWrap variant="body2" sx={[styles.text, { color: 'text.secondary' }]}>
            {userRoleToLabel[data.role]}
          </Typography>
        </Box>
        {onRemove && <UserDeleteOutlinedIcon style={{ fontSize: 18 }} />}
      </CardActionArea>
    </Box>
  )
}

function SelectParticipant({ conversationId, onSelect, loading: outsideLoading = false, ...rest }) {
  const [inputValue, setInputValue] = useState('')

  const search = useDebounce(inputValue.trim(), 500)

  const { data, isFetching } = usePossibleUsers(conversationId, search, {
    // Increase stale time to avoid unnecessary requests
    staleTime: 60 * 1000,
    placeholderData: keepPreviousData,
    enabled: search.length > 0,
  })

  const loading = useLoadingState(isFetching || outsideLoading)

  return (
    <Autocomplete
      autoComplete
      selectOnFocus
      forcePopupIcon={false}
      getOptionLabel={() => ''}
      filterOptions={(x) => x}
      value={null}
      options={data || []}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, user) => onSelect(user)}
      onInputChange={(event, search) => {
        if (!event || event.type === 'click') return setInputValue('')
        setInputValue(search)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Add user"
          sx={styles.input}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      renderOption={({ key, ...props }, user) => (
        <li key={user.id} {...props}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar user={user} />
            <Stack sx={{ flex: '0 0 auto' }}>
              <Typography>{`${user.fullName} #${user.id}`}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {[userRoleToLabel[user.role], user.email].join(', ')}
              </Typography>
            </Stack>
          </Stack>
        </li>
      )}
      {...rest}
    />
  )
}
