import { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import CardMedia from '@mui/material/CardMedia'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'
import { prettyBytes, toBase64 } from '@shared/utils'

import { DeleteOutlinedIcon, ReloadOutlinedIcon } from '@icons'
import UploadCover from '@assets/upload/upload.svg'

const styles = {
  error: {
    color: 'error.main',
    borderColor: 'error.light',
    bgcolor: 'error.lighter',
  },
  coverImage: {
    width: 150,
  },
  texts: {
    textAlign: { xs: 'center', sm: 'left' },
  },
  hover: {
    '&:hover': {
      opacity: 0.65,
      cursor: 'pointer',
    },
  },
  replaceDropzone: {
    p: 1,
    borderRadius: 1,
    border: '2px dashed',
    borderColor: 'primary.main',
    backgroundColor: 'grey.100',
    alignItems: 'center',
    textAlign: 'center',
  },
}

InsuranceImage.propTypes = {
  /** The label to display */
  label: PropTypes.string.isRequired,

  /** The image to display */
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      mimeType: PropTypes.string.isRequired,
      base64: PropTypes.string.isRequired,
    }),
  ]),

  /** The error to display */
  error: PropTypes.string,

  /** Called when the image changes */
  onChange: PropTypes.func.isRequired,

  /** Called when the image is removed */
  onRemove: PropTypes.func.isRequired,

  /** Disables image selection */
  disabled: PropTypes.bool,
}

export default function InsuranceImage({ label, image, error, onChange, onRemove, disabled }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    multiple: false,
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    onDropAccepted: (acceptedFiles) => {
      const blob = acceptedFiles[0]
      toBase64(blob).then((base64File) => {
        const newBase64File = base64File.split(',')[1]
        onChange({ mimeType: blob.type, base64: newBase64File })
      })
    },
  })

  const src = useMemo(() => {
    if (image?.base64) {
      return `data:${image.mimeType};base64,${image.base64}`
    }
    return image
  }, [image])

  const isError = isDragReject || fileRejections.length > 0 || error

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <InputLabel error={isError} sx={{ color: 'text.inputLabel' }}>
        {label}
      </InputLabel>
      {image ? (
        <DropzoneWrapper sx={(isDragReject || fileRejections.length > 0 || error) && styles.error}>
          <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
            <Image fit="contain" src={src} duration={500} sx={{ maxHeight: 200 }} />
            <Stack
              spacing={1}
              {...getRootProps()}
              sx={[
                styles.replaceDropzone,
                styles.hover,
                isDragActive && { opacity: 0.65 },
                (isDragReject || fileRejections.length > 0 || error) && styles.error,
              ]}
            >
              <input {...getInputProps()} />
              <ReloadOutlinedIcon />
              <Typography variant="h4" sx={{ color: 'text.secondary' }}>
                Replace
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Drop file here or click to select from your device
              </Typography>
            </Stack>
            <IconButton size="large" color="error" variant="contained" onClick={onRemove}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Stack>
        </DropzoneWrapper>
      ) : (
        <DropzoneWrapper
          {...getRootProps()}
          sx={[styles.hover, isDragActive && { opacity: 0.65 }, (isDragReject || fileRejections.length > 0 || error) && styles.error]}
        >
          <input {...getInputProps()} />
          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia component="img" image={UploadCover} sx={styles.coverImage} />
            <Stack spacing={1} sx={styles.texts}>
              <Typography variant="h5">Drag & Drop or Select file</Typography>
              <Typography color="secondary">Drop file here or click to select from your device.</Typography>
              <Typography variant="body2" color="secondary">
                (Only .png and .jpg files will be accepted)
              </Typography>
            </Stack>
          </Stack>
        </DropzoneWrapper>
      )}
      <Error reasons={fileRejections} />
    </Stack>
  )
}

function Error({ reasons }) {
  const error = useMemo(() => {
    if (!reasons?.length) return ''

    return reasons.reduce((acc, { file, errors }) => {
      return acc + `${file.name}(${prettyBytes(file.size)}) - ${errors.map((error) => error.message).join(', ')} \n`
    }, '')
  }, [reasons])

  if (!reasons?.length) return null

  return (
    <FormHelperText error sx={{ whiteSpace: 'pre-wrap' }}>
      {error}
    </FormHelperText>
  )
}

const DropzoneWrapper = styled('div')(({ theme }) => ({
  outline: 'none',
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
}))
