export default function Tabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        vertical: {
          overflow: 'visible',
        },
        indicator: {
          height: 4,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
      },
    },
  }
}
