import { decodeNumber, encodeString } from 'use-query-params'

export const isMATask = (task) => task?.identifier?.startsWith('MA-') ?? false
export const isSupportTask = (task) => task?.identifier?.startsWith('CS-') ?? false
export const isPharmacyTask = (task) => task?.identifier?.startsWith('PH-') ?? false
export const isProviderTask = (task) => task?.identifier?.startsWith('P-') ?? false

export const TaskParam = {
  encode: (str) => encodeString(str),
  decode: (input) => {
    let s = typeof input === 'string' ? input.match(/\d+/g)?.[0] : input
    return decodeNumber(s)
  },
}
