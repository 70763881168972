import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import CardActionArea from '@mui/material/CardActionArea'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { LabStatus, LabTypes, UserRole } from '@shared/utils'

import Property from '@components/Details/Property'
import InputControl from '@components/InputControl'

import { useLabUpdate } from '../Lab.hooks'
import { isLabFailed } from '../Lab.utils'

const styles = {
  form: {
    borderBottom: '1px solid',
    borderColor: 'divider',
    px: 2,
    py: 1,
    gap: 1,
    backgroundColor: 'background.default',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'background.paper',
    },
  },
  property: {
    label: {
      color: 'primary.main',
      fontWeight: 'bold',
    },
  },
}

export default function LabStatusUpdate({ readOnly, lab, onClose, onStatusUpdate }) {
  const me = useMe()
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const updateLab = useLabUpdate(lab.id)

  const handleSubmit = ({ status, note, message, hivTestCompleted, positiveSTIResult }) => {
    return updateLab
      .mutateAsync({
        status,
        note,
        message,
        ...(lab.lab === LabTypes.Manual && {
          hiv_test_completed: hivTestCompleted,
        }),
        ...(status === LabStatus.Ready &&
          lab.stiPositive && {
            positive_sti_result: positiveSTIResult,
          }),
      })
      .then((lab) => {
        setOpen(false)
        onClose?.()
        onStatusUpdate?.()
        // Provider should be redirected to newly created STI Follow Up Async visit
        // which is created when lab has positive STI results and provider checked STI Follow Up checkbox
        if (lab.stiFollowUpAsyncVisitId && me.role === UserRole.Provider) {
          navigate(`/app/visits/${lab.stiFollowUpAsyncVisitId}`)
        }
      })
  }

  const possibleStatuses = useLookup(Lookup.LabReviewStatuses)
  const status = possibleStatuses[lab.status] || lab.status
  const statusDate = lab.updatedAt ? dayjs(lab.updatedAt).format('L LT') : undefined

  const isFailed = isLabFailed(lab.status)

  return (
    <>
      <Property label="Status" sx={styles.property}>
        <CardActionArea ref={anchorRef} disabled={readOnly} onClick={() => setOpen((o) => !o)} sx={{ p: 1, m: -1 }}>
          <Stack>
            <Typography sx={{ color: isFailed ? 'text.danger' : 'text.primary', fontWeight: 'bold' }}>{status || '—'}</Typography>
            <Typography sx={{ color: 'text.secondary' }}>{statusDate || '—'}</Typography>
          </Stack>
        </CardActionArea>
      </Property>
      <Collapse in={open} unmountOnExit>
        <Edit lab={lab} onCancel={() => setOpen(false)} onSubmit={handleSubmit} />
      </Collapse>
    </>
  )
}

function Edit({ lab, onCancel, onSubmit }) {
  const possibleStatuses = useLookup(Lookup.LabReviewStatuses, (statuses) => {
    delete statuses[LabStatus.Released]
    return statuses
  })
  const isPossibleStatus = Boolean(possibleStatuses[lab.status])
  const isReleased = lab.status === LabStatus.Released

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: lab.status,
      reviewed: false,
      hivTestCompleted: lab.hivTestCompleted || false,
      positiveSTIResult: false,
      note: '',
      message: '',
      encounter: lab.encounter,
    },
    validationSchema: Yup.object({
      status: Yup.string()
        .required('Status is required')
        .test('status-changed', 'Status must be changed', (value) => value !== lab.status),
      note: Yup.string()
        .trim()
        .when('status', {
          is: (status) => status !== LabStatus.NeedsFollowUp,
          then: (schema) => schema.required('Note is required'),
        }),
      reviewed: Yup.boolean(),
      hivTestCompleted: Yup.boolean(),
      positiveSTIResult: Yup.boolean(),
      message: Yup.string(),
      encounter: Yup.object().when('status', {
        is: (status) => status === LabStatus.Ready,
        then: (schema) => schema.required('Encounter is required'),
      }),
    }),
    onSubmit,
  })

  const isReadyForPatient = formik.values.status === LabStatus.Ready
  const hasPositiveSTIResults = Boolean(lab.stiPositive)

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack sx={styles.form}>
        <InputControl field="note" formikProps={formik}>
          <TextField
            label="Review Lab"
            required={formik.values.status !== 'Needs Follow-up'}
            fullWidth
            multiline
            rows={3}
            placeholder="Write something..."
            disabled={formik.isSubmitting || formik.values.reviewed}
          />
        </InputControl>
        <InputControl hideLabel field="status" formikProps={formik}>
          <TextField
            select
            required
            fullWidth
            disabled={formik.isSubmitting}
            onChange={(e) => {
              formik.setFieldValue('message', '')
              formik.setFieldValue('positiveSTIResult', false)
              formik.handleChange(e)
            }}
          >
            {!isPossibleStatus && (
              <MenuItem value={lab.status} disabled>
                {lab.status}
              </MenuItem>
            )}
            {Object.entries(possibleStatuses).map(([value, label]) => (
              <MenuItem key={value} value={value} disabled={lab.status === value || (isReleased && value !== LabStatus.Ready)}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </InputControl>
        <Collapse in={isReadyForPatient}>
          <Stack spacing={1}>
            {hasPositiveSTIResults && (
              <FormControlLabel
                name="positiveSTIResult"
                label="STI Follow Up"
                labelPlacement="end"
                control={<Checkbox checked={formik.values.positiveSTIResult} onChange={formik.handleChange} sx={{ my: -1 }} />}
              />
            )}
            <InputControl field="message" formikProps={formik}>
              <TextField
                fullWidth
                multiline
                label="Message to patient"
                minRows={3}
                maxRows={12}
                placeholder="Write something..."
                disabled={formik.isSubmitting}
              />
            </InputControl>
          </Stack>
        </Collapse>
        {formik.errors.encounter && <FormHelperText error>{formik.errors.encounter}</FormHelperText>}
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {LabTypes.Manual === lab.lab ? (
            <FormControlLabel
              name="hivTestCompleted"
              label="This outside lab result includes HIV result, do not bill to CBO"
              labelPlacement="end"
              control={
                <Checkbox name="hivTestCompleted" checked={formik.values.hivTestCompleted} onChange={formik.handleChange} sx={{ my: -1 }} />
              }
              sx={{ flex: '1 1 auto' }}
            />
          ) : (
            <FormControlLabel
              name="reviewed"
              label="Labs reviewed and no abnormals noted"
              labelPlacement="end"
              control={
                <Checkbox
                  name="reviewed"
                  checked={formik.reviewed}
                  onChange={(e, v) => {
                    formik.setFieldTouched('note', false)
                    formik.setFieldValue('note', v ? 'Labs reviewed and no abnormals noted' : '')
                    formik.handleChange(e)
                  }}
                  sx={{ my: -1 }}
                />
              }
              sx={{ flex: '1 1 auto' }}
            />
          )}

          <Button onClick={onCancel} disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button loading={formik.isSubmitting} type="submit" variant="contained">
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

LabStatusUpdate.Loading = () => (
  <Property label="Status" sx={styles.property}>
    <Stack>
      <Typography>
        <Skeleton width={200} />
      </Typography>
      <Typography>
        <Skeleton width={180} />
      </Typography>
    </Stack>
  </Property>
)
