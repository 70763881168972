import { useMemo } from 'react'
import dayjs from 'dayjs'

import { UserOutlinedIcon } from '@icons'
import Property, { CBOProperty, PatientProperty, ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'

export default function PatientSection({ data, mini = false }) {
  const user = data.user

  const dob = useMemo(() => {
    if (!user.dob) return '—'
    const age = dayjs().diff(dayjs(user.dob), 'years')
    return `${dayjs(user.dob).format('L')} ${age}yo`
  }, [user.dob])

  return (
    <Section paper mini={mini} title="Patient" icon={mini ? null : <UserOutlinedIcon />}>
      <PatientProperty label="Name" user={user} />
      <Property label="DOB">{dob}</Property>
      <CBOProperty label="CBO" data={user.cbo} />
      <ProviderProperty label="Provider" user={data.patientProvider} />
    </Section>
  )
}

PatientSection.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Patient" icon={mini ? null : <UserOutlinedIcon />}>
    <PatientProperty.Loading label="Name" />
    <Property.Loading label="DOB" />
    <CBOProperty.Loading label="CBO" />
    <ProviderProperty.Loading label="Provider" />
  </Section>
)
