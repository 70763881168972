import { Link as RouterLink } from 'react-router'
import { keepPreviousData } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import CopyIconButton from '@components/CopyIconButton'
import { spacing } from '@components/Details/Details.utils'
import { usePatientAccessible } from '@components/PatientAccessGuard'

import DetailsSection from './components/DetailsSection'
import FillsHistorySection from './components/FillsHistorySection'
import NotesSection from './components/NotesSection'
import PatientSection from './components/PatientSection'
import PharmacySection from './components/PharmacySection'
import { invalidateEncounterNotes, usePrescription } from './Prescription.hooks'

export default function PrescriptionDetailsModal({ prescriptionId, hidePatientData = false, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
    >
      <Content prescriptionId={prescriptionId} hidePatientData={hidePatientData} onClose={onClose} />
    </Dialog>
  )
}

function Content({ prescriptionId, hidePatientData, onClose }) {
  const me = useMe()

  const { data } = usePrescription(prescriptionId, {
    enabled: Boolean(prescriptionId),
    placeholderData: keepPreviousData,
  })
  const patientAccessible = usePatientAccessible(data?.user.id)

  const allowedToAddNotes = isUserAllowed(me, [UserRole.Provider, UserRole.Pharmacy, UserRole.ClinicalLead])
  const canAddNotes = allowedToAddNotes && patientAccessible
  const showData = Boolean(data)

  return (
    <>
      <DialogTitle onClose={onClose}>
        {showData ? (
          <Stack spacing={-0.5}>
            <Stack direction="row" spacing={1}>
              <Link component={RouterLink} fontWeight="normal" to={`/app/prescriptions/${data.id}`} target="_blank" color="text.secondary">
                ID: {data.id}
              </Link>
              <CopyIconButton fn={(copy) => copy(data.id)} />
            </Stack>
            <Typography component="h2" variant="h4">
              {`${data.drugName}${data.isRefill ? ' (Refill)' : ''}`}
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={-0.5}>
            <Typography>
              <Skeleton width={100} />
            </Typography>
            <Typography component="h2" variant="h4">
              <Skeleton width={300} />
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          {showData ? (
            <>
              <DetailsSection mini prescription={data} />
              <PharmacySection mini data={data.pharmacy} localPharmacyName={data.localPharmacyName} />
              {!hidePatientData && <PatientSection mini user={data.user} cbo={data.cbo} />}
              <FillsHistorySection mini data={data.rxfillsTrackingInformation} />
            </>
          ) : (
            <>
              <DetailsSection.Loading mini />
              <PharmacySection.Loading mini />
              {!hidePatientData && <PatientSection.Loading mini />}
              <FillsHistorySection.Loading mini />
            </>
          )}
          <NotesSection mini prescriptionId={prescriptionId} readOnly={!canAddNotes} onCreate={() => invalidateEncounterNotes(data)} />
        </Stack>
      </DialogContent>
    </>
  )
}
