import dayjs from 'dayjs'
import { createEnumParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import { TaskErrorThresholdDays, TaskParam, TaskStatus, TaskWarningThresholdDays } from '@shared/utils'

export const TabParam = createEnumParam(['board', 'backlog', 'completed'])
export const PriorityParam = createEnumParam(['low', 'medium', 'high'])
export const OrderParam = createEnumParam(['asc', 'desc'])
export const SortParam = createEnumParam(['kind', 'identifier', 'status', 'created_at', 'updated_at'])

export const CommonFilters = {
  tab: withDefault(TabParam, 'board'),
  task: TaskParam,
  priority: PriorityParam,
  users: withDefault(DelimitedNumericArrayParam, []),
  kind: withDefault(DelimitedArrayParam, []),
  provider: NumberParam,
  search: StringParam,
}

export const getTaskOverdueStatus = (task) => {
  const isDone = [TaskStatus.Completed, TaskStatus.Expired].includes(task.status)

  const daysPassed = dayjs(isDone ? task.updatedAt : undefined).diff(task.createdAt, 'days') + 1
  const overdueWarning = !isDone && daysPassed > TaskWarningThresholdDays
  const overdueError = !isDone && daysPassed > TaskErrorThresholdDays
  const status = overdueError ? 'error' : overdueWarning ? 'warning' : undefined

  return [status, daysPassed]
}
