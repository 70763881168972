/**
 * Returns a list of objects sorted by its keys
 *
 * @param {Object} object
 *
 * @returns {Object[]} {id, value}
 */
export function sortObjectByKeys(object) {
  return Object.keys(object)
    .sort(compare)
    .reduce((array, key) => {
      array.push({ id: key, value: object[key] })

      return array
    }, [])
}

/**
 * Returns a list of objects sorted by the values of its keys
 *
 * @param {Object} object
 *
 * @returns {Object[]} {id, value}
 */
export function sortObjectByValues(object) {
  return Object.values(object)
    .sort(compare)
    .reduce((array, value) => {
      array.push({ id: getObjectKeyForValue(value, object), value })

      return array
    }, [])
}

/**
 * Returns the key for a value
 *
 * @param {String|Number} value
 * @param {Object} object
 *
 * @returns {String|Number}
 */
function getObjectKeyForValue(value, object) {
  return Object.keys(object).find((k) => object[k] === value)
}

function compare(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase())
}

/**
 * Sanitize object from sensitive data
 * (e.g. password, secret keys, etc.)
 *
 * @example
 * const data = {
 *     password: "12345",
 *     user: {
 *         name: "John Doe",
 *         password: "67890"
 *     }
 * };
 *
 * const sanitizedData = sanitizeObject(data, ['password']);
 * // {
 * //     password: "***",
 * //     user: {
 * //         name: "John Doe",
 * //         password: "***"
 * //     }
 * // }
 *
 * @param obj - object to sanitize
 * @param blacklist - array of keys to sanitize
 *
 * @returns {{}|*} - sanitized object
 */
export function sanitizeObject(obj, blacklist = []) {
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => sanitizeObject(item, blacklist))
  }

  const sanitizedObj = {}
  for (const [key, value] of Object.entries(obj)) {
    if (blacklist.includes(key)) {
      sanitizedObj[key] = '***'
    } else {
      sanitizedObj[key] = sanitizeObject(value, blacklist)
    }
  }

  return sanitizedObj
}

/**
 * Helper function to merge MUI Slot Props instead of using lodash.merge
 * since it doesn't use original objects
 */
export function mergeSlotProps(defaultSlotProps, childSlotProps = {}) {
  return {
    ...defaultSlotProps,
    ...childSlotProps,
    formHelperText: {
      ...(defaultSlotProps.formHelperText || {}),
      ...(childSlotProps.formHelperText || {}),
    },
    htmlInput: {
      ...(defaultSlotProps.htmlInput || {}),
      ...(childSlotProps.htmlInput || {}),
    },
    select: {
      ...(defaultSlotProps.select || {}),
      ...(childSlotProps.select || {}),
    },
  }
}
