import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

export function TabPanel({ id, value, children, ...other }) {
  const isHidden = `${value}-panel` !== id

  return (
    <Fade in={!isHidden} unmountOnExit>
      <Box role="tabpanel" hidden={isHidden} id={id} aria-labelledby={`${id}-tab`} {...other}>
        {children}
      </Box>
    </Fade>
  )
}

export const mapDataForLabOrder = (encounterId, reason, vendorId, panels, shippingAddress) => {
  return {
    encounter_id: encounterId,
    reason,
    lab_vendor_id: vendorId,
    mtl_panel: panels,

    // Shipping address
    address: [shippingAddress.address, shippingAddress.address2].join(' '),
    city: shippingAddress.city,
    state: shippingAddress.state,
    zip: shippingAddress.zip,
  }
}

export const mapDataForConfirmation = (allVendors, selected) => {
  return Object.entries(selected).map(([vendorId, panels]) => {
    const vendor = allVendors[vendorId]
    return {
      vendor: { id: vendorId, name: vendor.name, description: vendor.description },
      panels: panels.map((panelId) => vendor.panels[panelId]),
    }
  })
}

export const objectifyVendors = (vendors) => {
  const vendorsObj = keyBy(vendors, 'id')
  return mapValues(vendorsObj, (vendor) => ({
    ...vendor,
    panels: keyBy(vendor.panels, 'id'),
  }))
}

/**
 * Updates the selected vendors and panels based on the newly available vendors.
 *
 * @param {Object} allVendors - The latest vendors data.
 * @param {Object} selected - The previously selected vendors and panels.
 * @returns {Object} - The updated selected vendors and panels.
 */
export const updateSelectedVendors = (allVendors, selected) => {
  // Initialize an empty object to store the updated selections
  const updatedSelected = {}

  // Iterate over each vendorId in the selected object
  for (const vendorId in selected) {
    // Check if the vendor exists in allVendors
    if (allVendors.hasOwnProperty(vendorId)) {
      const selectedPanels = selected[vendorId]
      const vendorPanels = allVendors[vendorId].panels

      // Initialize an array to hold valid panels
      const validPanels = []

      // Iterate over each panelId in the selected panels
      for (const panelId of selectedPanels) {
        // Check if the panel exists in the vendor's panels
        if (vendorPanels.hasOwnProperty(panelId)) {
          validPanels.push(panelId)
        }
      }

      // If there are any valid panels left, add them to updatedSelected
      if (validPanels.length > 0) {
        updatedSelected[vendorId] = validPanels
      }
      // If no valid panels remain, do not include the vendor
    }
    // If the vendor does not exist in allVendors, skip it
  }

  return updatedSelected
}
