import { useMemo } from 'react'

import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'

import usePopover from '@shared/hooks/src/usePopover'

import { NotificationsPopover } from '@pages/Notifications'
import { useUserNotifications } from '@pages/Notifications/Notifications.hooks'
import { BellOutlinedIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function Notifications() {
  const popover = usePopover()
  const { data: unreadNotificationsCache } = useUserNotifications({ read: false }, { enabled: false })
  const { data: allNotificationsCache } = useUserNotifications({ read: undefined }, { enabled: false })

  const hasUnread = useMemo(() => {
    return [...(unreadNotificationsCache || []), ...(allNotificationsCache || [])].some((notification) => !notification.read)
  }, [allNotificationsCache, unreadNotificationsCache])

  return (
    <Box>
      <IconButton
        id="notifications-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        aria-label="Notifcations"
        data-testid="header-notifcations"
      >
        <Badge color="error" variant="dot" invisible={!hasUnread} data-testid="header-notifications-badge" data-test-unread={hasUnread}>
          <BellOutlinedIcon style={{ fontSize: 18 }} />
        </Badge>
      </IconButton>
      <NotificationsPopover anchorEl={popover.anchorRef.current} open={popover.open} onClose={popover.handleClose} />
    </Box>
  )
}
