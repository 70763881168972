import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { handleError } from '@shared/utils'

import InputControl from '@components/InputControl'

import { useApplicationExpire } from './GileadApplication.hooks'

export default function ExpireGileadForm({ open, setOpen }) {
  const patient = usePatient()
  const reasons = useLookup(Lookup.ExpireGAReasons)

  const expireApplication = useApplicationExpire(patient.id)

  const onClose = () => {
    setOpen(false)
    setTimeout(() => formik.resetForm(), 300)
  }

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: (data) => {
      return expireApplication
        .mutateAsync(data)
        .then(onClose)
        .catch((e) => handleError(e, { showResponse: true }))
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form noValidate onSubmit={formik.handleSubmit}>
        <DialogTitle>Expire Gilead Advancing Access application</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            <Alert severity="warning" variant="border">
              This will expire any Gilead Advancing Access application which is currently in progress.
            </Alert>
            <InputControl field="reason" formikProps={formik}>
              <TextField select required fullWidth label="Reason" disabled={formik.isSubmitting}>
                {reasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </TextField>
            </InputControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error" variant="text">
            Cancel
          </Button>
          <Button loading={formik.isSubmitting} type="submit" variant="contained">
            Expire
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
