import { useMemo, useRef } from 'react'
import dayjs from 'dayjs'

import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import useUserSetting from '@shared/hooks/src/useUserSetting'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { getPhoneFormatted, TaskStatus, toTitleCase, UserSettingName } from '@shared/utils'

import { GlobalOutlinedIcon, MailOutlinedIcon, PhoneOutlinedIcon } from '@icons'

import Files from '../Files'
import Insurances from '../Insurances'
import Labs from '../Labs'
import Notes from '../Notes'
import Prescriptions from '../Prescriptions'

export default function PatientDetails({ task }) {
  const ref = useRef()
  const disabled = [TaskStatus.Completed, TaskStatus.Expired].includes(task.status)

  return (
    <Stack ref={ref} spacing={5} sx={{ flex: 1 }}>
      <Contacts />
      <Address />
      <Labs task={task} />
      <Prescriptions task={task} />
      <Insurances task={task} />
      <Files task={task} disabled={disabled} />
      <Notes task={task} disabled={disabled} containerRef={ref} />
    </Stack>
  )
}

function Loading() {
  return (
    <Stack spacing={5} sx={{ flex: 1 }}>
      <Contacts.Loading />
      <Address.Loading />
      <Skeleton variant="rounded" height={180} />
      <Skeleton variant="rounded" height={180} />
      <Skeleton variant="rounded" height={180} />
      <Skeleton variant="rounded" height={180} />
      <Notes.Loading />
    </Stack>
  )
}

function Contacts() {
  const patient = usePatient()
  const contactMethodSetting = useUserSetting(patient, UserSettingName.ContactMethod)

  if (!patient) return <Contacts.Loading />

  const dob = patient.dob ? dayjs(patient.dob).format('L') : ''

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <PhoneIcon />
            <Typography copy>{patient.phone ? getPhoneFormatted(patient.phone) : ''}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <MailIcon />
            <Typography copy>{patient.email}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <GlobalIcon />
            <Typography>{patient.language}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Stack>
          <Typography>Opted-{patient.smsOptOut ? 'out' : 'in'}</Typography>
          {contactMethodSetting && <Typography>Prefers {toTitleCase(contactMethodSetting.value)}</Typography>}
          <Typography copy={dob}>{`DOB: ${dob}`}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

Contacts.Loading = function () {
  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Stack>
          <Typography>
            <Skeleton width={100} />
          </Typography>
          <Typography>
            <Skeleton width={80} />
          </Typography>
          <Typography>
            <Skeleton width={70} />
          </Typography>
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Stack>
          <Typography>
            <Skeleton width={100} />
          </Typography>
          <Typography>
            <Skeleton width={80} />
          </Typography>
          <Typography>
            <Skeleton width={70} />
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

const Address = () => {
  const patient = usePatient()

  if (!patient) return <Address.Loading />

  return (
    <Grid container>
      {patient.homeAddress && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <AddressBlock title="Home Address" data={patient.homeAddress} />
        </Grid>
      )}
      {patient.shippingAddress && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <AddressBlock title="Shipping Address" data={patient.shippingAddress} />
        </Grid>
      )}
    </Grid>
  )
}

Address.Loading = function () {
  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6 }}>
        <AddressBlock.Loading />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <AddressBlock.Loading />
      </Grid>
    </Grid>
  )
}

const AddressBlock = ({ title, data }) => {
  const { address, address2, city, state, zip } = data

  const fullAddress = useMemo(() => {
    return [address, address2, [city, state, zip].join(', ')].filter(Boolean).join('\n')
  }, [address, address2, city, state, zip])

  return (
    <Stack>
      <Typography variant="h5" copy={fullAddress} sx={{ fontWeight: 'light', pb: 1 }}>
        {title}
      </Typography>
      <Typography copy sx={{ whiteSpace: 'nowrap' }}>
        {address}
      </Typography>
      {address2 && (
        <Typography copy sx={{ whiteSpace: 'nowrap' }}>
          {address2}
        </Typography>
      )}
      <Typography copy sx={{ whiteSpace: 'nowrap' }}>
        {[city, state, zip].join(', ')}
      </Typography>
    </Stack>
  )
}

AddressBlock.Loading = function () {
  return (
    <Stack>
      <Typography variant="h5" sx={{ fontWeight: 'light', pb: 1 }}>
        <Skeleton width={100} />
      </Typography>
      <Typography>
        <Skeleton width={120} />
      </Typography>
      <Typography>
        <Skeleton width={60} />
      </Typography>
      <Typography>
        <Skeleton width={100} />
      </Typography>
    </Stack>
  )
}

PatientDetails.Loading = Loading

const PhoneIcon = styled(PhoneOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

const MailIcon = styled(MailOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

const GlobalIcon = styled(GlobalOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
