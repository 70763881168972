import dayjs from 'dayjs'

import { Yup } from '@shared/utils'

export const initialValues = {
  dateType: 'ordered_date',
  startDate: null,
  endDate: null,
  status: [],
}

export const toApiValues = (values) => ({
  date_filter: values.dateType,
  from_date: values.startDate?.format('YYYY-MM-DD'),
  to_date: values.endDate?.format('YYYY-MM-DD'),
  status: values.status,
})

export const validationSchema = Yup.object()
  .shape({
    dateType: Yup.string().oneOf(['ordered_date', 'creation_date']).required('Required'),
    startDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-start', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    endDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-end', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    status: Yup.array().of(Yup.string()).nullable(),
  })
  .test({
    name: 'validate-range',
    test: function ({ startDate, endDate }) {
      if (dayjs.isDayjs(startDate) && dayjs.isDayjs(endDate)) {
        if (startDate.isAfter(endDate)) {
          return this.createError({ path: 'range', message: 'Start must be before End' })
        }
        if (endDate.diff(startDate, 'year') >= 1) {
          return this.createError({ path: 'range', message: 'Range cannot exceed 1 year' })
        }
      }
      return true
    },
  })
