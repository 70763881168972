import { useMemo } from 'react'

import useQuery from '@shared/hooks/src/useQuery'
import { QK } from '@shared/utils'

/**
 * Get last message of conversation from the react-query cache
 */
export default function useCachedConversationLastMessage(conversationId) {
  // Just read the cache without triggering a request if the conversation is not loaded
  const { data } = useQuery({
    queryKey: QK.conversations.id(conversationId).messages.list(),
    enabled: false,
  })

  return useMemo(() => {
    if (!data) return
    const lastPage = data.pages[0]
    const lastMessage = lastPage[lastPage.length - 1]
    return lastMessage
  }, [data])
}
