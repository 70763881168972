import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { viewBase64 } from '@shared/utils'

import { PlusOutlinedIcon } from '@icons'
import File from '@components/File'
import { AttachFileButton } from '@components/Kanban'

import { useDocumentRemove, useFiles } from './Files.hooks'

const styles = {
  content: {
    position: 'relative',
    height: 150,
  },
  list: {
    position: 'absolute',
    inset: 0,
    overflowX: 'auto',
    p: 1,
    gap: 1,
  },
}

export default function Files({ task, disabled }) {
  const { data, isPending } = useFiles(task.id)
  const removeDocument = useDocumentRemove(task.id)

  const handleDownload = (file) => viewBase64(file.content, file.mimetype, file.filename)
  const handleDelete = (id) => removeDocument.mutateAsync(id)

  if (data?.length === 0 || isPending) return null

  return (
    <Stack>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
        <Typography variant="h5">Files</Typography>
        <AttachFileButton task={task} disabled={disabled} icon={<PlusIcon />} />
      </Stack>
      <Divider />
      <Box sx={styles.content}>
        <Stack direction="row" sx={styles.list}>
          {data.map(({ id, file }) => (
            <File key={id} data={file} onDownload={() => handleDownload(file)} onDelete={disabled ? undefined : () => handleDelete(id)} />
          ))}
        </Stack>
      </Box>
    </Stack>
  )
}

const PlusIcon = styled(PlusOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 15,
}))
