import { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { useTheme } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { SettingOutlinedIcon } from '@icons'
import DeviceSelectionDialog from '@components/_twilio/components/DeviceSelectionDialog'
import Transitions from '@components/Transitions'

import { IconButton } from '../Header.utils'

export default function Settings() {
  const menuButton = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false)

  const theme = useTheme()

  const handleMenuToggle = () => setMenuOpen((prevOpen) => !prevOpen)
  const handleMenuClose = (event) => {
    if (menuButton.current && menuButton.current.contains(event.target)) return
    setMenuOpen(false)
  }

  const handleListItemClick = (setting) => {
    if (setting === 'avSettings') {
      setDeviceSettingsOpen(true)
    }
    setMenuOpen(false)
  }

  return (
    <Box>
      <IconButton
        ref={menuButton}
        onClick={handleMenuToggle}
        aria-controls={menuOpen ? 'settings-grow' : undefined}
        aria-label="open settings"
        aria-haspopup="true"
        data-testid="header-settings"
      >
        <SettingOutlinedIcon style={{ fontSize: 18 }} />
      </IconButton>

      <Popper open={menuOpen} anchorEl={menuButton.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Transitions type="fade" in={menuOpen} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick('avSettings')}>
                    <ListItemText primary={<Typography color="textPrimary">Audio/Video Settings</Typography>} />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <DeviceSelectionDialog open={deviceSettingsOpen} onClose={() => setDeviceSettingsOpen(false)} />
    </Box>
  )
}
