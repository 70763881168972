import Box from '@mui/material/Box'
import Typography from '@components/_mui/Typography'

const styles = {
  hover: {
    cursor: 'pointer',
    position: 'absolute',
    inset: 0,
    p: 1,
    backgroundColor: 'grey.800',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0,
    '&:hover': {
      opacity: 0.7,
    },
  },
  borders: {
    width: '100%',
    height: '100%',
    border: '1px dashed',
    borderColor: 'grey.0',
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default function FileHover({ label = 'Click to View' }) {
  return (
    <Box sx={styles.hover}>
      <Box sx={styles.borders}>
        <Typography variant="h5" sx={{ color: 'text.contrast' }}>
          {label}
        </Typography>
      </Box>
    </Box>
  )
}
