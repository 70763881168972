import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useApplicationSubmit(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).gilead.create(data),
    onSuccess: () => {
      queryClient.setQueryData(QK.patients.id(patientId).details, (prev = {}) => ({
        ...prev,
        pendingGileadApplications: true,
      }))
    },
  })
}

export function useApplicationExpire(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).gilead.expire(data),
    onSuccess: (resp) => {
      toast(resp.info)
      queryClient.setQueryData(QK.patients.id(patientId).details, (prev = {}) => ({
        ...prev,
        pendingGileadApplications: false,
      }))
    },
  })
}
