import { useState } from 'react'
import { toast } from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useLocalStorage } from 'usehooks-ts'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import usePrompt from '@shared/hooks/src/usePrompt'
import { useMe } from '@shared/providers/src/MeProvider'
import { handleError } from '@shared/utils'

import { CloseOutlinedIcon, SnippetsOutlinedIcon } from '@icons'
import AvatarPatient from '@components/AvatarPatient'
import DocTitle from '@components/DocTitle'
import { SlideUp } from '@components/Transitions'
import VaccinesForm from '@components/VaccinesForm'

import { useGetChangeUrl, useSyncPrescriptions } from './PrescriptionOrderModal.hooks'
import { styles } from './PrescriptionOrderModal.utils'
import Rx from './Rx'
import { Insurances, PatientInfo, Prescriptions } from './tabs'

PrescriptionOrderModal.propTypes = {
  /** The patient the prescription will be associated with */
  patient: PropTypes.object.isRequired,

  /** The encounter this prescription will be related to */
  encounter: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    date: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,

  /** When true, the modal will slide up from the bottom */
  slide: PropTypes.bool,

  /** When true, the vaccine tab will be hidden */
  hideVaccine: PropTypes.bool,

  /** When true, modal will be displayed */
  open: PropTypes.bool,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,
}

/**
 * Displays a modal to order prescriptions for a patient
 */
export default function PrescriptionOrderModal({ open = false, slide = false, patient, encounter, hideVaccine, onClose }) {
  return (
    <Dialog
      open={open}
      fullScreen
      disableEscapeKeyDown
      TransitionComponent={slide ? SlideUp : undefined}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
      sx={{ '& .MuiDialogContent-root': { p: 0 } }}
    >
      <Content patient={patient} encounter={encounter} hideVaccine={hideVaccine} onClose={onClose} />
    </Dialog>
  )
}

function Content({ patient, encounter, hideVaccine, onClose }) {
  const [, setQPhrasesDrawer] = useLocalStorage('q-phrases-drawer', false)
  const [disableNavigation, setDisableNavigation] = useState(false)
  const [changeUrl, setChangeUrl] = useState(null)
  const [currentInfoTab, setCurrentInfoTab] = useState('rx-history')
  const [currentOrderTab, setCurrentOrderTab] = useState('drug')

  const me = useMe()
  const providerId = me?.providerId

  const getChangeUrl = useGetChangeUrl(providerId)
  const syncPrescriptions = useSyncPrescriptions(providerId)

  // Block navigation if the user has not "completed" the order
  usePrompt('Please use the Complete Order button or your prescription will not be saved!', disableNavigation)

  // Query BE to get the change url and disable navigation
  const handleLoadRxPad = ({ diagnosis }) => {
    getChangeUrl
      .mutateAsync({
        provider_id: providerId,
        patient_id: patient.id,
        encounter_id: encounter.id,
        diagnosis,
      })
      .then((data) => {
        setChangeUrl(data.url)
        setDisableNavigation(true)
      })
      .catch(handleError)
  }

  // handle cleanup and close the main dialog
  const handleClose = () => {
    setChangeUrl(null)
    onClose()
  }

  // Tell the BE to sync the prescriptions, close the main modal and allow navigation
  const handleCompleteOrder = () => {
    setDisableNavigation(false)

    syncPrescriptions
      .mutateAsync({ patient_id: patient.id })
      .then(() => {
        toast.success('Your prescription was recorded')
        handleClose()
      })
      .catch(handleError)
  }

  // Handle a case where the Rx Pad was loaded but the user does not order a script and wants to close the modal.
  const handleDiscardPartialOrder = () => {
    setDisableNavigation(false)
    handleClose()
  }

  // Display a dialog if closing while the rx pad is loaded
  const handleCloseWhileActive = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      onComplete: () => {
        close()
        handleCompleteOrder()
      },
      onCancel: () => close(),
      onConfirm: () => {
        close()
        handleDiscardPartialOrder()
      },
    }),
  })

  return (
    <>
      <DocTitle title="Order Prescription" />
      <AppBar sx={{ position: 'relative', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={changeUrl ? handleCloseWhileActive : handleClose} aria-label="close">
            <CloseOutlinedIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            Order Prescription
          </Typography>
          <IconButton onClick={() => setQPhrasesDrawer((o) => !o)} sx={{ color: 'inherit' }}>
            <SnippetsOutlinedIcon style={{ fontSize: 18 }} />
          </IconButton>
          {changeUrl && (
            <Button color="primary" variant="contained" onClick={handleCompleteOrder} sx={{ ml: 1 }}>
              Complete Order
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <DialogContent>
          <Box sx={styles.grid}>
            <Paper variant="outlined" sx={{ gridArea: 'content' }}>
              <Stack sx={styles.hideScroll}>
                <Box sx={{ p: 2 }}>
                  <AvatarPatient patient={patient} clipboard hover="card" />
                </Box>
                <Tabs value={currentInfoTab} onChange={(e, tab) => setCurrentInfoTab(tab)}>
                  <Tab id="rx-history-tab" aria-controls="rx-history-panel" value="rx-history" label="Rx History" />
                  <Tab id="summary-tab" aria-controls="summary-panel" value="summary" label="Summary" />
                  <Tab id="insurance-tab" aria-controls="insurance-panel" value="insurance" label="Insurance" />
                </Tabs>
                <Divider />
                <Box sx={styles.scroll}>
                  <TabPanel id="rx-history-panel" aria-controls="rx-history-tab" value={currentInfoTab} sx={{ height: '100%' }}>
                    <Prescriptions patient={patient} />
                  </TabPanel>
                  <TabPanel id="summary-panel" aria-controls="summary-tab" value={currentInfoTab}>
                    <PatientInfo patient={patient} />
                  </TabPanel>
                  <TabPanel id="insurance-panel" aria-controls="insurance-tab" value={currentInfoTab} sx={{ height: '100%' }}>
                    <Insurances patient={patient} />
                  </TabPanel>
                </Box>
              </Stack>
            </Paper>
            <Paper variant="outlined" sx={{ gridArea: 'select', overflow: 'hidden' }}>
              <Stack sx={styles.hideScroll}>
                {!hideVaccine && (
                  <>
                    <Tabs value={currentOrderTab} onChange={(e, tab) => setCurrentOrderTab(tab)}>
                      <Tab id="drug-tab" aria-controls="drug-panel" value="drug" label="Drug" />
                      <Tab id="vaccine-tab" aria-controls="vaccine-panel" value="vaccine" label="Vaccine" />
                    </Tabs>
                    <Divider />
                  </>
                )}
                <Box sx={styles.scroll}>
                  <TabPanel id="drug-panel" aria-controls="drug-tab" value={currentOrderTab} sx={{ p: 2, height: '100%' }}>
                    <Rx
                      patient={patient}
                      encounter={encounter}
                      rxPadLoaded={Boolean(changeUrl)}
                      rxPadLoading={getChangeUrl.isPending}
                      onLoadRxPad={handleLoadRxPad}
                      changeUrl={changeUrl}
                    />
                  </TabPanel>
                  {!hideVaccine && (
                    <TabPanel id="vaccine-panel" aria-controls="vaccine-tab" value={currentOrderTab} sx={{ p: 2, height: '100%' }}>
                      <VaccinesForm patientId={patient.id} encounter={encounter} />
                    </TabPanel>
                  )}
                </Box>
              </Stack>
            </Paper>
            <Paper variant="outlined" sx={{ gridArea: 'pad' }}>
              <Stack>
                <Typography sx={{ p: 2 }}>DoseSpot prescription order form</Typography>
                <Divider sx={{ mb: 2 }} />

                {/* DoseSpot IFrame */}
                {changeUrl ? (
                  <iframe
                    title="prescription-order-frame"
                    id="prescription-order-frame"
                    scrolling="yes"
                    frameBorder="0"
                    src={changeUrl}
                    style={{ width: '100%', height: '60rem' }}
                  />
                ) : (
                  <Typography variant="h4" align="center" sx={{ fontWeight: 'light' }}>
                    Please complete ordering info, then Load Rx Pad to view
                  </Typography>
                )}
              </Stack>
            </Paper>
          </Box>
        </DialogContent>
      </Container>
    </>
  )
}

function TabPanel({ id, value, children, ...other }) {
  const isHidden = `${value}-panel` !== id

  return (
    <Fade in={!isHidden}>
      <Box role="tabpanel" hidden={isHidden} id={id} aria-labelledby={`${id}-tab`} {...other}>
        {children}
      </Box>
    </Fade>
  )
}

function Confirmation({ open, onComplete, onCancel, onConfirm }) {
  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="xs">
      <DialogTitle>Discard Prescription?</DialogTitle>
      <DialogContent dividers>
        <Typography>If you have written a prescription using the Rx Pad, please use 'Complete Order' to close the prescription.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={onConfirm} color="secondary" variant="outlined">
          Discard
        </Button>
        <Button onClick={onComplete} variant="contained">
          Complete Order
        </Button>
      </DialogActions>
    </Dialog>
  )
}
