import { keyframes } from '@emotion/react'

import Dialog from '@mui/material/Dialog'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@components/_mui/Typography'

import { EncounterType } from '@shared/utils'

import Avatar from '@components/Avatar'

export const EncounterToColor = {
  [EncounterType.InjectablePrEP]: 'brand.purple',
  [EncounterType.PrepInitial]: 'brand.purple',
  [EncounterType.PrepFollowUp]: 'brand.purple',
  [EncounterType.PrepQuarterly]: 'brand.blue',
  [EncounterType.HepC]: 'brand.yellow',
  [EncounterType.HivEnrollment]: 'brand.green',
  [EncounterType.HivInitial]: 'brand.green',
  [EncounterType.HivFollowUp]: 'brand.green',
  [EncounterType.HivAdherence]: 'brand.green',
  [EncounterType.STIFollowUp]: 'brand.gray',
  [EncounterType.STITesting]: 'brand.gray',
  [EncounterType.PEP]: 'error.dark',
  [EncounterType.Wellness]: 'warning.dark',
}

const pulsate = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`

export const styles = {
  list: {
    width: '100%',
    transition: 'opacity 0.5s ease-in-out',
  },
  refreshing: {
    opacity: 0.5,
    animation: `${pulsate} 2s ease-out infinite`,
  },
  details: {
    width: '100%',
    position: 'sticky',
    top: (theme) => `calc(${theme.mixins.toolbar.height}px + ${theme.spacing(2)})`,
  },
}

export const Empty = ({ children }) => (
  <Typography variant="h5" align="center" sx={{ color: 'text.secondary', p: 2 }}>
    {children}
  </Typography>
)

export function MobileDetailsAdaptation({ children, open, onClose }) {
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  if (matchDownSm) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        {children}
      </Dialog>
    )
  }

  return <Stack sx={styles.details}>{children}</Stack>
}

export function Patient({ data }) {
  const subtitle = [`MRN ${data.id}`, data.pronouns].filter(Boolean).join(' • ')

  return (
    <Stack direction="row" spacing={1}>
      <Avatar user={data} hover="card" />
      <Stack sx={{ display: 'inline-grid' }}>
        <Typography noWrap sx={{ lineHeight: '1.5' }}>
          {data.fullName}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary', lineHeight: '1.3' }}>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  )
}

Patient.Loading = function () {
  return (
    <Stack direction="row" spacing={1}>
      <Skeleton variant="rounded" width={40} height={40} />
      <Stack>
        <Typography sx={{ lineHeight: '1.5' }}>
          <Skeleton width={100} />
        </Typography>
        <Typography variant="subtitle2" sx={{ lineHeight: '1.3' }}>
          <Skeleton width={60} />
        </Typography>
      </Stack>
    </Stack>
  )
}
