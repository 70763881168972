import { useState } from 'react'
import toast from 'react-hot-toast'
import PubSub from 'pubsub-js'

import Button from '@mui/material/Button'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { handleError, LabTypes } from '@shared/utils'

import { useLabOrder, useLabOrderDraft, useLabVendors } from '../LabsOrderModal.hooks'
import { mapDataForConfirmation, mapDataForLabOrder, objectifyVendors } from '../LabsOrderModal.utils'
import ConfirmDialog from './ConfirmDialog'

export default function Order({ patient, encounter, onClose }) {
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const { draft, removeDraft } = useLabOrderDraft(patient?.id, encounter?.id)

  const { data: vendors } = useLabVendors(patient?.id, encounter?.id, draft.reason, {
    enabled: false,
    select: objectifyVendors,
  })

  const orderLab = useLabOrder(patient?.id)

  const [orderLabs, ordering] = usePromiseLoading(({ questNote }) => {
    const allOrders = Object.entries(draft.labs)
      .map(([vendorId, panels]) => {
        const data = mapDataForLabOrder(encounter?.id, draft.reason, vendorId, panels, patient?.shippingAddress)
        const isQuest = vendors[vendorId].name === LabTypes.Quest
        if (isQuest) data.note = questNote
        return data
      })
      .filter((order) => order.mtl_panel.length > 0)
      .map((order) => orderLab.mutateAsync(order))

    return Promise.all(allOrders)
      .then(() => {
        PubSub.publish(PubSubEvents.LabOrdered)
        toast('Labs have been ordered')
        setConfirmationOpen(false)
        removeDraft()
        onClose()
      })
      .catch(handleError)
  })

  const canOrder = Boolean(draft.reason) && Object.values(draft.labs).some((labs) => labs.length > 0)

  return (
    <>
      <Button fullWidth disabled={!canOrder} variant="contained" color="primary" size="large" onClick={() => setConfirmationOpen(true)}>
        Order Labs
      </Button>
      <ConfirmDialog
        loading={ordering}
        selected={confirmationOpen ? mapDataForConfirmation(vendors, draft.labs) : undefined}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={orderLabs}
      />
    </>
  )
}
