import { useState } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import range from 'lodash/range'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Typography from '@components/_mui/Typography'

import { LabsModal } from '@pages/Labs'
import { LabDetailsModal } from '@pages/Labs/Lab'
import { usePatientLabs, usePatientLabsFilters } from '@pages/Labs/PatientLabs'
import { ExpandAltOutlinedIcon } from '@icons'
import StatusChip from '@components/StatusChip'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'

export default function Labs() {
  const { id } = useParams()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [labId, setLabId] = useState()

  const [{ labsTab, labsExpanded }, updateFilters] = usePatientLabsFilters()
  const { data, isRefreshing, isPending } = usePatientLabs(id, { filter: labsTab, limit: 5 })

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Tile
      title="Labs"
      refreshing={isRefreshing}
      action={
        <IconCircleButton size="small" onClick={() => updateFilters({ labsExpanded: true })}>
          <ExpandAltOutlinedIcon />
        </IconCircleButton>
      }
      sx={{ height: '100%', overflowY: 'hidden' }}
    >
      <LabDetailsModal labId={labId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
      <LabsModal patientId={id} open={labsExpanded} onClose={() => updateFilters({ labsExpanded: false })} />
      <Tabs value={labsTab} onChange={(e, labsTab) => updateFilters({ labsTab })}>
        <Tab label="All" value="all" />
        <Tab label="Complete" value="complete" />
        <Tab label="Pending" value="pending" />
        <Tab label="Follow-up" value="follow-up" />
        <Tab label="Failed" value="failed" />
      </Tabs>
      <TableContainer>
        <Table size="small">
          <TableBody data-testid="labs">
            {showLoading && <Loading />}
            {showEmpty && <Empty />}
            {showData &&
              data.map((lab) => (
                <TableRow
                  key={lab.id}
                  hover
                  onClick={() => {
                    setLabId(lab.id)
                    setDetailsOpen(true)
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                      <Typography>{lab.lab}</Typography>
                      <StatusChip.Lab status={lab.status} noLabel />
                    </Stack>
                  </TableCell>
                  <TableCell>{lab.panelsToString}</TableCell>
                  <TableCell>{lab.ordered ? dayjs(lab.ordered).format('L') : ''}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Tile>
  )
}

function Loading() {
  return range(0, 3).map((i) => (
    <TableRow hover key={i}>
      <TableCell width={100}>
        <Skeleton width={80} />
      </TableCell>
      <TableCell>
        <Skeleton width={150} />
      </TableCell>
      <TableCell width={100}>
        <Skeleton width={80} />
      </TableCell>
    </TableRow>
  ))
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={3} align="center">
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No data to display
        </Typography>
      </TableCell>
    </TableRow>
  )
}
