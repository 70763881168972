import { deserialize } from 'deserialize-json-api'
import { chain, head } from 'lodash'

import { TodoType, UserRole } from '@shared/utils'

export const fileConfig = { skipDeserialization: true, timeout: Infinity, responseType: 'blob' }

export const deserializeTodos = (data) => {
  return data.map((item) => {
    if ([TodoType.QuestLabOrdered, TodoType.LabsReceived].includes(item.type)) {
      const { serializable_lab: serializableLab, ...rest } = item
      return {
        ...rest,
        lab: deserialize(serializableLab, { transformKeys: 'camelCase' })?.data ?? {},
      }
    }
    if (item.type === TodoType.Appointment) {
      const { serializable_appointment: serializableAppointment, ...rest } = item
      return {
        ...rest,
        appointment: deserialize(serializableAppointment, { transformKeys: 'camelCase' })?.data ?? {},
      }
    }
    if (item.type === TodoType.Insurance) {
      const { serializable_pharmacy: data, ...rest } = item
      const pharmacy = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
      return { ...rest, pharmacy }
    }
    if (item.type === TodoType.Generic) {
      const { serializable_todo: data, ...rest } = item
      const todo = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
      return { ...rest, todo }
    }
    return item
  })
}

export const deserializeEmbeddedSoapNote = (data) => {
  if (data.soap_note && 'string' === typeof data.soap_note && data.soap_note.length) {
    data.soap_note = JSON.parse(data.soap_note)
  } else {
    data.soap_note = {}
  }

  return data
}

export const mapRuleSets = (data) => ({
  grant340BInformation: JSON.parse(data?.grant340BInformation ?? '{}'),
  additionalServices: JSON.parse(data?.additionalServices ?? '{}'),
  patientCare: JSON.parse(data?.patientCare ?? '{}'),
  financials: JSON.parse(data?.financials ?? '{}'),
  contacts: JSON.parse(data?.contacts ?? '[]'),
  prescriptionPartners: JSON.parse(data?.prescriptionPartners ?? '[]'),
})

export const mapDashboardLabs = (data) => ({
  review: deserialize(data.review_status, { transformKeys: 'camelCase' })?.data ?? [],
  followUp: deserialize(data.follow_up_status, { transformKeys: 'camelCase' })?.data ?? [],
  positive: deserialize(data.positive_status, { transformKeys: 'camelCase' })?.data ?? [],
})

export const mapGaps = (data) => {
  const appointments = Object.entries(data.appointments).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  const availabilities = Object.entries(data.availabilities).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  return {
    timezone: data.timezone,
    appointments,
    availabilities,
    providers: deserialize(data.providers, { transformKeys: 'camelCase' })?.data ?? [],
    gaps: deserialize(data.gaps, { transformKeys: 'camelCase' })?.data ?? [],
  }
}

export const mapUserCard = (data) => ({
  id: data.id,
  fullName: data.fullName,
  phone: data.phone,
  profilePictureThumbUrl: data.profilePictureThumbUrl,
  role: data.role,
  pronouns: data.pronouns,
  timezone: data.timezone,
  outOfOfficeMessage: data.outOfOfficeMessage,
  userSettings: data.userSettings,
  disabled: data.disabled,
  accessible: data.accessible,
  ...(data.role === UserRole.Provider && {
    fullName: data.providerFullName,
    provider: {
      id: data.providerId,
      providerTypes: data.providerTypes,
      licenses: data.licenses,
      homeAddress: {
        city: data.providerCity,
        state: data.providerStateAbbreviation,
      },
    },
  }),
  ...(data.role === UserRole.CBO && {
    cbo: {
      name: data.cboName,
    },
  }),
  ...(data.role === UserRole.Pharmacy && {
    pharmacy: {
      name: data.pharmacyName,
    },
  }),
  ...(data.role === UserRole.Patient && {
    enrolled: data.enrolled,
    consent: data.consent,
    patient: {
      treatmentTypes: data.treatmentTypes,
      homeAddress: {
        city: data.homeAddressCity,
        state: data.homeAddressState,
      },
      ...(data.providerFullName && {
        provider: {
          fullName: data.providerFullName,
          profilePictureThumbUrl: data.providerProfilePictureThumbUrl,
        },
      }),
      cbo: {
        name: data.cboName,
      },
      pharmacy: {
        name: data.pharmacyName,
      },
    },
  }),
})

export const mapSettings = (data) => {
  if (data.length === 0) return undefined
  return data.reduce((acc, setting) => ({ ...acc, [setting.key]: setting.value }), {})
}

export const mapTours = (data) => chain(data).groupBy('name').mapValues(head).value()

export const mapServices = (data) => {
  const services = deserialize(data.service_lines, { transformKeys: 'camelCase' })?.data
  const onDemand = deserialize(data.on_demand_service, { transformKeys: 'camelCase' })?.data
  return services.map((service) => ({ ...service, onDemand: onDemand.some((o) => o.id === service.id) }))
}
