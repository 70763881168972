import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { getTestId } from '@shared/utils'

import { useAvailableVaccines, useOrderedVaccines } from './VaccinesForm.hooks'

export default function SelectVaccines({ patientId, encounter, onNext, selectedVaccines: previouslySelectedVaccines, ...rest }) {
  const testId = getTestId(rest, 'select-vaccines')

  const { data: orderedVaccines, isPending: areOrderedVaccinesPending } = useOrderedVaccines(patientId, encounter?.id)
  const { data: vaccines, isPending: areVaccinesPending } = useAvailableVaccines(encounter?.encounterTypeId)
  const isLoading = useLoadingState(areOrderedVaccinesPending || areVaccinesPending)

  const [selectedVaccines, setSelectedVaccines] = useState(previouslySelectedVaccines || [])
  const handleSelectedVaccine = useCallback(
    (vaccine) => {
      const selectedVaccineIds = selectedVaccines.map((v) => v.id)
      if (!selectedVaccineIds.includes(vaccine.id)) setSelectedVaccines([...selectedVaccines, vaccine])
      if (selectedVaccineIds.includes(vaccine.id)) setSelectedVaccines(selectedVaccines.filter((v) => v.id !== vaccine.id))
    },
    [selectedVaccines]
  )

  return (
    <Slide in direction="left">
      <Stack spacing={2} sx={{ justifyContent: 'space-between', minHeight: '400px', height: '100%' }}>
        <Stack spacing={1}>
          <Typography variant="body1">Recommend a vaccine</Typography>
          <Typography variant="body2" color="textSecondary">
            An order for the vaccine will not be placed. A written document will be sent to the patient for their local provider to
            administer the vaccine.
          </Typography>
          <List disablePadding data-testid={`${testId}-vaccines`}>
            {isLoading && <Loading />}
            {!isLoading &&
              vaccines.map((vaccine) => (
                <ListItemButton
                  key={vaccine.id}
                  selected={selectedVaccines.map((v) => v.id).includes(vaccine.id)}
                  onClick={() => handleSelectedVaccine(vaccine)}
                  divider
                >
                  <ListItemText primary={vaccine.name} secondary={vaccine.description} />
                  {orderedVaccines?.includes(vaccine.id) && <ListItemText sx={{ textAlign: 'right' }}>ordered</ListItemText>}
                </ListItemButton>
              ))}
          </List>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
          <Box sx={{ width: '50%' }}>
            <Button
              disabled={isLoading || !selectedVaccines.length}
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => onNext(selectedVaccines)}
              data-testid={`${testId}-next`}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Slide>
  )
}

function Loading() {
  return (
    <>
      <ListItem>
        <Skeleton width={300} />
      </ListItem>
      <ListItem>
        <Skeleton width={300} />
      </ListItem>
      <ListItem>
        <Skeleton width={300} />
      </ListItem>
      <ListItem>
        <Skeleton width={300} />
      </ListItem>
    </>
  )
}
