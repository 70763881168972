import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'

import Stack from '@mui/material/Stack'

import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'

import { RxSortOrder, usePatientPrescriptions } from '../../PatientPrescriptions/PatientPrescriptions.hooks'
import PrescriptionsTable from '../../PrescriptionsTable/PrescriptionsTable'

const perPage = 5

export default function Prescriptions({ patient }) {
  const [order, setOrder] = useState(RxSortOrder.DESC)
  const [page, setPage] = useState(1)

  const { data, isPending } = usePatientPrescriptions(
    patient?.id,
    { order, limit: perPage, offset: (page - 1) * perPage },
    { placeholderData: keepPreviousData }
  )

  return (
    <Stack sx={{ height: '100%' }}>
      <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No prescriptions found">
        <PrescriptionsTable prescriptions={data} patientId={patient.id} order={order} onOrderChange={setOrder} />
      </RenderControl>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Pagination
          disableRowsPerPage
          page={page}
          perPage={perPage}
          setPageParams={({ page }) => setPage(page)}
          loading={isPending}
          last={data?.length < perPage}
        />
      </Stack>
    </Stack>
  )
}
