import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { handleError } from '@shared/utils'

import { LabDetailsModal } from '@pages/Labs/Lab'

import { useTasksFilters } from '../../Tasks.hooks'
import { useMarkCompleted } from './TaskInfo.hooks'
import { isNotDone, Patient } from './TaskInfo.utils'

export default function IncompleteLabInfo({ data, patient }) {
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [resolveOpen, setResolveOpen] = useState(false)

  const notDone = isNotDone(data)
  return (
    <>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" color="secondary" onClick={() => setDetailsOpen(true)}>
          Lab Details
        </Button>
        {notDone && (
          <Button variant="contained" onClick={() => setResolveOpen(true)}>
            Resolve
          </Button>
        )}
      </Stack>
      {notDone && (
        <Dialog open={resolveOpen} onClose={() => setResolveOpen(false)} fullWidth maxWidth="xs">
          <ResolveContent task={data} patient={patient} onClose={() => setResolveOpen(false)} />
        </Dialog>
      )}
      <LabDetailsModal labId={data.relatedModelId} open={detailsOpen} onClose={() => setDetailsOpen(false)} />
    </>
  )
}

function ResolveContent({ task, patient, onClose }) {
  const [, updateFilters] = useTasksFilters()
  const reasons = useLookup(Lookup.LabFollowUpTaskResolveReasons)
  const markCompleted = useMarkCompleted(task.id)

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: ({ reason }) => {
      return markCompleted
        .mutateAsync(reason)
        .then(() => updateFilters({ task: undefined }))
        .catch(handleError)
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Resolve 'ABNL Lab Follow Up'</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <RadioGroup aria-labelledby="reason" name="reason" value={formik.values.reason} onChange={formik.handleChange}>
            {reasons.map((reason) => (
              <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
            ))}
          </RadioGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} type="submit" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </form>
  )
}
