import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

import { getTestId } from '@shared/utils'

/**
 * Helper component for sorting table cells
 *
 * @param active - is this column currently sorted
 * @param direction - current sort direction
 * @param onChange - callback for changing sort direction
 * @param children - table cell content
 * @param rest - additional props to pass to TableCell
 *
 * @returns {JSX.Element}
 */
export default function TableSortCell({ active = true, direction, onChange, children, ...rest }) {
  const testId = getTestId(rest, `table-sort-cell-${children}`)

  const handleChange = () => {
    if (active) {
      onChange(direction === 'desc' ? 'asc' : 'desc')
    } else {
      onChange(direction)
    }
  }

  return (
    <TableCell sortDirection={direction} sx={active ? { backgroundColor: 'primary.100' } : {}} {...rest}>
      <TableSortLabel
        active={active}
        direction={direction}
        onClick={handleChange}
        data-testid={testId}
        data-test-active={active}
        data-test-direction={direction}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  )
}
