import { useMemo, useRef } from 'react'
import { Document, Thumbnail } from 'react-pdf'
import dayjs from 'dayjs'
import { useHover } from 'usehooks-ts'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'
import useDialog from '@shared/hooks/src/useDialog'
import { base64toBlob } from '@shared/utils'

import { CheckOutlinedIcon, CloudDownloadOutlinedIcon, DeleteOutlinedIcon, FileOutlinedIcon } from '@icons'
import Confirmation from '@components/Dialog/Confirmation'

import styles from './File.styles'

export default function File({ data, onDownload, onDelete, selected = false, onClick }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)

  const { name, filename, created } = data

  const confirmDelete = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      title: 'Delete File?',
      description: 'Delete the file? Once deleted there is no undo.',
      rejectLabel: 'Cancel',
      confirmLabel: 'Delete',
      onReject: () => close(),
      onConfirm: () => {
        const result = onDelete()
        if (result instanceof Promise) {
          return result.then(() => close())
        } else {
          close()
        }
      },
    }),
  })

  return (
    <Card ref={ref} elevation={4} sx={[styles.card, onClick && styles.clickable, selected && styles.selected]} onClick={onClick}>
      <Box sx={styles.content}>
        <Preview file={data} />
        {(onDelete || onDownload) && (
          <Fade in={isHovered}>
            <Stack sx={styles.overlay}>
              <Stack direction="row" spacing={1} sx={{ p: 1, justifyContent: 'flex-end' }}>
                {onDownload && (
                  <IconButton
                    variant="contained"
                    color="primary"
                    shape="rounded"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDownload()
                    }}
                  >
                    <CloudDownloadOutlinedIcon style={styles.icon} />
                  </IconButton>
                )}
                {onDelete && (
                  <IconButton
                    variant="contained"
                    color="primary"
                    shape="rounded"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      confirmDelete()
                    }}
                  >
                    <DeleteOutlinedIcon style={styles.icon} />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Fade>
        )}
      </Box>
      {(name || filename || created) && (
        <Stack sx={styles.description}>
          {(name || filename) && (
            <Typography noWrap variant="subtitle2">
              {name || filename}
            </Typography>
          )}
          {created && (
            <Typography noWrap variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {dayjs(created).format('L LT')}
            </Typography>
          )}
        </Stack>
      )}
      {selected && (
        <Box sx={styles.checked}>
          <CheckIcon rotate={-45} sx={{ position: 'absolute', top: 14, left: 3 }} />
        </Box>
      )}
    </Card>
  )
}

function Preview({ file }) {
  const { content, mimetype } = file

  const isImage = mimetype?.startsWith('image')
  const isPdf = mimetype === 'application/pdf'
  const pdf = useMemo(() => {
    return isPdf ? base64toBlob(content, mimetype) : null
  }, [content, isPdf, mimetype])

  if (isImage) {
    return <Image fit="cover" duration={500} src={`data:image/*;base64,${content}`} errorIcon={<FileIcon />} />
  }

  if (isPdf) {
    return (
      <Document file={pdf}>
        <Thumbnail pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={150} />
      </Document>
    )
  }

  return <Unrecognized />
}

function Unrecognized() {
  return (
    <Box sx={styles.unrecognized}>
      <FileIcon />
    </Box>
  )
}

const CheckIcon = styled(CheckOutlinedIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
  fontSize: 12,
}))

const FileIcon = styled(FileOutlinedIcon)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: 40,
}))
