import { useMutation } from '@tanstack/react-query'
import { BooleanParam, createEnumParam, NumberParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

/**
 * Hook to retrieve a patients insurances.
 */
export function useInsurances(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.list(query),
    queryFn: () => API.patients.id(patientId).insurances.list(query),
    ...options,
  })
}

/**
 * Hook to retrieve a the photos for an insurance record
 */
export function useInsurance(patientId, insuranceId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.id(insuranceId).details,
    queryFn: () => API.patients.id(patientId).insurances.id(insuranceId).details(),
    ...options,
  })
}

/**
 * Add an insurance record.
 * @param {string} patientId - the patient id
 */
export function useInsuranceCreation(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).insurances.create(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).insurances.lists })
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).insurances.summary })
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).details })
    },
  })
}

/**
 * Hook to remove a patient insurance.
 * @param {string} patientId - the patient id
 */
export function useInsuranceRemove(patientId) {
  return useMutation({
    mutationFn: (insuranceId) => API.patients.id(patientId).insurances.id(insuranceId).remove(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).insurances.lists })
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).insurances.summary })
    },
  })
}

/**
 * Hook to update patient's insurance.
 *
 * @param {string} patientId - the patient id
 * @param {string} insuranceId - the insurance id
 */
export function useInsuranceUpdate(patientId, insuranceId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).insurances.id(insuranceId).update(data),
    // Remove front and back images from the response because they are always null
    onSuccess: ({ front, back, ...data }, variables) => {
      queryClient.setQueryData(QK.patients.id(patientId).insurances.id(insuranceId).details, (prev) => {
        // update the cache with the new data to increase performance
        // front and back pictures must be updated only if new values are not empty
        return {
          ...prev,
          ...data,
          ...(variables.front_remove ? { front: undefined } : { front: variables.front_base64_image || prev.front }),
          ...(variables.back_remove ? { back: undefined } : { back: variables.back_base64_image || prev.back }),
        }
      })

      // Check if the insurance is in the ANY list of insurances of that patient and update it
      queryClient.setQueriesData(
        {
          queryKey: QK.patients.id(patientId).insurances.lists,
          // Make sure the insurance is in the list,
          // because otherwise the cache will be considered fresh after the setQueryData
          // and the query will not be refetched when it is enabled and not stale
          predicate: (query) => (query.state.data || []).some((item) => item.id === data.id),
        },
        (prev = []) => prev.map((item) => (item.id === data.id ? data : item))
      )
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).insurances.summary })
    },
  })
}

const SortParam = createEnumParam(['asc', 'desc'])

/**
 * Hook to set and retrieve the insurances filters.
 */
export function useInsurancesFilters() {
  return useFiltering({
    insurancesExpanded: withDefault(BooleanParam, false),
    insurancesSort: withDefault(SortParam, 'desc'),
    insDetailed: NumberParam,
  })
}
