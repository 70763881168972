export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: theme.typography.body1.fontSize,
          fontWeight: 'bold',
          color: theme.palette.background.paper,
        },
      },
    },
  }
}
