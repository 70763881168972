import { useMemo } from 'react'
import partition from 'lodash/partition'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import useEncounterTypeData from '@shared/hooks/src/useEncounterTypeData'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { LabTypes, VendorType } from '@shared/utils'

import { CloseCircleFilledIcon } from '@icons'

import { useLabOrderDraft, useLabVendors } from '../LabsOrderModal.hooks'
import { objectifyVendors, updateSelectedVendors } from '../LabsOrderModal.utils'

export default function Flow({ patient, encounter }) {
  const { draft, setDraft } = useLabOrderDraft(patient?.id, encounter?.id)

  const { data: encounterTypeData, isPending: isEncounterTypeDataPending } = useEncounterTypeData(encounter?.encounterTypeId)
  const vendorsQuery = useLabVendors(patient?.id, encounter?.id, draft.reason)

  useQueryEvents(vendorsQuery, {
    onSuccess: (data) => {
      const labs = updateSelectedVendors(objectifyVendors(data), draft.labs)
      setDraft({ ...draft, labs })
    },
  })

  const { data: labVendors } = vendorsQuery

  const [atHome, inPerson] = useMemo(() => {
    if (!labVendors) return []
    const vendors = labVendors.filter((v) => Object.values(VendorType).includes(v.vendorType))
    return partition(vendors, (v) => v.vendorType === VendorType.AtHome)
  }, [labVendors])

  const showAtHomeLabs = atHome?.length > 0
  const showInPersonLabs = inPerson?.length > 0
  const reasons = encounterTypeData?.labReasons || []

  return (
    <Stack sx={{ p: 2 }}>
      <Stack spacing={1}>
        <Typography variant="h4">Step #1: Select reason</Typography>
        <TextField select required fullWidth value={draft.reason} onChange={(event) => setDraft({ ...draft, reason: event.target.value })}>
          {isEncounterTypeDataPending ? (
            <MenuItem value={undefined}>Loading...</MenuItem>
          ) : (
            reasons.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))
          )}
        </TextField>
      </Stack>
      <Collapse in={Boolean(draft.reason) && Boolean(labVendors)}>
        <Stack spacing={1} sx={{ mt: 3 }}>
          <Typography variant="h4">Step #2: Select Labs</Typography>
          <Stack spacing={2}>
            {showAtHomeLabs && (
              <Stack spacing={2}>
                <Typography variant="h5">At-home labs</Typography>
                {atHome.map((vendor) => (
                  <Panels key={vendor.id} patientId={patient?.id} encounterId={encounter?.id} vendor={vendor} />
                ))}
              </Stack>
            )}
            {showInPersonLabs && (
              <Stack spacing={2}>
                <Typography variant="h5">In-person labs</Typography>
                {inPerson.map((vendor) => (
                  <Panels key={vendor.id} patientId={patient?.id} encounterId={encounter?.id} reason={draft.reason} vendor={vendor} />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

/**
 * Display an input to select the panels for the lab vendor.
 */
function Panels({ patientId, encounterId, vendor }) {
  const { draft, setDraft } = useLabOrderDraft(patientId, encounterId)

  const selected = draft.labs[vendor.id] || []
  const onUpdate = (panels) =>
    setDraft({
      ...draft,
      labs: {
        ...draft.labs,
        [vendor.id]: panels,
      },
    })

  const handleChange = (event) => {
    const value = event.target.value

    // On autofill, we get a stringified value.
    onUpdate(typeof value === 'string' ? value.split(',') : value)
  }

  const handleDelete = (value) => onUpdate(selected.filter((v) => v !== value))

  /** Transform the array of labs in an object so searching is much faster */
  const panels = useMemo(() => {
    const panels = vendor?.panels || []
    return panels.reduce((acc, panel) => {
      acc[panel.id] = panel.description
      return acc
    }, {})
  }, [vendor?.panels])

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id="labs-selector-label">{vendor.name}</InputLabel>
      <Select
        id="labs-selector"
        labelId="labs-selector-label"
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        fullWidth
        multiple={vendor.name !== LabTypes.Ash}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={panels[value]}
                deleteIcon={<CloseCircleFilledIcon onMouseDown={(event) => event.stopPropagation()} />}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {Object.keys(panels).map((id) => (
          <MenuItem key={id} value={id}>
            <Typography noWrap>{panels[id]}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
