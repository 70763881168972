import { experimental_createPersister } from '@tanstack/react-query-persist-client'
import { clear, createStore, del, get, set } from 'idb-keyval'

const IDBStorage = (idbStore) => ({
  getItem: async (key) => await get(key, idbStore),
  setItem: async (key, value) => await set(key, value, idbStore),
  removeItem: async (key) => await del(key, idbStore),
})

const isIDBAvailable = () => typeof window !== 'undefined' && 'indexedDB' in window

const getStore = () => {
  if (!isIDBAvailable()) return null
  return createStore('careplus', 'react-query-cache')
}

export const clearIDBStore = () => {
  const store = getStore()
  if (!store) return Promise.resolve()
  return clear(store)
}

export const getPersister = () => {
  const cachePersisted = JSON.parse(localStorage.getItem('cache-persisted'))
  const cachePersistedOverride = JSON.parse(localStorage.getItem('cache-persisted-override'))

  if (
    isIDBAvailable() &&
    cachePersistedOverride !== 'not-persisted' &&
    (cachePersistedOverride === 'persisted' || cachePersisted === true)
  ) {
    return experimental_createPersister({
      storage: IDBStorage(getStore()),
      maxAge: 1000 * 60 * 60 * 8, // 8 hours,
      serialize: (persistedQuery) => persistedQuery,
      deserialize: (cached) => cached,
      prefix: 'query',
    })
  }
  return undefined
}
