import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'

import { TaskKind, taskStatusToLabel } from '@shared/utils'

import { MoreOutlinedIcon } from '@icons'
import TableFilters from '@components/TableFilters'

import PatientCard from '../components/PatientCard'
import RequiredFields from '../components/RequiredFields'
import { Empty, MobileDetailsAdaptation, styles } from '../Provider.utils'
import TaskCard from './components/TaskCard'
import { GileadInfo, IncompleteLabInfo, LabFollowUpInfo, PendingQuestOrderInfo, RxClarificationInfo } from './components/TaskInfo'
import { useFiltersSchema, useTasks, useTasksFilters } from './Tasks.hooks'

export function List() {
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useTasks()
  const [{ task, kind }, updateFilters] = useTasksFilters()
  const schema = useFiltersSchema()

  // Show loader only if there is no data and we are fetching
  const showLoading = !data?.length && isFetching
  const showNotFound = !showLoading && data?.length === 0 && kind
  const showEmpty = !showLoading && data?.length === 0 && !kind
  const showData = !showEmpty && data?.length > 0

  return (
    <Stack sx={styles.list}>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'flex-start', minHeight: 50 }}>
        <TableFilters schema={schema} values={{ kind }} onChange={updateFilters} sx={{ pb: 1 }} />
      </Stack>
      <Divider />
      <Stack spacing={2} sx={[styles.list, isFetching && styles.refreshing, { pt: 2 }]}>
        {showLoading && (
          <>
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
          </>
        )}
        {showEmpty && <Empty>You don’t have any tasks to review :)</Empty>}
        {showNotFound && <Empty>No tasks found by selected type</Empty>}
        {showData && (
          <>
            {data.map((a) => (
              <TaskCard key={a.id} data={a} selected={task === a.id} onClick={() => updateFilters({ task: a.id })} />
            ))}
            {hasNextPage && (
              <Button
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
              >
                more
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export function Details() {
  const { data, isFetched } = useTasks({ enabled: false })
  const [{ task }, updateFilters] = useTasksFilters()

  const selectedTask = data?.find((t) => t.id === task)

  const showLoading = !selectedTask && task && !isFetched
  const showData = !showLoading && selectedTask

  return (
    <MobileDetailsAdaptation open={Boolean(task)} onClose={() => updateFilters({ task: undefined })}>
      {showLoading && <PatientCard.Loading />}
      {showData && <TaskContent task={selectedTask} />}
    </MobileDetailsAdaptation>
  )
}

export function TaskDialog({ task, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {task && (
        <>
          <DialogTitle onClose={onClose}>
            {taskStatusToLabel[task.status]}: {task.identifier}
          </DialogTitle>
          <TaskContent task={task} />
        </>
      )}
    </Dialog>
  )
}

function TaskContent({ task }) {
  const TaskInfo = taskInfoFabric(task)

  if (!TaskInfo) return null

  return (
    <PatientCard patientId={task?.patientId} title={task?.kind}>
      {({ patient }) => (
        <>
          <RequiredFields patient={patient} />
          <TaskInfo data={task} patient={patient} />
        </>
      )}
    </PatientCard>
  )
}

function taskInfoFabric(task) {
  if (task?.kind === TaskKind.GileadProvider) {
    return GileadInfo
  }
  if (task?.kind === TaskKind.IncompleteLabs) {
    return IncompleteLabInfo
  }
  if (task?.kind === TaskKind.RxClarification) {
    return RxClarificationInfo
  }
  if (task?.kind === TaskKind.PendingQuestOrder) {
    return PendingQuestOrderInfo
  }
  if (task?.kind === TaskKind.ABNLFollowUp) {
    return LabFollowUpInfo
  }
  return null
}
