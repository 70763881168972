const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  item: {
    cursor: 'pointer',
    border: '2px solid',
    borderColor: 'background.default',
    transition: (theme) =>
      theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
      }),
    '&:not(:last-child)': {
      ml: -1,
    },
    '&:hover': {
      zIndex: 1,
      transform: 'translateY(-8px)',
    },
  },
  selectButton: {
    cursor: 'pointer',
    fontSize: '1rem',
    width: 40,
    height: 40,
    ml: -1,
    border: '2px solid',
    borderColor: 'background.default',
    '&:hover': {
      zIndex: 1,
    },
  },
  selected: {
    outline: '2px solid',
    outlineColor: (theme) => theme.palette.primary.main,
  },
}

export default styles
