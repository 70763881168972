import { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { CloseOutlinedIcon, MoreOutlinedIcon, SearchOutlinedIcon } from '@icons'
import StatusChip from '@components/StatusChip'

import { usePrescriptions } from './SelectPrescription.hooks'

SelectPrescription.propTypes = {
  /** Patient id to fetch prescriptions */
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Selected prescription */
  value: PropTypes.object,

  /** Callback when prescription is selected */
  onChange: PropTypes.func.isRequired,

  /** Error state */
  error: PropTypes.bool,

  /** Helper text */
  helperText: PropTypes.string,
}

/**
 * Select prescription component
 */
export default function SelectPrescription({ patientId, value, onChange, error, helperText, ...rest }) {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const { data, isFetching, isFetched, fetchNextPage, hasNextPage, isFetchingNextPage } = usePrescriptions(patientId)

  const handleClick = (prescription) => {
    onChange(prescription)
    setOpen(false)
  }

  const showLoading = isFetching
  const showEmpty = isFetched && data.length === 0
  const showData = isFetched && data.length > 0

  const disabled = !patientId
  const inputValue = useMemo(() => {
    if (!value) return ''
    const date = value.createdAt ? `${dayjs(value.createdAt).format('L')} ` : ''
    return `${date}${value.drugName}${value.isRefill ? ' (Refill)' : ''}`
  }, [value])

  return (
    <>
      <TextField
        ref={ref}
        fullWidth
        autoComplete="off"
        variant="outlined"
        value={inputValue}
        onChange={() => undefined}
        onFocus={() => setOpen(true)}
        placeholder="Select Rx..."
        error={error}
        helperText={helperText}
        disabled={disabled}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <IconButton color="inherit" disabled={disabled} sx={{ p: 0 }} onClick={() => onChange(null)}>
                    <CloseOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton color="inherit" disabled={disabled} sx={{ p: 0 }}>
                    <SearchOutlinedIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        {...rest}
      />
      <Popover
        id="select-prescription-popover"
        open={open && Boolean(patientId)}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        sx={{ '& .MuiPaper-root': { width: 500, maxHeight: '30vh' } }}
      >
        {showLoading && <Loading />}
        {showEmpty && <Empty />}
        {showData && (
          <List disablePadding>
            {(data || []).map((item) => (
              <PrescriptionItem key={item.id} data={item} onClick={() => handleClick(item)} />
            ))}
            {hasNextPage && (
              <Button
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
              >
                more
              </Button>
            )}
          </List>
        )}
      </Popover>
    </>
  )
}

function Loading() {
  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: 300 }}>
      <CircularProgress />
    </Stack>
  )
}

function Empty() {
  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: 300 }}>
      <Typography variant="h5" align="center" sx={{ color: 'text.secondary' }}>
        Patient doesn't have any prescriptions
      </Typography>
    </Stack>
  )
}

function PrescriptionItem({ data, onClick }) {
  return (
    <ListItemButton divider onClick={onClick} sx={{ px: 3 }}>
      <Stack sx={{ width: '100%' }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">{data.createdAt ? dayjs(data.createdAt).format('L') : ''}</Typography>
          <StatusChip.Rx status={data.rxStatus} />
        </Stack>
        <Typography noWrap sx={{ color: 'text.secondary' }}>
          {`${data.drugName}${data.isRefill ? ' (Refill)' : ''}`}
        </Typography>
      </Stack>
    </ListItemButton>
  )
}
