import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'
import { getTestId } from '@shared/utils'

import { useOrderVaccines } from './VaccinesForm.hooks'

export default function ConfirmVaccines({ patientId, encounterId, selectedVaccines, onBack, onNext, ...rest }) {
  const testId = getTestId(rest, 'confirm-vaccines')
  const createVaccineOrder = useOrderVaccines(patientId, encounterId)
  const user = useMe()
  const handleConfirmation = () =>
    createVaccineOrder.mutateAsync({ provider_id: user.providerId, vaccine_ids: selectedVaccines.map((v) => v.id) }).then(() => {
      onNext()
    })

  return (
    <Slide in direction="left">
      <Stack spacing={2} sx={{ justifyContent: 'space-between', minHeight: '400px', height: '100%' }}>
        <Stack spacing={1}>
          <Typography variant="body1">Recommend the following vaccines?</Typography>
          <List disablePadding data-testid={`${testId}-vaccines`}>
            {selectedVaccines
              .sort((a, b) => a.name > b.name)
              .map((vaccine) => (
                <ListItemButton key={vaccine.id} selected divider sx={{ pointerEvents: 'none' }}>
                  <ListItemText>{vaccine.name}</ListItemText>
                </ListItemButton>
              ))}
          </List>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ width: '50%' }}>
            <Button fullWidth size="medium" variant="text" color="inherit" onClick={() => onBack()} data-testid={`${testId}-back`}>
              Back
            </Button>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Button
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleConfirmation()}
              data-testid={`${testId}-next`}
            >
              Create Recommendation
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Slide>
  )
}
