import { useInfiniteQuery } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { flatten, pageParam, QK } from '@shared/utils'

const LIMIT = 10

export function usePrescriptions(patientId) {
  const query = { order: 'desc', limit: LIMIT }

  return useInfiniteQuery({
    queryKey: QK.patients.id(patientId).prescriptions.list(query),
    queryFn: ({ pageParam }) => API.patients.id(patientId).prescriptions.list({ ...query, offset: pageParam * LIMIT }),
    enabled: Boolean(patientId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}
