import dayjs from 'dayjs'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { getPageHistoryId, PageHistoryType, usePagesHistoryListener } from '@hooks/usePagesHistory'
import { PillIcon } from '@icons'
import EncounterUpdate from '@components/Details/EncounterUpdate'
import Property, { ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'
import StatusChip from '@components/StatusChip'

import { usePrescriptionUpdate } from '../Prescription.hooks'
import PrescriptionStatusUpdate from './PrescriptionStatusUpdate'

export default function DetailsSection({ prescription, mini = false }) {
  usePagesHistoryListener(PageHistoryType.Patient, prescription.user)
  usePagesHistoryListener(PageHistoryType.Rx, prescription, getPageHistoryId(PageHistoryType.Patient, prescription.user?.id))

  const me = useMe()

  const updatePrescription = usePrescriptionUpdate(prescription.id)

  const isTransferred = prescription.rxStatus.toUpperCase() === 'TRANSFERRED'
  const transferDate = prescription.transferDate ? dayjs(prescription.transferDate).format('L') : undefined
  const location = [prescription.transferLocation, transferDate].filter(Boolean).join(', ')

  return (
    <>
      {!prescription.approvedDrug && (
        <Alert severity="warning" variant="border">
          <AlertTitle>This drug is not on the approved list</AlertTitle>
        </Alert>
      )}
      <Section paper mini={mini} title="Details" icon={mini ? null : <PillIcon />}>
        <Property label="Created">{prescription.createdAt ? dayjs(prescription.createdAt).format('L LT') : '—'}</Property>
        <Property label="Diagnosis">{prescription.diagnosisDescription || '—'}</Property>
        <Property label="Reason">{prescription.reasonForMedication || '—'}</Property>
        <Property label="CUI">{prescription.cui || '—'}</Property>
        <Property label="Qty">
          <Stack direction="row" spacing={1}>
            <StatusChip label={`${prescription.quantity}+${prescription.refills}`} type="warning" />
            <StatusChip label={prescription.remain || 0} type="success" />
          </Stack>
        </Property>
        <Property label="Encounter">
          <EncounterUpdate
            readOnly={me.role !== UserRole.Provider}
            patientId={prescription.user.id}
            encounter={prescription.encounter}
            encounterId={prescription.encounterId}
            onChange={(encounterId) => updatePrescription.mutateAsync({ encounter_id: encounterId })}
          />
        </Property>
        <Property label="Status">
          <PrescriptionStatusUpdate readOnly={me.role !== UserRole.Pharmacy} prescription={prescription} />
        </Property>
        {isTransferred && <Property label="Location">{location}</Property>}
        <Property label="TX">
          <Stack>
            <Typography>{prescription.txStatus || '—'}</Typography>
            {prescription.txMsg && <Typography sx={{ color: 'text.secondary' }}>{prescription.txMsg}</Typography>}
          </Stack>
        </Property>
        <ProviderProperty label="Provider" user={prescription.provider} />
      </Section>
    </>
  )
}

DetailsSection.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Details" icon={mini ? null : <PillIcon />}>
    <Property.Loading label="Created" />
    <Property.Loading label="Diagnosis" />
    <Property.Loading label="Reason" />
    <Property.Loading label="CUI" />
    <Property.Loading label="Qty" />
    <Property label="Encounter">
      <EncounterUpdate.Loading />
    </Property>
    <Property label="Status">
      <PrescriptionStatusUpdate.Loading />
    </Property>
    <Property.Loading label="TX" />
    <ProviderProperty.Loading label="Provider" />
  </Section>
)
