import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

import { handleError } from '@shared/utils'

import { PlusOutlinedIcon } from '@icons'
import InputControl from '@components/InputControl'

import InsuranceImage from './InsuranceImage'
import { formikToApiData, getInitialValues, validationSchema } from './InsuranceUpdate.utils'

InsuranceUpdate.prototypes = {
  /** The insurance to edit */
  insurance: PropTypes.object,

  /** Called after the update action */
  onUpdate: PropTypes.func.isRequired,

  /** Visible when true */
  open: PropTypes.bool.isRequired,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,

  /** If true, the photo upload will be hidden */
  hidePhoto: PropTypes.bool,
}

// Create/Edit patient's insurance
export default function InsuranceUpdate({ hidePhoto = false, insurance, onUpdate, open, onClose }) {
  return (
    <Dialog fullWidth maxWidth={hidePhoto ? 'sm' : 'lg'} open={open} onClose={onClose} scroll="body">
      <Form hidePhoto={hidePhoto} insurance={insurance} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

function Form({ hidePhoto, insurance, onUpdate, onClose }) {
  const showPhoto = !hidePhoto

  const formik = useFormik({
    initialValues: getInitialValues(insurance),
    validationSchema,
    onSubmit: (values) => {
      const data = formikToApiData(values, hidePhoto)
      return onUpdate(data)
        .then(onClose)
        .catch((error) => handleError(error, { showResponse: true }))
    },
  })

  return (
    <>
      <DialogTitle>{insurance ? 'Edit insurance' : 'Add new insurance'}</DialogTitle>
      <DialogContent dividers>
        {/* The main 2 column grid */}
        <Grid container spacing={2}>
          {/* Column 1 */}
          <Grid size={{ xs: 12, sm: hidePhoto ? 12 : 6 }}>
            <Grid container spacing={2}>
              <Grid size={12}>
                <InputControl field="subscriber" formikProps={formik}>
                  <TextField autoFocus required fullWidth label="Subscriber Name" />
                </InputControl>
              </Grid>
              <Grid size={12}>
                <InputControl field="company" formikProps={formik}>
                  <TextField required fullWidth label="Insurance Provider" />
                </InputControl>
              </Grid>

              <Grid size={12}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <InputControl field="idnum" formikProps={formik}>
                      <TextField required fullWidth label="ID Number" />
                    </InputControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <InputControl field="bin" formikProps={formik}>
                      <TextField fullWidth label="Rx BIN" />
                    </InputControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid size={12}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <InputControl field="groupnum" formikProps={formik}>
                      <TextField fullWidth label="Rx Group Number" />
                    </InputControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <InputControl field="pcn" formikProps={formik}>
                      <TextField fullWidth label="Rx PCN" />
                    </InputControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          {showPhoto && (
            <Grid size={{ xs: 12, sm: 6 }}>
              <Stack spacing={1} sx={{ alignItems: 'center', mb: 2 }}>
                <InsuranceImage
                  key={formik.values.front}
                  label="Front of Insurance Card"
                  image={formik.values.front}
                  onChange={(image) => formik.setFieldValue('front', image)}
                  onRemove={() => formik.setFieldValue('front', undefined)}
                  error={Boolean(formik.touched.front && formik.errors.front)}
                  disabled={formik.isSubmitting}
                />
              </Stack>
              <Stack spacing={1} sx={{ alignItems: 'center' }}>
                <InsuranceImage
                  key={formik.values.back}
                  label="Back of Insurance Card"
                  image={formik.values.back}
                  onChange={(image) => formik.setFieldValue('back', image)}
                  onRemove={() => formik.setFieldValue('back', undefined)}
                  error={Boolean(formik.touched.back && formik.errors.back)}
                  disabled={formik.isSubmitting}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={formik.isSubmitting}
          variant="contained"
          loadingPosition="end"
          endIcon={<PlusOutlinedIcon />}
          onClick={formik.handleSubmit}
        >
          Save Insurance
        </Button>
      </DialogActions>
    </>
  )
}
