import toast from 'react-hot-toast'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Link from '@components/_mui/Link'

import { useMe } from '@shared/providers/src/MeProvider'
import { handleError, LabStatus, LabTypes } from '@shared/utils'

import { useLabResubmit } from '../Lab.hooks'

export default function QuestQuestionnaire({ data }) {
  const me = useMe()
  const resubmit = useLabResubmit(data.id)

  if (!data || data.lab !== LabTypes.Quest || data.status !== LabStatus.Pending || !data.aoeMessage) return

  const canResubmit = me.id === data.provider?.userId
  const handleResubmit = () => {
    return resubmit
      .mutateAsync()
      .then(() => toast.success('Your Quest lab order has been resubmitted successfully'))
      .catch((e) => handleError(e, { showResponse: true }))
  }

  return (
    <Alert
      severity="warning"
      variant="border"
      action={
        canResubmit && (
          <Button loading={resubmit.isPending} onClick={handleResubmit} color="primary" variant="contained">
            Resubmit
          </Button>
        )
      }
    >
      <AlertTitle>Quest requires additional information from {data.provider?.fullName}</AlertTitle>
      {canResubmit && (
        <>
          Please click{' '}
          <Link href={data.aoeMessage} target="_blank">
            here
          </Link>{' '}
          to complete the questionnaire and then resubmit the lab order.
        </>
      )}
    </Alert>
  )
}
