import { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import useDialog from '@shared/hooks/src/useDialog'
import API from '@shared/services/src/API'
import { pageParam, QK, TaskStatus } from '@shared/utils'

import { hasDoneReasons, useTaskDoneReasons } from '@hooks/useTaskDoneReasons'
import Confirmation from '@components/Dialog/Confirmation'

import DoneConfirmationWithReasons from '../components/DoneConfirmationWithReasons'
import { useCommonFilters, useStatusUpdate } from '../Kanban.hooks'

const LIMIT = 30

const TasksContext = createContext(undefined)

export function useTasks() {
  const context = useContext(TasksContext)
  if (!context) {
    throw new Error('useTasks must be used within a TasksProvider')
  }
  return context
}

export function TasksProvider({ children }) {
  const state = useState({})

  return <TasksContext.Provider value={state}>{children}</TasksContext.Provider>
}

export function useDragHandler() {
  const moveTask = useTaskMove()
  const confirmDone = useDoneConfirmationDialog(moveTask)
  const confirmDoneWithReason = useDoneReasonConfirmationDialog(moveTask)
  const getReasons = useTaskDoneReasons()

  return useCallback(
    ({ over, active }) => {
      if (!over) return

      const oldStatus = active.data.current.status
      const newStatus = over.id

      if (newStatus === oldStatus) return

      const data = { id: active.id, oldStatus, newStatus }

      if (newStatus === TaskStatus.Completed) {
        const kind = active.data.current.kind
        if (hasDoneReasons(kind)) {
          confirmDoneWithReason({ data, reasons: getReasons(kind) })
        } else {
          confirmDone(data)
        }
      } else {
        moveTask(data)
      }
    },
    [confirmDone, confirmDoneWithReason, getReasons, moveTask]
  )
}

function useTaskMove() {
  const [, setTasks] = useTasks()
  const updateStatus = useStatusUpdate()

  return useCallback(
    ({ id, oldStatus, newStatus, reason }) => {
      // Optimistic update
      setTasks((tasks) => {
        const task = tasks[oldStatus].find((task) => task.id === id)

        return {
          ...tasks,
          [oldStatus]: tasks[oldStatus].filter((task) => task.id !== id),
          [newStatus]: [...tasks[newStatus], { ...task, status: newStatus }].sort((a, b) => a.id - b.id),
        }
      })

      updateStatus.mutate({ id, newStatus, oldStatus, reason })
    },
    [setTasks, updateStatus]
  )
}

function useDoneConfirmationDialog(onConfirm) {
  return useDialog({
    component: Confirmation,
    props: ({ item: data, close }) => ({
      title: 'Confirm Task Completion',
      description:
        'Are you sure you want to move this task to the "Done" column? \n Once moved, it cannot be moved elsewhere and no further actions will be available.',
      onReject: () => close(),
      onConfirm: () => {
        onConfirm(data)
        close()
      },
    }),
  })
}

function useDoneReasonConfirmationDialog(onConfirm) {
  return useDialog({
    component: DoneConfirmationWithReasons,
    props: ({ item, close }) => {
      const { data, reasons } = item
      return {
        reasons,
        onCancel: () => close(),
        onConfirm: (reason) => {
          onConfirm({ ...data, reason })
          close()
        },
      }
    },
  })
}

export function useColumn(status) {
  const [{ priority, users, kind, provider, search }] = useCommonFilters()
  const [tasks, setTasks] = useTasks()

  const query = useMemo(
    () => ({
      priority,
      assignee_ids: users,
      provider_id: provider,
      search_term: search,
      kind,
      sort: 'identifier',
      order: 'asc',
      board_view: true,
      limit: LIMIT,
    }),
    [kind, priority, provider, search, users]
  )

  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: QK.tasks.list({ ...query, status }),
    queryFn: ({ pageParam }) => API.tasks.list({ ...query, status, offset: pageParam * LIMIT }),
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })

  useLayoutEffect(() => {
    if (!data) return
    setTasks((prev) => ({ ...prev, [status]: data.pages.flat() }))
  }, [data, status]) // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => {
    return {
      data: tasks[status],
      isPending,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isPending, status, tasks])
}
