import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import useEncounterTypeData from '@shared/hooks/src/useEncounterTypeData'
import { hashToArray } from '@shared/utils'

import InputControl from '@components/InputControl'
import Property from '@components/Property'

const validationSchema = Yup.object().shape({
  diagnosis: Yup.string().required('Please select a diagnosis'),
})
Rx.propTypes = {
  /** Optional: A with all the patient properties, you must provide if patientId is not supplied */
  patient: PropTypes.object,

  /** The encounter this prescription will be related to */
  encounter: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startTime: PropTypes.string,
    description: PropTypes.string,
  }),

  /** True when rxPad has been loaded */
  rxPadLoaded: PropTypes.bool,

  /** Show a loading indicator while rxPad is loading */
  rxPadLoading: PropTypes.bool,

  /** Called after the load rx pad action */
  onLoadRxPad: PropTypes.func.isRequired,
}

/**
 * Displays information required to order a prescription
 */
export default function Rx({ patient, encounter, onLoadRxPad, rxPadLoading = false, rxPadLoaded = false }) {
  // Diagnosis list varies by encounterType
  const { data: encounterTypeData, isPending: isEncounterTypeDataPending } = useEncounterTypeData(encounter?.encounterTypeId)
  const diagnosisList = useMemo(() => encounterTypeData?.diagnosis, [encounterTypeData?.diagnosis])

  const formik = useFormik({
    validationSchema,
    enableOnBlur: false,
    initialValues: { diagnosis: '' },
    onSubmit: (values) => onLoadRxPad(values),
  })
  return (
    <Stack spacing={1} sx={{ height: '100%' }}>
      <Property label="Pharmacy" value={patient.pharmacy?.name} direction="row" labelWidth="6em" align="top" />
      <Property label="CBO" value={patient.cbo?.name} direction="row" labelWidth="6em" align="top" />
      <Property label="Initial Dx" value={patient.initialDiagnosis || 'n/a'} direction="row" labelWidth="6em" align="top" />
      <Grid container>
        <Grid size={8}>
          <Property
            label="Last Rx"
            value={patient.latestPrescriptionDate ? dayjs(patient.latestPrescriptionDate).format('L') : 'n/a'}
            direction="row"
            labelWidth="6em"
          />
        </Grid>
        <Grid size={4}>
          <Property label="Encounter #" value={patient.prepEncounterCount || 'n/a'} direction="row" />
        </Grid>
      </Grid>
      <Property
        label="Encounter"
        value={encounter ? `${dayjs(encounter?.startTime).format('L')} - ${encounter?.description} - ${encounter?.id}` : '-'}
        direction="row"
        labelWidth="6em"
        align="top"
      />
      <Box sx={{ flex: '1 1 auto' }} />
      <Stack spacing={2}>
        <InputControl field="diagnosis" formikProps={formik}>
          <TextField required fullWidth select disabled={rxPadLoaded}>
            {isEncounterTypeDataPending ? (
              <MenuItem value={undefined}>Loading...</MenuItem>
            ) : (
              hashToArray(diagnosisList).map((opt) => (
                <MenuItem key={opt.id} value={opt.id}>
                  {opt.value}
                </MenuItem>
              ))
            )}
          </TextField>
        </InputControl>
        <Button loading={rxPadLoading} variant="contained" disabled={rxPadLoaded} onClick={formik.handleSubmit}>
          Load Rx Pad
        </Button>
      </Stack>
    </Stack>
  )
}
