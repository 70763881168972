import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { LabTypes } from '@shared/utils'

import { SendOutlinedIcon } from '@icons'

/**
 * Displays a confirmation dialog for the labs that will be ordered
 */
export default function ConfirmDialog({ loading, selected = [], open, onClose, onConfirm }) {
  const [questNote, setQuestNote] = useState('')

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Order Labwork?</DialogTitle>
      <DialogContent dividers>
        <Typography>The following labs will be ordered</Typography>
        <List disablePadding sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {selected.map(({ vendor, panels }) => (
            <React.Fragment key={vendor.id}>
              <ListItem disableGutters>
                <ListItemText primary={vendor.description} secondary={panels.map((panel) => panel.description).join('\n')} />
              </ListItem>
              {vendor.name === LabTypes.Quest && (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={questNote}
                  onChange={(e) => setQuestNote(e.target.value)}
                  helperText="Optional note for Quest lab order"
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          variant="contained"
          loadingPosition="end"
          endIcon={<SendOutlinedIcon />}
          onClick={() => onConfirm({ questNote })}
        >
          Order Labs
        </Button>
      </DialogActions>
    </Dialog>
  )
}
