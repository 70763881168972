import { useState } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Fade from '@mui/material/Fade'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'

import { resolveLogo } from '@utils/Helper'
import config from '@config'
import NotAuthorizedImage from '@assets/errors/401.webp'
import NotFoundImage from '@assets/errors/404.webp'
import ServerErrorImage from '@assets/errors/500.webp'

import { Content, Description, Page } from './ErrorBase.components'

ErrorBase.propTypes = {
  /** The title of the page */
  title: PropTypes.string.isRequired,

  /** The subtitle of the page */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** HTTP error response code */
  code: PropTypes.oneOf([401, 404, 500]),

  /** Details of the error  */
  details: PropTypes.string,
}

const codeToImage = {
  401: NotAuthorizedImage,
  404: NotFoundImage,
  500: ServerErrorImage,
}

/**
 * Base component for handling all error pages with standardizing props and styles
 */
export default function ErrorBase({ title, subtitle, code, details, reset = () => undefined, backToHomeForbidden = false }) {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  const img = codeToImage[code]

  return (
    <Fade in>
      <Page>
        {details && <ErrorDialog details={details} open={open} onClose={() => setOpen(false)} />}
        {img ? (
          <Box sx={{ width: { xs: 250, sm: 590 }, height: { xs: 130, sm: 300 } }}>
            <Image src={img} fit="contain" duration={1000} />
          </Box>
        ) : (
          <Box sx={{ width: { xs: 250, sm: 400 }, height: { xs: 70, sm: 110 } }}>
            <Image src={resolveLogo(theme.palette.mode)} fit="contain" duration={1000} />
          </Box>
        )}
        <Content>
          <Description>
            <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center">
              {title}
            </Typography>
            <Typography color="textSecondary" align="center" sx={{ width: '73%' }}>
              {subtitle}
            </Typography>
          </Description>
          {!backToHomeForbidden && (
            <Button component={Link} to={config.defaultPath} variant="contained" onClick={reset}>
              Back To Home
            </Button>
          )}
          {details && (
            <Button color="error" variant="outlined" onClick={() => setOpen(true)}>
              View Error
            </Button>
          )}
        </Content>
      </Page>
    </Fade>
  )
}

function ErrorDialog({ open, onClose, details }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Error</DialogTitle>
      <DialogContent dividers>
        <Typography component="pre" variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {details}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
