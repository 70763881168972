import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

const styles = {
  container: {
    alignItems: 'center',
    color: 'text.secondary',
    p: 1,
    pt: 3,
    mt: 'auto',
  },
}

export default function Footer() {
  return (
    <Stack direction="row" sx={styles.container}>
      <Typography>© {new Date().getFullYear()} QCare Plus</Typography>
    </Stack>
  )
}
