import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { keepPreviousData } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import usePageParams from '@shared/hooks/src/usePageParams'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { handleError, UserRole } from '@shared/utils'

import { DeleteOutlinedIcon } from '@icons'
import Confirmation from '@components/Dialog/Confirmation'
import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import TableRowLink from '@components/TableRowLink'

import { usePatientEncounterRemove, usePatientEncounters, usePatientEncountersFilters } from '../PatientEncounters'

export default function EncountersModal({ id, open, onClose, canDelete }) {
  const navigate = useNavigate()
  const [{ encountersSort }, updateFilters] = usePatientEncountersFilters()
  const [page, perPage] = usePageParams({ id: 'encounters' })

  const { data, isRefreshing, isPending } = usePatientEncounters(
    id,
    {
      order: encountersSort,
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )
  const removeEncounter = usePatientEncounterRemove(id)

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ item: encounterId, close }) => ({
      title: 'Delete Encounter?',
      description: 'Are you sure you want to delete this encounter?',
      rejectLabel: 'Cancel',
      confirmLabel: 'Delete',
      onReject: () => close(),
      onConfirm: () =>
        removeEncounter
          .mutateAsync(encounterId)
          .then(() => {
            close()
            toast.success('Encounter has been deleted')
          })
          .catch(handleError),
    }),
  })

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="paper">
      <DialogTitle onClose={onClose}>Encounters</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Encounter</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Diagnosis</TableCell>
                  <TableCell sortDirection={encountersSort}>
                    <TableSortLabel
                      active
                      direction={encountersSort}
                      onClick={() => updateFilters({ encountersSort: encountersSort === 'desc' ? 'asc' : 'desc' })}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  {canDelete && (
                    <RoleGuard allowed={[UserRole.Admin, UserRole.Provider]}>
                      <TableCell align="right">Actions</TableCell>
                    </RoleGuard>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((encounter) => (
                  <TableRowLink key={encounter.id} hover onClick={() => navigate(`/app/patients/${id}/encounters/${encounter.id}`)}>
                    <TableCell>
                      <Typography copy>{encounter.id}</Typography>
                    </TableCell>
                    <TableCell>{encounter.description}</TableCell>
                    <TableCell>{encounter.provider?.name}</TableCell>
                    <TableCell>{encounter.diagnosis?.map((d) => d.name).join(', ')}</TableCell>
                    <TableCell>{encounter.startOrCreateInProviderTimezone}</TableCell>
                    {canDelete && (
                      <RoleGuard allowed={[UserRole.Admin, UserRole.Provider]}>
                        <TableCell align="right">
                          <IconButton
                            sx={{ m: -0.5 }}
                            disabled={!encounter.canDelete}
                            onClick={(e) => {
                              e.stopPropagation()
                              confirmRemove(encounter.id)
                            }}
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </RoleGuard>
                    )}
                  </TableRowLink>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <Pagination id="encounters" loading={isPending} last={data?.length < perPage} />
      </DialogActions>
    </Dialog>
  )
}
