import { Link as RouterLink } from 'react-router'

import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { TaskStatus } from '@shared/utils'

import PatientBase from '@components/TableCell/Patient'

export function Patient({ user }) {
  if (!user) return

  return (
    <PatientBase user={user}>
      <Typography>
        MRN:
        <Link
          component={RouterLink}
          to={`/app/patients/${user.id}`}
          target="_blank"
          rel="noopener"
          sx={{ ml: 1 }}
          onClick={(e) => e.stopPropagation()}
        >
          {user.id}
        </Link>
      </Typography>
    </PatientBase>
  )
}

export const isNotDone = (task) => ![TaskStatus.Completed, TaskStatus.Expired, TaskStatus.NotApplicable].includes(task.status)
