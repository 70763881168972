import { useMutation } from '@tanstack/react-query'

import useEncounterTypeData from '@shared/hooks/src/useEncounterTypeData'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { extendCache, handleError, QK } from '@shared/utils'

export function useOrderedVaccines(patientId, encounterId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).encounters.id(encounterId).vaccines.list(),
    queryFn: () => API.patients.id(patientId).encounters.id(encounterId).vaccines.list(),
    enabled: Boolean(patientId) && Boolean(encounterId),
    select: (data = []) => {
      return data.reduce((carry, order) => {
        order.vaccineIds.forEach((id) => {
          if (!carry.includes(id)) carry.push(id)
        })

        return carry
      }, [])
    },
    ...options,
  })
}

export function useAvailableVaccines(encounterTypeId) {
  return useEncounterTypeData(encounterTypeId, {
    select: (data) => data?.vaccines || [],
  })
}

export function useOrderVaccines(patientId, encounterId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).encounters.id(encounterId).vaccines.create(data),
    onSuccess: (newVaccine) => {
      return queryClient.setQueriesData(
        { queryKey: QK.patients.id(patientId).encounters.id(encounterId).vaccines.lists },
        extendCache((list) => [newVaccine, ...list], 5)
      )
    },
    onError: (e) => handleError(e, { showResponse: true }),
  })
}
