import { createContext, useContext } from 'react'

import usePatientQuery from '@shared/hooks/src/usePatient'

const PatientContext = createContext(undefined)

/**
 * Provide a context for detailed patient information.
 * This context can be accessed in children via usePatient hook
 *
 * @param {string} id - The patient id
 * @param {React.ReactNode} children - The children to render
 */
export default function PatientProvider({ id, children }) {
  const { data } = usePatientQuery(id, { enabled: Boolean(id) })

  return <PatientContext.Provider value={data}>{children}</PatientContext.Provider>
}

export const usePatient = () => useContext(PatientContext)

// HOC version of usePatient
export const withPatient = (Component) => (props) => (
  <PatientContext.Consumer>{(patient) => <Component {...props} patient={patient} />}</PatientContext.Consumer>
)
