import { QueryClient, QueryClientProvider as QueryClientProviderBase } from '@tanstack/react-query'

import { handleErrorSilently } from '@shared/utils'

import { clearIDBStore, getPersister } from './IndexedDBPersister'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Remove the refetch because https connection seems to be closed by the time when refetch is happening,
      // and it doesn't establish a new connection instead
      refetchOnWindowFocus: false, // disable refetch on window focus
      retry: false, // no retry if fails
      staleTime: 30 * 1000, // 30 sec after data is considered stale
      gcTime: 1000 * 60 * 5, // 5 minutes after data is removed from the cache
      persister: getPersister(),
    },
  },
})

window.queryClient = queryClient

export const cleanUp = async () => {
  try {
    if (!queryClient) return Promise.resolve()
    queryClient.clear()
    return clearIDBStore()
  } catch (error) {
    handleErrorSilently(error)
  }
}

export default function QueryClientProvider({ children }) {
  return <QueryClientProviderBase client={queryClient}>{children}</QueryClientProviderBase>
}

const queryClientMocked = new QueryClient({
  // silence react-query errors
  logger: {
    log: console.log,
    warn: console.warn,
    error: () => {},
  },
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

export function QueryClientProviderMocked({ children }) {
  return <QueryClientProviderBase client={queryClientMocked}>{children}</QueryClientProviderBase>
}
