import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { getIncompleteFields } from '@components/PhotoIdReminder'

export default function RequiredFields({ patient, children }) {
  const errors = getIncompleteFields(patient)

  if (errors.length === 0 && !children) return null

  return (
    <Stack spacing={1}>
      <Typography>Profile incomplete</Typography>
      <Stack>
        {children}
        {errors.map((error) => (
          <Typography key={error} color="error">
            {error}
          </Typography>
        ))}
      </Stack>
    </Stack>
  )
}

RequiredFields.Loading = function () {
  return (
    <Stack spacing={1}>
      <Typography>
        <Skeleton width={120} />
      </Typography>
      <Stack>
        <Typography>
          <Skeleton width={140} />
        </Typography>
        <Typography>
          <Skeleton width={110} />
        </Typography>
      </Stack>
    </Stack>
  )
}
