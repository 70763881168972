import { BooleanParam, createEnumParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePatientLabs(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).labs.list(query),
    queryFn: () => API.patients.id(patientId).labs.list(query),
    ...options,
  })
}

const TabParam = createEnumParam(['all', 'complete', 'pending', 'follow-up', 'failed'])
const OrderParam = createEnumParam(['asc', 'desc'])
const SortParam = createEnumParam(['ordered', 'lab', 'result_date'])

export function usePatientLabsFilters() {
  return useFiltering({
    labsTab: withDefault(TabParam, 'all'),
    labsExpanded: withDefault(BooleanParam, false),
    labsOrder: withDefault(OrderParam, 'desc'),
    labsSort: withDefault(SortParam, 'result_date'),
  })
}

export const patientLabsFiltersResetState = {
  labsExpanded: undefined,
  labsTab: undefined,
  labsOrder: undefined,
  labsSort: undefined,
}
