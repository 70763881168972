import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router'

import Alert from '@mui/material/Alert'
import MenuMui from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@components/_mui/IconButton'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'
import { isMATask, MedicalRecordStatus, TaskKind, TaskStatus } from '@shared/utils'

import { usePatientDirectCall } from '@providers/VoiceCallProvider'
import { ConversationDialog } from '@pages/Conversations/Conversation'
import { CreateRoiMedicalRecord, FaxRoiMedicalRecord } from '@pages/RoiMedicalRecords'
import { useROIMedicalRecord } from '@pages/RoiMedicalRecords/RoiMedicalRecords.hooks'
import { CloseOutlinedIcon, LinkOutlinedIcon, MessageIcon, MoreOutlinedIcon, PhoneOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import CopyToClipboard from '@components/CopyToClipboard'
import { AttachFileButton } from '@components/Kanban'
import { getTaskOverdueStatus } from '@components/Kanban/Kanban.utils'

export default function Header({ task, onClose }) {
  const disabled = [TaskStatus.Completed, TaskStatus.Expired].includes(task.status)
  const [overdueStatus, daysPassed] = getTaskOverdueStatus(task)

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>
        <Patient task={task} />
        <Stack spacing={2} direction="row">
          <AttachFileButton task={task} disabled={disabled} />
          <Message task={task} />
          <VoiceCall patient={task.patient} />
          <More task={task} />
          <Close onClick={onClose} />
        </Stack>
      </Stack>
      {overdueStatus && (
        <Alert severity={overdueStatus} variant="border">
          {overdueStatus === 'error'
            ? `This task is overdue by ${daysPassed} days! Please address immediately.`
            : `This task is due soon. ${daysPassed} days have passed. Please prioritize accordingly.`}
        </Alert>
      )}
    </Stack>
  )
}

function Loading() {
  return (
    <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between' }}>
      <Patient.Loading />
      <Stack spacing={2} direction="row">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Close />
      </Stack>
    </Stack>
  )
}

function Patient({ task }) {
  return (
    <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
      <Avatar user={task.patient} />
      <Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Link component={RouterLink} target="_blank" to={`/app/patients/${task.patientId}`}>
            {task.patient.fullName} ({task.patientId})
          </Link>
          <Typography>/</Typography>
          <Identifier task={task} />
        </Stack>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {task.patient.pronouns}
        </Typography>
      </Stack>
    </Stack>
  )
}

function Identifier({ task }) {
  const theme = useTheme()

  return (
    <CopyToClipboard>
      {({ copy }) => (
        <Link
          color="text.primary"
          onClick={() => copy(`${import.meta.env.VITE_URL}/redirect?to=${import.meta.env.VITE_URL}/app?task=${task.identifier}`)}
          sx={{ cursor: 'pointer' }}
        >
          {task.identifier}
          <LinkOutlinedIcon style={{ color: theme.palette.text.secondary, marginLeft: '4px' }} />
        </Link>
      )}
    </CopyToClipboard>
  )
}

Patient.Loading = function () {
  return (
    <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
      <Skeleton variant="rounded" width={40} height={40} />
      <Stack>
        <Typography>
          <Skeleton width={200} />
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          <Skeleton width={50} />
        </Typography>
      </Stack>
    </Stack>
  )
}

function Close({ onClick }) {
  return (
    <IconButton shape="rounded" size="small" color="inherit" onClick={onClick}>
      <CloseOutlinedIcon style={{ fontSize: '24px' }} />
    </IconButton>
  )
}

function Message({ task }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton shape="rounded" size="small" color="primary" variant="contained" onClick={() => setOpen(true)}>
        <MessageIcon style={{ fontSize: '18px' }} />
      </IconButton>
      <ConversationDialog user={task.patient} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

function VoiceCall({ patient }) {
  const theme = useTheme()
  const { makeCall, disabled } = usePatientDirectCall(patient)

  return (
    <IconButton disabled={disabled} shape="rounded" size="small" color="secondary" variant="outlined" onClick={makeCall}>
      <PhoneOutlinedIcon style={{ fontSize: '18px', color: theme.palette.text.primary }} />
    </IconButton>
  )
}

function More({ task }) {
  const me = useMe()

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const isROIMedicalRecord = isMATask(task) && task?.kind === TaskKind.RoiPDF && task.assigneeId === me.id
  const isDone = [TaskStatus.Completed, TaskStatus.Expired].includes(task.status)

  if (isDone || !isROIMedicalRecord) return

  return (
    <>
      <IconButton ref={anchorRef} shape="rounded" size="small" color="inherit" onClick={() => setOpen((o) => !o)}>
        <MoreOutlinedIcon style={{ fontSize: '18px' }} />
      </IconButton>
      <MenuMui
        keepMounted
        open={open}
        onClose={() => setOpen((o) => !o)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ROIMedicalRecordMenuItem task={task} onClose={() => setOpen(false)} />
      </MenuMui>
    </>
  )
}

function ROIMedicalRecordMenuItem({ task, onClose }) {
  const [createRoiOpen, setCreateRoiOpen] = useState(false)
  const [resendRoiOpen, setResendRoiOpen] = useState(false)

  const hasMedicalRecord = Boolean(task?.relatedModelId)

  const { data, isPending } = useROIMedicalRecord(task.patientId, task.relatedModelId, {
    enabled: hasMedicalRecord,
  })

  if (hasMedicalRecord) {
    const isFailed = data?.status === MedicalRecordStatus.FaxFailed
    return (
      <Tooltip title={isFailed ? '' : 'Resend is only available when fax failed'}>
        <div>
          <MenuItem
            disabled={isPending || !isFailed}
            onClick={() => {
              onClose()
              setResendRoiOpen(true)
            }}
          >
            Resend ROI Medical Record
          </MenuItem>
          <FaxRoiMedicalRecord patientId={task.patientId} data={data} open={resendRoiOpen} onClose={() => setResendRoiOpen(false)} />
        </div>
      </Tooltip>
    )
  }

  return (
    <>
      <MenuItem
        onClick={() => {
          onClose()
          setCreateRoiOpen(true)
        }}
      >
        Create ROI Medical Record
      </MenuItem>
      <CreateRoiMedicalRecord patientId={task.patientId} task={task} open={createRoiOpen} onClose={() => setCreateRoiOpen(false)} />
    </>
  )
}

Header.Loading = Loading
