import dayjs from 'dayjs'

import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { taskStatusToLabel, toTitleCase } from '@shared/utils'

import { PriorityIcon } from '@icons'
import Avatar from '@components/Avatar'
import Property, { PatientProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'

export default function DetailsSection({ task, mini = false }) {
  return (
    <Section paper mini={mini} title="Details">
      <Property label="Status">{taskStatusToLabel[task.status]}</Property>
      <Property label="Priority">
        <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center' }}>
          <PriorityIcon value={task.priority} />
          <Typography>{toTitleCase(task.priority)}</Typography>
        </Stack>
      </Property>
      <Property label="Created">{task.createdAt ? dayjs(task.createdAt).format('L LT') : '—'}</Property>
      <Property label="Assignee">
        {task.assignee ? (
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar size="xs" user={task.assignee} hover="card" />
            <Typography>{task.assignee.fullName}</Typography>
          </Stack>
        ) : (
          <Typography>Unassigned</Typography>
        )}
      </Property>
      <PatientProperty label="Patient" user={task.patient} />
    </Section>
  )
}

DetailsSection.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Details">
    <Property.Loading label="Status" />
    <Property.Loading label="Priority" />
    <Property.Loading label="Created" />
    <Property.Loading label="Assignee" />
  </Section>
)
