import dayjs from 'dayjs'

import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { TaskKind, toTitleCase } from '@shared/utils'

import { PhotoIdMissingIcon } from '@icons'
import DateTooltip from '@components/DateTooltip'
import EntityFlag, { useEntityFlagState } from '@components/EntityFlag'
import Menu from '@components/Menu'

import CardBase from '../../../components/CardBase'
import { Patient } from '../../../Provider.utils'

/**
 * Task card component
 *
 * @param data - task data
 * @param selected - whether the card is selected
 * @param onClick - callback when card is clicked
 */
export default function TaskCard({ data, selected, onClick }) {
  const date = useIntervalResult(() => dayjs(data.createdAt).fromNow())
  const [flagged] = useEntityFlagState('tasks', data.id)

  return (
    <CardBase color={KindToColor[data.kind]} selected={selected} flagged={flagged} onClick={onClick}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant="h5">{data.kind}</Typography>
            <EntityFlag entity="tasks" id={data.id} />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            {!data.patient.hasPhotoIdentification && <PhotoIdMissingIcon sx={{ color: 'text.danger' }} />}
            {data.relatedModel?.status && <Chip size="small" label={toTitleCase(data.relatedModel.status)} />}
            <MoreMenu id={data.id} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'end', justifyContent: 'space-between' }}>
          <Patient data={data.patient} />
          <DateTooltip date={data.createdAt}>
            <Typography align="right" sx={{ lineHeight: '1.2' }}>
              {date}
            </Typography>
          </DateTooltip>
        </Stack>
      </Stack>
    </CardBase>
  )
}

TaskCard.Loading = function () {
  return (
    <CardBase.Loading>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">
            <Skeleton width={140} />
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'end', justifyContent: 'space-between' }}>
          <Patient.Loading />
          <Typography>
            <Skeleton width={80} />
          </Typography>
        </Stack>
      </Stack>
    </CardBase.Loading>
  )
}

function MoreMenu({ id }) {
  const [flagged, updateFlags] = useEntityFlagState('tasks', id)

  return (
    <Menu>
      {({ close }) => (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            close()
            updateFlags((flags) => ({ ...flags, [id]: !flagged }))
          }}
        >
          {flagged ? 'Remove' : 'Add'} flag
        </MenuItem>
      )}
    </Menu>
  )
}

const KindToColor = {
  [TaskKind.GileadProvider]: 'error.main',
  [TaskKind.PAR]: 'warning.main',
  [TaskKind.PEP_PAR]: 'warning.main',
  [TaskKind.HIV_PAR]: 'warning.main',
  [TaskKind.IncompleteLabs]: 'brand.blue',
  [TaskKind.ABNLFollowUp]: 'brand.blue',
  [TaskKind.RxClarification]: 'brand.green',
  [TaskKind.PendingQuestOrder]: 'brand.yellow',
}
