import PropTypes from 'prop-types'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@components/_mui/IconButton'

import { getDropzoneData } from '@shared/utils'

import { CloseCircleFilledIcon, FileFilledIcon } from '@icons'

const styles = {
  previewItem: {
    p: 0,
    m: 0.5,
    width: 80,
    height: 80,
    borderRadius: 1.25,
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'text-top',
    border: (theme) => `solid 1px ${theme.palette.divider}`,
  },
  item: {
    my: 1,
    px: 2,
    py: 0.75,
    borderRadius: 0.75,
    border: (theme) => `solid 1px ${theme.palette.divider}`,
  },
  removeButton: {
    top: -10,
    right: -10,
    position: 'absolute',
  },
}

FilesPreview.propTypes = {
  /** Can show preview of images */
  showPreview: PropTypes.bool,

  /** Array of files */
  files: PropTypes.array,

  /** Function to remove file */
  onRemove: PropTypes.func.isRequired,
}

/**
 * Show preview of uploaded files.
 */
export default function FilesPreview({ files = [], onRemove }) {
  const hasFile = files.length > 0

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      {files.map((file, index) => {
        const { key, name } = getDropzoneData(file, index)

        return (
          <ListItem key={key} sx={styles.item}>
            <ListItemIcon>
              <FileFilledIcon style={{ fontSize: '1rem', marginRight: 8 }} />
            </ListItemIcon>
            <ListItemText
              primary={typeof file === 'string' ? file : name}
              slotProps={{
                primary: { variant: 'subtitle2' },
              }}
            />
            {onRemove && (
              <IconButton edge="end" color="primary" onClick={() => onRemove(file)}>
                <CloseCircleFilledIcon style={{ fontSize: '24px' }} />
              </IconButton>
            )}
          </ListItem>
        )
      })}
    </List>
  )
}
