import toast from 'react-hot-toast'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, handleError, includesOneOfErrorMessages, pageParam, QK } from '@shared/utils'

const LIMIT = 20

export function useDocuments(patientId) {
  const query = { order: 'desc', content: true, limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.patients.id(patientId).documents.list(query),
    queryFn: ({ pageParam }) => API.patients.id(patientId).documents.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

export function useDocumentUpload(patientId, taskId) {
  return useMutation({
    mutationFn: (file) => API.patients.id(patientId).documents.create({ task_id: taskId, file }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QK.tasks.id(taskId).documents.lists })
      queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).documents.lists })
    },
  })
}

export function useDocumentAttach(taskId) {
  return useMutation({
    mutationFn: (data) => API.tasks.id(taskId).documents.attach(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.tasks.id(taskId).documents.lists }),
    onError: (e) => {
      if (includesOneOfErrorMessages(e, ['unique per task'])) {
        toast.error('This document is already attached to this task.')
      } else {
        handleError(e)
      }
    },
  })
}
