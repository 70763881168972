import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export default function useEncounterTypeData(encounterTypeId, options = {}) {
  return useQuery({
    queryKey: QK.encounterTypes.id(encounterTypeId).details,
    queryFn: () => API.platform.encounterTypes.id(encounterTypeId).details(),
    enabled: Boolean(encounterTypeId),
    ...options,
  })
}
