import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export default function Property({ label, sx = {}, children }) {
  return (
    <Stack
      sx={[
        {
          gap: { xs: 0, sm: 1 },
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' },
          flexWrap: 'nowrap',
        },
        sx,
      ]}
    >
      {['string', 'number'].includes(typeof label) ? <Typography sx={{ minWidth: 100 }}>{label}</Typography> : label}
      {children}
    </Stack>
  )
}
