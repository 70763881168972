const DarkTheme = {
  palette: {
    mode: 'dark',
    divider: 'rgba(255, 255, 255, 0.15)',
    text: {
      primary: 'rgba(255, 255, 255, 0.9)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.1)',
      mark: '#f0f0f0',
      warning: '#d89614',
      danger: '#cc3b47',
      success: '#49aa19',
      contrast: '#000000',
      inputLabel: '#cacaca',
    },
    action: {
      disabled: '#8c8c8c',
    },
    background: {
      paper: '#1e1e1e',
      default: '#121212',
      overlay: 'rgba(255, 255, 255, 0.06)',
      backdrop: 'rgba(0, 0, 0, 0.7)',
    },
    primary: {
      100: '#1a4c75',
      200: '#3179a0',
      400: '#53b3e4',
      700: '#3a8cd8',
      900: '#185ab1',
      lighter: '#123649',
      light: '#285f9e',
      main: '#53b3e4',
      dark: '#2e669d',
      darker: '#174d7a',
      contrastText: '#fff',
    },
    secondary: {
      100: '#1e1e1e',
      200: '#595959',
      400: '#bfbfbf',
      600: '#f0f0f0',
      800: '#fafafa',
      lighter: '#1e1e1e',
      light: '#8c8c8c',
      main: '#d9d9d9',
      dark: '#f5f5f5',
      darker: '#ffffff',
      A100: '#000000',
      A200: '#434343',
      A300: '#1f1f1f',
      contrastText: '#000000',
    },
    error: {
      lighter: '#3a0c0d',
      light: '#7c1d20',
      main: '#cc3b47',
      dark: '#f68a9c',
      darker: '#f9b6b9',
      contrastText: '#fff',
    },
    warning: {
      lighter: '#2b2111',
      light: '#7c5914',
      main: '#d89614',
      dark: '#f3cc62',
      darker: '#faedb5',
      contrastText: '#1e1e1e',
    },
    info: {
      lighter: '#112123',
      light: '#146262',
      main: '#13a8a8',
      dark: '#58d1c9',
      darker: '#b2f1e8',
      contrastText: '#fff',
    },
    success: {
      lighter: '#162312',
      light: '#306317',
      main: '#49aa19',
      dark: '#8fd460',
      darker: '#d5f2bb',
      contrastText: '#fff',
    },
    grey: {
      0: '#000000',
      50: '#141414',
      100: '#1e1e1e',
      200: '#595959',
      300: '#8c8c8c',
      400: '#bfbfbf',
      500: '#d9d9d9',
      600: '#f0f0f0',
      700: '#f5f5f5',
      800: '#fafafa',
      900: '#ffffff',
    },
    brand: {
      blue: '#53b3e4',
      green: '#8ac541',
      purple: '#a576a7',
      yellow: '#fdd106',
      gray: '#707272',
    },
    tag: {
      secondary: {
        light: '#2f2f2f',
        dark: '#adb3c0',
      },
      success: {
        light: '#215242',
        dark: '#19d7a0',
      },
      warning: {
        light: '#4d3c2b',
        dark: '#ffa327',
      },
      error: {
        light: '#5a1c1c',
        dark: '#ff7878',
      },
      errorFilled: {
        light: '#eb5757',
        dark: '#3b0c0c',
      },
      info: {
        light: '#1b2e4c',
        dark: '#5f88e3',
      },
      infoFilled: {
        light: '#3a4b5e',
        dark: '#cbd6e2',
      },
    },
  },
}

export default DarkTheme
