import { useFormik } from 'formik'
import * as Yup from 'yup'

import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'

import Avatar from '@components/Avatar'
import SelectUser from '@components/SelectUser'

import { useAssigneeUpdate } from '../Kanban.hooks'

export default function SelectAssignee({ task, disabled = false }) {
  const me = useMe()
  const updateAssignee = useAssigneeUpdate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { assignee: task.assignee || null },
    validationSchema: Yup.object({ assignee: Yup.object().nullable() }),
    onSubmit: (values) => {
      if (task.assignee?.id === values.assignee?.id) return
      return updateAssignee.mutate({ taskId: task.id, userId: values.assignee?.id || null })
    },
  })

  if (disabled) {
    return (
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar size="xs" user={task.assignee} hover="card" />
        <Typography>{task.assignee?.fullName}</Typography>
      </Stack>
    )
  }

  return (
    <SelectUser
      key={task.assignee?.id}
      fullWidth
      value={formik.values.assignee}
      onChange={(user) => formik.setFieldValue('assignee', user)}
      error={formik.touched.assignee && Boolean(formik.errors.assignee)}
      helperText={formik.touched.assignee && formik.errors.assignee}
      onBlur={formik.handleSubmit}
      role={me.role}
      hover="card"
      sx={{ ml: -1 }}
    />
  )
}
