import { useNavigate } from 'react-router'

import Chip from '@mui/material/Chip'

import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { ExclamationCircleOutlined } from '@icons'

export default function OnDemandPending() {
  const me = useMe()
  const navigate = useNavigate()

  if (me.role === UserRole.Provider && me.hasPendingPatientsOnDemandAppointments) {
    return (
      <Chip
        color="warning"
        variant="outlined"
        icon={<ExclamationCircleOutlined />}
        label="On-Demand pending"
        clickable
        onClick={() => navigate('/app/appointments?tab=on-demand')}
        sx={{ ml: 1 }}
      />
    )
  }
}
