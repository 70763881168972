import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, QK } from '@shared/utils'

export function useROIMedicalRecordFax(patientId, medicalRecordId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).roiMedicalRecords.id(medicalRecordId).fax(data),
    onSuccess: (data) => queryClient.setQueryData(QK.patients.id(patientId).roiMedicalRecords.id(medicalRecordId).details, data),
    onError: handleError,
  })
}
