import Box from '@mui/material/Box'

import { SOPsDialog, SOPsDrawer, useSOPsFilters } from '@pages/StandardOperatingProcess'
import { PolicyOutlineIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function SOPs() {
  const [{ sops }, updateFilters] = useSOPsFilters()

  return (
    <>
      <Box>
        <IconButton id="sop-button" onClick={() => updateFilters({ sops: 'list' })} aria-label="SOPs toggler" data-testid="header-sops">
          <PolicyOutlineIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Box>
      <SOPsDrawer open={Boolean(sops)} onClose={() => updateFilters({ sops: undefined })} />
      <SOPsDialog open={['view', 'edit', 'create'].includes(sops)} onClose={() => updateFilters({ sops: 'list', sopId: undefined })} />
    </>
  )
}
