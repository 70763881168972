const styles = {
  content: ({ disabled, isFlagged, isDragging, transform = {} } = {}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    touchAction: 'none',
    userSelect: 'none',
    padding: 2,
    borderRadius: 1,
    backgroundColor: (theme) => (isFlagged ? theme.palette.warning.lighter : theme.palette.background.paper),
    boxShadow: (theme) => (isDragging ? theme.shadows[4] : theme.shadows[1]),
    transform: `translate(${transform?.x ?? 0}px, ${transform?.y ?? 0}px)`,
    cursor: disabled ? 'pointer' : 'grab',
    '&:active': {
      zIndex: 10,
      cursor: disabled ? 'pointer' : 'grabbing',
    },
  }),
  errorCard: {
    outline: '1px solid',
    outlineColor: 'error.main',
  },
  warningCard: {
    outline: '1px solid',
    outlineColor: 'warning.main',
  },
}

export default styles
