import React from 'react'

import TableRow from '@mui/material/TableRow'

/**
 * Wrapper around MUI TableRow with redirect link on click
 *
 * @why
 * https://stackoverflow.com/questions/17147821/how-to-make-a-whole-row-in-a-table-clickable-as-a-link/17147876#17147876
 */
export default function TableRowLink({ href, disabled = false, hover, sx, ...props }) {
  if (disabled) return <TableRow sx={sx} {...props} onClick={undefined} />

  return <TableRow hover={hover} onClick={() => (window.location = href)} sx={[{ cursor: 'pointer' }, sx]} {...props} />
}
