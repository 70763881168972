import SvgIcon from '@mui/material/SvgIcon'

export default function AttachmentIcon(props) {
  return (
    <SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
      <g clipPath="url(#o8w4r7dpxa)">
        <path
          d="M9.833 3.208v6.709a2.333 2.333 0 1 1-4.667 0V2.625a1.459 1.459 0 0 1 2.917 0V8.75c0 .32-.262.583-.583.583a.585.585 0 0 1-.583-.583V3.208h-.875V8.75a1.459 1.459 0 0 0 2.916 0V2.625a2.333 2.333 0 1 0-4.667 0v7.292A3.207 3.207 0 0 0 7.5 13.125a3.207 3.207 0 0 0 3.208-3.208V3.208h-.875z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="o8w4r7dpxa">
          <path fill="currentColor" transform="rotate(-90 7.25 6.75)" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
