import Typography from '@components/_mui/Typography'

import { LabTypes } from '@shared/utils'

import { MessageOutlinedIcon } from '@icons'
import Section from '@components/Details/Section'

export default function QuestNoteSection({ data, mini = false }) {
  if (!data || data.lab !== LabTypes.Quest || !data.questNote) return

  return (
    <Section mini={mini} title="Quest Order Note" icon={mini ? null : <MessageOutlinedIcon />}>
      <Typography sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{data.questNote}</Typography>
    </Section>
  )
}
