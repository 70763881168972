import { useNavigate, useParams } from 'react-router'
import range from 'lodash/range'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@components/_mui/Typography'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { UserRole } from '@shared/utils'

import { EncountersModal } from '@pages/Encounters'
import { usePatientEncounters, usePatientEncountersFilters } from '@pages/Encounters/PatientEncounters'
import { ExpandAltOutlinedIcon } from '@icons'
import PatientAccessGuard from '@components/PatientAccessGuard'
import TableRowLink from '@components/TableRowLink'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'
import { CreateEncounterButton } from './Encounters.utils'

export default function Encounters() {
  const { id } = useParams()
  const navigate = useNavigate()

  const patient = usePatient()
  const { data, isRefreshing, isPending } = usePatientEncounters(id, { order: 'desc', limit: 5 })
  const [{ encountersExpanded }, updateFilters] = usePatientEncountersFilters()

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Tile
      title="Encounters"
      refreshing={isRefreshing}
      action={
        <Stack direction="row" spacing={1}>
          <IconCircleButton disabled={(data?.length ?? 0) === 0} size="small" onClick={() => updateFilters({ encountersExpanded: true })}>
            <ExpandAltOutlinedIcon />
          </IconCircleButton>
          <RoleGuard prohibited={[UserRole.Pharmacy, UserRole.MA, UserRole.Manager]}>
            <PatientAccessGuard patientId={id}>
              <CreateEncounterButton patient={patient} />
            </PatientAccessGuard>
          </RoleGuard>
        </Stack>
      }
      sx={{ height: '100%' }}
    >
      <EncountersModal id={id} open={encountersExpanded} canDelete onClose={() => updateFilters({ encountersExpanded: false })} />
      <TableContainer>
        <Table size="small">
          <TableBody data-testid="encounters">
            {showLoading && <Loading />}
            {showEmpty && <Empty />}
            {showData &&
              data.map((encounter) => (
                <TableRowLink hover key={encounter.id} onClick={() => navigate(`/app/patients/${id}/encounters/${encounter.id}`)}>
                  <TableCell>{encounter.id}</TableCell>
                  <TableCell>{encounter.description}</TableCell>
                  <TableCell align="right">{encounter.startOrCreateInProviderTimezone}</TableCell>
                </TableRowLink>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Tile>
  )
}

function Loading() {
  return range(0, 5).map((i) => (
    <TableRow hover key={i}>
      <TableCell width={80}>
        <Skeleton width={70} />
      </TableCell>
      <TableCell>
        <Skeleton width={150} />
      </TableCell>
      <TableCell width={100}>
        <Skeleton width={80} />
      </TableCell>
    </TableRow>
  ))
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={3} align="center">
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No data to display
        </Typography>
      </TableCell>
    </TableRow>
  )
}
