import { useParams } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useSendInsurancePartnerInvite() {
  const { id } = useParams()
  return useMutation({
    mutationFn: () => API.patients.id(id).insurancePartners.invite(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).insurances.summary }),
  })
}

export function useSetInsurancePartnerContacted() {
  const { id } = useParams()
  return useMutation({
    mutationFn: () => API.patients.id(id).insurancePartners.update({ date_type: 'contacted' }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).insurances.summary }),
  })
}

export function useSetInsurancePartnerInsured() {
  const { id } = useParams()
  return useMutation({
    mutationFn: () => API.patients.id(id).insurancePartners.update({ date_type: 'insured' }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(id).insurances.summary }),
  })
}
