import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, QK } from '@shared/utils'

export function useROISubmit(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).roiMedicalRecords.create(data),
    onSuccess: (data, { task_id }) => {
      queryClient.setQueryData(QK.tasks.id(task_id).details, (oldData) => ({ ...oldData, relatedModelId: data.id }))
      return queryClient.setQueryData(QK.patients.id(patientId).roiMedicalRecords.id(data.id).details, data)
    },
    onError: handleError,
  })
}

export function useROIPreview(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).roiMedicalRecords.preview(data),
    onMutate: () => toast("Your file is being prepared. You will be notified when it's ready for review"),
    onSuccess: (data) => PubSub.publish(PubSubEvents.FileReady, data),
    onError: handleError,
  })
}
