import { useState } from 'react'
import { Link as RouterLink } from 'react-router'
import dayjs from 'dayjs'

import CardActionArea from '@mui/material/CardActionArea'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import AssessmentDetailsModal from '@pages/Assessments/AssessmentDetailsModal'
import { LabDetailsModal } from '@pages/Labs/Lab'
import { PrescriptionDetailsModal } from '@pages/Prescriptions/Prescription'
import Avatar from '@components/Avatar'

import { Property } from '../PatientCard/PatientCard.utils'

const styles = {
  clickable: (theme) => ({
    py: 0.5,
    px: 1,
    my: `${theme.spacing(-0.5)} !important`,
    mr: `${theme.spacing(-1)} !important`,
    ml: '0 !important',
  }),
}

export function CBOProperty({ data }) {
  if (!data) return <Property label="CBO">—</Property>

  const subtitle = [data.city, data.state].filter(Boolean).join(', ')

  return (
    <Property label="CBO">
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar.CBO card data={data} />
        <Stack spacing={-0.5} sx={{ display: 'grid' }}>
          <Typography noWrap>{data.name}</Typography>
          {subtitle && <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>}
        </Stack>
      </Stack>
    </Property>
  )
}

CBOProperty.Loading = function () {
  return (
    <Property label={<Skeleton width={70} />}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </Stack>
    </Property>
  )
}

export function LabProperty({ data }) {
  const [open, setOpen] = useState(false)

  if (!data) return <Property label="Last Lab">—</Property>

  return (
    <Property label="Last Lab">
      <CardActionArea onClick={() => setOpen(true)} sx={styles.clickable}>
        <Stack spacing={-0.5} sx={{ display: 'grid' }}>
          <Typography noWrap>{[data.lab, data.panelsToString].filter(Boolean).join(' - ')}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{dayjs(data.createdAt).format('L')}</Typography>
        </Stack>
      </CardActionArea>
      <LabDetailsModal labId={data.id} open={open} onClose={() => setOpen(false)} />
    </Property>
  )
}

LabProperty.Loading = function () {
  return (
    <Property label={<Skeleton width={70} />}>
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={160} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Property>
  )
}

export function PrescriptionProperty({ data }) {
  const [open, setOpen] = useState(false)

  if (!data) return <Property label="Last Rx">—</Property>

  return (
    <Property label="Last Rx">
      <CardActionArea onClick={() => setOpen(true)} sx={styles.clickable}>
        <Stack spacing={-0.5} sx={{ display: 'grid' }}>
          <Typography noWrap>{`${data.drugName}${data.isRefill ? ' (Refill)' : ''}`}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{dayjs(data.createdAt).format('L')}</Typography>
        </Stack>
      </CardActionArea>
      <PrescriptionDetailsModal prescriptionId={data.id} open={open} onClose={() => setOpen(false)} />
    </Property>
  )
}

PrescriptionProperty.Loading = function () {
  return (
    <Property label={<Skeleton width={70} />}>
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={160} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Property>
  )
}

export function EncounterProperty({ data }) {
  if (!data) return <Property label="Encounter">—</Property>

  return (
    <Property label="Encounter">
      <CardActionArea component={RouterLink} to={`/app/patients/6503/encounters/${data.id}`} sx={styles.clickable}>
        <Stack spacing={-0.5} sx={{ display: 'grid' }}>
          <Typography noWrap>{`${data.description}, ID: ${data.id}`}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{dayjs(data.startTime).format('L LT')}</Typography>
        </Stack>
      </CardActionArea>
    </Property>
  )
}

EncounterProperty.Loading = function () {
  return (
    <Property label={<Skeleton width={70} />}>
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={160} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Property>
  )
}

export function AssessmentProperty({ data }) {
  const [open, setOpen] = useState(false)

  if (!data) return <Property label="Last QHA">—</Property>

  return (
    <Property label="Last QHA">
      <CardActionArea onClick={() => setOpen(true)} sx={styles.clickable}>
        <Stack spacing={-0.5} sx={{ display: 'grid' }}>
          <Typography noWrap>{data.name}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{dayjs(data.createdAt).format('L')}</Typography>
        </Stack>
      </CardActionArea>
      <AssessmentDetailsModal assessment={data} open={open} onClose={() => setOpen(false)} />
    </Property>
  )
}

AssessmentProperty.Loading = function () {
  return (
    <Property label={<Skeleton width={70} />}>
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={160} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Property>
  )
}
