import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

import useDraft from '@shared/hooks/src/useDraft'

import LinearProgress from '@components/LinearProgress'

AddNote.propTypes = {
  /** When true, the dialog will be displayed */
  open: PropTypes.bool.isRequired,

  /** Called after the user confirms adding a note */
  onConfirm: PropTypes.func.isRequired,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,

  /** Can display a loading indicator */
  loading: PropTypes.bool,
}

/**
 * Displays a dialog that allows the user to add a note to a patient
 */
export default function AddNote({ patientId, open, onConfirm, onClose, loading = false }) {
  const { draft, setDraft, removeDraft } = useDraft(`patient-note-${patientId}`)

  const handleSubmit = () => {
    if (!draft) return
    onConfirm(draft)
    removeDraft()
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="body" data-testid="page-add-note">
      <DialogTitle>Add a note</DialogTitle>
      <LinearProgress loading={loading} color="warning" />
      <DialogContent dividers>
        <TextField
          name="message"
          fullWidth
          multiline
          minRows={8}
          maxRows={16}
          placeholder="Write something..."
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!draft || loading} onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
