import React from 'react'

import IconButtonMui from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

export const IconButton = React.forwardRef((props, ref) => {
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <IconButtonMui ref={ref} color="secondary" size={matchDownSm ? 'small' : 'medium'} sx={{ color: 'text.primary', ml: 0.5 }} {...props} />
  )
})
