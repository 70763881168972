import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, flatten, handleError, mapCache, pageParam, QK } from '@shared/utils'

export function usePrescription(prescriptionId, options = {}) {
  return useQuery({
    queryKey: QK.prescriptions.id(prescriptionId).details,
    queryFn: () => API.prescriptions.id(prescriptionId).details(),
    ...options,
  })
}

export function usePrescriptionDelete() {
  return useMutation({
    mutationFn: (prescriptionId) => API.prescriptions.id(prescriptionId).remove(),
    onSuccess: (data, prescriptionId) => {
      queryClient.setQueryData(QK.prescriptions.id(prescriptionId).details, null)
      queryClient.setQueriesData(
        { queryKey: QK.prescriptions.lists },
        filterCache((old) => old.id !== prescriptionId)
      )
    },
    onError: handleError,
  })
}

export function usePrescriptionUpdate(prescriptionId) {
  return useMutation({
    mutationFn: (data) => API.prescriptions.id(prescriptionId).update(data),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(QK.prescriptions.id(prescriptionId).details, data)
      queryClient.setQueriesData(
        { queryKey: QK.prescriptions.lists },
        mapCache((old) => (old.id === data.id ? { ...old, ...data } : old))
      )

      if (variables.status && variables.note) {
        queryClient.invalidateQueries({ queryKey: QK.prescriptions.id(prescriptionId).notes.lists })
      }
    },
    onError: handleError,
  })
}

const NOTES_LIMIT = 5

export function usePrescriptionNotes(prescriptionId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.prescriptions.id(prescriptionId).notes.list(query),
    queryFn: ({ pageParam }) => API.prescriptions.id(prescriptionId).notes.list({ ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(prescriptionId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function usePrescriptionNoteCreation(prescriptionId, onCreate) {
  return useMutation({
    mutationFn: (message) => API.prescriptions.id(prescriptionId).notes.create({ message }),
    onSuccess: () => {
      onCreate?.()
      return queryClient.invalidateQueries({ queryKey: QK.prescriptions.id(prescriptionId).notes.lists })
    },
    onError: handleError,
  })
}

export const invalidateEncounterNotes = (prescription) => {
  if (!prescription) return

  const patientId = prescription?.user?.id
  const encounterId = prescription?.encounter?.id

  if (!patientId || !encounterId) return

  queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).encounters.id(encounterId).notes.lists })
}
