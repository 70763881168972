import { useEffect, useMemo } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useMutation } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { BuildEnv, handleErrorSilently } from '@shared/utils'

/**
 * Handles the user being idle for a period of time and checks their session.
 *
 * @note Inactivity time can be overridden by the user in the local storage
 * with awayAfter key with the value in seconds.
 */
export default function InactivityHandler({ interval }) {
  // using mutation like a lazy query for convenience, no mutation is actually performed
  const heartbeatMutation = useMutation({
    mutationFn: () => API.heartbeat.check(null, { skipHandling: true }),
  })

  // Use local storage to override the inactivity time for QA and testing.
  const inactivityTimeout = useMemo(() => {
    let intervalValue = interval

    if (import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production) {
      const awayAfter = localStorage.getItem('awayAfter')
      const override = parseFloat(awayAfter)
      if (override) intervalValue = override
    }

    // convert to milliseconds
    return intervalValue * 1000
  }, [interval])

  const { start } = useIdleTimer({
    startManually: true,
    stopOnIdle: true,
    timeout: inactivityTimeout,
    onIdle: () =>
      heartbeatMutation
        .mutateAsync()
        .then(() => start())
        .catch(handleErrorSilently),
  })

  useEffect(() => {
    start()
  }, [start])

  return null
}
