import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePrescription(patientId, prescriptionId) {
  return useQuery({
    queryKey: QK.patients.id(patientId).prescriptions.id(prescriptionId).details,
    queryFn: () => API.patients.id(patientId).prescriptions.id(prescriptionId).details({ skipHandling: true }),
    enabled: Boolean(patientId) && Boolean(prescriptionId),
  })
}
