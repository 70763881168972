import { useLocalStorage } from 'usehooks-ts'

import Fade from '@mui/material/Fade'
import { useTheme } from '@mui/material/styles'

import { FlagOutlinedIcon } from '@icons'

export function useEntityFlagState(entity, id) {
  const [flags, updateFlags] = useLocalStorage(`${entity}-flags`, {})
  return [flags?.[id] || false, updateFlags]
}

export default function EntityFlag({ entity, id }) {
  const theme = useTheme()
  const [flagged] = useEntityFlagState(entity, id)

  return (
    <Fade in={flagged}>
      <FlagOutlinedIcon style={{ color: theme.palette.error.main }} />
    </Fade>
  )
}
