import React from 'react'
import PropTypes from 'prop-types'

import Collapse from '@mui/material/Collapse'
import TableCell from '@mui/material/TableCell'

TableCollapsableCell.propTypes = {
  /** Whether the cell is shown */
  open: PropTypes.bool,

  /** Content */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export const expandedCellStyle = (open) => ({
  position: 'relative',
  padding: 0,
  ...(!open && { borderBottom: 0 }),
  '&:after': {
    position: 'absolute',
    content: '" "',
    top: 0,
    left: 0,
    backgroundColor: 'primary.main',
    width: 3,
    height: 'calc(100%)',
  },
})

/**
 * Utility for hiding and showing children component
 *
 * @example:
 * <TableRow>
 *   <CollapsableCell open={open} colSpan={6}>
 *     <SomeComponent data={data} />
 *   </CollapsableCell>
 * </TableRow>
 */
export default function TableCollapsableCell({ open = false, children, sx, ...rest }) {
  const styles = [expandedCellStyle(open), sx]

  return (
    <TableCell sx={styles} {...rest}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </TableCell>
  )
}
