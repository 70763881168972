import { isMATask, isProviderTask, isSupportTask, UserRole } from '@shared/utils'

import { hasDoneReasons } from '@hooks/useTaskDoneReasons'

export const isTaskClickable = (me, task) => {
  if (me.role === UserRole.MA && isMATask(task)) return true
  if (me.role === UserRole.Support && isSupportTask(task)) return true
  if (me.role === UserRole.Provider && isProviderTask(task) && task.assignee?.id === me.id) return true
  return false
}

export const getTaskType = (types, task) => {
  let type = types[task.kind] || task.kind
  if (hasDoneReasons(task.kind) && isMATask(task)) {
    type = [type, task.reason].filter(Boolean).join(' – ')
  }
  return type
}
