import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, QK } from '@shared/utils'

export function useTaskCreation() {
  return useMutation({
    mutationFn: (data) => API.tasks.create(data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: QK.patients.id(variables.task.patient_id).tasks.lists })
      queryClient.invalidateQueries({
        queryKey: QK.tasks.lists,
        predicate: ({ queryKey }) => {
          const { status } = queryKey[queryKey.length - 1]
          return Array.isArray(status) ? status.includes(variables.task.status) : status === variables.task.status
        },
      })
    },
    onError: handleError,
  })
}
