import SvgIcon from '@mui/material/SvgIcon'

export default function VerifiedIcon(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M6.727 9.166 5.18 7.613l-.987.993 2.534 2.54L11.62 6.24l-.987-.993-3.906 3.92z" fill="currentColor" />
      <path
        d="m15.333 7.993-1.627-1.86.227-2.46-2.406-.546L10.267 1 8 1.973 5.733 1l-1.26 2.127-2.406.54.226 2.466-1.626 1.86 1.626 1.86-.227 2.467 2.407.547L5.733 15 8 14.02l2.267.973 1.26-2.126 2.406-.547-.226-2.46 1.626-1.867zM12.7 8.98l-.373.433.053.567.12 1.3-1.827.413-.293.494-.66 1.12-1.187-.514L8 12.567l-.527.226-1.186.514-.66-1.114-.294-.493-1.827-.413.12-1.307.054-.567-.373-.433-.86-.98.86-.987.373-.433-.06-.573-.12-1.294L5.327 4.3l.293-.493.66-1.12 1.186.513.534.227.527-.227 1.186-.513.66 1.12.293.493 1.827.413-.12 1.3-.053.567.373.433.86.98-.853.987z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
