import { useCallback } from 'react'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { TaskKind } from '@shared/utils'

export function useTaskDoneReasons() {
  const priorAuthDoneReasons = useLookup(Lookup.PriorAuthDoneReasons)
  const gileadDoneReasons = useLookup(Lookup.GileadTaskDoneReasons)
  const appealDoneReasons = useLookup(Lookup.AppealTaskDoneReasons)

  return useCallback(
    (kind) => {
      if (!hasDoneReasons(kind)) return
      if (kind === TaskKind.PriorAuth) return priorAuthDoneReasons
      if (kind === TaskKind.GileadCustomerSupport) return gileadDoneReasons
      if (kind === TaskKind.Appeals) return appealDoneReasons
    },
    [appealDoneReasons, gileadDoneReasons, priorAuthDoneReasons]
  )
}

export const hasDoneReasons = (kind) => [TaskKind.PriorAuth, TaskKind.GileadCustomerSupport, TaskKind.Appeals].includes(kind)
