import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, flatten, handleError, mapCache, pageParam, QK, UserRole } from '@shared/utils'

export function useLab(labId, options = {}) {
  return useQuery({
    queryKey: QK.labs.id(labId).details,
    queryFn: () => API.labs.id(labId).details(),
    ...options,
  })
}

export function useLabDelete() {
  return useMutation({
    mutationFn: (labId) => API.labs.id(labId).remove(),
    onSuccess: (data, labId) => {
      queryClient.setQueryData(QK.labs.id(labId).details, null)
      queryClient.setQueriesData(
        { queryKey: QK.labs.lists },
        filterCache((lab) => lab.id !== labId)
      )
    },
  })
}

export function useLabUpdate(labId) {
  const me = useMe()

  return useMutation({
    mutationFn: (data) => API.labs.id(labId).update(data),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(QK.labs.id(labId).details, data)
      queryClient.setQueriesData(
        { queryKey: QK.labs.lists },
        mapCache((old) => (old.id === data.id ? { ...old, ...data } : old))
      )

      if (variables.status && variables.note) {
        queryClient.invalidateQueries({ queryKey: QK.labs.id(labId).notes.lists })
        invalidateEncounterNotes(data)
      }

      if (variables.status && me.role === UserRole.Provider) {
        queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).dashboard.labs.lists })
      }
    },
    onError: handleError,
  })
}

export function useLabResubmit(labId) {
  return useMutation({ mutationFn: () => API.labs.id(labId).resubmit() })
}

const NOTES_LIMIT = 5

export function useLabNotes(labId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.labs.id(labId).notes.list(query),
    queryFn: ({ pageParam }) => API.labs.id(labId).notes.list({ ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(labId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useLabNoteCreation(labId, onCreate) {
  return useMutation({
    mutationFn: (message) => API.labs.id(labId).notes.create({ message }),
    onSuccess: () => {
      onCreate?.()
      return queryClient.invalidateQueries({ queryKey: QK.labs.id(labId).notes.lists })
    },
    onError: handleError,
  })
}

export function usePatientLabFile(patientId, fileId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).labs.file(fileId),
    queryFn: () => API.patients.id(patientId).labs.files.id(fileId).details(),
    ...options,
  })
}

export function usePatientLabFileRename(patientId, labId, fileId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).labs.files.id(fileId).rename(data),
    onSuccess: (data, { filename }) => {
      queryClient.setQueryData(QK.patients.id(patientId).labs.file(fileId), (oldData) => ({ ...oldData, filename }))
      queryClient.setQueryData(QK.labs.id(labId).details, (lab) => ({
        ...lab,
        resultFiles: lab.resultFiles?.map((file) => (file.id === fileId ? { ...file, filename } : file)),
      }))
    },
  })
}

export function useLabTaskCreation() {
  return useMutation({
    mutationFn: (data) => API.tasks.create(data),
    onSuccess: (task, variables) => {
      queryClient.invalidateQueries({ queryKey: QK.tasks.lists })
      queryClient.setQueryData(QK.labs.id(variables.lab_id).details, (lab) => ({
        ...lab,
        labFollowUpTasks: [task, ...(lab.labFollowUpTasks || [])],
      }))
    },
    onError: handleError,
  })
}

export const invalidateEncounterNotes = (lab) => {
  if (!lab) return

  const patientId = lab?.user?.id
  const encounterId = lab?.encounter?.id

  if (!patientId || !encounterId) return

  queryClient.invalidateQueries({
    queryKey: QK.patients.id(patientId).encounters.id(encounterId).notes.lists,
  })
}
