import { TaskPriority } from '@shared/utils'

export default function Priority({ value }) {
  switch (value) {
    case TaskPriority.Low:
      return <Low />
    case TaskPriority.Medium:
      return <Medium />
    case TaskPriority.High:
      return <High />
    default:
      return null
  }
}

function Low() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m2.58 5.235 5.131 5.95c.147.17.43.17.578 0l5.132-5.95c.19-.222.018-.547-.29-.547H2.87c-.308 0-.48.325-.29.547z"
        fill="#BFBFBF"
      />
    </svg>
  )
}

function Medium() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 11.715v-1.428h12v1.428H2zM2 4.43v1.142c0 .079.064.143.143.143h11.714A.143.143 0 0 0 14 5.572V4.43a.143.143 0 0 0-.143-.143H2.143A.143.143 0 0 0 2 4.43z"
        fill="#F7A600"
      />
    </svg>
  )
}

function High() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m7.711 4.816-5.131 5.95c-.19.222-.019.547.289.547h10.263c.307 0 .48-.325.289-.547l-5.132-5.95a.391.391 0 0 0-.578 0z"
        fill="#F74A00"
      />
    </svg>
  )
}
