import { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

export default function DoneConfirmationWithReasons({ reasons, open, onCancel, onConfirm }) {
  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="xs">
      <Content reasons={reasons} onCancel={onCancel} onConfirm={onConfirm} />
    </Dialog>
  )
}

function Content({ reasons = [], onCancel, onConfirm }) {
  const [reason, setReason] = useState('')

  return (
    <>
      <DialogTitle>Confirm Task Completion</DialogTitle>
      <DialogContent dividers>
        <TextField select label="Please select a reason" value={reason} onChange={(e) => setReason(e.target.value)} sx={{ minWidth: 200 }}>
          {reasons.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="contained" disabled={reason.trim() === ''} onClick={() => onConfirm(reason)}>
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}
