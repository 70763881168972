/* eslint-disable react/forbid-foreign-prop-types */
import React, { useMemo } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

import { camelToTitleCase, mergeSlotProps } from '@shared/utils'

import CopyIconButton from '@components/CopyIconButton'

InputControl.propTypes = {
  /** An input/control component to render */
  children: PropTypes.node.isRequired,

  /** The property name used to provide data to the component */
  field: PropTypes.string.isRequired,

  /** Inputs can be highlighted when the value is missing */
  missing: PropTypes.bool,

  /**
   * A function to determine if the value is missing for the custom comparisons
   *
   * @example if value is boolean missing would be:
   * isMissingFn: (value) => [undefined, null].includes(value)
   * */
  isMissingFn: PropTypes.func,

  /** Can disable automatically appending (optional) on non-required fields */
  disableOptionalLabel: PropTypes.bool,

  /** Hide the generated label */
  hideLabel: PropTypes.bool,

  /** Show the children label */
  showChildrenLabel: PropTypes.bool,

  /** The formik props that the control will use */
  formikProps: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,

  /** Stack props */
  stackProps: PropTypes.object,
}

/**
 * A wrapper/helper to pass the typical formik props to a component.
 *
 * This will inject a number of properties into the child component that are used
 * by formik to provide the standard behavior. The "field" value will be used for
 * the id, name, label, data-testid.
 *
 * @example
 * const { values, handleChange, handleBlur, touched, errors } = useFormik({
 *    const formikProps = { values, touched, errors, handleChange, handleBlur }
 *
 *    return (
 *      <InputControl field="doctorName" formikProps={formikProps}>
 *        <TextField required />
 *      </InputControl>
 *  )
 * })
 */
function InputControl({
  children,
  clipboard = false,
  missing = false,
  isMissingFn,
  disableOptionalLabel = false,
  hideLabel = false,
  showChildrenLabel = false,
  field,
  formikProps,
  stackProps = {},
}) {
  const theme = useTheme()

  // Pull any known props from the child for re-use
  const childRequired = children.props.required || false
  const childLabel = children.props.label
  const childOnChange = children.props.onChange
  const childOnBlur = children.props.onBlur
  const childHelperText = children.props.helperText
  const disabled = children.props.disabled

  const error = get(formikProps.touched, field) && Boolean(get(formikProps.errors, field))
  const value = get(formikProps.values, field)

  // Calculate the label 'type'
  const label = useMemo(() => {
    const showOptional = !childRequired && !disableOptionalLabel

    // If label not provided, use the field name
    if (!childLabel) {
      return `${camelToTitleCase(field)}${showOptional ? ' (optional)' : ''}`
    }

    // If label is provided, and it is a string type, use it and extend with optional if not required
    if (typeof childLabel === 'string') {
      return `${childLabel}${showOptional ? ' (optional)' : ''}`
    }

    // If label is provided, and it is not a string type, assume it is a component and return it
    return childLabel
  }, [childLabel, childRequired, disableOptionalLabel, field])

  const isMissing = useMemo(() => {
    if (!missing) return false
    if (isMissingFn) return isMissingFn(value)
    return !value
  }, [isMissingFn, missing, value])

  const slotProps = useMemo(
    () =>
      mergeSlotProps(
        {
          formHelperText: { 'data-testid': `helper-${field}` },
          htmlInput: { required: false, 'data-testid': `input-${field}` },
        },
        children.props.slotProps
      ),
    [children.props.slotProps, field]
  )

  const additionalProps = {
    required: childRequired,
    variant: 'outlined',
    id: field,
    name: field,
    value,
    label: showChildrenLabel ? label : undefined,
    onChange: childOnChange ?? formikProps.handleChange,
    onBlur: childOnBlur ?? formikProps.handleBlur,
    error,
    helperText: (get(formikProps.touched, field) && get(formikProps.errors, field)) || childHelperText,
    slotProps,
  }

  return (
    <Stack spacing={1} {...stackProps} sx={{ backgroundColor: isMissing ? 'warning.lighter' : 'initial' }}>
      {label && !hideLabel && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <InputLabel
            htmlFor={field}
            error={error}
            disabled={disabled}
            sx={{ color: isMissing ? 'warning.darker' : theme.palette.text.inputLabel }}
          >
            {label}
          </InputLabel>
          {clipboard && <CopyIconButton fn={(copy) => copy(value)} />}
        </Stack>
      )}
      {React.cloneElement(children, additionalProps)}
    </Stack>
  )
}

export default React.memo(InputControl)
