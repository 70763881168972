import SvgIcon from '@mui/material/SvgIcon'

export default function GuardedIcon(props) {
  return (
    <SvgIcon width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <g clipPath="url(#gmcydfs9pa)" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.128 6.87V4.057L5.753 2.15 1.378 4.058v2.864c0 2.648 1.867 5.127 4.375 5.728.32-.076.63-.187.933-.32a3.484 3.484 0 0 0 2.859 1.487c1.93 0 3.5-1.57 3.5-3.5a3.499 3.499 0 0 0-2.917-3.448zm-4.083 3.447c0 .326.046.647.134.945-.14.064-.28.128-.426.175-1.85-.583-3.208-2.474-3.208-4.515v-2.1l3.208-1.4 3.208 1.4v2.047a3.499 3.499 0 0 0-2.916 3.448zm3.5 2.333a2.333 2.333 0 1 1-.002-4.665 2.333 2.333 0 0 1 .002 4.665z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.545 10.608c-.426 0-1.278.21-1.307.63.292.415.77.683 1.307.683.536 0 1.015-.268 1.306-.682-.029-.42-.88-.63-1.306-.63z"
        />
        <path d="M9.545 10.095a.653.653 0 1 0 0-1.306.653.653 0 0 0 0 1.306z" />
      </g>
    </SvgIcon>
  )
}
