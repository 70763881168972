import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@components/_mui/DialogTitle'

import usePageParams from '@shared/hooks/src/usePageParams'

import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'

import { RxSortOrder, usePatientPrescriptions } from '../PatientPrescriptions/PatientPrescriptions.hooks'
import PrescriptionsTable from '../PrescriptionsTable'

export default function PrescriptionsModal({ id, encounterId, open, onClose }) {
  const [order, setOrder] = useState(RxSortOrder.DESC)

  const [page, perPage] = usePageParams({ id: 'rxhx' })
  const { data, isRefreshing, isPending } = usePatientPrescriptions(
    id,
    {
      encounter_id: encounterId,
      order,
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="paper">
      <DialogTitle onClose={onClose}>ERx</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <PrescriptionsTable order={order} onOrderChange={setOrder} prescriptions={data} />
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <Pagination id="rxhx" loading={isPending} last={data?.length < perPage} />
      </DialogActions>
    </Dialog>
  )
}
