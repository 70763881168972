import { useState } from 'react'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'

import { Lookup } from '@shared/providers/src/DropdownOptionsProvider'

import { rubyLink } from '@config'

import { FollowUpDialog, isNotDone, ResolveDialog } from './TaskInfo.utils'

export default function PatientAtRiskInfo({ task, patient }) {
  const [followUpOpen, setFollowUpOpen] = useState(false)
  const [resolveOpen, setResolveOpen] = useState(false)

  return (
    <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', pt: 3 }}>
      <Button
        variant="outlined"
        color="secondary"
        component={Link}
        target="_blank"
        rel="noopener"
        href={rubyLink(`patients/${task.patientId}/quarterly_automations`)}
      >
        Q Flows
      </Button>
      {isNotDone(task) && (
        <>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" color="primary" onClick={() => setFollowUpOpen(true)}>
              Follow Up
            </Button>
            <Button variant="contained" onClick={() => setResolveOpen(true)}>
              Resolve
            </Button>
          </Stack>

          <FollowUpDialog patient={patient} open={followUpOpen} onClose={() => setFollowUpOpen(false)} defaultMessage={defaultMessage} />
          <ResolveDialog
            taskId={task.id}
            patient={patient}
            reasonsKey={Lookup.PatientAtRiskDoneReasons}
            freeTextReason="No new flow sent - Other"
            open={resolveOpen}
            onClose={() => setResolveOpen(false)}
          />
        </>
      )}
    </Stack>
  )
}

const defaultMessage = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "It looks like you did not complete the quarterly prompts to renew your PrEP. No appointments are needed to resume. But I will send you a text and email in the next 24 hours. The text will have instructions on how to order another set of labs. You'll then get a follow up link on completing a health assessment so you can update me on any new health information that may have changed since the last time we met. Let me know when you've completed those steps and I'll renew your prescription.",
        },
      ],
    },
  ],
}
