import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, handleError, pageParam, QK } from '@shared/utils'

export function usePatientTask(patientId, taskId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).tasks.id(taskId).details,
    queryFn: () => API.patients.id(patientId).tasks.id(taskId).details(),
    ...options,
  })
}

const NOTES_LIMIT = 5

export function usePatientTaskNotes(patientId, taskId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.patients.id(patientId).tasks.id(taskId).notes.list(query),
    queryFn: ({ pageParam }) =>
      API.patients
        .id(patientId)
        .tasks.id(taskId)
        .notes.list({ ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(patientId) && Boolean(taskId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function usePatientTaskNoteCreation(patientId, taskId) {
  return useMutation({
    mutationFn: (message) => API.patients.id(patientId).tasks.id(taskId).notes.create({ message }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).tasks.id(taskId).notes.lists }),
    onError: handleError,
  })
}
