import { keepPreviousData, useMutation } from '@tanstack/react-query'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, mapCache, QK, TaskStatus } from '@shared/utils'

import { CommonFilters } from './Kanban.utils'

export const useCommonFilters = () => useFiltering(CommonFilters)

export function useTasks(query, options = {}) {
  return useQuery({
    queryKey: QK.tasks.list(query),
    queryFn: () => API.tasks.list(query),
    ...options,
  })
}

export function useTask(taskId) {
  return useQuery({
    queryKey: QK.tasks.id(taskId).details,
    queryFn: () => API.tasks.id(taskId).details(),
    enabled: Boolean(taskId),
    placeholderData: keepPreviousData,
  })
}

export function useStatusUpdate() {
  return useMutation({
    mutationFn: ({ id, newStatus, reason }) => {
      if (newStatus === TaskStatus.ToDo) {
        return API.tasks.id(id).mark.todo()
      } else if (newStatus === TaskStatus.InProgress) {
        return API.tasks.id(id).mark.inProgress()
      } else if (newStatus === TaskStatus.Waiting) {
        return API.tasks.id(id).mark.waiting()
      } else if (newStatus === TaskStatus.Completed) {
        return API.tasks.id(id).mark.completed({ reason })
      } else if (newStatus === TaskStatus.Expired) {
        return API.tasks.id(id).mark.expired({ reason })
      }
    },
    onMutate: ({ id, newStatus: status }) => {
      const taskKey = QK.tasks.id(id).details

      const state = queryClient.getQueryState(taskKey)
      if (Boolean(state)) {
        queryClient.setQueryData(taskKey, (oldData) => ({ ...oldData, status }))
      }

      queryClient.setQueriesData(
        { queryKey: QK.tasks.list({ board_view: false }) },
        mapCache((task) => (task.id === id ? { ...task, status } : task))
      )
    },
    onSuccess: (data, { newStatus, oldStatus }) => {
      queryClient.setQueriesData(
        { queryKey: QK.patients.id(data.patientId).tasks.lists },
        mapCache((task) => (task.id === data.id ? { ...task, ...data } : task))
      )

      queryClient.setQueryData(QK.tasks.id(data.id).details, () => data)

      // Optimize the cache invalidation
      queryClient.invalidateQueries({
        queryKey: QK.tasks.lists,
        predicate: ({ queryKey }) => {
          const { status, board_view } = queryKey[queryKey.length - 1]

          // Invalidate two columns on the board
          if (board_view && (status === newStatus || status === oldStatus)) return true

          // Invalidate the list view if the status changed to complete or expired
          if (!board_view && [TaskStatus.Completed, TaskStatus.Expired].includes(newStatus)) return true

          // Return false to keep the rest of the queries
          return false
        },
      })
    },
    onError: (error, variables) => handleErrorAndResetTheCache(error, variables.id),
  })
}

export function useAssigneeUpdate() {
  return useMutation({
    mutationFn: ({ taskId, userId }) => API.tasks.id(taskId).updateAssignee({ task: { assignee_id: userId } }),
    onSuccess: updateTheCache,
    onError: (error, variables) => handleErrorAndResetTheCache(error, variables.taskId),
  })
}

export function usePriorityUpdate() {
  return useMutation({
    mutationFn: ({ taskId, priority }) => API.tasks.id(taskId).updatePriority({ task: { priority } }),
    onSuccess: updateTheCache,
    onError: (error, variables) => handleErrorAndResetTheCache(error, variables.taskId),
  })
}

function updateTheCache(data) {
  const mapTasks = mapCache((t) => (t.id === data.id ? { ...t, ...data } : t))

  queryClient.setQueryData(QK.tasks.id(data.id).details, () => data)
  queryClient.setQueriesData({ queryKey: QK.patients.id(data.patientId).tasks.lists }, mapTasks)
  queryClient.setQueriesData({ queryKey: QK.tasks.lists }, mapTasks)
}

function handleErrorAndResetTheCache(error, taskId) {
  // Reset the board
  handleError(error)
  queryClient.invalidateQueries({ queryKey: QK.tasks.id(taskId).details })
  queryClient.invalidateQueries({ queryKey: QK.tasks.lists })
}
