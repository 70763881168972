import { useState } from 'react'
import { useLocation } from 'react-router'

import CardActionArea from '@mui/material/CardActionArea'
import Snackbar from '@mui/material/Snackbar'

import usePubNub from '@shared/hooks/src/usePubNub'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { QK, UserRole } from '@shared/utils'

import useNotificationSettings, { NotificationSetting } from '@hooks/useNotificationSettings'
import useSound from '@hooks/useSound'
import { useNotificationOnClick } from '@pages/Notifications/Notifications.hooks'
import { NotificationContent } from '@pages/Notifications/NotificationsPopover'

import { styles, Title } from '../Notifications.utils'
import { useUser } from './UserNotifications.hooks'
import { mapNotification, notificationToLabel } from './UserNotifications.utils'

export default function UserNotifications() {
  const me = useMe()
  const [settings] = useNotificationSettings()

  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState(undefined)

  const currentPath = useLocation().pathname
  const isVisitPage = currentPath.startsWith('/app/visits')
  const enabled =
    (me.role === UserRole.Provider && settings[NotificationSetting.GeneralDuringVisit]) ||
    ([UserRole.MA, UserRole.Support].includes(me.role) && settings[NotificationSetting.GeneralOnTheKanban]) ||
    !isVisitPage

  const { data: author } = useUser(notification?.authorId)

  const playSound = useSound({
    howlProps: { src: '/sounds/notification2.mp3' },
  })

  usePubNub(`user_${me.id}`, ({ action, attributes }) => {
    if (action !== 'new_notification') return

    queryClient.invalidateQueries({ queryKey: QK.users.id(me.id).notifications.lists })

    if (enabled) {
      setNotification(mapNotification(attributes))
      setOpen(true)
      playSound()
    }
  })

  const notificationWithAuthor = { ...(notification || {}), author }

  const handleClose = () => setOpen(false)
  const onClick = useNotificationOnClick(notificationWithAuthor)

  return (
    <Snackbar
      open={open && notification && (!notification.authorId || (author && author.id === notification.authorId))}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CardActionArea
        onClick={() => {
          onClick()
          handleClose()
        }}
        sx={styles.container}
      >
        <Title label={notificationToLabel(notification)} onClose={handleClose} />
        <NotificationContent notification={notificationWithAuthor} />
      </CardActionArea>
    </Snackbar>
  )
}
