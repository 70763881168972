import ReactDOM from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import { RouterProvider } from 'react-router/dom'

import { LicenseInfo } from '@mui/x-date-pickers-pro'

import HoneybadgerProvider from '@shared/providers/src/HoneybadgerProvider'

import '@shared/utils/src/dayjsImports'
import 'simplebar-react/dist/simplebar.min.css'
import './assets/third-party/apex-chart.css'
import './assets/third-party/react-table.css'

import router from './pages/Router'

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE)

ReactDOM.createRoot(document.getElementById('root')).render(
  <HoneybadgerProvider>
    <RouterProvider router={router} />
  </HoneybadgerProvider>
)
