import { useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import useEffectState from '@shared/hooks/src/useEffectState'
import { TaskStatus, taskStatusToLabel } from '@shared/utils'

import { hasDoneReasons, useTaskDoneReasons } from '@hooks/useTaskDoneReasons'
import { outlineSearchStyling } from '@utils/StylesHelper'

import { useStatusUpdate } from '../Kanban.hooks'
import CompleteConfirmation from './CompleteConfirmation'
import DoneConfirmationWithReasons from './DoneConfirmationWithReasons'
import ExpireConfirmation from './ExpireConfirmation'

export default function SelectStatus({ task, disabled = false, canExpire = false, canComplete = false }) {
  const [status, setStatus] = useEffectState(task.status)
  const [expireConfirmationOpen, setExpireConfirmationOpen] = useState(false)
  const [doneConfirmationOpen, setDoneConfirmationOpen] = useState(false)
  const [doneReasonConfirmationOpen, setDoneReasonConfirmationOpen] = useState(false)

  const getReasons = useTaskDoneReasons()
  const updateStatus = useStatusUpdate()

  const resetStatus = () => setStatus(task.status)
  const handleSubmit = (data = {}) => {
    return updateStatus.mutate({ id: task.id, newStatus: status, oldStatus: task.status, ...data })
  }

  const handleBlur = () => {
    if (status === task.status) return
    if (status === TaskStatus.Completed) {
      return hasDoneReasons(task.kind) ? setDoneReasonConfirmationOpen(true) : setDoneConfirmationOpen(true)
    }
    if (status === TaskStatus.Expired) {
      return setExpireConfirmationOpen(true)
    }
    handleSubmit()
  }

  if (disabled) {
    return <Typography>{taskStatusToLabel[task.status]}</Typography>
  }

  return (
    <>
      <TextField
        key={task.status}
        select
        fullWidth
        id="status"
        name="status"
        size="small"
        variant="outlined"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        onBlur={handleBlur}
        sx={{ ml: -1, ...outlineSearchStyling }}
        slotProps={{
          select: { IconComponent: () => null },
        }}
      >
        <MenuItem value={TaskStatus.ToDo}>
          <Typography>To Do</Typography>
        </MenuItem>
        <MenuItem value={TaskStatus.InProgress}>
          <Typography>In Progress</Typography>
        </MenuItem>
        <MenuItem value={TaskStatus.Waiting}>
          <Typography>Waiting</Typography>
        </MenuItem>
        {canComplete && (
          <MenuItem value={TaskStatus.Completed}>
            <Typography>Done</Typography>
          </MenuItem>
        )}
        {canExpire && (
          <MenuItem value={TaskStatus.Expired}>
            <Typography>Expired</Typography>
          </MenuItem>
        )}
      </TextField>
      {canComplete && (
        <CompleteConfirmation
          open={doneConfirmationOpen}
          onConfirm={() => {
            handleSubmit()
            setDoneConfirmationOpen(false)
          }}
          onCancel={() => {
            resetStatus()
            setDoneConfirmationOpen(false)
          }}
        />
      )}
      {canComplete && (
        <DoneConfirmationWithReasons
          open={doneReasonConfirmationOpen}
          reasons={getReasons(task.kind)}
          onConfirm={(reason) => {
            handleSubmit({ reason })
            setDoneReasonConfirmationOpen(false)
          }}
          onCancel={() => setDoneReasonConfirmationOpen(false)}
        />
      )}
      {canExpire && (
        <ExpireConfirmation
          open={expireConfirmationOpen}
          onConfirm={(reason) => {
            handleSubmit({ reason })
            setExpireConfirmationOpen(false)
          }}
          onCancel={() => {
            resetStatus()
            setExpireConfirmationOpen(false)
          }}
        />
      )}
    </>
  )
}
