import { useLocalStorage } from 'usehooks-ts'

import Box from '@mui/material/Box'

import { QPhrasesDrawer } from '@pages/QPhrases'
import { SnippetsOutlinedIcon } from '@icons'

import { IconButton } from '../Header.utils'

export default function QPhrases() {
  const [open, setOpen] = useLocalStorage('q-phrases-drawer', false)

  return (
    <>
      <Box>
        <IconButton id="q-phrases-button" onClick={() => setOpen((o) => !o)} aria-label="QPhrases toggler" data-testid="header-q-phrases">
          <SnippetsOutlinedIcon style={{ fontSize: 18 }} />
        </IconButton>
      </Box>
      <QPhrasesDrawer open={open} onClose={() => setOpen(false)} />
    </>
  )
}
