import { useDraggable } from '@dnd-kit/core'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { TaskStatus, UserRole } from '@shared/utils'

import { PriorityIcon } from '@icons'
import Avatar from '@components/Avatar'
import EntityFlag, { useEntityFlagState } from '@components/EntityFlag'
import { useCommonFilters } from '@components/Kanban/Kanban.hooks'
import { getTaskOverdueStatus } from '@components/Kanban/Kanban.utils'
import Menu from '@components/Menu'

import styles from './Task.styles'

Task.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
}

export default function Task({ item }) {
  const me = useMe()
  const [, updateFilters] = useCommonFilters()
  const taskTypeToLabel = useLookup(me.role === UserRole.MA ? Lookup.MATasks : Lookup.CSTasks, (data) => {
    return data.reduce((acc, { kind, label }) => ({ ...acc, [kind]: label }), {})
  })
  const disabled = [TaskStatus.Completed, TaskStatus.Expired].includes(item.status)
  const [overdueStatus, daysPassed] = getTaskOverdueStatus(item)
  const [isFlagged] = useEntityFlagState('tasks', item.id)

  const { isDragging, attributes, listeners, transform, setNodeRef } = useDraggable({
    id: item.id,
    disabled,
    data: {
      assigneeId: item.assignee?.id,
      kind: item.kind,
      status: item.status,
    },
  })

  return (
    <Paper
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onClick={() => updateFilters({ task: item.identifier })}
      sx={[styles.content({ disabled, isFlagged, isDragging, transform }), styles[`${overdueStatus}Card`]]}
    >
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography noWrap variant="h4">
            {taskTypeToLabel[item.kind]}
          </Typography>
          <EntityFlag entity="tasks" id={item.id} />
        </Stack>
        <MoreMenu id={item.id} />
      </Stack>
      <Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
              MRN
            </Typography>
            <Typography>{item.patient.id}</Typography>
          </Stack>
          <Typography variant="body2">{dayjs(item.createdAt).format('L')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography noWrap variant="body2">
            {item.patient.fullName}
          </Typography>
          <Typography variant="body2">{item.patient.state}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <PriorityIcon value={item.priority} />
        </Box>
        {item.patient.provider && (
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center', minWidth: 0 }}>
            <Typography noWrap variant="subtitle2">
              {item.patient.provider.fullName}
            </Typography>
            <Avatar size="xs" user={item.patient.provider} hover="card" />
          </Stack>
        )}
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {item.assignee && <Avatar size="xs" user={item.assignee} hover="card" />}
        <Typography variant="subtitle2" sx={{ flex: '1 1 auto' }}>
          {item.identifier}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: overdueStatus ? `${overdueStatus}.main` : 'text.primary',
            fontWeight: overdueStatus ? 'bold' : 'normal',
          }}
        >
          Day: {daysPassed}
        </Typography>
      </Stack>
    </Paper>
  )
}

function Loading() {
  return (
    <Paper sx={styles.content(false)}>
      <Typography noWrap variant="h4">
        <Skeleton width={200} />
      </Typography>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>
          <Skeleton width={100} />
        </Typography>
        <Typography variant="subtitle2">
          <Skeleton width={20} />
        </Typography>
      </Stack>
      <Typography variant="subtitle2">
        <Skeleton width={70} />
      </Typography>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box />
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">
            <Skeleton width={100} />
          </Typography>
          <Skeleton variant="rounded" width={24} height={24} />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton variant="rounded" width={24} height={24} />
        <Typography variant="subtitle2">
          <Skeleton width={80} />
        </Typography>
      </Stack>
    </Paper>
  )
}

function MoreMenu({ id }) {
  const [flagged, updateFlags] = useEntityFlagState('tasks', id)

  return (
    <Menu>
      {({ close }) => (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            close()
            updateFlags((flags) => ({ ...flags, [id]: !flagged }))
          }}
        >
          {flagged ? 'Remove' : 'Add'} flag
        </MenuItem>
      )}
    </Menu>
  )
}

Task.Loading = Loading
