import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import usePageParams from '@shared/hooks/src/usePageParams'
import { toTitleCase } from '@shared/utils'

import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import TableSortCell from '@components/TableSortCell'

import { useAddNote, useNotes, useNotesFilters } from './Notes.hooks'

NotesModal.propTypes = {
  /** Id of the patient whose notes will be shown */
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** When true, modal will be displayed */
  open: PropTypes.bool.isRequired,

  /** When true the modal will display an option to create a note */
  canAddNotes: PropTypes.bool,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,
}

/**
 * Displays a modal that shows all the notes for a specific patient
 */
export function NotesModal({ patientId, open, canAddNotes = false, onClose }) {
  const [{ notesOrder, notesSort }, updateFilters] = useNotesFilters()
  const [page, perPage] = usePageParams({ id: 'notes' })

  const addNote = useAddNote(patientId)

  const { data, isRefreshing, isPending } = useNotes(
    patientId,
    {
      order: notesOrder,
      sort: notesSort,
      limit: perPage,
      offset: (page - 1) * perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="paper" data-testid="page-notes">
      <DialogTitle onClose={onClose}>Notes</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers sx={{ height: '70vh' }}>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableSortCell
                    active={notesSort === 'createdAt'}
                    direction={notesOrder}
                    onChange={(notesOrder) => updateFilters({ notesOrder, notesSort: 'createdAt' })}
                  >
                    Date
                  </TableSortCell>
                  <TableCell>Note</TableCell>
                  <TableSortCell
                    active={notesSort === 'authorName'}
                    direction={notesOrder}
                    onChange={(notesOrder) => updateFilters({ notesOrder, notesSort: 'authorName' })}
                  >
                    User
                  </TableSortCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((note) => {
                  const testId = note.message.slice(0, 15)
                  return (
                    <TableRow hover key={note.id} sx={{ '& .MuiTableCell-root': { verticalAlign: 'top' } }} data-testid={`note-${testId}`}>
                      <TableCell width="15%">
                        <Stack>
                          <Typography data-testid={`note-date-${testId}`}>{dayjs(note.createdAt).format('L LT')}</Typography>
                          <Typography data-testid={`note-source-${testId}`} sx={{ color: 'text.secondary' }}>
                            {toTitleCase(note.source)}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell data-testid={`note-content-${testId}`}>{note.message}</TableCell>
                      <TableCell width="15%">
                        <Stack>
                          <Typography noWrap data-testid={`note-author-${testId}`}>
                            {note.authorName}
                          </Typography>
                          <Typography data-testid={`note-authorType-${testId}`} sx={{ color: 'text.secondary' }}>
                            {toTitleCase(note.authorUserType)}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Pagination id="notes" loading={isPending} last={data?.length < perPage} />
        {canAddNotes && (
          <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} onClick={() => addNote()}>
            Add Note
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
