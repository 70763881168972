import dayjs from 'dayjs'

import { Yup } from '@shared/utils'

export const initialValues = {
  startDate: null,
  endDate: null,
  cbo: '',
  status: [],
  fillsRemaining: '',
}

export const toApiValues = (values) => ({
  from_date: values.startDate?.format('YYYY-MM-DD'),
  to_date: values.endDate?.format('YYYY-MM-DD'),
  cbo: values.cbo,
  status: values.status,
  fills: values.fillsRemaining,
})

export const validationSchema = Yup.object()
  .shape({
    startDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-start', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    endDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-end', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    cbo: Yup.string().nullable(),
    status: Yup.array().of(Yup.string()).nullable(),
    fillsRemaining: Yup.number().transform((val, orig) => (orig === '' ? undefined : val)),
  })
  .test({
    name: 'validate-range',
    test: function ({ startDate, endDate }) {
      if (dayjs.isDayjs(startDate) && dayjs.isDayjs(endDate)) {
        if (startDate.isAfter(endDate)) {
          return this.createError({ path: 'range', message: 'Start must be before End' })
        }
        if (endDate.diff(startDate, 'year') >= 1) {
          return this.createError({ path: 'range', message: 'Range cannot exceed 1 year' })
        }
      }
      return true
    },
  })
