import Stack from '@mui/material/Stack'

import Image from '@shared/components/src/Image'
import { viewBase64 } from '@shared/utils'

import Overlay from '@components/Overlay'
import RenderControl from '@components/RenderControl'

export default function InsuranceDetailed({ data, isLoading }) {
  return (
    <RenderControl
      loading={isLoading}
      isEmpty={!isLoading && !data?.front && !data?.back}
      emptyTitle="No images available"
      emptyProps={{ variant: 'h4', color: 'text.primary' }}
    >
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', p: 4 }}>
        {data?.front && (
          <Overlay.Download onClick={() => viewBase64(data.front, 'image/jpeg')}>
            <Image
              fit="contain"
              src={`data:image/*;base64,${data.front}`}
              duration={500}
              width="unset"
              sx={{ maxWidth: 300, maxHeight: 200, minHeight: 100 }}
              alt="front of insurance card"
            />
          </Overlay.Download>
        )}
        {data?.back && (
          <Overlay.Download onClick={() => viewBase64(data.back, 'image/jpeg')}>
            <Image
              fit="contain"
              src={`data:image/*;base64,${data.back}`}
              duration={500}
              width="unset"
              sx={{ maxWidth: 300, maxHeight: 200, minHeight: 100 }}
              alt="back of insurance card"
            />
          </Overlay.Download>
        )}
      </Stack>
    </RenderControl>
  )
}
