import dayjs from 'dayjs'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import useDialog from '@shared/hooks/src/useDialog'
import { RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { LabTypes, TaskKind, TaskStatus, UserRole } from '@shared/utils'

import { getPageHistoryId, PageHistoryType, usePagesHistoryListener } from '@hooks/usePagesHistory'
import { ExperimentOutlinedIcon } from '@icons'
import EncounterUpdate from '@components/Details/EncounterUpdate'
import Property, { CBOProperty, ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'
import Confirmation from '@components/Dialog/Confirmation'
import StatusChip from '@components/StatusChip'

import { useLabTaskCreation, useLabUpdate } from '../Lab.hooks'
import { isLabReadyToReview } from '../Lab.utils'
import LabStatusUpdate from './LabStatusUpdate'

export default function DetailsSection({ data, mini = false, onClose, onStatusUpdate }) {
  usePagesHistoryListener(PageHistoryType.Patient, data.user)
  usePagesHistoryListener(PageHistoryType.Lab, data, getPageHistoryId(PageHistoryType.Patient, data.user?.id))

  const me = useMe()
  const canUpdate = [UserRole.Admin, UserRole.MA, UserRole.Provider].includes(me.role) && isLabReadyToReview(data.status)

  const updateLab = useLabUpdate(data.id)

  return (
    <Section paper mini={mini} title="Details" icon={mini ? null : <ExperimentOutlinedIcon />} action={<Actions lab={data} />}>
      <Property label="Created">{data.createdAt ? dayjs(data.createdAt).format('L LT') : '—'}</Property>
      <Property label="Type">{[data.lab, data.eid].filter(Boolean).join(' - ')}</Property>
      <Property label="Panels">{data.panelsToString || '—'}</Property>
      <Property label="Reason">{data.reason || '—'}</Property>
      {data.rejectedReason && <Property label="Rejected Reason">{data.rejectedReason}</Property>}
      <ProviderProperty label="Ordered By" user={data.provider} />
      <CBOProperty label="CBO" data={data.cbo} />
      <Property label="Encounter">
        <EncounterUpdate
          readOnly={LabTypes.Manual !== data.lab || !canUpdate}
          patientId={data.user.id}
          encounter={data.encounter}
          encounterId={data.encounterId}
          onChange={(encounter) => updateLab.mutateAsync({ encounter, status: data.status })}
        />
      </Property>
      <LabStatusUpdate readOnly={!canUpdate} lab={data} onClose={onClose} onStatusUpdate={onStatusUpdate} />
      {data.labError && (
        <Property label="Error" sx={{ value: { color: 'error.main' } }}>
          {data.labError}
        </Property>
      )}
    </Section>
  )
}

DetailsSection.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Details" icon={mini ? null : <ExperimentOutlinedIcon />}>
    <Property.Loading label="Created" />
    <Property.Loading label="Type" />
    <Property.Loading label="Panels" />
    <Property.Loading label="Reason" />
    <ProviderProperty.Loading label="Ordered By" />
    <CBOProperty.Loading label="CBO" />
    <Property label="Encounter">
      <EncounterUpdate.Loading />
    </Property>
    {/* HIV TEST COMPLETED LOADER */}
    {/*<Property.Loading />*/}
    <LabStatusUpdate.Loading />
  </Section>
)

function Actions({ lab }) {
  const createTask = useLabTaskCreation()

  const confirmCreate = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      title: "Create 'ABNL Lab Follow Up' task ?",
      description: "Please confirm that you want to add a new task for the patient's assigned provider",
      onReject: () => close(),
      onConfirm: () =>
        createTask
          .mutateAsync({
            lab_id: lab.id,
            task: {
              kind: TaskKind.ABNLFollowUp,
              patient_id: lab.user.id,
              assignee_id: lab.patientProvider.userId,
              status: TaskStatus.InProgress,
            },
          })
          .then(close),
    }),
  })

  const hasFollowUpTask = lab.labFollowUpTasks?.length > 0

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      {lab.positiveResult > 0 && <StatusChip.Lab positive />}
      <RoleGuard allowed={[UserRole.Provider]}>
        {lab.patientProvider && !hasFollowUpTask && (
          <Button size="small" variant="outlined" onClick={confirmCreate}>
            + Lab Follow Up task
          </Button>
        )}
      </RoleGuard>
    </Stack>
  )
}
