import { useMemo } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { CopyOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import CopyToClipboard from '@components/CopyToClipboard'

AvatarPatient.propTypes = {
  user: PropTypes.shape({
    nickname: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    pronouns: PropTypes.string,
    fullName: PropTypes.string,
  }),

  /** Can open the patient link in a new tab */
  newTab: PropTypes.bool,

  /** Can hide the patient's id */
  hideId: PropTypes.bool,

  /** Can force patient ID to allow copying to clipboard. This overrides the link */
  clipboard: PropTypes.bool,

  /** Can display as a stacked block on a single line */
  inline: PropTypes.bool,

  /** Can be hovered to trigger popper with detailed information */
  hover: PropTypes.oneOf(['none', 'simple', 'card']),
}

export default function AvatarPatient({ patient, newTab = false, hideId = false, clipboard = false, inline = false, hover = 'none' }) {
  const id = useMemo(() => {
    if (hideId) return null

    if (clipboard) {
      return (
        <CopyToClipboard>
          {({ copy }) => (
            <Stack direction="row" onClick={() => copy(patient.id)} sx={{ alignItems: 'center' }}>
              <Typography variant="h5" data-testid="patient-id" sx={{ flex: 1, cursor: 'pointer', mr: 1 }}>
                MRN: {patient.id}
              </Typography>
              <CopyIcon />
            </Stack>
          )}
        </CopyToClipboard>
      )
    }

    return (
      <Link
        to={`/app/patients/${patient.id}`}
        target={newTab ? '_blank' : '_self'}
        data-testid="patient-link"
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h5" data-testid="patient-id" sx={{ flex: 1 }}>
          MRN: {patient.id}
        </Typography>
      </Link>
    )
  }, [clipboard, hideId, newTab, patient.id])

  return (
    <Stack direction="row" sx={{ alignItems: inline ? 'center' : 'flex-start' }}>
      <Avatar user={patient} hover={hover} />
      <Stack direction={inline ? 'row' : 'column'} sx={{ alignItems: inline ? 'center' : 'flex-start', ml: 1 }}>
        <Typography color="inherit" sx={{ mr: 1, lineHeight: inline ? '1.57' : '1.5' }} data-testid="patient-fullname">
          {patient.fullName}
        </Typography>
        {patient.homeAddress && (
          <Typography
            variant="subtitle2"
            data-testid="patient-home-address"
            sx={{
              color: 'text.secondary',
              lineHeight: inline ? '1.57' : '1.3',
            }}
          >
            {[patient.homeAddress.city, patient.homeAddress.state, patient.homeAddress.zip].join(', ')}
          </Typography>
        )}
      </Stack>
      <Box sx={{ flex: 1, textAlign: 'right' }}>{id}</Box>
    </Stack>
  )
}

AvatarPatient.Loading = function ({ inline, hideId }) {
  return (
    <Stack direction="row" sx={{ alignItems: inline ? 'center' : 'flex-start' }}>
      <Skeleton variant="rounded" width={40} height={40} />
      <Stack direction={inline ? 'row' : 'column'} sx={{ alignItems: inline ? 'center' : 'flex-start', ml: 1 }}>
        <Typography color="inherit" sx={{ mr: 1, lineHeight: inline ? '1.57' : '1.5' }}>
          <Skeleton width={100} />
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.secondary',
            lineHeight: inline ? '1.57' : '1.3',
          }}
        >
          <Skeleton width={60} />
        </Typography>
      </Stack>
      <Box sx={{ flex: 1, textAlign: 'right' }}>
        {hideId ? null : (
          <Typography variant="h5">
            <Skeleton width={50} />
          </Typography>
        )}
      </Box>
    </Stack>
  )
}

const CopyIcon = styled(CopyOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
