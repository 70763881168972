import dayjs from 'dayjs'

import Tooltip from '@mui/material/Tooltip'

/**
 * Wrap children in a tooltip that displays the date in a friendly format on hover
 *
 * @param {dayjs} date - date to display
 * @param {string} format - format to display the date in
 * @param children - children to wrap
 */
export default function DateTooltip({ date, format = 'L LT', children }) {
  return (
    <Tooltip arrow title={date ? dayjs(date).format(format) : ''}>
      {children}
    </Tooltip>
  )
}
