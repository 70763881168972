import SvgIcon from '@mui/material/SvgIcon'

export default function LinkIcon(props) {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.643 10.93V1.073c0-.237.192-.428.429-.428h4.821c.059 0 .107.048.107.107v.75a.107.107 0 0 1-.107.107H1.607v8.786h8.786V6.109c0-.06.048-.107.107-.107h.75c.06 0 .107.048.107.107v4.821a.428.428 0 0 1-.428.429H1.072a.428.428 0 0 1-.429-.429zm10.239-7.678-.7-.699-3.432 3.43a.108.108 0 0 1-.151 0l-.568-.568a.108.108 0 0 1 0-.151l3.431-3.431-.701-.702a.107.107 0 0 1 .063-.182l2.402-.282c.07-.008.127.051.12.12l-.282 2.402a.108.108 0 0 1-.071.089.107.107 0 0 1-.111-.026z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
