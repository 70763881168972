const styles = {
  card: {
    minWidth: 150,
    width: 150,
    minHeight: 120,
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    border: '3px solid',
    borderColor: 'transparent',
    position: 'relative',
  },
  clickable: {
    cursor: 'pointer',
  },
  selected: {
    borderColor: 'primary.main',
    borderBottomRightRadius: 0,
  },
  checked: {
    position: 'absolute',
    bottom: -20,
    right: -20,
    width: 40,
    height: 40,
    transform: 'rotate(45deg)',
    backgroundColor: 'primary.main',
  },
  content: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'background.overlay',
  },
  description: {
    backgroundColor: 'grey.100',
    px: 1,
  },
  icon: {
    fontSize: 16,
  },
  unrecognized: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}

export default styles
