import { useState } from 'react'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'

import { Lookup } from '@shared/providers/src/DropdownOptionsProvider'

import { rubyLink } from '@config'

import { FollowUpDialog, isNotDone, ResolveDialog } from './TaskInfo.utils'

export default function PEPInfo({ task, patient }) {
  const [followUpOpen, setFollowUpOpen] = useState(false)
  const [resolveOpen, setResolveOpen] = useState(false)

  return (
    <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', pt: 3 }}>
      <Button
        variant="outlined"
        color="secondary"
        component={Link}
        target="_blank"
        rel="noopener"
        href={rubyLink(`patients/${task.patientId}/quarterly_automations`)}
      >
        Q Flows
      </Button>
      {isNotDone(task) && (
        <>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" color="primary" onClick={() => setFollowUpOpen(true)}>
              Follow Up
            </Button>
            <Button variant="contained" onClick={() => setResolveOpen(true)}>
              Resolve
            </Button>
          </Stack>

          <FollowUpDialog patient={patient} open={followUpOpen} onClose={() => setFollowUpOpen(false)} />
          <ResolveDialog
            taskId={task.id}
            patient={patient}
            reasonsKey={Lookup.PEPPatientAtRiskDoneReasons}
            freeTextReason="Other reason"
            open={resolveOpen}
            onClose={() => setResolveOpen(false)}
          />
        </>
      )}
    </Stack>
  )
}
