import { Link as RouterLink } from 'react-router'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import CopyIconButton from '@components/CopyIconButton'

export default function HeaderSection({ data }) {
  return (
    <Stack>
      <Typography component="span">
        <Typography component="span" variant="h4">
          {data.lab} Lab
        </Typography>{' '}
        /{' '}
        <Link component={RouterLink} fontWeight="normal" variant="body1" to={`/app/labs/${data.id}`} target="_blank" color="text.secondary">
          ID: {data.id}{' '}
        </Link>
        <CopyIconButton fn={(copy) => copy(data.id)} />
      </Typography>
      {data.user && (
        <Typography component="span">
          {data.user.fullName} /{' '}
          <Link component={RouterLink} fontWeight="normal" to={`/app/patients/${data.user.id}`} target="_blank" color="text.secondary">
            MRN: {data.user.id}
          </Link>
        </Typography>
      )}
    </Stack>
  )
}

HeaderSection.Loading = () => (
  <Stack>
    <Typography variant="h4">
      <Skeleton width={300} />
    </Typography>
    <Typography>
      <Skeleton width={200} />
    </Typography>
  </Stack>
)
