import { memo, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import dayjs from 'dayjs'
import { useHover } from 'usehooks-ts'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import Divider from '@mui/material/Divider'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { convertPossibleRichTextToPlainText, UserRole } from '@shared/utils'

import { ReplyIcon } from '@icons'
import Avatar, { Offline } from '@components/Avatar'
import RichText from '@components/RichText'

import { useHighlight } from './Message.hooks'
import { styles } from './Message.utils'

function Message({ data, isOwn, mini, readOnly = false, highlighted = false, onReply, onReact }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  const isHighlighted = useHighlight(highlighted)

  const position = isOwn ? 'right' : 'left'

  return (
    <Box id={data.id} sx={styles.container(position)}>
      <Stack ref={ref} direction={position === 'right' ? 'row-reverse' : 'row'} spacing={1} sx={styles.content(position)}>
        {!isOwn && <MessageAuthor hover="card" user={data.user} />}
        <Stack sx={{ flex: '1 1 auto', gap: 0.5 }}>
          <Card sx={styles.card(position, isHighlighted)}>
            <Stack sx={{ gap: 0.5 }}>
              {(!isOwn || !mini) && (
                <>
                  {data.user.role === UserRole.Patient ? (
                    <div>
                      <Link component={RouterLink} to={`/app/patients/${data.user.id}`} color="inherit" variant="body2" target="_blank">
                        {data.user.fullName}
                      </Link>
                    </div>
                  ) : (
                    <Typography color="inherit" variant="body2">
                      {data.user.fullName}
                    </Typography>
                  )}
                </>
              )}

              {(data.relatedMessageId || data.relatedMessage) && <RelatedMessage data={data.relatedMessage} position={position} />}
              <RichText content={data.message} />
            </Stack>
          </Card>
          <Box sx={styles.date(position)}>
            <Typography noWrap variant="caption" sx={{ color: 'text.secondary' }}>
              {dayjs(data.created).format('L LT')}
              {data.sentOnBehalf && ` by ${data.sentBy.fullName}`}
            </Typography>
          </Box>
        </Stack>
        {!isOwn && !readOnly && (onReact || onReply) && (
          <Grow in={isHovered} unmountOnExit>
            <Paper elevation={3} sx={styles.popover}>
              <Stack direction="row" spacing={1}>
                {onReact && (
                  <Stack direction="row" spacing={1}>
                    <ReactButton value="👍" onClick={onReact} />
                    <ReactButton value="👎" onClick={onReact} />
                  </Stack>
                )}
                <Divider orientation="vertical" sx={{ height: 30 }} />
                {onReply && (
                  <IconButton size="small" onClick={onReply} color="secondary">
                    <ReplyIcon style={{ fontSize: 24 }} />
                  </IconButton>
                )}
              </Stack>
            </Paper>
          </Grow>
        )}
      </Stack>
    </Box>
  )
}

// TODO Can be improved by scrolling into message instead of expanding it in place
const RelatedMessage = ({ data, position }) => {
  const [expanded, setExpanded] = useState(false)

  const showLoading = !data
  const showData = Boolean(data)

  return (
    <CardActionArea sx={styles.relatedMessage(position)} disabled={!data} onClick={() => setExpanded((v) => !v)}>
      <Box sx={{ display: 'inline-grid' }}>
        {showLoading && (
          <>
            <Typography variant="body2">
              <Skeleton width={150} />
            </Typography>
            <Typography variant="body2">
              <Skeleton width={300} />
            </Typography>
          </>
        )}
        {showData && (
          <>
            <Typography variant="body2">{data.user.fullName}</Typography>
            {expanded ? (
              <RichText content={data.message} variant="body2" />
            ) : (
              <Typography variant="body2" noWrap>
                {convertPossibleRichTextToPlainText(data.message)}
              </Typography>
            )}
          </>
        )}
      </Box>
    </CardActionArea>
  )
}

const ReactButton = ({ value, onClick }) => (
  <IconButton size="small" onClick={() => onClick(value)} color="secondary" sx={{ fontSize: 18 }}>
    {value}
  </IconButton>
)

export const MessageAuthor = ({ user, hover }) => (
  <Offline in={Boolean(user.outOfOfficeMessage)}>
    <Avatar hover={hover} user={user} variant="circular" />
  </Offline>
)

export const MessageLoading = ({ isOwn }) => {
  const position = isOwn ? 'right' : 'left'

  return (
    <Box sx={styles.container(position)}>
      <Stack direction={position === 'right' ? 'row-reverse' : 'row'} spacing={2} sx={styles.content(position)}>
        {!isOwn && <Skeleton variant="circular" width={40} height={40} />}
        <Stack sx={{ flex: '1 1 auto', gap: 0.5 }}>
          <Card sx={styles.card(position)}>
            <Stack sx={{ gap: 0.5 }}>
              <Typography variant="body2">
                <Skeleton width={100} />
              </Typography>
              <Typography>
                <Skeleton width={250} />
              </Typography>
            </Stack>
          </Card>
          <Box sx={styles.date(position)}>
            <Typography variant="caption">
              <Skeleton width={60} />
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

export default memo(Message)
