import { useMemo } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { checkMessagingAvailability } from '@shared/messaging/src/Helper'
import {
  getThreadName,
  useConversation,
  useConversationMessages,
  useOutOfOfficeListener,
  useSendMessage,
  useThreadUsers,
} from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import DocTitle from '@components/DocTitle'

import Message, { MessageLoading } from '../Message'
import ThreadHeader from '../ThreadHeader'
import ThreadMessageComposer from '../ThreadMessageComposer'
import { useBehalfUsers, useConversationJoinListener, useMessagesScroll } from './ThreadView.hooks'
import { Empty, Loader, PaginateIndicator, styles } from './ThreadView.utils'

export default function ThreadView({ mini = false, threadId, user, isLoading: outsideLoading, onClose }) {
  const me = useMe()
  const [messagesRef] = useMessagesScroll(threadId)

  const {
    data: messages,
    isPending: areMessagesPending,
    fetchNextPage: fetchNextMessages,
    hasNextPage: hasNextMessages,
    isFetchingNextPage: isFetchingNextMessages,
  } = useConversationMessages(threadId)
  const { data: thread, isFetched: isThreadFetched } = useConversation(threadId, { enabled: Boolean(threadId) })
  const { data: behalfUsers } = useBehalfUsers(threadId, {
    enabled: Boolean(threadId) && (me.superAdmin || [UserRole.MA, UserRole.Support].includes(me.role)),
  })

  useOutOfOfficeListener(thread)
  useConversationJoinListener(thread)

  const userId = user?.id
  const users = useThreadUsers(thread)
  const name = getThreadName(users)

  const sendMessage = useSendMessage({ conversationId: threadId, userId })

  const unavailableMessage = useMemo(() => {
    if (thread?.archived) return "This conversation is archived. You can't send messages."
    if (thread?.messageCanBeSent === false) return "This conversation is not accessible anymore. You can't send messages."

    const noAccess = me.role === UserRole.ClinicalAdmin && !thread?.users?.some((u) => u.id === me.id)
    if (noAccess) return "You can't send messages in this conversation."

    const patient = user || (users.length === 1 && users[0].role === UserRole.Patient ? users[0] : undefined)

    if (patient) {
      const [canMessage, description] = checkMessagingAvailability(patient)
      return !canMessage ? description : undefined
    }
  }, [me, thread, user, users])

  if (isThreadFetched && !thread) return <Empty>Thread not found</Empty>

  const showLoading = outsideLoading || (Boolean(threadId) && areMessagesPending)
  const showEmpty = !showLoading && (messages?.length === 0 || !threadId)
  const showData = !showEmpty && messages?.length > 0

  return (
    <Box sx={styles.container}>
      {!mini && <DocTitle title={name || 'Conversation'} />}
      {!mini && <ThreadHeader thread={thread} user={user} onClose={onClose} />}
      <Stack data-testid="messages" ref={messagesRef} sx={[styles.list, mini ? styles.listMini : undefined]}>
        <Loader loading={isFetchingNextMessages} />
        {showLoading && (
          <>
            <MessageLoading />
            <MessageLoading isOwn />
            {!mini && <MessageLoading />}
          </>
        )}
        {showEmpty && <Empty>There are no messages yet</Empty>}
        {showData && (
          <>
            <PaginateIndicator hasMore={hasNextMessages} fetch={fetchNextMessages} />
            {messages.map((message) => {
              const isOwn = message.user.id === me.id
              return <Message key={message.id} data={message} isOwn={isOwn} mini={mini} />
            })}
          </>
        )}
      </Stack>
      <ThreadMessageComposer
        key={threadId}
        mini={mini}
        threadId={threadId}
        users={behalfUsers}
        unavailable={unavailableMessage}
        disabled={!thread && !userId}
        onSend={({ message, fromUserId }) => sendMessage.mutate({ message, fromUserId })}
      />
    </Box>
  )
}
