import { useState } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import range from 'lodash/range'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@components/_mui/Typography'

import { RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { isPARKind, isProviderKind, UserRole } from '@shared/utils'

import { TaskDialog as ProviderPARTaskDialog } from '@pages/Dashboard/Provider/PatientAtRisk'
import { TaskDialog as ProviderTaskDialog } from '@pages/Dashboard/Provider/Tasks'
import { ExpandAltOutlinedIcon, PlusOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import DateTooltip from '@components/DateTooltip'
import { NewTaskDialog } from '@components/Kanban'
import { usePatientTask } from '@components/Kanban/PatientTaskDetails/PatientTaskDetails.hooks'
import KanbanTaskDialog from '@components/Kanban/TaskDialog'
import Tile from '@components/Tile'

import { IconCircleButton } from '../ProfileTab.utils'
import { useTasks, useTasksFilters, useTaskTypes } from './Tasks.hooks'
import { getTaskType, isTaskClickable } from './Tasks.utils'
import TasksModal, { Status } from './TasksModal'

export default function Tasks() {
  const [isNewTaskOpen, setIsNewTaskOpen] = useState(false)

  const { id } = useParams()

  const me = useMe()
  const [{ task, tasksExpanded }, updateFilters] = useTasksFilters()
  const types = useTaskTypes()
  const patient = usePatient()

  const { data, isRefreshing, isPending } = useTasks(id, { offset: 0, limit: 5, sort: 'created_at', order: 'desc' })

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  const onCloseTask = () => updateFilters({ task: undefined })
  const isTaskDetailsOpen = Boolean(task)

  return (
    <Tile
      title="Tasks"
      refreshing={isRefreshing}
      action={
        <Stack direction="row" spacing={1}>
          <IconCircleButton disabled={(data?.length ?? 0) === 0} size="small" onClick={() => updateFilters({ tasksExpanded: true })}>
            <ExpandAltOutlinedIcon />
          </IconCircleButton>
          <RoleGuard allowed={[UserRole.MA, UserRole.Support]}>
            <IconCircleButton
              disabled={!patient?.enrolled || !patient?.active || Boolean(patient?.disabled)}
              onClick={() => setIsNewTaskOpen(true)}
              size="small"
            >
              <PlusOutlinedIcon />
            </IconCircleButton>
          </RoleGuard>
        </Stack>
      }
      sx={{ height: '100%' }}
    >
      <RoleGuard allowed={[UserRole.Provider]}>
        <ProviderTask patientId={id} taskId={task} open={isTaskDetailsOpen} onClose={onCloseTask} />
      </RoleGuard>
      <RoleGuard allowed={[UserRole.MA, UserRole.Support]}>
        <KanbanTaskDialog taskId={task} open={isTaskDetailsOpen} onClose={onCloseTask} />
        <NewTaskDialog patient={patient} open={isNewTaskOpen} onClose={() => setIsNewTaskOpen(false)} />
      </RoleGuard>
      <TasksModal patientId={id} open={tasksExpanded} onClose={() => updateFilters({ tasksExpanded: false })} />
      <TableContainer>
        <Table size="small">
          <TableBody data-testid="tasks">
            {showLoading && <Loading />}
            {showEmpty && <Empty />}
            {showData &&
              data.map((task) => {
                const type = getTaskType(types, task)
                const canClick = isTaskClickable(me, task)

                return (
                  <TableRow
                    key={task.id}
                    hover={canClick}
                    onClick={canClick ? () => updateFilters({ task: task.identifier }) : undefined}
                    sx={{ cursor: canClick ? 'pointer' : 'default', '& .MuiTableCell-root': { verticalAlign: 'top' } }}
                  >
                    <DateTooltip date={task.createdAt}>
                      <TableCell width={100}>{dayjs(task.createdAt).format('L')}</TableCell>
                    </DateTooltip>
                    <TableCell width={120}>{task.identifier}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell width={120} align="center">
                      <Status value={task.status} />
                    </TableCell>
                    <TableCell width={180}>
                      {task.assignee && (
                        <Stack direction="row" spacing={1}>
                          <Avatar size="xs" user={task.assignee} hover="card" />
                          <Typography noWrap sx={{ fontWeight: 'bold' }}>
                            {task.assignee.fullName}
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Tile>
  )
}

function Loading() {
  return range(0, 5).map((i) => (
    <TableRow hover key={i}>
      <TableCell width={100}>
        <Skeleton width={80} />
      </TableCell>
      <TableCell width={120}>
        <Skeleton width={100} />
      </TableCell>
      <TableCell>
        <Skeleton width="100%" />
      </TableCell>
      <TableCell width={120}>
        <Skeleton width={80} />
      </TableCell>
      <TableCell width={180}>
        <Skeleton width={150} />
      </TableCell>
    </TableRow>
  ))
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={5} align="center">
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No data to display
        </Typography>
      </TableCell>
    </TableRow>
  )
}

function ProviderTask({ patientId, taskId, open, onClose }) {
  const { data } = usePatientTask(patientId, taskId, { enabled: Boolean(patientId) && Boolean(taskId) })

  const isDialogOpen = open && Boolean(data)

  return (
    <>
      <ProviderTaskDialog task={data} open={isDialogOpen && isProviderKind(data.kind)} onClose={onClose} />
      <ProviderPARTaskDialog task={data} open={isDialogOpen && isPARKind(data.kind)} onClose={onClose} />
    </>
  )
}
