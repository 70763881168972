import { useCallback, useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'

import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'
import useQuery from '@shared/hooks/src/useQuery'
import { useCachedConversationLastMessage } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useBehalfUsers(conversationId, options = {}) {
  return useQuery({
    queryKey: QK.conversations.id(conversationId).behalfUsers.list(),
    queryFn: () => API.conversations.id(conversationId).users.behalf(undefined, { skipHandling: true }),
    ...options,
  })
}

// Messages auto scroll helper
export function useMessagesScroll(threadId) {
  const messagesRef = useRef(null)
  const lastMessage = useCachedConversationLastMessage(threadId)
  const lastMessageId = lastMessage?.id

  const scrollToBottom = useCallback(() => {
    if (!messagesRef.current) return
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight
  }, [])

  // Scroll to the bottom of messages when last message changes
  useEffect(() => {
    if (lastMessageId) scrollToBottom()
  }, [lastMessageId, scrollToBottom])

  // Prevent user from scrolling to the top to hack scroll position being reset when fetching more items
  useEffect(() => {
    const setOverflowAuto = debounce(() => {
      if (messagesRef.current) {
        messagesRef.current.style.overflow = 'auto'
      }
    }, 300)

    const handleScroll = debounce((e) => {
      if (e.target.scrollTop < 10 && messagesRef.current) {
        // Prevents the scroll from being at the top when new messages loaded
        e.target.scrollTop = e.target.scrollTop || 1
        // Makes the scroll stop for a moment
        messagesRef.current.style.overflow = 'hidden'
        // Makes the scroll go back to normal
        setOverflowAuto()
      }
    }, 100)

    const currentRef = messagesRef.current
    currentRef?.addEventListener('scroll', handleScroll)
    return () => currentRef?.removeEventListener('scroll', handleScroll)
  }, [])

  return [messagesRef, scrollToBottom]
}

export function useConversationJoinListener(thread) {
  const me = useMe()

  usePubSub(
    PubSubEvents.ConversationMessageSent,
    ({ conversationId: eventConversationId }) => {
      if (thread.id !== eventConversationId) return
      const isCurrentUserParticipant = thread.users?.some((u) => u.id === me.id)
      if (isCurrentUserParticipant) return
      queryClient.setQueryData(QK.conversations.id(thread.id).details, (oldData) => ({
        ...oldData,
        users: [...oldData.users, me],
      }))
    },
    {
      enabled: Boolean(thread),
    }
  )
}
