import { useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'

import { InfoCircleOutlinedIcon } from '@icons'
import InputControl from '@components/InputControl/InputControl'
import LinearProgress from '@components/LinearProgress'

import { useUpcomingAppointments } from './Menu.hooks'

const testId = 'change-status-dialog'

/**
 * Display a dialog confirmation for changing a patient's status
 */
export default function ChangeStatusDialog({ open = false, title, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" PaperProps={{ 'data-testid': testId }}>
      <Form title={title} onConfirm={onConfirm} onClose={onClose} />
    </Dialog>
  )
}

// Extract formik into a separate component inside Dialog for unmount benefits like:
// - Automatically reset formik values on close
// - Don't fetch data if dialog is closed
function Form({ title, onConfirm, onClose }) {
  const { data: appointments, isPending } = useUpcomingAppointments()
  const reasons = useLookup(Lookup.StatusChangingReasons)

  const hasUpcomingAppointments = useMemo(() => {
    if (isPending) return false

    return appointments?.length > 0
  }, [appointments?.length, isPending])

  const formik = useFormik({
    initialValues: {
      reason: '',
      details: '',
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('Reason is required'),
      details: Yup.string().when('reason', {
        is: (reason) => reason === 'No longer interested',
        then: Yup.string().required('Details are required'),
      }),
    }),
    onSubmit: () => onConfirm(formik.values),
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>{title}</DialogTitle>
      <LinearProgress loading={isPending} />
      <DialogContent dividers>
        <Stack spacing={2}>
          {hasUpcomingAppointments && (
            <Alert color="warning" variant="border" icon={<InfoCircleOutlinedIcon />}>
              <AlertTitle>Future appointments</AlertTitle>
              This user has appointments scheduled. Changing their status will cancel all future appointments!
            </Alert>
          )}
          <InputControl field="reason" formikProps={formik}>
            <TextField label="Reason for changing status" disabled={isPending} required fullWidth select>
              {reasons?.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </TextField>
          </InputControl>
          <InputControl field="details" formikProps={formik}>
            <TextField required fullWidth select disabled={formik.values.reason !== 'No longer interested' || isPending}>
              <MenuItem value="Desires / requires injectable">Desires / requires injectable</MenuItem>
              <MenuItem value="Local care provider">Local care provider</MenuItem>
              <MenuItem value="Monogamous relationship">Monogamous relationship</MenuItem>
              <MenuItem value="Not sexually active">Not sexually active</MenuItem>
              <MenuItem value="Other telePrEP provider">Other telePrEP provider</MenuItem>
              <MenuItem value="Patient dissatisfied">Patient dissatisfied</MenuItem>
            </TextField>
          </InputControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="text" data-testid={`${testId}-reject`} disabled={isPending}>
          Cancel
        </Button>
        <Button
          loading={formik.isSubmitting}
          disabled={!formik.dirty || isPending}
          type="submit"
          variant="contained"
          data-testid={`${testId}-confirm`}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </form>
  )
}
