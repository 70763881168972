import SvgIcon from '@mui/material/SvgIcon'

export default function BackspaceIcon(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.667 2h-10c-.46 0-.82.233-1.06.587L0 8l3.607 5.407c.24.353.6.593 1.06.593h10C15.4 14 16 13.4 16 12.667V3.333C16 2.6 15.4 2 14.667 2zm0 10.667H4.713L1.6 8l3.107-4.667h9.96v9.334zM6.94 11.333 9.333 8.94l2.394 2.393.94-.94L10.273 8l2.394-2.393-.94-.94L9.333 7.06 6.94 4.667l-.94.94L8.393 8 6 10.393l.94.94z"
        fill="currentColor"
        fillOpacity=".54"
      />
    </SvgIcon>
  )
}
