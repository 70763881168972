import { keyframes } from '@emotion/react'

const getHighlightKeyframes = (start, end) =>
  keyframes`
      0% {
          background-color: ${start};
      }
      100% {
          background-color: ${end};
      }
  `

export const styles = {
  container: (position) => ({
    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
    flex: '0 0 auto',
    display: 'flex',
  }),
  content: (position) => ({
    position: 'relative',
    alignItems: 'flex-start',
    maxWidth: '500px',
    ml: position === 'right' ? 'auto' : 0,
    mr: position === 'left' ? 'auto' : 0,
  }),
  card: (position, highlighted) => ({
    borderRadius: 4,
    boxShadow: (theme) => theme.customShadows.z2,
    px: 2,
    py: 1,
    ...(position === 'right' && {
      bgcolor: 'primary.lighter',
    }),
    ...(highlighted && {
      animation: (theme) =>
        `${getHighlightKeyframes(
          theme.palette.warning.lighter,
          position === 'right' ? theme.palette.primary.lighter : 'transparent'
        )} 3s ease-in`,
    }),
  }),
  date: (position) => ({
    display: 'flex',
    justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
    px: 2,
  }),
  relatedMessage: (position) => ({
    borderRadius: 1,
    borderLeft: '3px solid',
    borderColor: 'primary.main',
    px: 1,
    bgcolor: position === 'right' ? 'grey.300' : 'grey.200',
  }),
  popover: {
    position: 'absolute',
    top: -15,
    right: -15,
  },
}
