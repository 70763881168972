import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { mapCache, QK } from '@shared/utils'

export function useMarkCompleted(taskId) {
  return useMutation({
    mutationFn: (reason) => API.tasks.id(taskId).mark.completed({ reason }),
    onSuccess: updateCache,
  })
}

export function useMarkNotApplicable(taskId) {
  return useMutation({
    mutationFn: (reason) => API.tasks.id(taskId).mark.notApplicable({ reason }),
    onSuccess: updateCache,
  })
}

export function useTaskNoteCreation(taskId) {
  return useMutation({
    mutationFn: (message) => API.tasks.id(taskId).notes.create({ message }),
  })
}

const updateCache = (updatedTask) => {
  const key = QK.patients.id(updatedTask.patientId).tasks.id(updatedTask.id).details
  queryClient.setQueryData(key, (task = {}) => ({ ...task, ...updatedTask }))
  queryClient.setQueriesData(
    { queryKey: QK.patients.id(updatedTask.patientId).tasks.lists },
    mapCache((task) => (task.id === updatedTask.id ? { ...task, ...updatedTask } : task))
  )
  queryClient.invalidateQueries({ queryKey: QK.tasks.lists })
}
