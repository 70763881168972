import dayjs from 'dayjs'
import startCase from 'lodash/startCase'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import { handleError, InsurancePartners } from '@shared/utils'

import { EyeOutlinedIcon } from '@icons'
import LinearProgress from '@components/LinearProgress'
import Property from '@components/Property'

import { useSendInsurancePartnerInvite, useSetInsurancePartnerContacted, useSetInsurancePartnerInsured } from './InsurancePartner.hooks'

export default function InsurancePartner({ insurance, patient, isLoading }) {
  const sendInvite = useSendInsurancePartnerInvite()
  const setContacted = useSetInsurancePartnerContacted()
  const setInsured = useSetInsurancePartnerInsured()

  const handleSetContacted = () => setContacted.mutateAsync(patient?.id).catch(handleError)
  const handleSetInsured = () => setInsured.mutateAsync(patient?.id).catch(handleError)
  const sendPartnerInvite = () => sendInvite.mutateAsync(patient?.id).catch(handleError)

  const viewDetails = useDialog({
    component: InsurancePartnerDialog,
    props: ({ item: data, close }) => ({
      data,
      onSetContacted: () => handleSetContacted().then(close),
      onSetInsured: () => handleSetInsured().then(close),
      onReject: () => close(),
    }),
  })

  if (isLoading || !patient)
    return (
      <Typography color="secondary">
        <Skeleton />
      </Typography>
    )

  if (!insurance.cboPartneredWithPrideLife && !insurance.cboPartneredWithAmericanExchange) return null

  const currentPartner = insurance.cboInsurancePartners?.find((partner) => partner.current)
  const previousPartner = insurance.cboInsurancePartners?.find((partner) => !partner.current)

  return (
    <>
      {currentPartner ? (
        <Partner label="Current Partnership" data={currentPartner} onDetails={() => viewDetails(currentPartner)} />
      ) : (
        <Partner
          label="Current Partnership"
          data={{ partner: insurance.cboPartneredWithPrideLife ? InsurancePartners.PrideLife : InsurancePartners.AmericanExchange }}
          onSendInvite={sendPartnerInvite}
        />
      )}
      {previousPartner && !currentPartner && (
        <Partner label="Previous Partnership" data={previousPartner} onDetails={() => viewDetails(previousPartner)} />
      )}
    </>
  )
}

function Partner({ label, data, onDetails, onSendInvite }) {
  return (
    <Property
      label={label}
      value={
        <Stack
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Typography color="secondary">{startCase(data.partner)}</Typography>
          {data.referredDate ? (
            <>
              <Typography color="secondary">{dayjs(data.referredDate).format('L')}</Typography>
              <IconButton size="medium" onClick={onDetails}>
                <EyeOutlinedIcon />
              </IconButton>
            </>
          ) : (
            onSendInvite && (
              <Button variant="contained" size="small" onClick={onSendInvite}>
                Send Invitation
              </Button>
            )
          )}
        </Stack>
      }
    />
  )
}

function InsurancePartnerDialog({ data, open, onClose, onSetContacted, onSetInsured, loading = false }) {
  const isCurrentPartner = data.current

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>{startCase(data.partner)}</DialogTitle>
      <LinearProgress loading={loading} color="warning" />
      <DialogContent dividers>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
          <Typography color="secondary" sx={{ marginRight: 6 }}>
            Referred
          </Typography>
          <Typography>{data.referredDate ? dayjs(data.referredDate).format('L') : 'na'}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
          <Typography color="secondary" sx={{ marginRight: 6 }}>
            Contacted
          </Typography>
          {data.contactedDate ? (
            <Typography>{dayjs(data.contactedDate).format('L')}</Typography>
          ) : isCurrentPartner ? (
            <Button variant="contained" onClick={onSetContacted}>
              Set Date
            </Button>
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
          <Typography color="secondary" sx={{ marginRight: 6 }}>
            Insured
          </Typography>
          {data.insuredDate ? (
            <Typography>{dayjs(data.insuredDate).format('L')}</Typography>
          ) : isCurrentPartner ? (
            <Button variant="contained" onClick={onSetInsured}>
              Set Date
            </Button>
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={loading}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
