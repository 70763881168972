import { useMutation } from '@tanstack/react-query'
import { BooleanParam, createEnumParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

/**
 * Hook to retrieve a patients encounters.
 *
 * @param {string} patientId - the patient id
 * @param {object} query - Query params to pass to the API.
 * @param {object} options - Options to pass to the useQuery hook.
 */
export function usePatientEncounters(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).encounters.list(query),
    queryFn: () => API.patients.id(patientId).encounters.list(query),
    ...options,
  })
}

/**
 * Hook to create a patient encounter.
 *
 * @param {string} patientId - the patient id
 */
export function usePatientEncounterCreate(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).encounters.create(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).encounters.lists }),
  })
}

/**
 * Hook to remove a patient encounter.
 * @param {string} patientId - the patient id
 */
export function usePatientEncounterRemove(patientId) {
  return useMutation({
    mutationFn: (encounterId) => API.patients.id(patientId).encounters.id(encounterId).remove(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).encounters.lists }),
  })
}

const SortParam = createEnumParam(['asc', 'desc'])

/**
 * Hook to set and retrieve the encounters filters.
 */
export function usePatientEncountersFilters() {
  return useFiltering({
    encountersExpanded: withDefault(BooleanParam, false),
    encountersSort: withDefault(SortParam, 'desc'),
  })
}

export const patientEncountersFiltersResetState = { encountersExpanded: undefined, encountersSort: undefined }
