import { useMemo } from 'react'
import dayjs from 'dayjs'

import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { CarOutlined } from '@icons'
import Avatar from '@components/Avatar'
import Property from '@components/Details/Property'
import Section from '@components/Details/Section'

const styles = {
  container: {
    p: 0,
    '& .MuiTimelineItem-root:before': {
      flex: 0,
      padding: 0,
    },
  },
  dot: {
    p: 0,
    borderWidth: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export default function ShippingSection({ data, mini = false }) {
  const hasShippingToLabInfo = data.shippedToLab || data.tracking?.inbound
  const hasShippingToPatientInfo = data.shippedToPatient || data.tracking?.outbound
  const hasShippingData = data.deliveredToLab || hasShippingToLabInfo || data.deliveredToPatient || hasShippingToPatientInfo

  return (
    <Section mini={mini} title="Shipping" icon={mini ? null : <CarOutlined />}>
      <Property label="Shipping Address">
        {data.address}
        <br />
        {`${data.city}, ${data.state} ${data.zip}`}
      </Property>
      {!hasShippingData && <Typography>The lab has not been shipped to the patient yet</Typography>}
      {hasShippingData && (
        <Timeline sx={styles.container}>
          {data.deliveredToLab && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="outlined" sx={styles.dot}>
                  <Avatar.Company />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={styles.content}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                  <b>Delivered to Lab</b>
                </Typography>
                {data.deliveredToLab && (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {dayjs(data.deliveredToLab).format('L LT')}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          )}

          {hasShippingToLabInfo && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" sx={styles.dot}>
                  <Avatar.Company />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={styles.content}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                  <b>Shipped to Lab</b>
                </Typography>
                <TrackingNumber url={data.mtlInboundUrl} tracking={data.tracking?.inbound} />
                {data.shippedToLab && (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {dayjs(data.shippedToLab).format('L LT')}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          )}

          {data.deliveredToPatient && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" sx={styles.dot}>
                  <Avatar.Company />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={styles.content}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                  <b>Delivered to Patient</b>
                </Typography>
                {data.deliveredToPatient && (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {dayjs(data.deliveredToPatient).format('L LT')}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          )}

          {hasShippingToPatientInfo && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" sx={styles.dot}>
                  <Avatar.Company />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={styles.content}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                  <b>Shipped to Patient</b>
                </Typography>
                <TrackingNumber url={data.mtlUrl} tracking={data.tracking?.outbound} />
                {data.shippedToPatient && (
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {dayjs(data.shippedToPatient).format('L LT')}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      )}
    </Section>
  )
}

ShippingSection.Loading = ({ mini = false }) => (
  <Section mini={mini} title="Shipping" icon={mini ? null : <CarOutlined />}>
    <Property label="Shipping Address">
      <Stack>
        <Typography>
          <Skeleton width={100} />
        </Typography>
        <Typography>
          <Skeleton width={200} />
        </Typography>
      </Stack>
    </Property>
    <Timeline sx={styles.container}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined" sx={styles.dot}>
            <Skeleton variant="circular" width={40} height={40} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle1">
            <Skeleton width={200} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={120} />
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined" sx={styles.dot}>
            <Skeleton variant="circular" width={40} height={40} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle1">
            <Skeleton width={200} />
          </Typography>
          <Typography>
            <Skeleton width={300} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={120} />
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  </Section>
)

function TrackingNumber({ url, tracking }) {
  const content = useMemo(() => {
    if (url && tracking) {
      return (
        <Link target="_blank" href={url}>
          {tracking}
        </Link>
      )
    }
    if (url) {
      return (
        <Link target="_blank" href={url}>
          {url}
        </Link>
      )
    }
    if (tracking) {
      return <Typography>{tracking}</Typography>
    }
    return null
  }, [tracking, url])

  if (!content) return null

  return (
    <Stack direction="row" spacing={1}>
      <Typography>Tracking number</Typography>
      {content}
    </Stack>
  )
}
