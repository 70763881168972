import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import { createEnumParam, NumberParam } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import { useMe } from '@shared/providers/src/MeProvider'
import API from '@shared/services/src/API'
import { flatten, pageParam, QK, TaskStatus } from '@shared/utils'

import { FilterType } from '@components/TableFilters'

const LIMIT = 20

const TypeParam = createEnumParam(['expired', 'declined'])

export const usePARsFilters = () => useFiltering({ par: NumberParam, boardParsType: TypeParam })

export function usePARTasks({ options = {} } = {}) {
  const me = useMe()
  const [{ boardParsType }] = usePARsFilters()

  const providerId = me.provider.id
  const query = { status: TaskStatus.InProgress, quarterly_status: boardParsType, limit: LIMIT }

  return useInfiniteQuery({
    queryKey: QK.providers.id(providerId).dashboard.parTasks.list(query),
    queryFn: ({ pageParam }) => API.providers.id(providerId).par.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    placeholderData: keepPreviousData,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}

export const schema = [
  {
    key: 'boardParsType',
    label: 'Type',
    type: FilterType.SELECT,
    options: [
      { label: 'Expired', value: 'expired' },
      { label: 'Declined', value: 'declined' },
    ],
  },
]
