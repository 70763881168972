import { useEffect, useMemo, useRef, useState } from 'react'
import { isMacOs } from 'react-device-detect'
import { useLocalStorage } from 'usehooks-ts'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDraft from '@shared/hooks/src/useDraft'
import { richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { processRichTextJsonToMessage } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { LockOutlinedIcon, SendOutlinedIcon } from '@icons'
import { getExtensions, RichTextEditor } from '@components/RichText'
import KeyboardHandlerExtension from '@components/RichText/KeyboardHandlerExtension'

import { ReplyTo, SendOnBehalfSelector } from './ThreadMessageComposer.utils'

/**
 * ThreadMessageComposer  - component to send messages in a thread
 *
 * @param mini - boolean to show a smaller composer
 * @param enableMentions - boolean to enable mentions
 * @param threadId - thread id
 * @param users - list of users available for sending on behalf
 * @param replyMessage - message to reply to
 * @param onReplyCancel - function to cancel reply
 * @param unavailable - message to show when thread is unavailable
 * @param disabled - boolean to disable the input
 * @param onSend - function to send the message
 */
export default function ThreadMessageComposer({
  mini = false,
  enableMentions = false,
  threadId = 'new',
  users = [],
  replyMessage,
  onReplyCancel,
  unavailable,
  disabled,
  onSend,
}) {
  const editorRef = useRef()
  const handleSendRef = useRef()

  const { draft, setDraft, removeDraft } = useDraft(`thread-${threadId}`)
  const [sendOnEnter] = useLocalStorage('send-on-enter', true)

  const [isOnBehalf, setIsOnBehalf] = useState(false)
  const [onBehalfOf, setOnBehalfOf] = useState('')

  const editorExtensions = [
    // Should be before mention extension
    KeyboardHandlerExtension.configure({
      sendOnEnter,
      onSend: () => handleSendRef.current(),
    }),
    ...getExtensions({ conversationId: threadId, enableMentions, enableQPhrases: true }),
  ]

  const handleSend = () => {
    const message = editorRef.current?.getJSON()

    onSend({
      message: processRichTextJsonToMessage(message),
      fromUserId: isOnBehalf ? onBehalfOf : undefined,
      replyTo: replyMessage?.id,
    })
    onReplyCancel?.()
    editorRef.current?.commands.clearContent()
    removeDraft()
  }

  const handleUpdate = ({ editor }) => {
    editor.isEmpty ? removeDraft() : setDraft(editor.getJSON())
  }

  handleSendRef.current = handleSend

  useEffect(() => {
    if (replyMessage) {
      editorRef.current?.commands.focus()
    }
  }, [replyMessage])

  const placeholder = useMemo(() => {
    const enterText = isMacOs ? 'Return' : 'Enter'
    const mentionText = enableMentions ? "Use '@' to mention a user. " : ''

    return sendOnEnter
      ? `${mentionText}Press '${enterText}' to send, 'Shift + ${enterText}' to add a new line`
      : `${mentionText}Press '${enterText}' to add a new line`
  }, [enableMentions, sendOnEnter])

  if (unavailable) {
    return (
      <Fade in>
        <Typography sx={{ color: 'text.secondary', bgcolor: 'grey.200', px: 3, py: 2 }}>
          <LockOutlinedIcon style={{ marginRight: '8px' }} />
          {unavailable}
        </Typography>
      </Fade>
    )
  }

  const btnDisabled = editorRef.current?.isEmpty || disabled

  return (
    <Stack>
      <Collapse in={Boolean(replyMessage)} unmountOnExit>
        <ReplyTo message={replyMessage} onCancel={onReplyCancel} />
      </Collapse>
      <Collapse in={users.length > 0} unmountOnExit>
        <SendOnBehalfSelector
          users={users}
          isOnBehalf={isOnBehalf}
          setIsOnBehalf={setIsOnBehalf}
          onBehalfOf={onBehalfOf}
          setOnBehalfOf={setOnBehalfOf}
        />
      </Collapse>
      <Stack spacing={1} sx={outlineRichTextEditorStyling}>
        <Box sx={[richTextStyles, { '& .tiptap': { maxHeight: mini ? 100 : 300 } }]}>
          <RichTextEditor ref={editorRef} extensions={editorExtensions} initialValue={draft} onUpdate={handleUpdate} />
        </Box>
        <Stack direction="row" sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <FormHelperText>{placeholder}</FormHelperText>
          <IconButton color="primary" size="small" variant={btnDisabled ? 'text' : 'contained'} disabled={btnDisabled} onClick={handleSend}>
            <SendOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
