import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePossibleEncounterTypes(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).encounters.available.list(query),
    queryFn: () => API.patients.id(patientId).encounters.available(query),
    ...options,
  })
}
