import { useState } from 'react'
import dayjs from 'dayjs'

import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import IconButton from '@components/_mui/IconButton'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import useUserSetting from '@shared/hooks/src/useUserSetting'
import { useMe } from '@shared/providers/src/MeProvider'
import PatientProvider, { usePatient } from '@shared/providers/src/PatientProvider'
import { getPhoneFormatted, TaskStatus, toTitleCase, UserRole, UserSettingName } from '@shared/utils'

import { CBORuleSetDetailsDialog } from '@pages/CBOs'
import { CloseOutlinedIcon, LinkIcon, LinkOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import CopyToClipboard from '@components/CopyToClipboard'
import DateTooltip from '@components/DateTooltip'
import Tile from '@components/Tile'

import Property from '../../components/Property'
import SelectAssignee from '../../components/SelectAssignee'
import SelectPriority from '../../components/SelectPriority'
import SelectStatus from '../../components/SelectStatus'
import { useCommonFilters, useTask } from '../../Kanban.hooks'

export default function Details({ id, onClose }) {
  const { data, isPending } = useTask(id)

  if (isPending) return <Loading onClose={onClose} />

  return (
    <PatientProvider id={data.patientId}>
      <Tile
        title={
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
            }}
          >
            <Identifier task={data} />
            <IconButton onClick={onClose} color="inherit" edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        }
      >
        <Stack spacing={4} sx={{ p: 2 }}>
          <Task data={data} />
          <Divider>Care Team</Divider>
          <CareTeam />
          <Divider>Patient</Divider>
          <Patient />
        </Stack>
      </Tile>
    </PatientProvider>
  )
}

function Task({ data }) {
  const me = useMe()
  const disabled = [TaskStatus.Completed, TaskStatus.Expired].includes(data.status)

  return (
    <Stack spacing={2}>
      <Property label="Type">
        <Typography>{[data.kind, data.reason].filter(Boolean).join(' – ')}</Typography>
      </Property>
      <Property label="Created">
        <DateTooltip date={data.createdAt}>
          <Typography>{dayjs(data.createdAt).format('L')}</Typography>
        </DateTooltip>
      </Property>
      {data.status === TaskStatus.Completed && data.updatedAt && (
        <Property label="Completed">
          <DateTooltip date={data.updatedAt}>
            <Typography>{dayjs(data.updatedAt).format('L')}</Typography>
          </DateTooltip>
        </Property>
      )}
      {data.status === TaskStatus.Expired && data.updatedAt && (
        <Property label="Expired">
          <DateTooltip date={data.updatedAt}>
            <Typography>{dayjs(data.updatedAt).format('L')}</Typography>
          </DateTooltip>
        </Property>
      )}
      <Property label="Status">
        <SelectStatus
          task={data}
          disabled={disabled}
          canExpire={me.role === UserRole.Support && me.id === data.assignee?.id}
          canComplete={me.id === data.assignee?.id}
        />
      </Property>
      <Property label="Priority">
        <SelectPriority task={data} disabled={disabled} />
      </Property>
      <Property label="Created By">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {data.author ? (
            <>
              <Avatar size="xs" user={data.author} hover="card" />
              <Typography>{data.author.fullName}</Typography>
            </>
          ) : (
            <>
              <Avatar size="xs" user={{ fullName: 'Q P', firstName: 'Q', lastName: 'P' }} />
              <Typography>Q Care Plus</Typography>
            </>
          )}
        </Stack>
      </Property>
      <Property label="Assignee">
        <SelectAssignee task={data} disabled={disabled} />
      </Property>
    </Stack>
  )
}

function CareTeam() {
  const [cboDetailed, setCboDetailed] = useState(false)
  const patient = usePatient()

  if (!patient) return <CareTeam.Loading />

  return (
    <Stack spacing={2}>
      <CBORuleSetDetailsDialog id={patient.cbo?.id} slide open={cboDetailed} onClose={() => setCboDetailed(false)} />
      <Property label="Provider">
        {patient.provider && (
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar size="xs" user={patient.provider} hover="card" />
            <Typography>{patient.provider.fullName}</Typography>
          </Stack>
        )}
      </Property>
      <Property
        label={
          <Stack direction="row" spacing={1} sx={{ minWidth: 100 }}>
            <Typography>CBO</Typography>
            <IconButton
              variant="contained"
              size="small"
              disabled={!patient.cbo?.id}
              onClick={() => setCboDetailed(true)}
              sx={{ width: 24, height: 24 }}
            >
              <LinkIcon fontSize="12px" />
            </IconButton>
          </Stack>
        }
      >
        {patient.cbo && <Typography>{patient.cbo.name}</Typography>}
      </Property>
      <Property label="Pharmacy">{patient.pharmacy && <Typography>{patient.pharmacy.name}</Typography>}</Property>
    </Stack>
  )
}

function Patient() {
  const patient = usePatient()
  const contactMethodSetting = useUserSetting(patient, UserSettingName.ContactMethod)

  if (!patient) return <Patient.Loading />

  return (
    <Stack spacing={2}>
      <Property label="Patient">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Avatar size="xs" user={patient} />
          <Typography>{patient.fullName}</Typography>
        </Stack>
      </Property>
      <Property label="Phone">
        <Typography>{patient.phone ? getPhoneFormatted(patient.phone) : ''}</Typography>
      </Property>
      <Property label="Email">
        <Typography>{patient.email}</Typography>
      </Property>
      <Property label="Contact">
        {contactMethodSetting && <Typography>Prefers {toTitleCase(contactMethodSetting.value)}</Typography>}
      </Property>
    </Stack>
  )
}

Task.Loading = function () {
  return (
    <Stack spacing={2}>
      <Property label="Type">
        <Typography>
          <Skeleton width={80} />
        </Typography>
      </Property>
      <Property label="Created">
        <Typography>
          <Skeleton width={60} />
        </Typography>
      </Property>
      <Property label="Priority">
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Property>
      <Property label="Created By">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="rounded" width={24} height={24} />
          <Skeleton width={100} />
        </Stack>
      </Property>
      <Property label="Assignee">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="rounded" width={24} height={24} />
          <Skeleton width={100} />
        </Stack>
      </Property>
    </Stack>
  )
}

CareTeam.Loading = function () {
  return (
    <Stack spacing={2}>
      <Property label="Provider">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="rounded" width={24} height={24} />
          <Skeleton width={100} />
        </Stack>
      </Property>
      <Property label="CBO">
        <Typography>
          <Skeleton width={120} />
        </Typography>
      </Property>
      <Property label="Pharmacy">
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Property>
    </Stack>
  )
}

Patient.Loading = function () {
  return (
    <Stack spacing={2}>
      <Property label="Patient">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="rounded" width={24} height={24} />
          <Skeleton width={100} />
        </Stack>
      </Property>
      <Property label="Phone">
        <Typography>
          <Skeleton width={80} />
        </Typography>
      </Property>
      <Property label="Email">
        <Typography>
          <Skeleton width={120} />
        </Typography>
      </Property>
      <Property label="Contact">
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Property>
    </Stack>
  )
}

function Loading({ onClose }) {
  return (
    <Tile
      title={
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            py: 1,
          }}
        >
          <Link component="a" href="#" variant="h5">
            <Skeleton width={70} />
          </Link>
          <IconButton onClick={onClose} color="inherit" edge="end">
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>
      }
    >
      <Stack spacing={4} sx={{ p: 2 }}>
        <Task.Loading />
        <Divider>Care Team</Divider>
        <CareTeam.Loading />
        <Divider>Patient</Divider>
        <Patient.Loading />
      </Stack>
    </Tile>
  )
}

function Identifier({ task }) {
  const theme = useTheme()
  const [, updateFilters] = useCommonFilters()

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Link variant="h5" onClick={() => updateFilters({ task: task.identifier })} sx={{ cursor: 'pointer' }}>
        {task.identifier}
      </Link>
      <CopyToClipboard>
        {({ copy }) => (
          <LinkOutlinedIcon
            onClick={() => copy(`${import.meta.env.VITE_URL}/redirect?to=${import.meta.env.VITE_URL}/app?task=${task.identifier}`)}
            style={{ color: theme.palette.primary.main }}
          />
        )}
      </CopyToClipboard>
    </Stack>
  )
}
