import Grid from '@mui/material/Grid2'

import Assessments from './Assessments'
import Encounters from './Encounters'
import Labs from './Labs'
import Notes from './Notes'
import Rx from './Rx'
import Status from './Status'
import Tasks from './Tasks'

export default function ProfileTab() {
  return (
    <>
      <Grid size={{ xs: 12, sm: 12, md: 5 }}>
        <Status />
      </Grid>
      <Grid container spacing={2} size={{ xs: 12, sm: 12, md: 7 }}>
        <Grid size={12}>
          <Labs />
        </Grid>
        <Grid size={12}>
          <Assessments />
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
        <Rx />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
        <Encounters />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
        <Tasks />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
        <Notes />
      </Grid>
    </>
  )
}
