import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'

import { TaskKind } from '@shared/utils'

import { MoreOutlinedIcon } from '@icons'
import TableFilters from '@components/TableFilters'

import PARCard from '../components/PARCard'
import { Empty, MobileDetailsAdaptation, styles } from '../Provider.utils'
import TaskCard from '../Tasks/components/TaskCard'
import { HIVInfo, PatientAtRiskInfo, PEPInfo } from './components/TaskInfo'
import { schema, usePARsFilters, usePARTasks } from './PatientAtRisk.hooks'

export function List() {
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = usePARTasks()
  const [{ par, boardParsType }, updateFilters] = usePARsFilters()

  // Show loader only if there is no data and we are fetching
  const showLoading = !data?.length && isFetching
  const showNotFound = !showLoading && data?.length === 0 && boardParsType
  const showEmpty = !showLoading && data?.length === 0 && !boardParsType
  const showData = !showEmpty && data?.length > 0

  return (
    <Stack sx={styles.list}>
      <TableFilters schema={schema} values={{ boardParsType }} onChange={updateFilters} sx={{ minHeight: 50 }} />
      <Divider />
      <Stack spacing={2} sx={[styles.list, isFetching && styles.refreshing, { pt: 2 }]}>
        {showLoading && (
          <>
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
            <TaskCard.Loading />
          </>
        )}
        {showEmpty && <Empty>You don’t have any 'Patient At Risk' tasks to review :)</Empty>}
        {showNotFound && <Empty>Nothing found by selected filter</Empty>}
        {showData && (
          <>
            {data.map((t) => (
              <TaskCard key={t.id} data={t} selected={par === t.id} onClick={() => updateFilters({ par: t.id })} />
            ))}
            {hasNextPage && (
              <Button
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
              >
                more
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export function Details() {
  const { data, isFetched } = usePARTasks({ enabled: false })
  const [{ par }, updateFilters] = usePARsFilters()

  const selectedTask = data?.find((t) => t.id === par)

  const showLoading = !selectedTask && par && !isFetched
  const showData = !showLoading && selectedTask

  return (
    <MobileDetailsAdaptation open={Boolean(par)} onClose={() => updateFilters({ par: undefined })}>
      {showLoading && <PARCard.Loading />}
      {showData && <ParTaskContent task={selectedTask} />}
    </MobileDetailsAdaptation>
  )
}

export function TaskDialog({ task, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {task && (
        <>
          <DialogTitle onClose={onClose}>{task?.identifier}</DialogTitle>
          <ParTaskContent task={task} />
        </>
      )}
    </Dialog>
  )
}

function ParTaskContent({ task }) {
  const TaskInfo = taskInfoFabric(task)

  if (!TaskInfo) return null

  return (
    <PARCard
      title={task.kind}
      patientId={task.patientId}
      encounterId={task.relatedModelType === 'Encounter' ? task.relatedModelId : undefined}
    >
      {({ patient }) => <TaskInfo task={task} patient={patient} />}
    </PARCard>
  )
}

function taskInfoFabric(task) {
  if (task?.kind === TaskKind.PAR) {
    return PatientAtRiskInfo
  }
  if (task?.kind === TaskKind.PEP_PAR) {
    return PEPInfo
  }
  if (task?.kind === TaskKind.HIV_PAR) {
    return HIVInfo
  }
  return null
}
