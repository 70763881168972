import { useMemo } from 'react'
import { Document, Thumbnail } from 'react-pdf'

import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'
import { base64toBlob } from '@shared/utils'

import { FileOutlinedIcon, FileSearchOutlinedIcon } from '@icons'
import Section from '@components/Details/Section'
import FileHover from '@components/FileHover'

import { usePatientLabFile } from '../Lab.hooks'

export default function RequisitionsSection({ data, mini = false }) {
  const filesAvailable = data.orderFiles?.length > 0 || data.resultFiles?.length > 0 || data.reviewFiles?.length > 0

  return (
    <Section
      mini={mini}
      title="Requisition"
      icon={mini ? null : <FileSearchOutlinedIcon />}
      action={data.requisition ? <Typography variant="h5">{`ID #${data.requisition}`}</Typography> : null}
    >
      {!filesAvailable && <Typography>No files available</Typography>}
      {filesAvailable && (
        <Stack direction="row" spacing={3} sx={{ '& .react-pdf__message': { height: 200 } }}>
          {data.orderFiles?.map((file) => (
            <FileWrap key={file.uid} title="Order">
              <FilePreview patientId={data.user?.id} file={file} />
            </FileWrap>
          ))}
          {data.resultFiles?.map((file) => (
            <FileWrap key={file.uid} title="Results">
              <FilePreview patientId={data.user?.id} file={file} />
            </FileWrap>
          ))}
          {data.reviewFiles?.map((file) => (
            <FileWrap key={file.uid} title="Reviews">
              <FilePreview patientId={data.user?.id} file={file} />
            </FileWrap>
          ))}
        </Stack>
      )}
    </Section>
  )
}

RequisitionsSection.Loading = ({ mini = false }) => (
  <Section
    mini={mini}
    title="Requisition"
    icon={mini ? null : <FileSearchOutlinedIcon />}
    action={
      <Typography variant="h5">
        <Skeleton width={100} />
      </Typography>
    }
  >
    <Stack direction="row" spacing={3}>
      <FileWrap title={<Skeleton width={60} />}>
        <FilePreviewLoading />
      </FileWrap>
      <FileWrap title={<Skeleton width={80} />}>
        <FilePreviewLoading />
      </FileWrap>
    </Stack>
  </Section>
)

function FileWrap({ title, children }) {
  return (
    <Stack spacing={1}>
      <Typography variant="h5" sx={{ fontWeight: 'light' }}>
        {title}
      </Typography>
      <Paper
        variant="outlined"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: 150,
          height: 200,
        }}
      >
        {children}
      </Paper>
    </Stack>
  )
}

function FilePreview({ patientId, file }) {
  const { mimetype } = file

  const isImage = mimetype?.startsWith('image')
  const isPdf = mimetype === 'application/pdf'

  if (isImage) {
    return (
      <Link target="_blank" href={file.url}>
        <Image fit="cover" duration={500} src={file.url} errorIcon={<FileOutlinedIcon />} />
        <FileHover />
      </Link>
    )
  }

  if (isPdf) {
    return (
      <Link target="_blank" href={file.url}>
        <PdfThumbnail patientId={patientId} fileId={file.id} />
        <FileHover />
      </Link>
    )
  }

  return (
    <Link target="_blank" href={file.url}>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          textAlign: 'center',
        }}
      >
        File type <br /> not supported
      </Stack>
      <FileHover />
    </Link>
  )
}

function PdfThumbnail({ patientId, fileId }) {
  const { data: file, isPending } = usePatientLabFile(patientId, fileId)

  const pdf = useMemo(() => {
    if (isPending || !file) return null
    return base64toBlob(file.content, file.mimetype)
  }, [file, isPending])

  if (isPending) return <FilePreviewLoading />

  return (
    <Document file={pdf}>
      <Thumbnail pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={150} />
    </Document>
  )
}

const FilePreviewLoading = () => <Skeleton variant="rounded" width={150} height={200} />
