import { useInfiniteQuery } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { flatten, pageParam, QK } from '@shared/utils'

const ENCOUNTERS_LIMIT = 20

export function useEncounters(patientId, enabled) {
  const query = { order: 'desc', limit: ENCOUNTERS_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.patients.id(patientId).encounters.list(query),
    queryFn: ({ pageParam }) => API.patients.id(patientId).encounters.list({ ...query, offset: pageParam * ENCOUNTERS_LIMIT }),
    enabled,
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(ENCOUNTERS_LIMIT),
  })
}
