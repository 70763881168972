import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { filterCache, QK } from '@shared/utils'

export function useFiles(taskId) {
  const query = { sort: 'created_at', order: 'desc' }
  return useQuery({
    queryKey: QK.tasks.id(taskId).documents.list(query),
    queryFn: () => API.tasks.id(taskId).documents.list(query),
  })
}

export function useDocumentRemove(taskId) {
  return useMutation({
    mutationFn: (documentId) => API.tasks.id(taskId).documents.id(documentId).remove(),
    onSuccess: (data, documentId) => {
      queryClient.setQueriesData(
        { queryKey: QK.tasks.id(taskId).documents.lists },
        filterCache((d) => d.id !== documentId)
      )
    },
  })
}
