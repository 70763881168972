import { useMemo, useState } from 'react'
import { useParams } from 'react-router'

import MenuMui from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

import { getUserSetting } from '@shared/hooks/src/useUserSetting'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { handleError, UserRole, UserSettingName } from '@shared/utils'

import { MoreHorizontalIcon } from '@icons'
import { usePatientAccessible } from '@components/PatientAccessGuard'

import { IconCircleButton } from '../../ProfileTab.utils'
import GileadApplication from '../GileadApplication'
import { UpdateType, useUserStatusUpdate } from '../Status.hooks'
import ChangeStatusDialog from './ChangeStatusDialog'

function Menu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [reasonDialog, setReasonDialog] = useState(null)

  const open = Boolean(anchorEl)
  const isReasonDialogOpen = Boolean(reasonDialog)

  const patient = usePatient()

  const enrolled = patient?.enrolled
  const active = patient?.active
  const disabled = patient?.disabled

  const statusTooltip = useMemo(() => {
    const agreeToTermsOfService = getUserSetting(patient, UserSettingName.AcceptTermsOfService)
    const agreePrivacyPolicy = getUserSetting(patient, UserSettingName.AcceptPrivacyPolicy)
    const agreeToTelehealthConsent = getUserSetting(patient, UserSettingName.AcceptTelehealthConsent)
    const agreeToShareDataWithCBO = getUserSetting(patient, UserSettingName.AllowSharingHealthData)
    const consentToTreat = getUserSetting(patient, UserSettingName.Initials)

    if (!agreeToTermsOfService?.value) return 'Patient has not agreed to the Terms of Service'
    if (!agreePrivacyPolicy?.value) return 'Patient has not agreed to the Privacy Policy'
    if (!agreeToTelehealthConsent?.value) return 'Patient has not agreed to the Telehealth Consent'
    if (!agreeToShareDataWithCBO?.value) return 'Patient has not agreed to share data with CBO'
    if (!consentToTreat?.value) return 'Patient has not consented to treatment'
  }, [patient])

  const updateUserStatus = useUserStatusUpdate()

  const handleMenuOpen = (event) => setAnchorEl(event?.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleMakeActive = () => {
    updateUserStatus.mutateAsync({ type: UpdateType.Activate }).catch(handleError)
  }

  const handleConfirm = ({ reason, details }) => {
    return updateUserStatus
      .mutateAsync({
        type: reasonDialog?.status === 'archived' ? UpdateType.Deactivate : UpdateType.Disable,
        data: { reason, reason_detail: details },
      })
      .then(() => setReasonDialog(null))
      .catch(handleError)
  }

  return (
    <>
      <IconCircleButton
        id="status-menu-button"
        aria-controls={open ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
        disabled={!patient}
        size="small"
        color="inherit"
      >
        <MoreHorizontalIcon />
      </IconCircleButton>
      <MenuMui
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{ 'aria-labelledby': 'status-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <RoleGuard prohibited={[UserRole.Pharmacy]}>
          {enrolled && (
            <Tooltip title={!active ? statusTooltip : undefined}>
              <div>
                <MenuItem
                  disabled={!active ? Boolean(statusTooltip) : false}
                  onClick={() => {
                    handleMenuClose()
                    !active
                      ? handleMakeActive()
                      : setReasonDialog({
                          title: active ? 'Set Inactive?' : 'Set Active?',
                          status: active ? 'archived' : 'active',
                        })
                  }}
                >
                  Set {!active ? 'Active' : 'Inactive'}
                </MenuItem>
              </div>
            </Tooltip>
          )}
        </RoleGuard>
        <RoleGuard prohibited={[UserRole.Provider, UserRole.Pharmacy]}>
          <Tooltip title={disabled ? statusTooltip : undefined}>
            <div>
              <MenuItem
                disabled={disabled ? Boolean(statusTooltip) : false}
                onClick={() => {
                  handleMenuClose()
                  disabled
                    ? handleMakeActive()
                    : setReasonDialog({
                        title: disabled ? 'Set Enabled?' : 'Set Disabled?',
                        status: disabled ? 'active' : 'deleted',
                      })
                }}
                sx={disabled ? undefined : { color: 'text.danger' }}
              >
                {disabled ? 'Enable' : 'Disable'}
              </MenuItem>
            </div>
          </Tooltip>
        </RoleGuard>
        <GileadApplication />
      </MenuMui>
      <ChangeStatusDialog
        open={isReasonDialogOpen}
        title={reasonDialog?.title}
        onClose={() => setReasonDialog(null)}
        onConfirm={handleConfirm}
      />
    </>
  )
}

export default function Wrap() {
  const { id } = useParams()
  const patient = usePatient()
  const patientAccessible = usePatientAccessible(id)

  return (
    <RoleGuard
      predicate={(me) => {
        if (me.role === UserRole.Manager) return false
        if (me.role === UserRole.Provider && !patient?.enrolled) return false
        return patientAccessible
      }}
    >
      <Menu />
    </RoleGuard>
  )
}
