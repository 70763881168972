import { useEffect, useState } from 'react'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { convertPossibleRichTextToPlainText, userRoleToLabel } from '@shared/utils'

import { CloseOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'

export const styles = {
  topping: {
    alignItems: 'center',
    borderLeft: '3px solid',
    borderColor: 'primary.main',
    pl: 1,
    mx: 1,
  },
}

export function ReplyTo({ message, onCancel }) {
  const [storedMessage, setStoredMessage] = useState(message)

  useEffect(() => {
    if (message) setStoredMessage(message)
  }, [message])

  return (
    <Stack direction="row" sx={[styles.topping, { justifyContent: 'space-between' }]}>
      <Stack sx={{ display: 'inline-grid' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Replying to {storedMessage.user.fullName}
        </Typography>
        <Typography variant="body2" noWrap>
          {convertPossibleRichTextToPlainText(storedMessage.message)}
        </Typography>
      </Stack>
      <IconButton onClick={onCancel}>
        <CloseOutlinedIcon />
      </IconButton>
    </Stack>
  )
}

export function SendOnBehalfSelector({ users, isOnBehalf, setIsOnBehalf, onBehalfOf, setOnBehalfOf }) {
  return (
    <Stack direction="row" sx={styles.topping}>
      <FormControlLabel
        label="Send on behalf of"
        control={<Checkbox checked={isOnBehalf} onChange={(e, v) => setIsOnBehalf(v)} />}
        value="end"
        labelPlacement="end"
      />
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        select
        disabled={!isOnBehalf}
        value={onBehalfOf}
        onChange={(e) => setOnBehalfOf(e.target.value)}
        slotProps={{
          select: {
            renderValue: (id) => users.find((u) => u.id === id)?.fullName,
          },
        }}
        sx={{ maxWidth: 200 }}
      >
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Avatar user={user} />
              <Stack spacing={-0.5}>
                <Typography>{user.fullName}</Typography>
                <Typography sx={{ color: 'text.secondary' }}>{userRoleToLabel[user.role]}</Typography>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  )
}
