import { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import range from 'lodash/range'

import CardActionArea from '@mui/material/CardActionArea'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePatientEncounter from '@hooks/usePatientEncounter'

import { useEncounters } from './EncounterUpdate.hooks'

export default function EncounterUpdate({ readOnly, patientId, encounter, encounterId, onChange }) {
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [currentEncounter, setCurrentEncounter] = useState(encounter)

  const { data: encounterData, isFetching: isFetchingEncounter } = usePatientEncounter(patientId, encounterId, {
    enabled: !currentEncounter && Boolean(encounterId),
  })
  const {
    data: encountersData,
    isPending: areEncountersPending,
    fetchNextPage: fetchNextEncounters,
    hasNextPage: hasMoreEncounters,
    isFetchingNextPage: isFetchingNextEncounters,
  } = useEncounters(patientId, open)

  useEffect(() => {
    if (!currentEncounter && encounterData) {
      setCurrentEncounter(encounterData)
    }
  }, [currentEncounter, encounterData])

  const handleChange = (newEncounter) => {
    if (currentEncounter?.id === newEncounter?.id) return
    setOpen(false)
    setCurrentEncounter(newEncounter)
    onChange(newEncounter?.id)
  }

  const subtitle = useMemo(() => {
    if (currentEncounter?.startTime) {
      return dayjs(currentEncounter.startTime).format('L')
    }
    return readOnly ? null : 'Please select one'
  }, [currentEncounter?.startTime, readOnly])

  if (isFetchingEncounter) return <EncounterUpdate.Loading />

  const showLoading = areEncountersPending
  const showEmpty = !showLoading && encountersData?.length === 0
  const showData = !showEmpty && encountersData?.length > 0

  return (
    <>
      <CardActionArea ref={anchorRef} disabled={readOnly} onClick={() => setOpen(true)} sx={{ p: 1, m: -1 }}>
        <Stack>
          <Typography>{currentEncounter ? `${currentEncounter.description}, #${currentEncounter.id}` : 'No Encounter'}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        </Stack>
      </CardActionArea>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{ paper: { sx: { width: 300, overflow: 'hidden' } } }}
      >
        <List disablePadding sx={{ overflowY: 'scroll', maxHeight: '50vh' }}>
          {showLoading && <LoadingList />}
          {showEmpty && <EmptyList />}
          {showData && (
            <>
              {encountersData.map((e) => (
                <ListItemButton key={e.id} divider onClick={() => handleChange(e)} selected={currentEncounter?.id === e.id}>
                  <ListItemText
                    primary={`${e.description}, #${e.id}`}
                    secondary={e.createdAt ? dayjs(e?.createdAt).format('L') : undefined}
                  />
                </ListItemButton>
              ))}
              {hasMoreEncounters && (
                <ListItemButton disabled={isFetchingNextEncounters} onClick={() => fetchNextEncounters()}>
                  <ListItemText primary="Load more" />
                </ListItemButton>
              )}
            </>
          )}
        </List>
      </Popover>
    </>
  )
}

const EmptyList = () => (
  <ListItemButton disabled>
    <ListItemText primary="No encounters found" />
  </ListItemButton>
)

const LoadingList = () =>
  range(5).map((i) => (
    <ListItemButton key={i} disabled divider>
      <ListItemText primary={<Skeleton width={200} />} secondary={<Skeleton width={100} />} />
    </ListItemButton>
  ))

EncounterUpdate.Loading = () => (
  <Stack>
    <Typography>
      <Skeleton width={200} />
    </Typography>
    <Typography>
      <Skeleton width={100} />
    </Typography>
  </Stack>
)
