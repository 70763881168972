import { useState } from 'react'
import PropTypes from 'prop-types'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { CloseOutlinedIcon } from '@icons'
import AvatarPatient from '@components/AvatarPatient'
import DocTitle from '@components/DocTitle'
import { SlideUp } from '@components/Transitions'

import { TabPanel } from './LabsOrderModal.utils'
import Details from './sections/Details'
import Flow from './sections/Flow'
import LabsHistory from './sections/LabsHistory'
import Order from './sections/Order'

LabsOrderModal.propTypes = {
  /** The patient the labs will be associated with */
  patient: PropTypes.object.isRequired,

  /** The encounter the labs will be associated with */
  encounter: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,

  /** When true, the modal will slide up from the bottom */
  slide: PropTypes.bool,

  /** When true, modal will be displayed */
  open: PropTypes.bool,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,
}

export default function LabsOrderModal({ open = false, slide = false, patient, encounter, onClose }) {
  return (
    <Dialog
      open={open}
      fullScreen
      disableEscapeKeyDown
      TransitionComponent={slide ? SlideUp : undefined}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
    >
      <DocTitle title="Order Labwork" />
      <AppBar sx={{ position: 'relative', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseOutlinedIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            Order Labwork
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Content patient={patient} encounter={encounter} onClose={onClose} />
      </Container>
    </Dialog>
  )
}

function Content({ patient, encounter, onClose }) {
  const [tab, setTab] = useState('flow')

  return (
    <Grid container spacing={{ xs: 1, sm: 2 }}>
      <Grid size={{ xs: 12, md: 8 }}>
        <Paper variant="outlined">
          <Stack>
            <Box sx={{ p: 2 }}>
              <AvatarPatient patient={patient} newTab />
            </Box>
            <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
              <Tab id="flow-tab" aria-controls="flow-panel" value="flow" label="Flow" />
              <Tab id="history-tab" aria-controls="history-panel" value="history" label="History" />
            </Tabs>
            <Divider />
            <TabPanel id="flow-panel" aria-controls="flow-tab" value={tab}>
              <Flow patient={patient} encounter={encounter} />
            </TabPanel>
            <TabPanel id="history-panel" aria-controls="history-tab" value={tab}>
              <LabsHistory patient={patient} />
            </TabPanel>
          </Stack>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <Stack spacing={2}>
          <Details patient={patient} encounter={encounter} />
          <Order patient={patient} encounter={encounter} onClose={onClose} />
        </Stack>
      </Grid>
    </Grid>
  )
}
