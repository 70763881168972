import { useMemo } from 'react'

import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePatient from '@shared/hooks/src/usePatient'

import useInsuranceSummary from '@hooks/useInsuranceSummary'
import { InsuranceCardPopper } from '@components/InsuranceCard'

import RequiredFields from '../RequiredFields'
import { FAQIcon, PatientHeader, Property, styles } from './PatientCard.utils'

export default function PatientCard({ patientId, title, children }) {
  const { data: patient, isPending } = usePatient(patientId)

  const content = useMemo(() => {
    if (typeof children === 'function') return children({ patient })
    return children
  }, [children, patient])

  if (isPending) return <Loading />

  return (
    <Paper variant="outlined" sx={styles.container}>
      <Stack spacing={3}>
        <PatientHeader user={patient} />
        <Typography variant="h4">{title}</Typography>
        <Stack spacing={1}>
          <Property label="Location">{[patient.homeAddress?.city, patient.homeAddress?.state].filter(Boolean).join(', ')}</Property>
          <Property label="Language">{patient.language || '-'}</Property>
          <Property label="Insurance">
            <Insurance patientId={patientId} />
          </Property>
          <Property label="CBO">{patient.cbo?.name || '-'}</Property>
          <Property label="Pharmacy">{patient.pharmacy?.name || '-'}</Property>
          <Property label="Gender">{patient.gender || '-'}</Property>
          <Property label="Born As">{patient.bornAs || '-'}</Property>
        </Stack>
        {content}
      </Stack>
    </Paper>
  )
}

function Loading() {
  return (
    <Paper variant="outlined" sx={styles.container}>
      <Stack spacing={3}>
        <PatientHeader.Loading />
        <Typography variant="h4">
          <Skeleton width={160} />
        </Typography>
        <Stack spacing={1}>
          <Property.Loading width={120} />
          <Property.Loading width={100} />
          <Property.Loading width={140} />
          <Property.Loading width={130} />
          <Property.Loading width={120} />
          <Property.Loading width={80} />
          <Property.Loading width={70} />
        </Stack>
        <RequiredFields.Loading />
      </Stack>
    </Paper>
  )
}

PatientCard.Loading = Loading

function Insurance({ patientId }) {
  const { data, isPending } = useInsuranceSummary(patientId)

  if (isPending) {
    return (
      <Typography>
        <Skeleton width={140} />
      </Typography>
    )
  }

  if (!data?.lastActiveInsurance) {
    return <Typography>-</Typography>
  }

  return (
    <InsuranceCardPopper patientId={patientId} insuranceId={data.lastActiveInsurance.id}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography>{data.lastActiveInsurance.company}</Typography>
        <FAQIcon />
      </Stack>
    </InsuranceCardPopper>
  )
}
