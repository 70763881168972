import { useState } from 'react'
import { range } from 'lodash'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useDialog from '@shared/hooks/src/useDialog'
import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { handleError, UserRole } from '@shared/utils'

import { CloseOutlinedIcon, FileAddOutlinedIcon, MoreOutlinedIcon } from '@icons'
import Confirmation from '@components/Dialog/Confirmation'
import LinearProgress from '@components/LinearProgress'
import MainCard from '@components/MainCard'
import SearchInput from '@components/SearchInput'

import { useQPhraseCreation, useQPhraseDelete, useQPhrases, useQPhrasesFilters, useQPhraseUpdate } from './QPhrases.hooks'
import { QPhrase, QPhraseForm } from './QPhrases.utils'
import QPhrasesImport from './QPhrasesImport'

const styles = {
  card: {
    border: 'none',
    borderRadius: 0,
    height: '100vh',
    '& .MuiCardHeader-root': {
      color: 'primary.contrastText',
      bgcolor: 'primary.main',
      '& .MuiTypography-root': { fontSize: '1rem' },
    },
  },
}

export default function QPhrasesDrawer({ open, onClose }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: 1300 }}
      PaperProps={{ sx: { maxWidth: 600, width: '100%' } }}
      data-testid="drawer-q-phrases"
    >
      <MainCard
        title="Q Phrases"
        secondary={
          <IconButton shape="rounded" color="inherit" onClick={onClose} data-testid="close-q-phrases-drawer">
            <CloseOutlinedIcon />
          </IconButton>
        }
        sx={styles.card}
        content={false}
        data-testid="q-phrases-drawer"
      >
        <Content />
      </MainCard>
    </Drawer>
  )
}

function Content() {
  const [adding, setAdding] = useState(false)

  const me = useMe()
  const [{ phrases, phrasesSearch }, updateFilters] = useQPhrasesFilters()
  const { data, isPending, isRefreshing, fetchNextPage, hasNextPage, isFetchingNextPage } = useQPhrases()

  const create = useQPhraseCreation()
  const update = useQPhraseUpdate()
  const remove = useQPhraseDelete()

  const hasSearch = Boolean(phrasesSearch)
  const isEmpty = !isPending && !hasSearch && data.length === 0
  const isEmptySearch = !isPending && hasSearch && data.length === 0
  const hasData = !isPending && !isEmpty && !isEmptySearch

  const isPersonal = phrases === 'personal'
  const isEditingAllowed = isPersonal || isUserAllowed(me, [UserRole.Admin])

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ item, close }) => ({
      title: 'Remove QPhrase',
      description: `Are you sure you want to remove the "${item.title}" QPhrase?`,
      rejectLabel: 'Cancel',
      confirmLabel: 'Remove',
      onReject: () => close(),
      onConfirm: () =>
        remove
          .mutateAsync(item.id)
          .then(close)
          .catch((e) => handleError(e, { showResponse: true })),
    }),
  })

  return (
    <Stack sx={{ height: '100%', overflowY: 'auto', pb: 12 }}>
      <LinearProgress loading={isRefreshing} color="warning" />
      <Stack sx={{ px: 2, py: 1 }}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs
            value={phrases}
            onChange={(e, phrases) => {
              setAdding(false)
              updateFilters({ phrases })
            }}
          >
            <Tab id="personal-tab" aria-controls="personal-panel" value="personal" label="Personal" data-testid="personal-tab" />
            <Tab id="team-tab" aria-controls="team-panel" value="team" label="Team" data-testid="team-tab" />
          </Tabs>
          <Fade in={isEditingAllowed} unmountOnExit>
            <Stack direction="row" spacing={1}>
              {me.superAdmin && <QPhrasesImport />}
              <Button variant="contained" size="small" endIcon={<FileAddOutlinedIcon />} onClick={() => setAdding((o) => !o)}>
                Add
              </Button>
            </Stack>
          </Fade>
        </Stack>
        <Divider />
        <SearchInput
          placeholder="Search on 'Enter'"
          searchOnEnter
          value={phrasesSearch}
          onChange={(phrasesSearch) => updateFilters({ phrasesSearch })}
          sx={{ my: 2 }}
        />

        <QPhraseForm card category={phrases} open={adding} onClose={() => setAdding(false)} onUpdate={(data) => create.mutateAsync(data)} />

        <Stack spacing={2}>
          {isPending && (
            <Stack>
              {range(5).map((i) => (
                <QPhrase.Loading key={i} />
              ))}
            </Stack>
          )}
          {isEmpty && <Empty>No QPhrases have been added yet</Empty>}
          {isEmptySearch && <Empty>We could not find a QPhrase that matches, please try a different search</Empty>}
          {hasData && (
            <Stack>
              {data?.map((phrase) => (
                <QPhrase
                  key={phrase.id}
                  data={phrase}
                  onUpdate={(data) => update.mutateAsync({ id: phrase.id, data })}
                  onRemove={() => confirmRemove(phrase)}
                />
              ))}
            </Stack>
          )}
          {hasNextPage && (
            <Button
              fullWidth
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              endIcon={<MoreOutlinedIcon rotate={90} />}
              loadingPosition="end"
              sx={{ textTransform: 'none' }}
            >
              more
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

function Empty({ children }) {
  return (
    <Stack sx={{ p: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h5" sx={{ color: 'text.secondary' }}>
        {children}
      </Typography>
    </Stack>
  )
}
