import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { getErrorDetails, handleError, includesOneOfErrorMessages, QK } from '@shared/utils'

export function usePinToggle(conversationId) {
  return useMutation({
    mutationFn: (pin) => (pin ? API.conversations.id(conversationId).pin() : API.conversations.id(conversationId).unpin()),
    onSuccess: cacheUpdate(conversationId),
    onError: (e) => {
      if (includesOneOfErrorMessages(e, ['only pin up to'])) {
        return toast.error('You have reached the maximum number of pinned conversations. Please unpin one to pin another.')
      }
      return handleError(e)
    },
  })
}

export function useArchiveToggle(conversationId) {
  return useMutation({
    mutationFn: (archive) => (archive ? API.conversations.id(conversationId).archive() : API.conversations.id(conversationId).unarchive()),
    onSuccess: cacheUpdate(conversationId),
    onError: handleError,
  })
}

export function useThreadUserRemove(conversationId) {
  return useMutation({
    mutationFn: (userId) => API.conversations.id(conversationId).users.id(userId).remove(),
    onSuccess: cacheUpdate(conversationId),
    onError: (e) => {
      const message = getErrorDetails(e).join(', ')
      toast.error(message)
    },
  })
}

export function useThreadUserAdd(conversationId) {
  return useMutation({
    mutationFn: (data) => API.conversations.id(conversationId).users.add(data),
    onSuccess: cacheUpdate(conversationId),
    onError: (e) => {
      const message = getErrorDetails(e).join(', ')
      toast.error(message)
    },
  })
}

const cacheUpdate = (conversationId) => (conversation) => {
  queryClient.setQueryData(QK.conversations.id(conversationId).details, (oldData) => ({ ...oldData, ...conversation }))
  queryClient.invalidateQueries({ queryKey: QK.conversations.lists })
}
