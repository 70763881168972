import Dialog from '@mui/material/Dialog'

import { useConversationIdCalc } from '@shared/messaging/src/hooks'

import ThreadView from '@components/Conversations/ThreadView'

const styles = {
  paper: {
    display: 'flex',
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'hidden',
    height: '100%',
  },
}

/**
 * Displays a conversation in a dialog.
 * Can work with thread id or user object.
 * Note: use thread id whenever possible.
 *
 * @param id - thread id
 * @param user - user object
 * @param open - dialog open state
 * @param onClose - dialog close handler
 */
export default function ConversationDialog({ id, user, open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} PaperProps={{ sx: styles.paper }}>
      {id ? <ThreadView threadId={id} onClose={onClose} /> : <ConversationWithUser user={user} onClose={onClose} />}
    </Dialog>
  )
}

function ConversationWithUser({ user, onClose }) {
  // Try to get conversationId from the BE based on userId
  const [conversationId, isPending] = useConversationIdCalc(user?.id)

  return <ThreadView threadId={conversationId} user={user} isLoading={isPending} onClose={onClose} />
}
