import dayjs from 'dayjs'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import { addressToString, getPhoneFormatted } from '@shared/utils'

import PropertyBase from '@components/Property'

export default function Details({ patient, encounter }) {
  return (
    <Paper variant="outlined">
      <Stack spacing={1} sx={{ p: 2 }}>
        <Property label="Home Address" value={addressToString(patient?.homeAddress)} />
        <Property label="Shipping Address" value={addressToString(patient?.shippingAddress)} />
        <Property
          label="Contact"
          value={[patient.phone ? getPhoneFormatted(patient.phone) : 'Phone not provided', patient.email].join('\n')}
        />
        <Property label="Provider" value={`# ${patient.provider.id} ${patient.provider.name}`} />
        <Property
          label="Encounter"
          value={encounter ? `${dayjs(encounter?.startTime).format('L')} - ${encounter?.description} - ${encounter?.id}` : '-'}
        />
      </Stack>
    </Paper>
  )
}

const Property = ({ label, value }) => <PropertyBase label={label} value={value} align="top" />
