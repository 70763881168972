import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function usePossibleUsers(conversationId, term, options = {}) {
  const query = { term }
  return useQuery({
    queryKey: QK.conversations.id(conversationId).users.search.list(query),
    queryFn: () => API.conversations.id(conversationId).users.search(query),
    ...options,
  })
}
