import { useCallback } from 'react'
import { defaults } from 'lodash'
import { useLocalStorage } from 'usehooks-ts'

export const NotificationSetting = {
  Sound: 'sound',
  GeneralDuringVisit: 'generalDuringVisit',
  GeneralOnTheKanban: 'generalOnTheKanban',
  OnDemandAppointmentsDuringVisit: 'onDemandAppointmentsDuringVisit',
}

const initialValue = {
  [NotificationSetting.Sound]: true,
  [NotificationSetting.GeneralDuringVisit]: false,
  [NotificationSetting.GeneralOnTheKanban]: true,
  [NotificationSetting.OnDemandAppointmentsDuringVisit]: true,
}

const getDefaulted = (settings) => defaults(settings, initialValue)

export default function useNotificationSettings() {
  const [settings, setSettings] = useLocalStorage('notification-settings', initialValue)

  const updateSettings = useCallback(
    (settings = {}) => {
      setSettings((current) => ({ ...getDefaulted(current), ...settings }))
    },
    [setSettings]
  )

  return [getDefaulted(settings), updateSettings]
}
