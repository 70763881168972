import { useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { NotesEntity, toTitleCase, userRoleToLabel } from '@shared/utils'

import Avatar from '@components/Avatar'
import DateTooltip from '@components/DateTooltip'
import { rubyLink } from '@config'

Note.propTypes = {
  /* Note data */
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    entity: PropTypes.oneOf(Object.values(NotesEntity)),
    recordId: PropTypes.number,
    source: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullName: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  /* whether to show the source of the note */
  showSource: PropTypes.bool,
}

/**
 * Displays a note
 * @param {object} data - note serializer data
 * @param {boolean} showSource - whether to show the source of the note
 *
 * @returns {JSX.Element} - note component
 */
export default function Note({ data, showSource = false }) {
  const date = useIntervalResult(() => dayjs(data.createdAt).fromNow())

  const href = useMemo(() => {
    if (data.entity === NotesEntity.Labs) return rubyLink(`labs/${data.recordId}`)
    if (data.entity === NotesEntity.Rx) return rubyLink(`prescriptions/${data.recordId}`)
    if (data.entity === NotesEntity.Encounters) return rubyLink(`encounters/${data.recordId}`)
    if (data.entity === NotesEntity.Appointments) return rubyLink(`appointments/${data.recordId}`)
    if (data.entity === NotesEntity.Users) return `${import.meta.env.VITE_URL}/app/patients/${data.recordId}`
    return '#'
  }, [data])

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <Avatar user={data.author} size="xs" hover="card" />
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
            <Typography>{`${data.author.fullName}, ${userRoleToLabel[data.author.role]}`}</Typography>
            <DateTooltip date={data.createdAt}>
              <Typography sx={{ color: 'text.secondary' }}>{date}</Typography>
            </DateTooltip>
          </Stack>
          {showSource && (
            <Link component="a" href={href} target="_blank" rel="noopener noreferrer">
              {toTitleCase(data.source)}
            </Link>
          )}
        </Stack>
      </Stack>
      <Typography>{data.message}</Typography>
    </Stack>
  )
}

Note.Loading = ({ showSource = false }) => (
  <Stack spacing={1} sx={{ p: 1 }}>
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Skeleton variant="rounded" width={24} height={24} />
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            <Skeleton width={100} />
          </Typography>
        </Stack>
        {showSource && (
          <Typography>
            <Skeleton width={100} />
          </Typography>
        )}
      </Stack>
    </Stack>
    <Typography>
      <Skeleton />
      <Skeleton />
    </Typography>
  </Stack>
)
