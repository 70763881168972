import { useParams } from 'react-router'
import { BooleanParam, createEnumParam, NumberParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useAssessments(query, options = {}) {
  const { id } = useParams()
  return useQuery({
    queryKey: QK.patients.id(id).assessments.list(query),
    queryFn: () => API.patients.id(id).assessments.list(query),
    select: (data) => data.map((a) => ({ ...a, answers: JSON.parse(a.answers) || [] })),
    ...options,
  })
}

export function useAssessment(assessmentId) {
  const { id } = useParams()
  return useQuery({
    queryKey: QK.patients.id(id).assessments.id(assessmentId).details,
    queryFn: () => API.patients.id(id).assessments.id(assessmentId).details(),
    select: (a) => ({ ...a, answers: JSON.parse(a.answers) || [] }),
  })
}

const OrderParam = createEnumParam(['asc', 'desc'])

export function useAssessmentsFilters() {
  return useFiltering({
    asmtDetailed: NumberParam,
    asmtsExpanded: withDefault(BooleanParam, false),
    asmtsOrder: withDefault(OrderParam, 'desc'),
  })
}
