import { UserOutlinedIcon } from '@icons'
import { CBOProperty, PatientProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'

export default function Patient({ user, cbo, mini = false }) {
  return (
    <Section paper mini={mini} title="Patient" icon={mini ? null : <UserOutlinedIcon />}>
      <PatientProperty label="Name" user={user} />
      <CBOProperty label="CBO" data={cbo} />
    </Section>
  )
}

Patient.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Patient" icon={mini ? null : <UserOutlinedIcon />}>
    <PatientProperty.Loading label="Name" />
    <CBOProperty.Loading label="CBO" />
  </Section>
)
