import { useCallback, useState } from 'react'

import ConfirmVaccines from './ConfirmVaccines'
import OrderedVaccines from './OrderedVaccines'
import SelectVaccines from './SelectVaccines'

export default function VaccinesForm({ patientId, encounter }) {
  const [step, setStep] = useState(1)
  const [selectedVaccines, setSelectedVaccines] = useState([])

  const handleGoBack = useCallback(() => setStep(step - 1), [step])
  const handleGoNext = useCallback(() => setStep(step + 1), [step])

  switch (step) {
    case 1:
      return (
        <SelectVaccines
          patientId={patientId}
          encounter={encounter}
          selectedVaccines={selectedVaccines}
          onNext={(vaccines) => {
            setSelectedVaccines(vaccines)
            setStep(step + 1)
          }}
        />
      )
    case 2:
      return (
        <ConfirmVaccines
          patientId={patientId}
          encounterId={encounter.id}
          selectedVaccines={selectedVaccines}
          onBack={handleGoBack}
          onNext={handleGoNext}
        />
      )
    case 3:
      return (
        <OrderedVaccines
          selectedVaccines={selectedVaccines}
          onFinish={() => {
            setSelectedVaccines([])
            setStep(1)
          }}
        />
      )
    default:
      throw new Error('Invalid step')
  }
}
