import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, ScrollRestoration } from 'react-router'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import QS from 'query-string'
import { QueryParamProvider } from 'use-query-params'

import ReactRouterAdapter from '@shared/components/src/ReactRouterAdapter'
import QueryClientProvider from '@shared/providers/src/QueryClientProvider'
import { BuildEnv } from '@shared/utils'

import AxiosErrorHandler from '@providers/AxiosErrorHandler'
import LoginHandler from '@providers/LoginHandler'

import ThemeProvider from '../../themes'
import Error from '../Misc/Error'

export default function Root() {
  return (
    <QueryParamProvider adapter={ReactRouterAdapter} options={{ searchStringToObject: QS.parse, objectToSearchString: QS.stringify }}>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={Error}>
          <AxiosErrorHandler>
            <QueryClientProvider>
              <Outlet />
              <ScrollRestoration getKey={(location) => location.pathname} />
              <LoginHandler />
              {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && (
                <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
              )}
            </QueryClientProvider>
          </AxiosErrorHandler>
        </ErrorBoundary>
      </ThemeProvider>
    </QueryParamProvider>
  )
}
