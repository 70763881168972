import { useState } from 'react'

import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { UserRole } from '@shared/utils'

import ExpireGileadForm from './ExpireGileadForm'
import SubmitGileadForm from './SubmitGileadForm'

export default function GileadApplication() {
  const patient = usePatient()

  const [submitFormOpen, setSubmitFormOpen] = useState(false)
  const [expireFormOpen, setExpireFormOpen] = useState(false)

  return (
    <RoleGuard allowed={[UserRole.Support, UserRole.Pharmacy]}>
      <RoleGuard allowed={[UserRole.Support]}>
        <Divider />
      </RoleGuard>
      {patient.pendingGileadApplications ? (
        <RoleGuard allowed={[UserRole.Support]}>
          <>
            <MenuItem onClick={() => setExpireFormOpen(true)}>Expire AA Application</MenuItem>
            <ExpireGileadForm open={expireFormOpen} setOpen={setExpireFormOpen} />
          </>
        </RoleGuard>
      ) : (
        <>
          <MenuItem onClick={() => setSubmitFormOpen(true)}>Submit AA Application</MenuItem>
          <SubmitGileadForm open={submitFormOpen} setOpen={setSubmitFormOpen} />
        </>
      )}
    </RoleGuard>
  )
}
