import { useState } from 'react'

import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'

import useDraft from '@shared/hooks/src/useDraft'
import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'

import { MoreOutlinedIcon } from '@icons'
import Note from '@components/Note'
import RenderControl from '@components/RenderControl'

import { usePatientNoteCreation, usePatientNotes, useTaskNoteCreation, useTaskNotes } from './Notes.hooks'

const styles = {
  sticky: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'background.paper',
    mx: -3,
    py: 3,
    px: 1,
    mt: '0 !important',
    mb: (theme) => `${theme.spacing(3)} !important`,
    borderTop: '1px solid',
    borderColor: 'divider',
  },
  panel: {
    mt: 1,
    p: 0,
  },
  patientNotes: (theme) => ({
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    backgroundColor: 'primary.100',
  }),
  patientInput: (theme) => ({
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    backgroundColor: 'primary.100',
  }),
}

export default function Notes({ task, disabled, inputSx = {}, containerRef }) {
  const [tab, setTab] = useState('task')
  const { draft, setDraft, removeDraft } = useDraft(`task-${task.id}-comment`, { message: '', both: false })

  const {
    data: taskNotes,
    isPending: areTaskNotesPending,
    fetchNextPage: fetchNextTaskNotes,
    hasNextPage: hasNextTaskNotes,
    isFetchingNextPage: isFetchingNextTaskNotes,
  } = useTaskNotes(task.id)

  const {
    data: patientNotes,
    isPending: arePatientNotesPending,
    fetchNextPage: fetchNextPatientNotes,
    hasNextPage: hasNextPatientNotes,
    isFetchingNextPage: isFetchingNextPatientNotes,
  } = usePatientNotes(task.patientId)

  const createPatientNote = usePatientNoteCreation(task.patientId)
  const createTaskNote = useTaskNoteCreation(task.id)

  const [handleSubmit, isSubmitting] = usePromiseLoading((values) => {
    if (!values.message.trim()) return Promise.resolve()

    return Promise.all([
      ...(values.both || tab === 'task' ? [createTaskNote.mutateAsync(values.message)] : []),
      ...(values.both || tab === 'patient' ? [createPatientNote.mutateAsync(values.message)] : []),
    ]).then(removeDraft)
  })

  const isPatientTab = tab === 'patient'

  return (
    <>
      <TabContext value={tab}>
        <Stack>
          <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
            <Tab id="task-tab" aria-controls="task-panel" label="Task Comments" value="task" />
            <Tab id="patient-tab" aria-controls="patient-panel" label="Patient Chart Notes" value="patient" />
          </Tabs>
          <Divider />
          <TabPanel id="task-panel" value="task" aria-labelledby="task-tab" aria-controls="task-tab" sx={styles.panel}>
            <RenderControl loading={areTaskNotesPending} isEmpty={taskNotes?.length === 0} emptyTitle="No data to display">
              <Stack>
                {taskNotes?.map((note) => (
                  <Note key={note.id} data={note} />
                ))}
              </Stack>
              {hasNextTaskNotes && (
                <Button
                  fullWidth
                  loading={isFetchingNextTaskNotes}
                  onClick={() => fetchNextTaskNotes()}
                  endIcon={<MoreOutlinedIcon rotate={90} />}
                  loadingPosition="end"
                  sx={{ textTransform: 'none' }}
                >
                  more
                </Button>
              )}
            </RenderControl>
          </TabPanel>
          <TabPanel
            id="patient-panel"
            value="patient"
            aria-labelledby="patient-tab"
            aria-controls="patient-tab"
            sx={[styles.panel, styles.patientNotes]}
          >
            <RenderControl loading={arePatientNotesPending} isEmpty={patientNotes?.length === 0} emptyTitle="No data to display">
              <Stack>
                {patientNotes?.map((note) => (
                  <Note key={note.id} data={note} showSource />
                ))}
              </Stack>
              {hasNextPatientNotes && (
                <Button
                  fullWidth
                  loading={isFetchingNextPatientNotes}
                  onClick={() => fetchNextPatientNotes()}
                  endIcon={<MoreOutlinedIcon rotate={90} />}
                  loadingPosition="end"
                  sx={{ textTransform: 'none' }}
                >
                  more
                </Button>
              )}
            </RenderControl>
          </TabPanel>
        </Stack>
      </TabContext>
      {disabled ? (
        <div />
      ) : (
        <Stack sx={[styles.sticky, isPatientTab && styles.patientInput, inputSx]} spacing={1}>
          <TextField
            name="message"
            fullWidth
            multiline
            onFocus={() => (containerRef ? containerRef.current.scrollIntoView({ block: 'end' }) : undefined)}
            placeholder={isPatientTab ? 'Add note to patient chart...' : 'Add chat to task...'}
            disabled={isSubmitting}
            value={draft?.message}
            onChange={(e) => setDraft({ message: e.target.value, both: draft.both })}
          />
          <Collapse in={Boolean(draft?.message)}>
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
              <FormControlLabel
                name="both"
                label={isPatientTab ? 'Include in task comments' : 'Include in patient chart notes'}
                disabled={isSubmitting}
                control={<Checkbox checked={draft?.both} onChange={(e, both) => setDraft({ message: draft.message, both })} />}
                labelPlacement="end"
              />
              <Stack direction="row" spacing={1}>
                <Button color="secondary" onClick={removeDraft} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button loading={isSubmitting} variant="contained" onClick={() => handleSubmit(draft)}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Collapse>
        </Stack>
      )}
    </>
  )
}

Notes.Loading = function Loading() {
  return (
    <Stack sx={{ pb: 3 }}>
      <Tabs value="task">
        <Tab id="task-tab" aria-controls="task-panel" label="Task Comments" value="task" />
        <Tab id="patient-tab" aria-controls="patient-panel" label="Patient Chart Notes" value="patient" />
      </Tabs>
      <Divider sx={{ mb: 3 }} />
      <Skeleton variant="rounded" height={180} />
    </Stack>
  )
}
