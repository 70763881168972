import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'
import range from 'lodash/range'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@components/_mui/Typography'

import { LabDetailsModal } from '@pages/Labs/Lab'
import { isLabFailed } from '@pages/Labs/Lab/Lab.utils'
import { usePatientLabs } from '@pages/Labs/PatientLabs'
import Pagination from '@components/Pagination'
import StatusChip from '@components/StatusChip'
import { Item, Provider } from '@components/TableCell'

const LIMIT = 5

/**
 * Displays a tile with the short list of a patient's previous labs
 */
export default function LabsHistory({ patient }) {
  const [page, setPage] = useState(1)
  const [isLabDetailsOpen, setIsLabDetailsOpen] = useState(false)
  const [selectedLabId, setSelectedLabId] = useState(null)

  const { data, isPending } = usePatientLabs(
    patient.id,
    { limit: LIMIT, offset: (page - 1) * LIMIT },
    { enabled: Boolean(patient), placeholderData: keepPreviousData }
  )

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Stack>
      <LabDetailsModal
        labId={selectedLabId}
        open={isLabDetailsOpen}
        onClose={() => {
          setIsLabDetailsOpen(false)
          setTimeout(() => setSelectedLabId(null), 300)
        }}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={70}>ID</TableCell>
              <TableCell width={120}>Encounter</TableCell>
              <TableCell>Ordered By</TableCell>
              <TableCell>Panel</TableCell>
              <TableCell width={120}>Status</TableCell>
              <TableCell width={160}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showLoading && <Loading columns={5} />}
            {showEmpty && <Empty>No labs found</Empty>}
            {showData && (
              <>
                {data?.map((l) => (
                  <Lab
                    key={l.id}
                    data={l}
                    onClick={() => {
                      setSelectedLabId(l.id)
                      setIsLabDetailsOpen(true)
                    }}
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Pagination
          disableRowsPerPage
          page={page}
          perPage={LIMIT}
          setPageParams={({ page }) => setPage(page)}
          loading={isPending}
          last={data?.length < LIMIT}
        />
      </Stack>
    </Stack>
  )
}

function Lab({ data, onClick }) {
  const isFailed = isLabFailed(data.status)
  const isPositive = data.positiveResult > 0

  return (
    <TableRow key={data.id} hover onClick={onClick} sx={{ cursor: 'pointer' }}>
      <TableCell>
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell>
        <Item small primary={data.encounterReason} secondary={data.lab} />
      </TableCell>
      <TableCell>
        <Provider small user={data.provider} />
      </TableCell>
      <TableCell>
        <Typography variant="body2">{data.panelsToString}</Typography>
      </TableCell>
      <TableCell sx={{ py: 0.5 }}>
        <Typography variant="body2" sx={{ color: isFailed ? 'text.danger' : 'text.primary', fontWeight: isFailed ? 'bold' : 'normal' }}>
          {data.status}
        </Typography>
        {isPositive && <StatusChip.Lab positive />}
      </TableCell>
      <TableCell>
        <Stack spacing={-0.5}>
          <Typography variant="body2">
            <Typography variant="body2" component="span" sx={{ color: 'text.secondary', mr: 1 }}>
              Ordered:
            </Typography>
            {dayjs(data.createdAt).format('L')}
          </Typography>
          <Typography variant="body2">
            <Typography variant="body2" component="span" sx={{ color: 'text.secondary', mr: 1 }}>
              Resulted:
            </Typography>
            {data.resultDate ? dayjs(data.resultDate).format('L') : 'n/a'}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

function Loading() {
  return range(5).map((i) => (
    <TableRow hover key={i}>
      <TableCell>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Item.Loading small />
      </TableCell>
      <TableCell>
        <Provider.Loading small />
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Item.Loading small />
      </TableCell>
    </TableRow>
  ))
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={6}>
        <Typography variant="h4" sx={{ color: 'text.secondary', fontWeight: 'normal' }}>
          No labs found
        </Typography>
      </TableCell>
    </TableRow>
  )
}
