import Skeleton from '@mui/material/Skeleton'

import { LabStatus } from '@shared/utils'

import Section from '@components/Details/Section'

export const isLabReadyToReview = (status) => {
  return [LabStatus.NeedsReview, LabStatus.NurseReviewed, LabStatus.NeedsFollowUp, LabStatus.Released].includes(status)
}

export const isLabFailed = (status) => {
  return [LabStatus.LabRejected, LabStatus.OrderFailed, LabStatus.FailedDeliveryToPatient].includes(status)
}

export function GeneralSectionLoader({ mini = false }) {
  return (
    <Section mini={mini} title={<Skeleton width={200} />}>
      <Skeleton variant="rounded" height={400} />
    </Section>
  )
}
