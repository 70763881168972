import { useState } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { handleError, QK } from '@shared/utils'

import { usePatientLabs } from '@pages/Labs/PatientLabs'
import { useNoteCreation } from '@pages/Patients/Patient/ProfileTab/Notes/Notes.hooks'
import CharCounter from '@components/CharCounter'
import InputControl from '@components/InputControl'

import { LabProperty } from '../../../components/PARCard/PARCard.utils'
import { Property } from '../../../components/PatientCard/PatientCard.utils'
import { useTasksFilters } from '../../Tasks.hooks'
import { useGileadApplication, useGileadDecline, useGileadSubmit } from './GileadInfo.hooks'
import { isNotDone, Patient } from './TaskInfo.utils'

export default function GileadInfo({ data, patient }) {
  const [, updateFilters] = useTasksFilters()

  const [pendingLabsOpen, setPendingLabsOpen] = useState(false)
  const [signOpen, setSignOpen] = useState(false)
  const [declineOpen, setDeclineOpen] = useState(false)
  const [declinedOpen, setDeclinedOpen] = useState(false)

  const { data: gilead } = useGileadApplication(data.patientId, data.relatedModelId)

  const { data: lastLab, isPending: isLastLabPending } = usePatientLabs(
    data.patientId,
    { order: 'desc', sort: 'result_date', filter: 'complete', limit: 1 },
    { select: (r) => r?.[0] }
  )

  return (
    <Stack spacing={2}>
      <Property label="Treatment">{patient?.treatmentType || '-'}</Property>
      {isLastLabPending ? <LabProperty.Loading /> : <LabProperty displayResultDate data={lastLab} />}
      <Button
        variant="outlined"
        color="secondary"
        component={Link}
        target="_blank"
        rel="noopener"
        disabled={!gilead?.previewUrl}
        href={gilead?.previewUrl}
      >
        View Application
      </Button>
      {isNotDone(data) && (
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Button variant="contained" color="error" onClick={() => setDeclineOpen(true)}>
            Decline
          </Button>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={() => setPendingLabsOpen(true)}>
              Pending Labs
            </Button>
            <Button variant="contained" onClick={() => setSignOpen(true)}>
              Sign and Submit
            </Button>
          </Stack>
          <Dialog open={declineOpen} onClose={() => setDeclineOpen(false)} fullWidth maxWidth="sm">
            <DeclineContent
              task={data}
              patient={patient}
              onClose={() => setDeclineOpen(false)}
              onDeclined={() => {
                setDeclineOpen(false)
                setDeclinedOpen(true)
              }}
            />
          </Dialog>
          <Dialog open={declinedOpen} onClose={() => setDeclinedOpen(false)} fullWidth maxWidth="sm">
            <DeclinedContent
              patient={patient}
              onClose={() => {
                updateFilters({ task: undefined })
                queryClient.invalidateQueries({ queryKey: QK.tasks.lists })
                setDeclinedOpen(false)
              }}
            />
          </Dialog>
          <Dialog open={signOpen} onClose={() => setSignOpen(false)} fullWidth maxWidth="sm">
            <SignApplicationContent task={data} patient={patient} gilead={gilead} onClose={() => setSignOpen(false)} />
          </Dialog>
          <Dialog open={pendingLabsOpen} onClose={() => setPendingLabsOpen(false)} fullWidth maxWidth="sm">
            <PendingLabsContent task={data} patient={patient} onClose={() => setPendingLabsOpen(false)} />
          </Dialog>
        </Stack>
      )}
    </Stack>
  )
}

function SignApplicationContent({ task, patient, gilead, onClose }) {
  const [, updateFilters] = useTasksFilters()
  const submitApplication = useGileadSubmit(task.patientId, task.relatedModelId)

  const handleSubmit = () => {
    return submitApplication
      .mutateAsync()
      .then(() => {
        onClose()
        updateFilters({ task: undefined })
        setTimeout(() => toast.success('The application will be submitted'), 300)
      })
      .catch(handleError)
  }

  const isHIVTreatment = patient.treatmentType.includes('HIV')

  return (
    <>
      <DialogTitle>Sign Gilead AA Application</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Patient user={patient} />
          <Typography>
            By Clicking submit below, I am confirming that I have reviewed the patient’s lab work and the application will be submitted to
            Gilead.
          </Typography>
          <Button
            disabled={!gilead?.previewUrl}
            component={Link}
            target="_blank"
            rel="noopener"
            href={gilead?.previewUrl}
            sx={{ width: 220 }}
          >
            View Gilead AA Application
          </Button>
          {!isHIVTreatment && (
            <Alert color="primary" variant="border">
              <AlertTitle>Negative HIV Status</AlertTitle>
              By signing this application you are confirming the patient has a negative HIV status.
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={submitApplication.isPending} variant="contained" onClick={handleSubmit}>
          Sign and Submit Application
        </Button>
      </DialogActions>
    </>
  )
}

const PendingLabMessage = 'Pending labs from Patient. Cannot sign Gilead app until received'

function PendingLabsContent({ task, patient, onClose }) {
  const addNote = useNoteCreation(task.patientId)

  const handleConfirm = () =>
    addNote.mutateAsync(PendingLabMessage).then(() => {
      toast.success('Note added to patient profile')
      return onClose()
    })

  return (
    <>
      <DialogTitle>Pending Labs</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Typography>
            This action will append a note to the patient's profile stating:
            <br />"{PendingLabMessage}"
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={addNote.isPending} onClick={handleConfirm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

function DeclineContent({ task, patient, onDeclined, onClose }) {
  const declineApplication = useGileadDecline(task.patientId, task.relatedModelId)

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: ({ reason }) => {
      return declineApplication.mutateAsync({ reason }).then(onDeclined).catch(handleError)
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Decline Application</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Patient user={patient} />
          <Stack sx={{ width: '100%' }}>
            <InputControl field="reason" formikProps={formik}>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label="Reason for declining"
                placeholder="Write something..."
                slotProps={{ htmlInput: { maxLength: 100 } }}
              />
            </InputControl>
            <CharCounter align="right" text={formik.values.reason} limit={100} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} color="error" type="submit" variant="contained">
          Decline
        </Button>
      </DialogActions>
    </form>
  )
}

function DeclinedContent({ patient, onClose }) {
  return (
    <>
      <DialogTitle>Application declined</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Patient user={patient} />
          <Alert severity="success">Please notify customer support that you declined the application.</Alert>
          <Alert severity="success">Please notify the patient via messages why you declined the application.</Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </>
  )
}
