import { useMemo } from 'react'
import dayjs from 'dayjs'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { getPhoneFormatted } from '@shared/utils'

import { AccessTimeOutlinedIcon, EnvironmentIcon, GlobalIcon, LocalPharmacyOutlinedIcon, PhoneOutlinedIcon } from '@icons'
import Property from '@components/Property'

import { Section } from '../CBORuleSet.utils'

export default function Details({ cbo, data, isLoading }) {
  const partners = useMemo(() => {
    if (!data) return []

    const result = (data.prescriptionPartners || [])
      .filter((p) => p.active)
      .map((p) => {
        const date = dayjs(p.start_date, 'MM/DD/YYYY')
        return `${p.partner_name}, since ${date.format('L')}`
      })

    if (result.length === 0) return ['No']
    return result
  }, [data])

  if (isLoading) return <Loading />

  return (
    <Section paper title="Details">
      <Stack spacing={1}>
        <div>
          <Grid container spacing={1}>
            <Grid size={4}>
              <Stack spacing={2}>
                <Typography noWrap>{cbo.name}</Typography>
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                  <PhoneOutlinedIcon />
                  <Typography noWrap>{cbo.phone ? getPhoneFormatted(cbo.phone) : ''}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                  <EnvironmentIcon fontSize="inherit" />
                  <Typography noWrap>{[cbo.city, cbo.state].join(', ')}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                  <GlobalIcon fontSize="inherit" />
                  <Link noWrap href={cbo.url} target="_blank" rel="noopener">
                    {cbo.url}
                  </Link>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                  <AccessTimeOutlinedIcon fontSize="inherit" />
                  <Typography noWrap>{cbo.timezone}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid size={4}>
              <Stack spacing={2}>
                <Property label="Date Joined" value={cbo.startDate ? dayjs(cbo.startDate).format('L') : ''} />
                <Property label="Hours" value={cbo.hoursOfOperation} />
              </Stack>
            </Grid>
            <Grid size={4}>
              <Stack spacing={2}>
                <Property label="Accepting New Patients" value={cbo.acceptingNewPatients ? 'Yes' : 'No'} />
                <Property label="Allow Uninsured" value={cbo.cboPreventUninsured ? 'No' : 'Yes'} />
                {cbo.americanExchangePartner || cbo.prideLifePartner ? (
                  <Property
                    label={cbo.prideLifePartner ? 'Allow PrideLife' : 'Allow American Exchange'}
                    value={
                      <Stack>
                        <Typography>Yes</Typography>
                        <Link noWrap href={cbo.cboInsurancePartnerLink} target="_blank" rel="noopener">
                          {cbo.cboInsurancePartnerLink}
                        </Link>
                      </Stack>
                    }
                  />
                ) : (
                  <Property label="Insurance Partner" value="No" />
                )}
                <Property
                  label="Receiving Q Care Files"
                  value={
                    <Stack>
                      {partners.map((p) => (
                        <Typography key={p} noWrap>
                          {p}
                        </Typography>
                      ))}
                    </Stack>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </div>
        {(cbo.primaryPharmacy || cbo.secondaryPharmacy) && (
          <>
            <Divider />
            <div>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                    <LocalPharmacyOutlinedIcon fontSize="inherit" />
                    <Typography>Pharmacy</Typography>
                  </Stack>
                </Grid>
                {cbo.primaryPharmacy && (
                  <Grid size={6}>
                    <Stack spacing={1}>
                      <Typography variant="h5">Primary</Typography>
                      <Pharmacy data={cbo.primaryPharmacy} />
                    </Stack>
                  </Grid>
                )}
                {cbo.secondaryPharmacy && (
                  <Grid size={6}>
                    <Stack spacing={1}>
                      <Typography variant="h5">Secondary</Typography>
                      <Pharmacy data={cbo.secondaryPharmacy} />
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        )}
      </Stack>
    </Section>
  )
}

function Loading() {
  return (
    <Section paper title="Details">
      <Stack spacing={1}>
        <div>
          <Grid container spacing={1}>
            <Grid size={4}>
              <Stack spacing={2}>
                <Typography>
                  <Skeleton width={110} />
                </Typography>
                <Typography>
                  <Skeleton width={150} />
                </Typography>
                <Typography>
                  <Skeleton width={100} />
                </Typography>
                <Typography>
                  <Skeleton width={120} />
                </Typography>
                <Typography>
                  <Skeleton width={140} />
                </Typography>
              </Stack>
            </Grid>
            <Grid size={4}>
              <Stack spacing={2}>
                <Property label="Date Joined" value={<Skeleton width={80} />} />
                <Property label="Hours" value={<Skeleton width={100} />} />
              </Stack>
            </Grid>
            <Grid size={4}>
              <Stack spacing={2}>
                <Property label="Accepting New Patients" value={<Skeleton width={100} />} />
                <Property label="Allow Uninsured" value={<Skeleton width={60} />} />
                <Property label="Allow PrideLife" value={<Skeleton width={60} />} />
                <Property label="Receiving Q Care Files" value={<Skeleton width={60} />} />
              </Stack>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div>
          <Grid container spacing={2}>
            <Grid size={12}>
              <Typography>
                <Skeleton width={100} />
              </Typography>
            </Grid>
            <Grid size={6}>
              <Stack spacing={1}>
                <Typography variant="h5">
                  <Skeleton width={70} />
                </Typography>
                <Pharmacy.Loading />
              </Stack>
            </Grid>
            <Grid size={6}>
              <Stack spacing={1}>
                <Typography variant="h5">
                  <Skeleton width={70} />
                </Typography>
                <Pharmacy.Loading />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Stack>
    </Section>
  )
}

function Pharmacy({ data }) {
  return (
    <Stack>
      <Typography>{data.name}</Typography>
      {data.address && <Typography sx={{ whiteSpace: 'nowrap' }}>{data.address}</Typography>}
      <Typography sx={{ whiteSpace: 'nowrap' }}>{[data.city, data.state].join(', ')}</Typography>
      {data.phone && <Typography>{getPhoneFormatted(data.phone)}</Typography>}
    </Stack>
  )
}

Pharmacy.Loading = function () {
  return (
    <Stack>
      <Typography>
        <Skeleton width={100} />
      </Typography>
      <Typography>
        <Skeleton width={100} />
      </Typography>
      <Typography>
        <Skeleton width={100} />
      </Typography>
    </Stack>
  )
}
