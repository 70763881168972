import { useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CardActionArea from '@mui/material/CardActionArea'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import usePatient from '@shared/hooks/src/usePatient'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'

import InputControl from '@components/InputControl'

import { usePrescriptionUpdate } from '../Prescription.hooks'

export default function PrescriptionStatusUpdate({ readOnly, prescription }) {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const possibleStatuses = useLookup(Lookup.RXStatusesForPharmacy)
  const status = possibleStatuses[prescription.rxStatus] || prescription.rxStatus
  const statusDate = prescription.statusDate ? dayjs(prescription.statusDate).format('L LT') : undefined

  return (
    <>
      <CardActionArea ref={anchorRef} disabled={readOnly} onClick={() => setOpen(true)} sx={{ p: 1, m: -1 }}>
        <Stack>
          <Typography>{status || '—'}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{statusDate || '—'}</Typography>
        </Stack>
      </CardActionArea>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <Edit prescription={prescription} onClose={() => setOpen(false)} />
      </Dialog>
    </>
  )
}

function Edit({ prescription, onClose }) {
  const { data } = usePatient(prescription.user.id)

  const possibleStatuses = useLookup(Lookup.RXStatusesForPharmacy)
  const isPossibleStatus = Boolean(possibleStatuses[prescription.rxStatus])

  const updatePrescription = usePrescriptionUpdate(prescription.id)

  const formik = useFormik({
    initialValues: {
      status: prescription.rxStatus,
      note: '',
    },
    validationSchema: Yup.object({
      status: Yup.string()
        .required('Status is required')
        .test('status-changed', 'Status must be changed', (value) => value !== prescription.rxStatus),
      note: Yup.string().trim().required('Note is required'),
    }),
    onSubmit: ({ status, note }) => updatePrescription.mutateAsync({ status, note }).then(onClose),
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Review Prescription Status</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Collapse in={data?.pendingGileadApplications}>
            <Alert severity="warning" variant="border">
              Please note that patient has pending Gilead Advancing Access application. <br /> Please review the status carefully.
            </Alert>
          </Collapse>
          <InputControl field="status" formikProps={formik}>
            <TextField select required fullWidth label="Mark Prescription" disabled={formik.isSubmitting}>
              {!isPossibleStatus && (
                <MenuItem value={prescription.rxStatus} disabled>
                  {prescription.rxStatus}
                </MenuItem>
              )}
              {Object.entries(possibleStatuses).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputControl>
          <InputControl hideLabel field="note" formikProps={formik}>
            <TextField required fullWidth multiline rows={4} placeholder="Write something..." disabled={formik.isSubmitting} />
          </InputControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={formik.isSubmitting}>
          Cancel
        </Button>
        <Button loading={formik.isSubmitting} type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </form>
  )
}

PrescriptionStatusUpdate.Loading = () => (
  <Stack>
    <Typography>
      <Skeleton width={200} />
    </Typography>
    <Typography>
      <Skeleton width={180} />
    </Typography>
  </Stack>
)
