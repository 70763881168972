import { useInView } from 'react-intersection-observer'
import { useUpdateEffect } from 'usehooks-ts'

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fade from '@mui/material/Fade'
import Typography from '@components/_mui/Typography'

export const styles = {
  empty: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minHeight: 0,
  },
  list: {
    flex: '1 1 auto',
    overflowY: 'auto',
    p: 3,
    gap: 2,
  },
  listMini: {
    p: 1,
    gap: 1,
  },
  loader: {
    backgroundColor: 'background.paper',
    boxShadow: (theme) => theme.customShadows.z2,
    position: 'absolute',
    margin: '0 auto',
    top: '10%',
    left: 0,
    right: 0,
  },
}

export function PaginateIndicator({ hasMore, fetch }) {
  const { ref: topRef, inView } = useInView()

  useUpdateEffect(() => {
    if (hasMore && inView) {
      fetch()
    }
  }, [hasMore, inView])

  return <div ref={topRef} />
}

export function Loader({ loading }) {
  return (
    <Fade in={loading} unmountOnExit>
      <Avatar sx={styles.loader}>
        <CircularProgress size={30} />
      </Avatar>
    </Fade>
  )
}

export function Empty({ children }) {
  return (
    <Fade in>
      <Box sx={styles.empty}>
        <Typography sx={{ color: 'text.secondary' }}>{children}</Typography>
      </Box>
    </Fade>
  )
}
