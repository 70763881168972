import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

export const IconCircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary[300],
  color: theme.palette.primary.contrastText,
  borderRadius: '50%',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.primary[200],
  },
  '&.MuiIconButton-sizeSmall': {
    width: '24px',
    height: '24px',
    fontSize: '18px',
  },
  '&.MuiIconButton-sizeMedium': {
    width: '32px',
    height: '32px',
    fontSize: '22px',
  },
  '&.MuiIconButton-sizeLarge': {
    width: '40px',
    height: '40px',
    fontSize: '26px',
  },
}))
