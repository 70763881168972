import { keepPreviousData, useMutation } from '@tanstack/react-query'

import useDraft from '@shared/hooks/src/useDraft'
import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export const useLabOrderDraft = (patientId, encounterId) => {
  if (patientId && encounterId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDraft(`lab_order_${patientId}_${encounterId}`, { reason: '', labs: {} })
  }
  return {
    draft: undefined,
    setDraft: () => undefined,
    removeDraft: () => undefined,
  }
}

export function useLabOrder(patientId) {
  return useMutation({
    mutationFn: (data) => API.patients.id(patientId).labs.order(data),
  })
}

export function useLabVendors(patientId, encounterId, reason, options = {}) {
  const query = { encounter_id: encounterId, lab_reason: reason }

  return useQuery({
    queryKey: QK.patients.id(patientId).labs.vendors.list(query),
    queryFn: () => API.patients.id(patientId).labs.vendors(query),
    enabled: Boolean(patientId) && Boolean(encounterId) && Boolean(reason),
    placeholderData: keepPreviousData,
    ...options,
  })
}
