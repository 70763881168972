import PropTypes from 'prop-types'
import { BooleanParam, createEnumParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export const RxSortOrder = {
  ASC: 'asc',
  DESC: 'desc',
}

export const RxSortPropType = PropTypes.oneOf(Object.values(RxSortOrder))

export function usePatientPrescriptions(id, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(id).prescriptions.list(query),
    queryFn: () => API.patients.id(id).prescriptions.list(query),
    ...options,
  })
}

const OrderParam = createEnumParam([RxSortOrder.ASC, RxSortOrder.DESC])

export function usePatientPrescriptionsFilters() {
  return useFiltering({
    rxExpanded: withDefault(BooleanParam, false),
    orderERrxExpanded: withDefault(BooleanParam, false),
    rxOrder: withDefault(OrderParam, RxSortOrder.DESC),
  })
}

export const patientRXFiltersResetState = {
  rxExpanded: undefined,
  orderERrxExpanded: undefined,
  rxOrder: undefined,
}
