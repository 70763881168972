import { useMutation } from '@tanstack/react-query'
import { BooleanParam, createEnumParam, withDefault } from 'use-query-params'

import useDialog from '@shared/hooks/src/useDialog'
import useFiltering from '@shared/hooks/src/useFiltering'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, QK } from '@shared/utils'

import AddNote from './AddNote'

export function useNotes(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).notes.list(query),
    queryFn: () => API.patients.id(patientId).notes.list(query),
    ...options,
  })
}

export function useNoteCreation(patientId) {
  return useMutation({
    mutationFn: (message) => API.patients.id(patientId).notes.create({ message }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).notes.lists }),
  })
}

const OrderParam = createEnumParam(['asc', 'desc'])
const SortParam = createEnumParam(['createdAt', 'source', 'authorName', 'authorUserType'])

export function useNotesFilters() {
  return useFiltering({
    notesExpanded: withDefault(BooleanParam, false),
    notesOrder: withDefault(OrderParam, 'desc'),
    notesSort: withDefault(SortParam, 'createdAt'),
  })
}

/**
 * Returns a hook to open a dialog to add a note to a patient.
 * @param {string} patientId - the id of the patient to add the note
 * @returns {function} - a hook to open the dialog
 */
export function useAddNote(patientId) {
  const noteCreation = useNoteCreation(patientId)

  return useDialog({
    component: AddNote,
    props: ({ close }) => ({
      patientId,
      onReject: () => close(),
      onConfirm: (message) => noteCreation.mutateAsync(message).then(close).catch(handleError),
    }),
  })
}
