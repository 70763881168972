import { keepPreviousData } from '@tanstack/react-query'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import usePageParams from '@shared/hooks/src/usePageParams'
import { useMe } from '@shared/providers/src/MeProvider'
import { TaskStatus } from '@shared/utils'

import Avatar from '@components/Avatar'
import DateTooltip from '@components/DateTooltip'
import LinearProgress from '@components/LinearProgress'
import Pagination from '@components/Pagination'
import RenderControl from '@components/RenderControl'
import TableSortCell from '@components/TableSortCell'

import { useTasks, useTasksFilters, useTaskTypes } from './Tasks.hooks'
import { getTaskType, isTaskClickable } from './Tasks.utils'

TasksModal.propTypes = {
  /** Id of the patient whose notes will be shown */
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** When true, modal will be displayed */
  open: PropTypes.bool.isRequired,

  /** Called after the close action */
  onClose: PropTypes.func.isRequired,
}

/**
 * Displays a modal that shows all the notes for a specific patient
 */
export default function TasksModal({ patientId, open, onClose }) {
  const me = useMe()
  const [{ tasksOrder, tasksSort }, updateFilters] = useTasksFilters()
  const [page, perPage, setPageParams] = usePageParams({ id: 'tasks' })
  const types = useTaskTypes()

  const { data, isRefreshing, isPending } = useTasks(
    patientId,
    {
      order: tasksOrder,
      sort: tasksSort,
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    { enabled: open, placeholderData: keepPreviousData }
  )

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} scroll="paper" data-testid="page-notes">
      <DialogTitle onClose={onClose}>Tasks</DialogTitle>
      <LinearProgress loading={isRefreshing} color="warning" />
      <DialogContent dividers>
        <RenderControl loading={isPending} isEmpty={data?.length === 0} emptyTitle="No data to display">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableSortCell
                    active={tasksSort === 'created_at'}
                    direction={tasksOrder}
                    onChange={(tasksOrder) => {
                      updateFilters({ tasksOrder, tasksSort: 'created_at' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Created
                  </TableSortCell>
                  <TableSortCell
                    active={tasksSort === 'identifier'}
                    direction={tasksOrder}
                    onChange={(tasksOrder) => {
                      updateFilters({ tasksOrder, tasksSort: 'identifier' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Number
                  </TableSortCell>
                  <TableSortCell
                    active={tasksSort === 'kind'}
                    direction={tasksOrder}
                    onChange={(tasksOrder) => {
                      updateFilters({ tasksOrder, tasksSort: 'kind' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Type
                  </TableSortCell>
                  <TableSortCell
                    active={tasksSort === 'status'}
                    direction={tasksOrder}
                    onChange={(tasksOrder) => {
                      updateFilters({ tasksOrder, tasksSort: 'status' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Status
                  </TableSortCell>
                  <TableSortCell
                    active={tasksSort === 'assignee'}
                    direction={tasksOrder}
                    onChange={(tasksOrder) => {
                      updateFilters({ tasksOrder, tasksSort: 'assignee' })
                      setPageParams({ page: 1, perPage })
                    }}
                  >
                    Assignee
                  </TableSortCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((task) => {
                  const type = getTaskType(types, task)
                  const canClick = isTaskClickable(me, task)

                  return (
                    <TableRow
                      key={task.id}
                      hover={canClick}
                      onClick={canClick ? () => updateFilters({ task: task.identifier }) : undefined}
                      sx={{
                        cursor: canClick ? 'pointer' : 'default',
                        '& .MuiTableCell-root': { verticalAlign: 'top' },
                      }}
                    >
                      <DateTooltip date={task.createdAt}>
                        <TableCell width={100}>{dayjs(task.createdAt).format('L')}</TableCell>
                      </DateTooltip>
                      <TableCell width={120}>{task.identifier}</TableCell>
                      <TableCell>{type}</TableCell>
                      <TableCell width={120} align="center">
                        <Status value={task.status} />
                      </TableCell>
                      <TableCell width={180}>
                        {task.assignee && (
                          <Stack direction="row" spacing={1}>
                            <Avatar size="xs" user={task.assignee} hover="card" />
                            <Typography noWrap sx={{ fontWeight: 'bold' }}>
                              {task.assignee.fullName}
                            </Typography>
                          </Stack>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </RenderControl>
      </DialogContent>
      <DialogActions sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Pagination id="tasks" loading={isPending} last={data?.length < perPage} />
      </DialogActions>
    </Dialog>
  )
}

export function Status({ value }) {
  if (value === TaskStatus.Waiting) return <Chip size="small" variant="combined" label="Waiting" color="warning" />
  if (value === TaskStatus.ToDo) return <Chip size="small" variant="combined" label="Todo" color="primary" />
  if (value === TaskStatus.InProgress) return <Chip size="small" variant="combined" label="In Progress" color="success" />
  if (value === TaskStatus.Completed) return <Chip size="small" variant="combined" label="Done" color="secondary" />
  if (value === 'Expired') return <Chip size="small" variant="combined" label="Expired" color="error" />
  if (value === 'Not Applicable') return <Chip size="small" variant="combined" label="Not Applicable" color="error" />
}
