import { useEffect } from 'react'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { useMe } from '@shared/providers/src/MeProvider'

export default function useOutOfOfficeListener(thread) {
  const me = useMe()

  useEffect(() => {
    if (!thread) return
    const usersOut = thread.users?.filter((u) => u.id !== me.id).filter((u) => Boolean(u.outOfOfficeMessage)) ?? []
    if (usersOut.length > 0) PubSub.publish(PubSubEvents.UserOutOfOffice, usersOut)
  }, [thread?.id]) // eslint-disable-line react-hooks/exhaustive-deps
}
