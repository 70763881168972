import { useMemo } from 'react'

import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePatient from '@shared/hooks/src/usePatient'

import usePatientEncounter from '@hooks/usePatientEncounter'
import { usePatientLabs } from '@pages/Labs/PatientLabs'
import { usePatientPrescriptions } from '@pages/Prescriptions/PatientPrescriptions'

import { PatientHeader, styles } from '../PatientCard/PatientCard.utils'
import { usePatientAssessments } from './PARCard.hooks'
import { AssessmentProperty, CBOProperty, EncounterProperty, LabProperty, PrescriptionProperty } from './PARCard.utils'

export default function PARCard({ patientId, encounterId, title, children }) {
  const { data: patient, isPending } = usePatient(patientId)
  const { data: encounter, isPending: isEncounterPending } = usePatientEncounter(patientId, encounterId, {
    enabled: Boolean(patientId) && Boolean(encounterId),
  })
  const { data: lastPrescription, isPending: isLastPrescriptionPending } = usePatientPrescriptions(
    patientId,
    { order: 'desc', limit: 1 },
    { select: (r) => r?.[0] }
  )
  const { data: lastLab, isPending: isLastLabPending } = usePatientLabs(
    patientId,
    { order: 'desc', sort: 'ordered', limit: 1 },
    { select: (r) => r?.[0] }
  )
  const { data: lastAssessment, isPending: isLastAssessmentPending } = usePatientAssessments(
    patientId,
    { order: 'desc', limit: 1 },
    {
      select: (r) => {
        const a = r?.[0]
        if (a) return { ...a, answers: JSON.parse(a.answers) || [] }
        return a
      },
    }
  )

  const content = useMemo(() => {
    if (typeof children === 'function') return children({ patient })
    return children
  }, [children, patient])

  return (
    <Paper variant="outlined" sx={styles.container}>
      <Stack spacing={3}>
        {isPending ? <PatientHeader.Loading /> : <PatientHeader user={patient} />}
        <Typography variant="h4">{isPending ? <Skeleton width={160} /> : title}</Typography>
        <Stack spacing={1}>
          {isPending ? <CBOProperty.Loading /> : <CBOProperty data={patient.cbo} />}
          {encounterId && <>{isEncounterPending ? <EncounterProperty.Loading /> : <EncounterProperty data={encounter} />}</>}
          {isLastLabPending ? <LabProperty.Loading /> : <LabProperty data={lastLab} />}
          {isLastPrescriptionPending ? <PrescriptionProperty.Loading /> : <PrescriptionProperty data={lastPrescription} />}
          {isLastAssessmentPending ? <AssessmentProperty.Loading /> : <AssessmentProperty data={lastAssessment} />}
        </Stack>
      </Stack>
      {content}
    </Paper>
  )
}

function Loading() {
  return (
    <Paper variant="outlined" sx={styles.container}>
      <Stack spacing={3}>
        <PatientHeader.Loading />
        <Typography variant="h4">
          <Skeleton width={160} />
        </Typography>
        <Stack spacing={1}>
          <CBOProperty.Loading />
          <LabProperty.Loading />
          <PrescriptionProperty.Loading />
          <AssessmentProperty.Loading />
        </Stack>
      </Stack>
    </Paper>
  )
}

PARCard.Loading = Loading
