import SvgIcon from '@mui/material/SvgIcon'

export default function PhotoIdMissingIcon(props) {
  return (
    <SvgIcon width="16" height="18" viewBox="0 0 16 18" fill="none" {...props}>
      <g clipPath="url(#ke64jkc88a)" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.455 2.29H13.09c.807 0 1.455.65 1.455 1.463v4.393h-1.474V3.753H1.43v8.777h7.306l-.009 1.473H1.455A1.454 1.454 0 0 1 0 12.537l.007-8.785c0-.812.64-1.464 1.448-1.464z"
        />
        <path d="M4.533 7.366c.618 0 1.112-.498 1.112-1.119a1.112 1.112 0 1 0-2.224 0c0 .621.495 1.12 1.112 1.12zM2.587 10.782H6.48V9.046c0-.616-.5-1.12-1.112-1.12H3.7c-.611 0-1.112.504-1.112 1.12v1.736zM8.17 5.47h3.745v.754H8.17V5.47zM8.17 6.91h3.745v.753H8.17V6.91zM8.17 8.349h3.745v.754H8.17v-.754zM14.99 10.553l1.029 1.035-1.543 1.553 1.543 1.553-1.029 1.035-1.543-1.552-1.542 1.553-1.029-1.036 1.543-1.553-1.543-1.553 1.029-1.035 1.542 1.553 1.543-1.553z" />
      </g>
    </SvgIcon>
  )
}
