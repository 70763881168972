import Button from '@mui/material/Button'
import Typography from '@components/_mui/Typography'

import { getPhoneFormatted } from '@shared/utils'

import { CopyOutlinedIcon, MedicalServiceIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'
import Property from '@components/Details/Property'
import Section from '@components/Details/Section'

export default function Pharmacy({ data, mini = false, localPharmacyName = null }) {
  if (!data && localPharmacyName) {
    return (
      <Section paper mini={mini} title="Local Pharmacy" icon={mini ? null : <MedicalServiceIcon />}>
        <Property label="Name">{localPharmacyName}</Property>
      </Section>
    )
  }
  if (!data) return null

  return (
    <Section
      paper
      mini={mini}
      title="Pharmacy"
      icon={mini ? null : <MedicalServiceIcon />}
      action={
        <CopyToClipboard>
          {({ copy }) => (
            <Button endIcon={<CopyOutlinedIcon />} size="small" onClick={() => copy(pharmacyToString(data))}>
              Copy
            </Button>
          )}
        </CopyToClipboard>
      }
    >
      <Property label="Name">
        <Typography copy>{data.name}</Typography>
      </Property>
      <Property label="Address">
        <Typography copy>{data.address || '—'}</Typography>
      </Property>
      <Property label="Area">
        <Typography copy>{[data.city, data.state, data.zip].filter(Boolean).join(', ') || '—'}</Typography>
      </Property>
      <Property label="Phone">
        <Typography copy>{data.phone ? getPhoneFormatted(data.phone) : '—'}</Typography>
      </Property>
    </Section>
  )
}

Pharmacy.Loading = ({ mini = false }) => (
  <Section paper mini={mini} title="Pharmacy" icon={mini ? null : <MedicalServiceIcon />}>
    <Property.Loading label="Name" />
    <Property.Loading label="Address" />
    <Property.Loading label="Area" />
    <Property.Loading label="Phone" />
  </Section>
)

function pharmacyToString(data) {
  return [
    data.name,
    data.address,
    [data.city, data.state, data.zip].filter(Boolean).join(', '),
    data.phone ? getPhoneFormatted(data.phone) : undefined,
  ]
    .filter(Boolean)
    .join('\n')
}
