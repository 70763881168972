import { keepPreviousData } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'

import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { LabTypes, UserRole } from '@shared/utils'

import { spacing } from '@components/Details/Details.utils'
import { usePatientAccessible } from '@components/PatientAccessGuard'

import DetailsSection from './components/DetailsSection'
import HeaderSection from './components/HeaderSection'
import NotesSection from './components/NotesSection'
import PatientSection from './components/PatientSection'
import QuestNoteSection from './components/QuestNoteSection'
import QuestQuestionnaire from './components/QuestQuestionnaire'
import RequisitionsSection from './components/RequisitionSection'
import ResultsSection from './components/ResultsSection'
import ShippingSection from './components/ShippingSection'
import { invalidateEncounterNotes, useLab } from './Lab.hooks'
import { GeneralSectionLoader } from './Lab.utils'

export default function LabDetailsModal({ labId, hidePatientData = false, open, onClose, onStatusUpdate }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
      PaperProps={{ sx: { maxWidth: 825 } }}
    >
      <Content labId={labId} hidePatientData={hidePatientData} onClose={onClose} onStatusUpdate={onStatusUpdate} />
    </Dialog>
  )
}

function Content({ labId, hidePatientData, onClose, onStatusUpdate }) {
  const me = useMe()
  const { data } = useLab(labId, { enabled: Boolean(labId), placeholderData: keepPreviousData })
  const patientAccessible = usePatientAccessible(data?.user.id)

  const allowedToAddNotes = isUserAllowed(me, [
    UserRole.Admin,
    UserRole.ClinicalAdmin,
    UserRole.ClinicalLead,
    UserRole.Provider,
    UserRole.MA,
  ])
  const canAddNotes = allowedToAddNotes && patientAccessible
  const showData = Boolean(data)
  const isQuestLab = LabTypes.Quest === data?.lab

  return (
    <>
      <DialogTitle onClose={onClose}>{showData ? <HeaderSection data={data} /> : <HeaderSection.Loading />}</DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          {showData ? (
            <>
              {isQuestLab && <QuestQuestionnaire data={data} />}
              {!hidePatientData && <PatientSection mini data={data} />}
              <DetailsSection mini data={data} onClose={onClose} onStatusUpdate={onStatusUpdate} />
              <ResultsSection mini data={data} />
              <RequisitionsSection mini data={data} />
              {[LabTypes.Ash, LabTypes.MTL].includes(data.lab) && <ShippingSection mini data={data} />}
              {isQuestLab && <QuestNoteSection mini data={data} />}
            </>
          ) : (
            <>
              {!hidePatientData && <PatientSection.Loading mini />}
              <DetailsSection.Loading mini />
              <ResultsSection.Loading mini />
              <RequisitionsSection.Loading mini />
              <GeneralSectionLoader mini />
            </>
          )}
          <NotesSection mini labId={labId} readOnly={!canAddNotes} onCreate={() => invalidateEncounterNotes(data)} />
        </Stack>
      </DialogContent>
    </>
  )
}
