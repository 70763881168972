import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { getTestId } from '@shared/utils'

export default function OrderedVaccines({ selectedVaccines, onFinish, ...rest }) {
  const testId = getTestId(rest, 'ordered-vaccines')
  return (
    <Slide in direction="left">
      <Stack sx={{ justifyContent: 'space-between', minHeight: '400px', height: '100%' }}>
        <Stack spacing={1}>
          <Typography variant="body1">Recommendation(s) sent to patient.</Typography>
          <Typography variant="body2" color="textSecondary">
            The recommendation(s) were created, added to the patient’s documents and a notification sent to them.
          </Typography>
          <List disablePadding data-testid={`${testId}-vaccines`}>
            {selectedVaccines.map((vaccine) => (
              <ListItem key={vaccine.id} divider>
                <ListItemText>{vaccine.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
          <Box sx={{ width: '50%' }}>
            <Button
              fullWidth
              size="medium"
              type="submit"
              variant="dashed"
              color="secondary"
              onClick={() => onFinish()}
              data-testid={`${testId}-next`}
            >
              Another Vaccine
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Slide>
  )
}
