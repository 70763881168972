import { Extension } from '@tiptap/core'

const KeyboardHandlerExtension = Extension.create({
  name: 'keyboardHandler',
  addOptions() {
    return {
      sendOnEnter: false,
      onSend: () => undefined,
    }
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        if (this.options.sendOnEnter && this.options.onSend) {
          this.options.onSend()
          return true
        }
        return false
      },
      'Shift-Enter': ({ editor }) =>
        editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.splitListItem('listItem'),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]),
    }
  },
})

export default KeyboardHandlerExtension
