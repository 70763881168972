const styles = {
  paper: {
    textAlign: 'center',
    height: '100%',
    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'background.paper' : 'primary.lighter'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    flex: '1 1 auto',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400,
    textAlign: 'center',
    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'background.paper' : 'primary.lighter'),
  },
  content: {
    maxWidth: 420,
  },
}

export default styles
