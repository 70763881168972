import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

/**
 * Dialog for confirming task completion
 *
 * @param open - open state of the dialog
 * @param onClose - close handler
 * @param onConfirm - confirm handler
 */
export default function CompleteConfirmation({ open, onCancel, onConfirm }) {
  return (
    <Dialog open={open} onClose={() => onCancel()} fullWidth maxWidth="sm">
      <DialogTitle>Confirm Task Completion</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Are you sure you want to change this task status to "Done"?
          <br />
          Once updated, it cannot be changed and no further actions will be available.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button variant="contained" onClick={() => onConfirm()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
