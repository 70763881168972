import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@components/_mui/Typography'

import StatusChip from '@components/StatusChip'

import { RxSortOrder, RxSortPropType } from '../PatientPrescriptions/PatientPrescriptions.hooks'

PrescriptionsTable.propTypes = {
  /** Array of prescriptions to display */
  prescriptions: PropTypes.array,

  /** The order to sort the prescriptions by */
  order: RxSortPropType,

  /** Called when the order is changed */
  onOrderChange: PropTypes.func,
}

/**
 * Get data for prescriptions and display in a table
 */
export default function PrescriptionsTable({ prescriptions, order, onOrderChange }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Drug</TableCell>
            <TableCell>Diagnosis</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell sortDirection={order}>
              <TableSortLabel
                active
                direction={order}
                onClick={() => onOrderChange(order === RxSortOrder.DESC ? RxSortOrder.ASC : RxSortOrder.DESC)}
              >
                ERx Date
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prescriptions?.map((rx) => (
            <TableRow
              key={rx.id}
              hover
              onClick={() => window.open(`${import.meta.env.VITE_URL}/app/prescriptions/${rx.id}`, '_blank')}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell sx={{ verticalAlign: 'top' }}>
                <Typography>{`${rx.drugName}${rx.isRefill ? ' (Refill)' : ''}`}</Typography>
                <Typography color="secondary">{rx?.pharmacy?.name}</Typography>
              </TableCell>

              <TableCell sx={{ verticalAlign: 'top' }}>
                <Tooltip title={rx.diagnosisDescription ? rx.diagnosisDescription : ''}>
                  <Typography>{rx.diagnosis}</Typography>
                </Tooltip>
              </TableCell>

              <TableCell sx={{ verticalAlign: 'top' }}>
                <Stack direction="row" spacing={1}>
                  <StatusChip label={`${rx.quantity}+${rx.refills}`} type="warning" />
                  <StatusChip label={rx.remain || 0} type="success" />
                </Stack>
              </TableCell>

              <TableCell sx={{ verticalAlign: 'top' }}>
                <Typography>{rx.provider?.name}</Typography>
                <Typography>{[rx.provider?.city, rx.provider?.state].filter(Boolean).join(', ')}</Typography>
              </TableCell>

              <TableCell sx={{ verticalAlign: 'top' }}>
                <Typography>{rx.createdAt ? dayjs(rx.createdAt).format('L') : ''}</Typography>
                <StatusChip.Rx status={rx.rxStatus} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
