import { useRef, useState } from 'react'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'
import { usePatient } from '@shared/providers/src/PatientProvider'
import { getTaskKindLabel, TaskStatus, taskStatusToLabel, UserRole } from '@shared/utils'

import { hasDoneReasons, useTaskDoneReasons } from '@hooks/useTaskDoneReasons'
import { CBORuleSetDetailsDialog } from '@pages/CBOs'
import { CaretDownOutlinedIcon, LinkIcon } from '@icons'
import Avatar from '@components/Avatar'
import DateTooltip from '@components/DateTooltip'
import { PrescriptionCardPopper } from '@components/PrescriptionCardPopper'
import Tile from '@components/Tile'
import { rubyLink } from '@config'

import CompleteConfirmation from '../../components/CompleteConfirmation'
import DoneConfirmationWithReasons from '../../components/DoneConfirmationWithReasons'
import ExpireConfirmation from '../../components/ExpireConfirmation'
import Property from '../../components/Property'
import SelectAssignee from '../../components/SelectAssignee'
import SelectPriority from '../../components/SelectPriority'
import { useStatusUpdate } from '../../Kanban.hooks'

export default function TaskDetails({ task }) {
  const me = useMe()
  const updateStatus = useStatusUpdate()
  const disabled = [TaskStatus.Completed, TaskStatus.Expired].includes(task.status)

  return (
    <Stack spacing={3} sx={{ flexBasis: '400px' }}>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <StatusSelector
          disabled={disabled}
          task={task}
          canExpire={me.role === UserRole.Support && me.id === task.assignee?.id}
          canComplete={me.id === task.assignee?.id}
          onChange={(status, { reason } = {}) => {
            return updateStatus.mutate({ id: task.id, newStatus: status, oldStatus: task.status, reason })
          }}
        />
      </Box>
      <Details task={task} disabled={disabled} />
      <CareTeam />
    </Stack>
  )
}

function Details({ task, disabled }) {
  return (
    <Tile title="Details">
      <Stack spacing={2} sx={{ p: 2 }}>
        <Property label="Type">
          <Typography>{getTaskKindLabel(task)}</Typography>
        </Property>
        <Property label="Created">
          <DateTooltip date={task.createdAt}>
            <Typography>{dayjs(task.createdAt).format('L')}</Typography>
          </DateTooltip>
        </Property>
        {task.status === TaskStatus.Completed && task.updatedAt && (
          <Property label="Completed">
            <DateTooltip date={task.updatedAt}>
              <Typography>{dayjs(task.updatedAt).format('L')}</Typography>
            </DateTooltip>
          </Property>
        )}
        {task.status === TaskStatus.Expired && task.updatedAt && (
          <Property label="Expired">
            <DateTooltip date={task.updatedAt}>
              <Typography>{dayjs(task.updatedAt).format('L')}</Typography>
            </DateTooltip>
          </Property>
        )}
        <Property label="Related Rx">
          {task.relatedModelType === 'Prescription' && (
            <PrescriptionCardPopper
              patientId={task.patientId}
              prescriptionId={task.relatedModelId}
              popoverProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
              }}
            >
              <Link component="a" target="_blank" href={rubyLink(`prescriptions/${task.relatedModelId}`)} rel="noopener">
                {task.relatedModelId}
              </Link>
            </PrescriptionCardPopper>
          )}
        </Property>
        <Property label="Priority">
          <SelectPriority task={task} disabled={disabled} />
        </Property>
        <Property label="Created By">
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            {task.author ? (
              <>
                <Avatar size="xs" user={task.author} hover="card" />
                <Typography>{task.author.fullName}</Typography>
              </>
            ) : (
              <>
                <Avatar size="xs" user={{ fullName: 'Q P', firstName: 'Q', lastName: 'P' }} />
                <Typography>Q Care Plus</Typography>
              </>
            )}
          </Stack>
        </Property>
        <Property label="Assignee">
          <SelectAssignee task={task} disabled={disabled} />
        </Property>
      </Stack>
    </Tile>
  )
}

function CareTeam() {
  const [cboDetailed, setCboDetailed] = useState(false)
  const patient = usePatient()

  if (!patient) return <CareTeam.Loading />

  return (
    <Tile title="Care Team">
      <CBORuleSetDetailsDialog id={patient.cbo?.id} slide open={cboDetailed} onClose={() => setCboDetailed(false)} />
      <Stack spacing={2} sx={{ p: 2 }}>
        <Property label="Provider">
          {patient.provider && (
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Avatar size="xs" user={patient.provider} hover="card" />
              <Typography>{patient.provider.fullName}</Typography>
            </Stack>
          )}
        </Property>
        <Property
          label={
            <Stack direction="row" spacing={1} sx={{ minWidth: 100 }}>
              <Typography>CBO</Typography>
              <IconButton
                variant="contained"
                size="small"
                disabled={!patient.cbo?.id}
                onClick={() => setCboDetailed(true)}
                sx={{ width: 24, height: 24 }}
              >
                <LinkIcon fontSize="12px" />
              </IconButton>
            </Stack>
          }
        >
          {patient.cbo && <Typography>{patient.cbo.name}</Typography>}
        </Property>
        <Property label="Pharmacy">{patient.pharmacy && <Typography>{patient.pharmacy.name}</Typography>}</Property>
      </Stack>
    </Tile>
  )
}

Details.Loading = function () {
  return (
    <Tile title="Details">
      <Stack spacing={2} sx={{ p: 2 }}>
        <Property label="Type">
          <Typography>
            <Skeleton width={80} />
          </Typography>
        </Property>
        <Property label="Created">
          <Typography>
            <Skeleton width={60} />
          </Typography>
        </Property>
        <Property label="Related Rx">
          <Typography>
            <Skeleton width={60} />
          </Typography>
        </Property>
        <Property label="Priority">
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Property>
        <Property label="Created By">
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Skeleton variant="rounded" width={24} height={24} />
            <Skeleton width={100} />
          </Stack>
        </Property>
        <Property label="Assignee">
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Skeleton variant="rounded" width={24} height={24} />
            <Skeleton width={100} />
          </Stack>
        </Property>
      </Stack>
    </Tile>
  )
}

CareTeam.Loading = function () {
  return (
    <Tile title="Care Team">
      <Stack spacing={2} sx={{ p: 2 }}>
        <Property label="Provider">
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Skeleton variant="rounded" width={24} height={24} />
            <Skeleton width={100} />
          </Stack>
        </Property>
        <Property label="CBO">
          <Typography>
            <Skeleton width={120} />
          </Typography>
        </Property>
        <Property label="Pharmacy">
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Property>
      </Stack>
    </Tile>
  )
}

function Loading() {
  return (
    <Stack spacing={3} sx={{ flexBasis: '400px' }}>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Skeleton width={130} height={36.5} variant="rounded" />
      </Box>
      <Details.Loading />
      <CareTeam.Loading />
    </Stack>
  )
}

function StatusSelector({ task, onChange, disabled = false, canExpire = false, canComplete = false }) {
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [expireConfirmationOpen, setExpireConfirmationOpen] = useState(false)
  const [doneConfirmationOpen, setDoneConfirmationOpen] = useState(false)
  const [doneReasonConfirmationOpen, setDoneReasonConfirmationOpen] = useState(false)

  const getReasons = useTaskDoneReasons()

  const handleChange = (value, reason) => {
    setOpenMenu(false)
    onChange(value, { reason })
  }

  const status = task.status

  if (disabled) {
    return (
      <Button
        variant="outlined"
        color={taskStatusToColor[status]}
        disableRipple
        disableTouchRipple
        sx={{ width: 130, justifyContent: 'flex-start', cursor: 'default' }}
      >
        {taskStatusToLabel[status]}
      </Button>
    )
  }

  return (
    <>
      <Button
        ref={anchorRef}
        variant={status === TaskStatus.Completed ? 'outlined' : 'contained'}
        color={taskStatusToColor[status]}
        onClick={() => setOpenMenu(true)}
        endIcon={<CaretDownOutlinedIcon />}
        sx={{ width: 130, justifyContent: 'space-between' }}
      >
        {taskStatusToLabel[status]}
      </Button>
      <Menu anchorEl={anchorRef.current} open={openMenu} onClose={() => setOpenMenu(false)}>
        <MenuItem selected={status === TaskStatus.ToDo} onClick={() => handleChange(TaskStatus.ToDo)}>
          To Do
        </MenuItem>
        <MenuItem selected={status === TaskStatus.InProgress} onClick={() => handleChange(TaskStatus.InProgress)}>
          In Progress
        </MenuItem>
        <MenuItem selected={status === TaskStatus.Waiting} onClick={() => handleChange(TaskStatus.Waiting)}>
          Waiting
        </MenuItem>
        {canComplete && (
          <MenuItem
            selected={status === TaskStatus.Completed}
            onClick={() => {
              if (hasDoneReasons(task.kind)) {
                setDoneReasonConfirmationOpen(true)
              } else {
                setDoneConfirmationOpen(true)
              }
            }}
          >
            Done
          </MenuItem>
        )}
        {canExpire && (
          <MenuItem selected={status === TaskStatus.Expired} onClick={() => setExpireConfirmationOpen(true)}>
            Expired
          </MenuItem>
        )}
      </Menu>
      {canComplete && (
        <CompleteConfirmation
          open={doneConfirmationOpen}
          onConfirm={() => {
            handleChange(TaskStatus.Completed)
            setDoneConfirmationOpen(false)
          }}
          onCancel={() => setDoneConfirmationOpen(false)}
        />
      )}
      {canComplete && (
        <DoneConfirmationWithReasons
          open={doneReasonConfirmationOpen}
          reasons={getReasons(task.kind)}
          onConfirm={(reason) => {
            handleChange(TaskStatus.Completed, reason)
            setDoneReasonConfirmationOpen(false)
          }}
          onCancel={() => setDoneReasonConfirmationOpen(false)}
        />
      )}
      {canExpire && (
        <ExpireConfirmation
          open={expireConfirmationOpen}
          onConfirm={(reason) => {
            handleChange(TaskStatus.Expired, reason)
            setExpireConfirmationOpen(false)
          }}
          onCancel={() => setExpireConfirmationOpen(false)}
        />
      )}
    </>
  )
}

const taskStatusToColor = {
  [TaskStatus.ToDo]: 'secondary',
  [TaskStatus.InProgress]: 'primary',
  [TaskStatus.Waiting]: 'warning',
  [TaskStatus.Completed]: 'secondary',
  [TaskStatus.Expired]: 'error',
}

TaskDetails.Loading = Loading
